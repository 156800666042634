import React, {useState, useEffect} from 'react'
import {
  Grid, Typography, Box,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import countryToCurrency from "country-to-currency";

import Container from "../../components/Container";
import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";
import FormCountrySelectBox from "../../components/FormCountrySelectBox";
import FormSelectBox from "../../components/FormSelectBox";
import AlertDialogBox from "../../components/AlertDialogBox";

import {connect} from "react-redux";
import {getRegisterData, registerUser, updateRegister} from "../../redux/actions/userDataActions";

import {THEME_COLOR} from "../../constants";

import {ReactComponent as EyeVisibleIcon} from "../../assets/img/eye.svg";
import {ReactComponent as EyeHiddenIcon} from "../../assets/img/eye_slash.svg";

const password_req = "1. Minimum Length: Passwords should typically be a minimum of 8 to 12 characters. Longer passwords are generally more secure. \n2. Complexity: Passwords should include a combination of: \nUppercase letters (A-Z) \nLowercase letters (a-z) \nNumbers (0-9) \nSpecial characters (e.g., !, @, #, $, %, etc.)";

const Register = (props) => {
  const [country, setCountry] = useState(null);
  const [typeOfEntity, setTypeOfEntity] = useState('');
  const [nameOfEntity, setNameOfEntity] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [error, setError] = useState(null);
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);
  const [confirmPasswordEyeVisible, setConfirmPasswordEyeVisible] = useState(false);

  const navigate = useNavigate();
  //
  // useEffect(() => {
  //   props.getRegisterData();
  // }, []);
  //
  // useEffect(() => {
  //   if (props.register) {
  //     props.updateRegister(false)
  //   }
  // }, [props.register])

  // useEffect(() => {
  //   if (props.apiStatus == "REGISTER_USER_SUCCESS") {
  //     props.updateApiStatus("");
  //   }
  // }, [props.apiStatus]);

  useEffect(() => {
    props.getRegisterData();
  }, []);

  const onSignInClick = () => {
    navigate('/login')
  };

  const onSignUp = () => {
    let errorText = {};
    let currency = '';
    if (country == null) {
      errorText = {country: 'Country is required'};
    } else {
      currency = countryToCurrency[country?.code];
      if(currency == '') {
        errorText = {...errorText, country: 'Currency not fetched'};
      }
    }

    if (typeOfEntity == '') {
      errorText = {...errorText, typeOfEntity: 'Type of Entity is required'};
    }
    if (nameOfEntity == '') {
      errorText = {...errorText, nameOfEntity: 'Name of Entity is required'};
    }
    if (emailAddress == '') {
      errorText = {...errorText, emailAddress: 'Email is required'};
    } else if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(emailAddress)) {
      errorText = {...errorText, emailAddress: 'Invalid Email'};
    }
    if (password == '') {
      errorText = {...errorText, password: 'Password is required'};
    } else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_ "])[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,12}$/.test(password)) {
      errorText = {...errorText, password: 'Password not secure'};
    }
    if (confirmPassword == '') {
      errorText = {...errorText, confirmPassword: 'Confirm Password is required'};
    }
    if (password != confirmPassword) {
      errorText = {...errorText, confirmPassword: 'Password not match'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      country: country?.code,
      currency: currency,
      typeOfEntity: typeOfEntity,
      nameOfEntity: nameOfEntity,
      emailAddress: emailAddress,
      password: password,
    };

    props.registerUser(JSON.stringify(data))
  };

  const onPasswordRequirement = () => {
    setPopUpVisible(true);
  };

  const onPopupCloseClick = () => {
    setPopUpVisible(false);
  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false);
    } else {
      setPasswordEyeVisible(true);
    }
  };

  const onConfirmPasswordEyeIconClick = () => {
    if (confirmPasswordEyeVisible) {
      setConfirmPasswordEyeVisible(false)
    } else {
      setConfirmPasswordEyeVisible(true)
    }
  };

  return (
    <Container page={"Register"} title={'My Account'} subTitle={'Register'} showHeaderData={'false'}>
      <Grid container item md={12} xs={12} display={"flex"} flexDirection={"column"}
            alignItems={"center"} justifyContent={"center"} mt={6}>
        <Typography sx={styles.registerText}>Register Your Account</Typography>
        <Grid container item md={3} xs={12} display={"flex"} flexDirection={"column"} alignItems={"center"}
              justifyContent={"center"}>

          <Grid item md={12} xs={12} container mt={1}
                sx={styles.textInputView}>
            <FormCountrySelectBox
              page={'SignUp'}
              type={"code"}
              value={country}
              setValue={setCountry}
              placeholder={'Select a Country'}
              textInputViewStyle={styles.textInputViewStyle}
              error={error?.country}
              required={"true"}
            />
          </Grid>

          <Grid item md={12} xs={12}
                sx={styles.textInputView} container>
            <FormSelectBox
              page={'SignUp'}
              value={typeOfEntity}
              setValue={setTypeOfEntity}
              data={props.getRegisterDataList?.entityTypeList ? props.getRegisterDataList.entityTypeList : []}
              placeholder={"Type of Entity"}
              error={error?.typeOfEntity}
              textInputViewStyle={styles.textInputViewStyle}
              selectedItemViewStyle={styles.selectedItemViewStyle}
              required={true}
            />
          </Grid>

          <Grid item md={12} xs={12}
                sx={styles.textInputView} container>
            <FormTextInput
              page={'SignUp'}
              value={nameOfEntity}
              setValue={setNameOfEntity}
              placeholder={'Name of Entity'}
              error={error?.nameOfEntity}
              textInputViewStyle={styles.textInputViewStyle}
              required={true}
            />
          </Grid>

          <Grid container item md={12} xs={12}
                sx={styles.textInputView}>
            <FormTextInput
              page={'SignUp'}
              value={emailAddress}
              setValue={setEmailAddress}
              type={"email"}
              placeholder={'E-mail Address'}
              error={error?.emailAddress}
              textInputViewStyle={styles.textInputViewStyle}
              required={true}
            />
          </Grid>

          <Grid item md={12} xs={12} container display={'flex'}
                flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1}>
            <Grid
              onClick={() => onPasswordRequirement()}
              container item md={12} xs={12} display={'flex'} justifyContent={'flex-end'}>
              <Typography sx={styles.passwordReqText}>Password Requirements</Typography>
            </Grid>
          </Grid>

          <Grid container item md={12} xs={12}
                sx={styles.textInputView}>
            <FormTextInput
              page={'SignUp'}
              value={password}
              setValue={setPassword}
              placeholder={'Password'}
              error={error?.password}
              textInputViewStyle={styles.textInputViewStyle}
              required={true}
              type={passwordEyeVisible ? 'text' : 'password'}
              icon={true}
              rightIcon={passwordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> : <EyeHiddenIcon height={15} width={15}/>}
              onIconClick={onPasswordEyeIconClick}
            />
          </Grid>

          <Grid container item md={12} xs={12}
                sx={styles.textInputView}>
            <FormTextInput
              page={'SignUp'}
              value={confirmPassword}
              setValue={setConfirmPassword}
              placeholder={'Re-enter Password'}
              error={error?.confirmPassword}
              textInputViewStyle={styles.textInputViewStyle}
              required={true}
              type={confirmPasswordEyeVisible ? 'text' : 'password'}
              icon={true}
              rightIcon={confirmPasswordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> : <EyeHiddenIcon height={15} width={15}/>}
              onIconClick={onConfirmPasswordEyeIconClick}
            />
          </Grid>

          <Grid container
                item md={12} xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                mt={4}>
            <FormButton
              loading={props.loginLoading}
              title={'Create your Trebbs Seller Account'}
              btnStyleView={styles.btnStyleView}
              onSubmit={onSignUp}
            />
          </Grid>


          <Box
            mt={5}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Typography style={styles.notMemberText}>ALREADY HAVE AN ACCOUNT?</Typography>
            <Typography ml={1} style={styles.signInText} onClick={() => onSignInClick()}>SIGN IN</Typography>
          </Box>

        </Grid>
      </Grid>

      <AlertDialogBox
        confirmBox={popUpVisible}
        title={'Password Requirements'}
        content={password_req}
        closeIcon={true}
        type={'passwordReq'}
        onCancelClick={onPopupCloseClick}
      />

      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    registerLoading: state.userData.registerLoading,
    getRegisterDataList: state.userData.getRegisterDataList,
    // register: state.userData.register,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    registerUser: data => dispatch(registerUser(data)),
    getRegisterData: data => dispatch(getRegisterData(data)),
    updateRegister: data => dispatch(updateRegister(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);

const styles = {
  registerText: {
    textAlign: 'center',
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 35
  },
  notMemberText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15
  },
  signInText: {
    cursor: 'pointer',
    color: THEME_COLOR,
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
  passwordReqText: {
    fontSize: 12,
    fontFamily: 'InterMedium',
    color: THEME_COLOR,
    cursor: 'pointer'
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
};

