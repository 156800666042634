import React, {useEffect, useRef} from 'react'
import {
  Grid, Typography, Box, Card, CardActions, CardContent, Checkbox, Divider,
} from '@mui/material';

import Container from "../../components/Container";

import styles from './PrivacyPolicy.module.css';

const information_provided_list = [
  {
    number: `a`,
    content: `you will be asked to provide certain contact information that is necessary to register
your account, including name, address, phone number and email address;`
  },
  {
    number: `b`,
    content: `you will be asked to provide certain information necessary to verify your business or
personal identity, such as: Trade License/Commercial Register, Tax Registration (if
applicable), Export Import License, Power of Attorney (if applicable) and any related
information about your business, such as your company name, business type and
industry; o if you are a Seller, we will ask you to provide details about the products that
you intend to sell, as well as your transactions on Trebbs.com;`
  },
  {
    number: `c`,
    content: `if you are a Seller, we will collect bank account details to transfer sales proceeds;`
  },
  {
    number: `d`,
    content: `if you are a Buyer and use the payment gateway, bank transfer or our escrow services to
pay for a transaction, we will collect certain payment information, such the bank
account number, credit/debit card numbers, expiration dates and security code;`
  },
  {
    number: `e`,
    content: `if you choose to use our Credit Offerings, we will collect certain Business and Financial
information such as Passport/Visa/EID of the Manager(s)/Owner(s), Trade License,
Memorandum of Association, Bank Statements, Financial Statements, Tax Registration
and Returns. Additionally, we will access 3rd Party Credit Reference Data Providers, such
as, but not limited to, Al Etihad Credit Bureau, selectively sharing your Business and
Financial Information in the process, after obtaining your consent to do so;`
  },
  {
    number: `f`,
    content: `for both Buyer and Seller, we will ask you to provide information on each transaction,
including the products purchased and transaction price;`
  },
  {
    number: `g`,
    content: `information provided to us in connection with disputes or to support complaints
regarding products purchased on Trebbs.com;`
  },
  {
    number: `h`,
    content: `information you submit to Trebbs.com for publication (for example, Seller reviews) will
be made publicly available.`
  },
];

const information_collected_list = [
  {
    number: `a`,
    content: `We will collect details of user activities and interactions on Trebbs.com, delivery
information, dispute and complaint records, communications between users and any
information disclosed in any discussion forum.`
  },
  {
    number: `b`,
    content: `We may collect information about the existing and prospective users, during or in
connection with trade shows and other industry events, including representative
contact name, company name, address, phone number, fax number and email address.`
  },
  {
    number: `c`,
    content: `If you visit Trebbs.com, we will automatically collect certain personal information from
your device. This information may include IP addresses, device type, unique device
identification numbers, browser type, broad geographic location (country or city-level),
browsing patterns and details of how you have interacted with Trebbs.com and the
products and services available on it. In addition, we gather statistical information about
Trebbs.com and visitors to Trebbs.com including browser software, operating system,
software and hardware attributes, pages viewed, number of sessions and unique
visitors. Collecting this information enables us to better understand the visitors to
Trebbs.com, where they come from and what content is of interest to them. We use this
information for our internal purposes, to improve the quality of Trebbs.com. Some of
this information may be collected using cookies`
  },
];


const use_of_personal_information_list = [
  {
    number: `a`,
    content: `verifying your identity and conducting security, KYC (know your customer) and antimoney laundering due diligence and background checks to set up user accounts; `
  },
  {
    number: `b`,
    content: `verifying your eligibility to register as a Trebbs.com user under our General User Terms
and Conditions`
  },
  {
    number: `c`,
    content: `setting up user accounts;`
  },
  {
    number: `d`,
    content: `providing users with customer service support;`
  },
  {
    number: `e`,
    content: `facilitating communication between Buyers and Sellers, processing transactions and
settlement between Buyers and Sellers, completing fund withdrawal requests from
Sellers;`
  },
  {
    number: `f`,
    content: `providing logistics, delivery and warehousing services (including customs clearance)`
  },
  {
    number: `g`,
    content: `assessing and monitoring account security and transaction risks;`
  },
  {
    number: `h`,
    content: `sharing data with our partners and third parties to facilitate any applicable service;`
  },
  {
    number: `i`,
    content: `personalizing marketing and communications with you based on your browsing records
and order history;`
  },
  {
    number: `j`,
    content: `sending Trebbs.com promotions via SMS, where applicable;`
  },
  {
    number: `k`,
    content: `performing research or statistical analysis to improve the content and layout of
Trebbs.com;`
  },
  {
    number: `l`,
    content: `identifying and developing marketing and advertising products and services that will add
value to you;`
  },
  {
    number: `m`,
    content: `if you are a Buyer, we may share details of the last viewed products on the Platform to
enable Sellers to contact you with details of products and/or services that might interest
you.`
  },
];

const to_whom_we_may_disclose_your_information_list = [
  {
    number: `a`,
    content: `Trebbs.com’s professional advisers, government and regulatory agencies, police and
insurers, when necessary to comply with applicable laws or investigations, or to
exercise, establish or defend Trebbs.com’s legal rights;`
  },
  {
    number: `b`,
    content: `An actual or potential buyer (and its agents and advisers) in connection with any actual
or proposed purchase, merger or acquisition of any part of our business;`
  },
  {
    number: `c`,
    content: `Other Trebbs.com users where necessary in connection with the facilitation of
purchases and communications made between you and the other users;`
  },
  {
    number: `d`,
    content: `Third-party business partners, service providers or affiliates engaged by or working with
Trebbs.com assist us to provide services to you or who otherwise process personal
information for purposes described in this Privacy Policy. These partners or service
providers include:`
  },
  {
    number: `e`,
    content: `our payment gateway provider;`
  },
  {
    number: `f`,
    content: `our banks in which bank transfers or escrow payments are being held;`
  },
  {
    number: `g`,
    content: `our finance partners;`
  },
  {
    number: `h`,
    content: `logistics partners for providing delivery, return and warehousing services for Buyers and
Sellers;`
  },
  {
    number: `i`,
    content: `custom agents for customs clearances purposes;`
  },
  {
    number: `j`,
    content: `cloud computing service providers to provide cloud storage services;`
  },
  {
    number: `k`,
    content: `customer service providers to provide after-sale services;`
  },
  {
    number: `l`,
    content: `our business partners (for example, for them to provide you with discounts or offers
that may interest you);`
  },
  {
    number: `m`,
    content: `marketing and advertising platforms, such as but not limited to Google, Twitter,
Facebook and Instagram, and providers of analytics services relating to users’ behavior,
to tailor the content you see when visiting our Platform.`
  },
  {
    number: `n`,
    content: `To any other person or business, with your consent to the disclosure.`
  },
]

const how_can_you_access_and_amend_your_information_list = [
  {
    number: `a`,
    content: `up-to-date information regarding recent orders;`
  },
  {
    number: `b`,
    content: `personally identifiable information (including name, e-mail, password, communications
and personalized advertising preferences);`
  },
  {
    number: `c`,
    content: `payment settings (including credit card information); and`
  },
  {
    number: `d`,
    content: `e-mail notification settings.`
  },
]

const PrivacyPolicy = (props) => {
  const privacyPolicyRef = useRef(null);

  useEffect(() => {
    if (privacyPolicyRef?.current) {
      privacyPolicyRef?.current?.scrollIntoView({behavior: "smooth"});
    }
  }, []);

  return (
    <Container page={"PrivacyPolicy"} title={'Privacy Policy'} subTitle={'Privacy Policy'} showHeaderData={'false'}>
      <Grid container item md={12} xs={12} display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={5} mb={6}
            ref={privacyPolicyRef}>
        <Grid container item md={8} xs={10} display={"flex"}
              alignItems={"center"}>

          <Grid item md={12} xs={12} mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography className={styles.mainTitleText}>Privacy Policy</Typography>
          </Grid>

          <Typography className={styles.contentText}>
            This Privacy Policy applies to the collection, use and disclosure of information in connection
            with the products and services offered through the Trebbs.com website and any mobile
            applications operated by TREBBS TRADE SOLUTIONS PRIVATE LIMITED, India and its affiliates
            (“Trebbs.com”).
          </Typography>

          <Typography className={styles.contentText} mt={2}>
            Trebbs.com may update this Privacy Policy at any time. We will only ask for your consent for
            any material Privacy Policy changes, if required by applicable data protection laws. Any changes
            to this Privacy Policy will be posted on this page. Once posted, the new Privacy Policy will be
            effective immediately
          </Typography>

          <Typography className={styles.contentText} mt={2}>
            We understand that you care how your information is used and shared. By visiting Trebbs.com,
            you are accepting the below conditions. If you have any questions or concerns about the use of
            your personal information, please Contact us.
          </Typography>

          <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}>
            <Box mt={3}>
              <Typography className={styles.titleText}>1. COLLECTION OF INFORMATION</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>The personal information that we may collect about you falls
                into the following
                categories:</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>1.1 Information you provide to Trebbs.com</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>If you are a representative of any Buyer or
                Seller:</Typography>
            </Box>

            <Box pl={3} mt={1}>
              {information_provided_list.map((item, index) => {
                return (
                  <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={1} key={index}>
                    <Typography className={styles.contentText}>({item?.number})</Typography>
                    <Typography pl={1} className={styles.contentText}>{item?.content}</Typography>
                  </Box>
                )
              })}
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>1.2 Information that Trebbs.com collects</Typography>
            </Box>

            <Box pl={3} mt={1}>
              {information_collected_list.map((item, index) => {
                return (
                  <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={1} key={index}>
                    <Typography className={styles.contentText}>({item?.number})</Typography>
                    <Typography pl={1} className={styles.contentText}>{item?.content}</Typography>
                  </Box>
                )
              })}
            </Box>
          </Grid>

          <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}>
            <Box mt={3}>
              <Typography className={styles.titleText}>2. USE OF PERSONAL INFORMATION</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>We collect and use your personal information for the following
                purposes:</Typography>
            </Box>

            <Box pl={3} mt={1}>
              {use_of_personal_information_list.map((item, index) => {
                return (
                  <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={1} key={index}>
                    <Typography className={styles.contentText}>({item?.number})</Typography>
                    <Typography pl={1} className={styles.contentText}>{item?.content}</Typography>
                  </Box>
                )
              })}
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>We may also use your personal information for other purposes
                that are permitted within the
                applicable privacy laws.</Typography>
            </Box>

          </Grid>

          <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}>
            <Box mt={3}>
              <Typography className={styles.titleText}>3. TO WHOM WE MAY DISCLOSE YOUR INFORMATION</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>Information about our customers is an important part of our
                business. We share your
                Information only as described below and with businesses that follow practices at least as
                protective as those described in this Privacy Policy: We may disclose (or provide access to)
                personal information to the following:</Typography>
            </Box>

            <Box pl={3} mt={1}>
              {to_whom_we_may_disclose_your_information_list.map((item, index) => {
                return (
                  <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={1} key={index}>
                    <Typography className={styles.contentText}>({item?.number})</Typography>
                    <Typography pl={1} className={styles.contentText}>{item?.content}</Typography>
                  </Box>
                )
              })}
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>We may provide aggregated or anonymized data to third parties,
                but when we do so, the
                information we share is in a de-identified format that does not personally identify you.</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                <span className={styles.titleText}>Protection of Our Platform and Others.</span> We release account and
                other Information when we
                believe such a release is appropriate to comply with the law and law enforcement
                investigations and to protect the rights, property or safety of our users or others. This includes
                exchanging information with other companies and organizations for various reasons, such as
                fraud protection and credit risk mitigation.
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                Note that our Platform may, from time to time, contain links to and from the websites of our
                partner networks, advertisers and affiliates. If you follow a link to any of these websites, please
                note that these websites have their own privacy policies and that we do not accept any
                responsibility or liability for these policies. Please check these policies before you submit any
                personal data or other information to these websites. We retain your personal information if
                we have a legitimate business need, or as required or permitted by applicable laws.
              </Typography>
            </Box>

          </Grid>

          <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}>
            <Box mt={3}>
              <Typography className={styles.titleText}>4. HOW WE STORE YOUR INFORMATION</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                The Information that we collect from you may be transferred to, and stored at, a destination
                outside of INDIA. It may also be processed by staff operating outside the INDIA who work for us
                or for one of our suppliers. Such staff may be engaged in, among other things, the fulfilment of
                your order, the processing of your payment details and the provision of support services. We
                will store your Information for as long as necessary to fulfil the purposes indicated in this
                Privacy Policy or as otherwise permitted or required by law.
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                Your Information may be transferred, stored, processed and used by our affiliated companies
                and/or non-affiliated service providers in one or more countries outside your originating
                country. Your payment details may be transferred to and stored with our affiliated companies
                to, among other things, process your payment details and provide support services to you.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}>
            <Box mt={3}>
              <Typography className={styles.titleText}>5. WHAT SECURITY MEASURES WE APPLY</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                We maintain commercially reasonable technical, administrative, and physical safeguards to
                ensure your Information is treated securely and in accordance with this Privacy Policy, and to
                protect against unauthorized access or alteration to, disclosure, or destruction of your
                Information. We may, for example, use encryption technology to secure your Information
                during transmission to our Platform as well as external firewall and on-host firewall technology
                to prevent network level attacks. Only those authorized employees, contractors, and agents
                who need to know your Information in connection with the performance of their services are
                allowed to access this Information. It is important for you to protect yourself against
                unauthorized access to your password and to your devices used to access our Services.
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                You are responsible for keeping your password confidential. For example, ensure that you sign
                off when you have finished using a shared device. Unfortunately, the transmission of
                information via the internet is not completely secure. Although we will do our best to protect
                your Information, we cannot guarantee the security of your Information transmitted to our
                Platform and any transmission is at your own risk.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}>
            <Box mt={3}>
              <Typography className={styles.titleText}>6. HOW CAN YOU ACCESS AND AMEND YOUR INFORMATION?</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                You can access a broad range of information about your account and your interactions with the
                Platform for the purpose of viewing and, in certain cases, updating your Information. Examples
                of information you can access easily at the Platform include:
              </Typography>
            </Box>

            <Box pl={3} mt={1}>
              {how_can_you_access_and_amend_your_information_list.map((item, index) => {
                return (
                  <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={1} key={index}>
                    <Typography className={styles.contentText}>({item?.number})</Typography>
                    <Typography pl={1} className={styles.contentText}>{item?.content}</Typography>
                  </Box>
                )
              })}
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                You can opt-out of receiving future marketing communications from us at any time by adjusting
                your customer communication preferences, through the unsubscribe link within the email
                communication.
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                If you wish us to erase your personal data from our systems, please contact us with your
                request, providing accurate, reliable information to identify yourself (such that we can
                complete your request) and we will act accordingly, within the parameters of the applicable
                legislation and this Policy. Kindly bear in mind that a data erasure request will mean that you
                will no longer have access to some, or all the services provided by Trebbs.com. For marketing
                via your mobile application, you will need to adjust your notifications settings in the general
                section of your mobile.
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                Also, our system will place cookies when you log on to our Platform and this is to ensure you
                have an enjoyable user experience and are able to utilize all aspects of the Platform. You may
                disable Cookies by changing the settings on your browser. If you disable Cookies, it will affect
                how our Platform works, and you may not be able to access or use certain areas of our Platform
                or full functionality. For example, performance cookies collect information about how you use
                the Site, for instance, which pages you visit most often, which allows us to provide you with
                targeted and relevant choices that enhance your Site experience.
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                We may retain a copy of your Information for compliance reasons. When you update
                Information, we may retain a copy of the prior version for our records.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}>
            <Box mt={3}>
              <Typography className={styles.titleText}>7. WHAT IF WE CHANGE OUR PRIVACY POLICY?</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                Our business changes constantly, and our Privacy Policy may therefore also need to change. We
                will post the current version of this Privacy Policy on the Platform and each such change will be
                effective upon posting on the Platform or upon the date designated by us as the "effective
                date". We may e-mail periodic reminders of our notices and conditions, but you should check
                our Platform frequently to see recent changes.
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.titleText}>
                It is your obligation to regularly check the Privacy Policy. Your continued use of the Platform
                following any such change constitutes your agreement to this Privacy Policy as so modified.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}>
            <Box mt={3}>
              <Typography className={styles.titleText}>8. HOW YOU CAN CONTACT US</Typography>
            </Box>

            <Box mt={2}>
              <Typography className={styles.contentText}>
                If you have any concerns about your Information on the Platform, please email to:
                legal@trebbs.com with a thorough description, and we will try to resolve it.
              </Typography>
            </Box>
          </Grid>

        </Grid>
      </Grid>

    </Container>
  )
}

export default PrivacyPolicy
