import React from 'react';
import {Grid, Typography, Box} from '@mui/material';

import {THEME_COLOR} from "../constants";

const ItemCategoryTabList = (props) => {
  let item = props.data;

  const onTabSelect = () => {
    props.onSelect(item);
  };

  return (
    <Grid container display={"flex"} flexDirection={"row"} justifyContent={"center"}>
      <Box onClick={onTabSelect} mr={1} ml={1}
           sx={[styles.tabView, props.selected?.['cat_id'] == item?.cat_id ? styles.bottomLine : '']}>
        <Typography
          sx={[styles.tabText, props.selected?.['cat_id'] == item?.cat_id ? styles.selectedColor : styles.nonSelectedColor]}>{item?.cat_name}</Typography>
      </Box>
      {props.index != props.length ?
        <Box style={styles.borderLine}>|</Box>
        : null}
    </Grid>
  )
};

export default ItemCategoryTabList;

const styles = {
  tabView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingBottom: 1,
    minWidth: {md: 200, xs: 100},
  },
  bottomLine: {
    borderBottom: '1px solid ' + THEME_COLOR,
  },
  tabText: {
    fontFamily: "InterBold",
    fontSize: {md: 16, xs: 13},
  },
  selectedColor: {
    color: THEME_COLOR,
  },
  nonSelectedColor: {
    color: '#000',
  },
  borderLine: {
    color: '#BBB'
  },
};
