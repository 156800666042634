import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
  Grid,
  Box,
  Typography
} from '@mui/material';

import Container from "../../components/Container";

import {THEME_COLOR3} from '../../constants'

const Verification = (props) => {

  return (
    <Container page={"Verification"} title={'Account Verification'} subTitle={'Verification'}  showHeaderData={'false'}>
      <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"}
            alignItems={'flex-start'}
            justifyContent={"center"} mt={6} sx={styles.mainView}>
        {/*<Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}*/}
        {/*      justifyContent={"center"}>*/}

        <Grid container item md={5} xs={5}>
          <Grid item md={11} xs={11} mb={2} sx={styles.boxView} pt={1} pb={1} px={2}>
            <Typography sx={styles.titleText}>Account under verification !!!</Typography>
          </Grid>

          <Typography sx={styles.descText}>Dear buyer your account has been created and submitted for
            verification. once the verification is completed and get approved you will be directed to the account.
          </Typography>
        </Grid>

        <Box sx={styles.borderLineView} pr={2} pl={2}/>

        <Grid container item md={5} xs={5}>
          <Typography sx={styles.descSubText}>If you have any query regarding the verification process
            please <a style={styles.supportText}> contact
              support team. </a></Typography>
        </Grid>

        {/*</Grid>*/}
      </Grid>
    </Container>
  )
};

export default Verification;

const styles = {
  mainView: {
    height: 500
  },
  boxView: {
    border: '1px solid #ccc'
  },
  titleText: {
    color: '#55AC10',
    fontFamily: 'InterBold',
    fontSize: 20
  },
  descText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  borderLineView: {
    borderLeft: '1px solid #ccc',
    height: 500
  },
  descSubText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  supportText: {
    color: THEME_COLOR3,
    fontSize: 14,
    fontFamily: 'InterMedium',
    textDecoration: 'none'
  }
};
