import React, {useEffect, useState} from 'react'
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import OrderTab from "../../components/OrderTab";

import {connect} from "react-redux";
import {
  getCurrencyRateList,
  getOrderCount
} from "../../redux/actions/userDataActions";

import styles from "./Orders.module.css";

import {THEME_COLOR3, THEME_COLOR4} from '../../constants'
import OrderTabList from "../../components/OrderTabList";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Orders = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    props?.getCurrencyRateList()
  },[])

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let order_status = params.get('status');
    if (order_status) {
      if(order_status == 'pending') {
        setValue(0)
      } else if(order_status == 'unshipped') {
        setValue(1)
      } else if(order_status == 'shipped') {
        setValue(2)
      } else if(order_status == 'delivered') {
        setValue(3)
      } else if(order_status == 'cancelled') {
        setValue(4)
      }
    }
  },[])

  useEffect(() => {
    if (props?.orderMessage != null) {
      if(props?.orderMessage?.type == 'order_cancelled') {
        setValue(4)
      }
    }
  }, [props?.orderMessage]);

  useEffect(() => {
    props.getOrderCount()
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container page={"Order"} title={'Order'} subTitle={'Order'}>

      <Grid container item md={12} xs={12} mt={2}
            display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Grid item md={11.6} xs={11.6} className={styles.borderView} px={2} pt={2}>
          <Typography className={styles.manageOrderText} mb={2}>Manage Orders</Typography>
          <Tabs scrollButtons="on" variant="scrollable" value={value} onChange={handleChange}
                sx={{
                  "& .MuiTab-root": {
                    color: THEME_COLOR3,
                    fontSize: 15,
                    fontFamily: 'InterBold',
                    textTransform: 'capitalize',
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: THEME_COLOR4,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: THEME_COLOR4,
                    fontSize: 15,
                    fontFamily: 'InterBold',
                    textTransform: 'capitalize'
                  }
                }}
                aria-label="basic tabs example">
            <Tab label={props?.pendingOrderCount + ' Pending'} {...a11yProps(0)} />
            <Tab label={props?.unshippedOrderCount + ' Unshipped'} {...a11yProps(1)} />
            <Tab label={props?.shippedOrderCount + ' Shipped'} {...a11yProps(2)} />
            <Tab label={props?.deliveredOrderCount + ' Delivered'} {...a11yProps(3)} />
            <Tab label={props?.cancelledOrderCount + ' Cancelled'} {...a11yProps(4)} />
          </Tabs>
        </Grid>
        {/*</Grid>*/}

        <Grid item md={11.6} xs={11.6}>
          <TabPanel value={value} index={0}>
            <OrderTab
              tab={'pending'}
              currencyRateList={props?.currencyRateList}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <OrderTab
              tab={'unshipped'}
              currencyRateList={props?.currencyRateList}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <OrderTab
              tab={'shipped'}
              currencyRateList={props?.currencyRateList}
            />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <OrderTab
              tab={'delivered'}
              currencyRateList={props?.currencyRateList}
            />
          </TabPanel>

          <TabPanel value={value} index={4}>
            <OrderTab
              tab={'cancelled'}
              currencyRateList={props?.currencyRateList}
            />
          </TabPanel>
        </Grid>
      </Grid>
      <AlertMsg/>

    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    pendingOrderCount: state.userData.pendingOrderCount,
    unshippedOrderCount: state.userData.unshippedOrderCount,
    shippedOrderCount: state.userData.shippedOrderCount,
    deliveredOrderCount: state.userData.deliveredOrderCount,
    cancelledOrderCount: state.userData.cancelledOrderCount,
    orderMessage: state.userData.orderMessage,
    currencyRateList: state.userData.currencyRateList,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderCount: data => dispatch(getOrderCount(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
