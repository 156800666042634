import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";

import {connect} from "react-redux";
import {updatePassword, updateUserName} from "../redux/actions/userDataActions";

import styles from "./AccountDetails.module.css";

const AccountDetails = (props) => {
  const [username, setUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.userName) {
      setUsername(props.userName);
    }
  }, [props.userName]);

  const onUsernameSaveClick = () => {
    let errorText = {};
    if (username == '') {
      errorText = {username: 'Username is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      username: username,
    };

    props.updateUserName(JSON.stringify(data))
  };

  const onChangePasswordClick = () => {
    let errorText = {};
    if (currentPassword == '') {
      errorText = {currentPassword: 'Current Password is required'};
    }
    if (newPassword == '') {
      errorText = {...errorText, newPassword: 'New Password is required'};
    } else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_ "])[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,12}$/.test(newPassword)) {
      errorText = {...errorText, newPassword: 'New Password not secure'};
    }
    if (confirmPassword == '') {
      errorText = {...errorText, confirmPassword: 'Confirm Password is required'};
    }
    if (newPassword != confirmPassword) {
      errorText = {...errorText, confirmPassword: 'Password not match'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    props.updatePassword(JSON.stringify(data))
  };


  return (
    <Grid item md={12} xs={12}>
      <Grid className={styles.descBox}>
        <Typography className={styles.descText}>The following addresses will be used on the checkout page by
          default.</Typography>
      </Grid>

      <Grid item md={12} xs={12} mt={4} className={styles.mainTitleBox}
            display={"flex"} justifyContent={"center"} flexDirection={"column"} p={5}>

        <Grid item md={12} xs={12} className={styles.titleView} px={1}>
          <Typography className={styles.titleText}>USERNAME CHANGE</Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <FormTextInput
            page={'AccountDetails'}
            value={username}
            setValue={setUsername}
            label={'Username'}
            error={error?.username}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
          />
        </Grid>

      </Grid>

      <Grid item md={12} xs={12}
            display="flex" flexDirection="row" justifyContent="right" mt={4}>
        <FormButton
          btnStyleView={{height: 50}}
          title={'SAVE CHANGES'}
          onSubmit={onUsernameSaveClick}
        />
      </Grid>


      <Grid item md={12} xs={12} mt={6} className={styles.mainTitleBox}
            display={"flex"} justifyContent={"center"} flexDirection={"column"} p={5}>

        <Grid item md={12} xs={12} className={styles.titleView} px={1}>
          <Typography className={styles.titleText}>PASSWORD CHANGE</Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <FormTextInput
            page={'AccountDetails'}
            value={currentPassword}
            setValue={setCurrentPassword}
            label={'Current Password'}
            error={error?.currentPassword}
            type={'password'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
          />
        </Grid>

        <Grid item md={12} xs={12} mt={3}>
          <FormTextInput
            page={'AccountDetails'}
            value={newPassword}
            setValue={setNewPassword}
            label={'New Password'}
            error={error?.newPassword}
            type={'password'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
          />
        </Grid>

        <Grid item md={12} xs={12} mt={3}>
          <FormTextInput
            page={'AccountDetails'}
            value={confirmPassword}
            setValue={setConfirmPassword}
            label={'Confirm Password'}
            error={error?.confirmPassword}
            type={'password'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
          />
        </Grid>
      </Grid>

      <Grid item md={12} xs={12}
            display="flex" flexDirection="row" justifyContent="right" mt={4}>
        <FormButton
          btnStyleView={{height: 50}}
          title={'SAVE CHANGES'}
          onSubmit={onChangePasswordClick}
        />
      </Grid>

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    userName: state.userData.userName,
  }
};

const matchDispatchToProps = (dispatch) => {
  return {
    updatePassword: data => dispatch(updatePassword(data)),
    updateUserName: data => dispatch(updateUserName(data))
  }
};

export default connect(mapStateToProps, matchDispatchToProps)(AccountDetails);
