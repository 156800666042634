import React, {useEffect, useState} from 'react'
import {
  Box, CircularProgress,
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography
} from '@mui/material';
import {useNavigate, useLocation} from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import AdminBeneficiaryDetails from "../../components/AdminBeneficiaryDetails";
import UploadPaymentDetails from "../../components/UploadPaymentDetails";
import OrderPaymentList from "../../components/OrderPaymentList";

import {getCurrencyConversionCalc, priceCalculation} from "../../assets/functions/common";

import styles from "./OrderPayment.module.css";

import {THEME_COLOR3} from "../../constants";

import {connect} from "react-redux";
import {getCurrencyRateList, getOrderDetail} from "../../redux/actions/userDataActions";

import {COUNTRIES} from "../../constants/countries";

const OrderPayment = (props) => {
  const [orderUid, setOrderUid] = useState('')
  const [orderDetail, setOrderDetail] = useState('')
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingCostPerPackage, setShippingCostPerPackage] = useState(0);
  const [shippingCostPerKg, setShippingCostPerKg] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [totalCargoPrice, setTotalCargoPrice] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [sellerPrice, setSellerPrice] = useState(0);
  const [sellerPricePerKg, setSellerPricePerKg] = useState(0);
  const [sellerTotalPrice, setSellerTotalPrice] = useState(0);
  const [sellerShippingCost, setSellerShippingCost] = useState('')
  const [sellerShippingCostPerPackage, setSellerShippingCostPerPackage] = useState(0);
  const [sellerShippingCostPerKg, setSellerShippingCostPerKg] = useState(0);
  const [sellerCargoPrice, setSellerCargoPrice] = useState(0);
  const [sellerTotalCargoPrice, setSellerTotalCargoPrice] = useState(0);
  const [sellerCurrencySymbol, setSellerCurrencySymbol] = useState('')
  const [orderStatus, setOrderStatus] = useState('')

  const [halfPaymentDate, setHalfPaymentDate] = useState('');
  const [pageType, setPageType] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    props?.getCurrencyRateList()
  }, [])

  useEffect(() => {
    var str = location.pathname;
    let page_type = str.split("/").pop();

    let order_uid = '';
    if (page_type == 'statement') {
      setPageType('statement');
      var arr = str.split('/');
      order_uid = arr[arr.length - 2];
    } else {
      setPageType('payment');
      order_uid = str.split("/").pop()
    }
    setOrderUid(order_uid);

    let data = {
      orderUid: order_uid,
    };
    props.getOrderDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    if (props?.orderDetail != null && pageType) {


      if (pageType == 'payment') {
        if ((props?.orderDetail?.order_status == 'pending' && props?.orderDetail?.payment_status == 'pending')
          || (props?.orderDetail?.order_status == 'unshipped' && props?.orderDetail?.payment_status == 'half_paid')) {

        } else {
          navigate('/order')
        }
      }

      setOrderDetail(props.orderDetail)

      let order_data = props.orderDetail;

      let user_currency_symbol = '';
      let converted_price = 0;
      let converted_price_per_kg = 0;
      let converted_total_price = 0;
      let converted_shipping_cost = 0;
      let converted_shipping_cost_per_package = 0;
      let converted_shipping_cost_per_kg = 0;
      let converted_cargo_price = 0;
      let converted_total_cargo_price = 0;
      let seller_currency_symbol = '';
      let seller_price = 0;
      let seller_price_per_kg = 0;
      let seller_total_price = 0;
      let seller_shipping_cost = 0;
      let seller_shipping_cost_per_package = 0;
      let seller_shipping_cost_per_kg = 0;
      let seller_cargo_price = 0;
      let seller_total_cargo_price = 0;

      let data_list = {
        price_per_package: order_data?.price_per_package,
        package_net_weight: order_data?.package_net_weight,
        referral_fee: order_data?.referral_fee,
        quantity: order_data?.quantity,
        shipping_cost: order_data?.shipping_cost,
        seller_currency: order_data?.seller_currency,
        user_currency: order_data?.user_currency,
        main_currency: order_data?.main_currency,
        rate: order_data?.rate,
        usd_rate: order_data?.usd_rate
      }

      let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
      if (data != null) {
        user_currency_symbol = data?.user_currency_symbol;
        converted_price = data?.converted_price;
        converted_price_per_kg = data?.converted_price_per_kg;
        converted_total_price = data?.converted_total_price;
        converted_shipping_cost = data?.converted_shipping_cost;
        converted_shipping_cost_per_package = data?.converted_shipping_cost_per_package;
        converted_shipping_cost_per_kg = data?.converted_shipping_cost_per_kg;
        converted_cargo_price = data?.converted_cargo_price;
        converted_total_cargo_price = data?.converted_cargo_price;
        seller_currency_symbol = data?.seller_currency_symbol;
        seller_price = data?.seller_price;
        seller_price_per_kg = data?.seller_price_per_kg;
        seller_total_price = data?.seller_total_price;
        seller_shipping_cost = data?.seller_shipping_cost;
        seller_shipping_cost_per_package = data?.shipping_cost_per_package;
        seller_shipping_cost_per_kg = data?.shipping_cost_per_kg;
        seller_cargo_price = data?.seller_cargo_price;
        seller_total_cargo_price = data?.seller_cargo_price;
      }

      setPrice(converted_price);
      setTotalPrice(converted_total_price);
      setPricePerKg(converted_price_per_kg);
      setCargoPrice(converted_cargo_price)
      setShippingCost(converted_shipping_cost)
      setShippingCostPerPackage(converted_shipping_cost_per_package)
      setShippingCostPerKg(converted_shipping_cost_per_kg)
      setCurrencySymbol(user_currency_symbol)

      setSellerPrice(seller_price);
      setSellerTotalPrice(seller_total_price)
      setSellerPricePerKg(seller_price_per_kg);
      setSellerCargoPrice(seller_cargo_price)
      setSellerShippingCost(seller_shipping_cost)
      setSellerShippingCostPerPackage(seller_shipping_cost_per_package)
      setSellerShippingCostPerKg(seller_shipping_cost_per_kg)
      setSellerCurrencySymbol(seller_currency_symbol)

      if (pageType == 'payment') {
        if (order_data?.payment_term_amount == 50) {
          converted_total_cargo_price = (converted_cargo_price * (50 / 100))
          seller_total_cargo_price = (seller_cargo_price * (50 / 100))
        }
      }

      setTotalCargoPrice(converted_total_cargo_price)
      setSellerTotalCargoPrice(seller_total_cargo_price)

      if (props?.orderDetail?.order_payment_list?.length > 0) {
        let list = [...props?.orderDetail?.order_payment_list];
        let index = list?.findIndex((x) => x.type == 'half_paid')
        if (index >= 0) {
          let half_payment_date = list[index]['payment_date'];
          setHalfPaymentDate(moment(half_payment_date).format('DD/MM/YYYY'));
        }
      }

      if (props?.orderDetail?.order_status == 'unshipped') {
        if (props?.orderDetail?.payment_status == 'paid') {
          setOrderStatus('Pickup Scheduled');
        } else {
          setOrderStatus('Confirmed')
        }
      } else {
        setOrderStatus(orderDetail?.order_status)
      }

    }
  }, [props.orderDetail, pageType, props?.currencyRateList])

  if (props.orderLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Container page={"OrderPayment"} title={pageType == 'payment' ? 'Order Payment' : 'Payment Statement'}
               subTitle={pageType == 'payment' ? 'Order Payment' : 'Payment Statement'}>

      <>
        <Grid item md={12} xs={12}
              display={"flex"}
              alignItems={"center"} justifyContent={"center"}
        >
          <Grid container item md={11.6} xs={11.6} mt={3}
                display={"flex"} alignItems={"center"} justifyContent={"center"}>

            <Grid item md={12} xs={12}
                  container mb={3}>
              <Typography className={styles.mainLabelText}>{'Order Details'}</Typography>
            </Grid>

            <Grid item md={12} xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography className={styles.labelText}>Order ID</Typography>
                        <Typography className={styles.subLabelText}
                                    ml={1}>{orderStatus?.replaceAll("_", " ")}</Typography>
                      </TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.labelText}>Order Date</Typography>
                      </TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.labelText}>Product Details</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.labelText}>Delivery Address</Typography>
                      </TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.labelText}>Shipping Details</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.labelText}>Payment</Typography>
                        <Typography className={styles.subLabelText} ml={1}>
                          {orderDetail?.payment_status == 'pending' ? 'Pending' :
                            (orderDetail?.payment_status == 'half_paid' ? '50% Paid' :
                              orderDetail?.payment_status)}
                        </Typography>
                      </TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.labelText}>Total Product Price</Typography>
                        <Typography className={styles.subLabelText}>EXW</Typography>
                      </TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.labelText}>Total Shipping Cost</Typography>
                        <Typography className={styles.subLabelText}>({orderDetail?.incoterm})</Typography>
                      </TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.labelText}>Total Cargo Cost</Typography>
                        <Typography className={styles.subLabelText}>({orderDetail?.incoterm})</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableCell>
                      <Typography className={styles.productNameText}>#{orderDetail?.order_uid}</Typography>
                    </TableCell>
                    <TableCell className={styles.dataView}>
                      <Typography className={styles.productNameText}>{orderDetail?.order_timestamp}</Typography>
                    </TableCell>
                    <TableCell className={styles.dataView}>
                      {orderDetail?.main_image ?
                        <Grid item md={4} xs={12} className={styles.imgView}>
                          <img src={orderDetail?.main_image} className={styles.imgStyle}/>
                        </Grid>
                        : null}
                      <Typography className={styles.productNameTitleText}>
                        {(orderDetail?.variety ? orderDetail.variety : 'NIL Variety')
                        + ', ' + (orderDetail?.name ? orderDetail.name : 'NIL')
                        + ', ' + (orderDetail?.variety_size ? orderDetail?.variety_size : 'NIL Size')
                        + ', ' + (orderDetail?.package_net_weight ?
                          (orderDetail?.package_net_weight + orderDetail?.unit + ' ' + orderDetail?.packing_material)
                          : 'NIL Packing')
                        + ', ' + (orderDetail?.pickup_location ? orderDetail?.pickup_location : 'NIL Location')}
                      </Typography>
                      <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        (<Typography className={styles.subPricePerKgText}>{currencySymbol}{pricePerKg?.toFixed(2)} /
                        Kg</Typography>
                        <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
                      </Box>
                      <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        (<Typography className={styles.subPricePerKgText}>{currencySymbol}{price?.toFixed(2)} / Package</Typography>
                        <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
                      </Box>
                      <Typography className={styles.hsCodeText} mt={1}>HS
                        Code: {orderDetail?.hs_code ? orderDetail.hs_code : ''}</Typography>
                      <Typography
                        className={styles.nameEntityText}>Seller: {orderDetail?.seller_name_entity ? orderDetail?.seller_name_entity : ''}</Typography>
                      <Typography
                        className={styles.productNameText}>{orderDetail?.seller_country ? '(' + COUNTRIES.filter(
                        (x) => x.code == orderDetail?.seller_country
                      )?.[0]?.label + ')' : ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={styles.deliveryAddressText}>
                        {orderDetail?.state_province ?
                          (orderDetail?.address_line_1
                            + ',' + orderDetail?.address_line_2
                            + ',' + orderDetail?.city
                            + ',' + orderDetail?.state_province
                            + ',' + orderDetail?.zip_code
                          )
                          : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.dataView}>
                      <Typography className={styles.shippingDetailText}>
                        Total number of packages : {orderDetail?.quantity ? orderDetail?.quantity : 0}
                      </Typography>
                      <Typography className={styles.shippingDetailText} mt={0.7}>
                        Total product quantity
                        : {orderDetail?.package_net_weight ? (orderDetail?.quantity * orderDetail?.package_net_weight + (orderDetail?.unit ? orderDetail.unit : '')) : 0}
                      </Typography>
                      <Typography className={styles.shippingDetailText} mt={0.7}>
                        Shipping method : {orderDetail?.mode_of_shipping ? orderDetail?.mode_of_shipping : '-'}
                      </Typography>
                      <Typography className={styles.shippingDetailText} mt={0.7}>
                        Container type : {orderDetail?.container_type ? orderDetail?.container_type : '-'}
                      </Typography>
                      <Typography className={styles.shippingDetailText} mt={0.7}>
                        Shipping date
                        : {orderDetail?.shipping_date ? moment(orderDetail?.shipping_date).format('DD-MM-YYYY') : '-'}
                      </Typography>
                      {orderDetail?.delivery_date ?
                        <Typography className={styles.shippingDetailText} mt={0.7}>
                          Delivery date
                          : {orderDetail?.delivery_date ? moment(orderDetail?.delivery_date).format('DD-MM-YYYY') : '-'}
                        </Typography>
                        : null}
                      {orderDetail?.carrier ?
                        <Typography className={styles.shippingDetailText} mt={0.7}>
                          Carrier: {orderDetail?.carrier ? orderDetail?.carrier : '-'}
                        </Typography>
                        : null}
                      {orderDetail?.loading_port ?
                        <Typography className={styles.shippingDetailText} mt={0.7}>
                          Port of loading: {orderDetail?.loading_port ? orderDetail?.loading_port : '-'}
                        </Typography>
                        : null}
                      {orderDetail?.discharge_port ?
                        <Typography className={styles.shippingDetailText} mt={0.7}>
                          Port of discharge: {orderDetail?.discharge_port ? orderDetail?.discharge_port : '-'}
                        </Typography>
                        : null}
                    </TableCell>
                    <TableCell>
                      {orderDetail?.payment_term ?
                        <>
                          <Typography
                            className={styles.paymentTermText}>{orderDetail?.payment_term}</Typography>
                        </>
                        : '-'}
                    </TableCell>
                    <TableCell className={styles.dataView}>
                      {sellerTotalPrice ?
                        <Box>
                          <Typography
                            className={styles.sellerSubPriceText}>
                            {sellerCurrencySymbol} {sellerTotalPrice?.toFixed(2)}
                          </Typography>
                          <Typography className={styles.sellerSubPricePerKgText}
                                      pl={1}>{sellerCurrencySymbol}{(parseFloat(sellerCargoPrice) / parseFloat(orderDetail?.package_net_weight * orderDetail?.quantity))?.toFixed(7)} /
                            kg</Typography>
                          <Typography className={styles.sellerSubPricePerKgText}
                                      pl={1}>{sellerCurrencySymbol}{(parseFloat(sellerCargoPrice) / parseFloat(orderDetail?.quantity))?.toFixed(6)} /
                            package</Typography>
                        </Box>
                        : null}
                      <Box pl={3} mt={1}>
                        <Typography
                          className={styles.subPriceText}>
                          ({currencySymbol} {totalPrice?.toFixed(2)})
                        </Typography>
                        <Typography pl={1} className={styles.subPricePerKgText}>
                          ({currencySymbol}{(parseFloat(cargoPrice) / parseFloat(orderDetail?.package_net_weight * orderDetail?.quantity))?.toFixed(7)} /
                          kg)</Typography>
                        <Typography pl={1} className={styles.subPricePerKgText}>
                          ({currencySymbol}{(parseFloat(cargoPrice) / parseFloat(orderDetail?.quantity))?.toFixed(6)} /
                          package)</Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={styles.dataView}>
                      {sellerShippingCost ?
                        <Typography className={styles.sellerCalculationPendingText}>
                          {sellerCurrencySymbol} {sellerShippingCost ? sellerShippingCost?.toFixed(2) : '0'}
                        </Typography>
                        : null}
                      <Typography className={styles.calculationPendingText} pl={3}>
                        ({currencySymbol} {shippingCost ? shippingCost?.toFixed(2) : '0'})
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.dataView}>
                      {sellerCargoPrice ?
                        <Typography
                          className={styles.sellerSubPriceText}>
                          {sellerCurrencySymbol} {sellerCargoPrice?.toFixed(2)}
                        </Typography>
                        : null}
                      <Typography pl={3}
                                  className={styles.subPriceText}>
                        ({currencySymbol} {cargoPrice?.toFixed(2)})
                      </Typography>
                    </TableCell>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>

        {orderDetail != null && (pageType == 'payment') ?
          orderDetail?.payment_term_amount == 50 ?
            <>
              <Grid item md={12} xs={12}
                    display={"flex"}
                    alignItems={"center"} justifyContent={"center"}
                    mt={4}
              >
                <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine}/>
              </Grid>

              <Grid item md={12} xs={12}
                    display={"flex"}
                    alignItems={"center"} justifyContent={"center"}
                    mt={4}>
                <Grid item md={11.6} xs={11.6} display={"flex"} flexDirection={'row'} alignItems={"center"}
                      className={styles.paymentMainDescView} p={2}>
                  {orderDetail?.payment_status == 'pending' ?
                    <Typography className={styles.paymentMainDescText}>Since you have opted for 50% Advance Payment &
                      50%
                      before shipping the amount to be paid to place this order
                      has been calculated below. Please visit Payment Manager to pay the remaining amount before
                      shipping</Typography>
                    : (orderDetail?.payment_status == 'half_paid' ?
                      <Typography className={styles.paymentMainDescText}>Since you have opted for 50% Advance payment &
                        50% before shipping. 50% of the amount has been paid on {halfPaymentDate}. please pay the
                        remaining 50%
                        before shipping the product.</Typography> : null)
                  }
                </Grid>
              </Grid>
            </>
            : null
          : null}


        {orderDetail != null && pageType == 'statement' ?
          <>
            <Grid item md={12} xs={12}
                  display={"flex"}
                  alignItems={"center"} justifyContent={"center"}
                  mt={4}
            >
              <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine}/>
            </Grid>

            <Grid item md={12} xs={12}
                  display={"flex"}
                  alignItems={"center"} justifyContent={"center"}
                  mt={4}>
              <Grid item md={11.6} xs={11.6} display={"flex"} flexDirection={'row'} alignItems={"center"}
                    justifyContent={"flex-end"}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <Box>
                    <Typography className={styles.totalPriceLabelText}>Sub Total</Typography>
                  </Box>
                </Box>
                <Box pl={4}>

                  {sellerTotalCargoPrice ?
                    <Typography className={styles.sellerTotalPriceText}>
                      {sellerCurrencySymbol}{sellerTotalCargoPrice?.toFixed(2)}
                    </Typography>
                    : null}
                  <Typography className={styles.totalPriceText} pl={3}>
                    ({currencySymbol}{totalCargoPrice?.toFixed(2)})
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
          : null}

        {orderDetail != null ?
          orderDetail?.order_payment_list?.length > 0 ?
            <>
              <Grid item md={12} xs={12}
                    display={"flex"}
                    alignItems={"center"} justifyContent={"center"}
                    mt={4}
              >
                <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine}/>
              </Grid>

              <Grid item md={12} xs={12}
                    display={"flex"}
                    alignItems={"center"} justifyContent={"center"}
                    mt={4}
              >
                <OrderPaymentList
                  data={orderDetail?.order_payment_list}
                  currencySymbol={currencySymbol}
                  sellerCurrencySymbol={sellerCurrencySymbol}
                  pageType={pageType}
                />
              </Grid>

              {pageType == 'payment' ?
                <Grid item md={12} xs={12}
                      display={"flex"}
                      alignItems={"center"} justifyContent={"center"}
                      mt={4}
                >
                  <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine}/>
                </Grid>
                : null}
            </>
            : null
          : null}


        <Grid item md={12} xs={12}
              display={"flex"}
              alignItems={"center"} justifyContent={"center"}
              mt={4}
        >
          <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine}/>
        </Grid>

        {orderDetail != null && pageType == 'payment' ?
          <Grid item md={12} xs={12}
                display={"flex"}
                alignItems={"center"} justifyContent={"center"}
                mt={4}>
            <Grid item md={11.6} xs={11.6} display={"flex"} flexDirection={'row'} alignItems={"center"}
                  justifyContent={"flex-end"}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box>
                  {orderDetail?.payment_term_amount == 50 ?
                    (orderDetail?.payment_status == 'half_paid' ?
                      <Typography className={styles.totalPriceLabelText}>Pay Balance</Typography>
                      : <Typography className={styles.totalPriceLabelText}>Pay</Typography>)
                    :
                    <Typography className={styles.totalPriceLabelText}>Pay</Typography>}
                </Box>
                <Box pl={1}>
                  {orderDetail?.payment_term_amount == 50 ?
                    <Typography className={styles.paymentAmtText}>
                      (50%)
                    </Typography>
                    : null}
                </Box>
              </Box>
              <Box pl={4}>
                {sellerTotalCargoPrice ?
                  <Typography className={styles.sellerTotalPriceText}>
                    {sellerCurrencySymbol}{sellerTotalCargoPrice?.toFixed(2)}
                  </Typography>
                  : null}
                <Typography className={styles.totalPriceText} pl={3}>
                  ({currencySymbol}{totalCargoPrice?.toFixed(2)})
                </Typography>
              </Box>
            </Grid>
          </Grid>
          : null}

        {pageType == 'payment' ?
          <>
            <Grid item md={12} xs={12}
                  display={"flex"}
                  alignItems={"center"} justifyContent={"center"}
                  mt={4}
            >
              <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine}/>
            </Grid>

            <Grid item md={12} xs={12}
                  display={"flex"}
                  alignItems={"center"} justifyContent={"center"}
                  mt={4}
            >
              <AdminBeneficiaryDetails/>
            </Grid>

            <Grid item md={12} xs={12}
                  display={"flex"}
                  alignItems={"center"} justifyContent={"center"}
                  mt={4}
            >
              <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine}/>
            </Grid>

            <Grid item md={12} xs={12}
                  display={"flex"}
                  alignItems={"center"} justifyContent={"center"}
                  mt={4}
            >
              <UploadPaymentDetails
                orderUid={orderUid}
                totalCargoPrice={totalCargoPrice}
                sellerTotalCargoPrice={sellerTotalCargoPrice}
                paymentTermAmount={orderDetail?.payment_term_amount}
                paymentStatus={orderDetail?.payment_status}
              />
            </Grid>
          </>
          : null}
      </>


      <AlertMsg/>

    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    orderDetail: state.userData.orderDetail,
    orderLoading: state.userData.orderLoading,
    currencyRateList: state.userData.currencyRateList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderDetail: data => dispatch(getOrderDetail(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPayment);
