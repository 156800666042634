import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField} from '@mui/material';

import {ReactComponent as Checkbox} from "../assets/img/radio_checkbox.svg";
import {ReactComponent as UnCheckbox} from "../assets/img/radio_uncheckbox.svg";

import styles from "./ReviewPaymentMethod.module.css"

const ReviewPaymentMethod = (props) => {
  // const [selectedPaymentTerm, setSelectedPaymentTerm] = useState('')

  useEffect(() => {
    if(props?.data?.paymentTermList?.length > 0) {
      props?.setSelectedPaymentTerm(props?.data?.paymentTermList?.[0]?.value)
    }
  },[props?.data?.paymentTermList])

  const onChoosePaymentTerm = (item) => {
    props?.setSelectedPaymentTerm(item?.value)
  };

  return (
    <Grid item md={12} xs={12}>
      <Grid container item md={10} xs={10} className={styles.mainView}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            mt={3}>

        {props?.data?.paymentTermList?.length > 0 ?
          props?.data?.paymentTermList?.map((item, index) => {
            return (
              <Box key={index} mb={2}
                   className={styles.paymentView}
                   display={'flex'} flexDirection={'row'} alignItems={'center'}
                   onClick={() => onChoosePaymentTerm(item)}>
                <Box>
                  {props?.selectedPaymentTerm == item?.value ?
                    <Checkbox height={15} width={15}/> :
                    <UnCheckbox height={15} width={15}/>}
                </Box>
                <Box display={'flex'} flexDirection={'column'}
                     justifyContent={'center'}
                     pl={1}>
                  <Typography
                    className={styles.labelText}>{item?.label}</Typography>
                  <Typography
                    className={styles.descText}>({item?.description})</Typography>
                </Box>
              </Box>
            )
          }) : null}

      </Grid>
    </Grid>
  )
};

export default ReviewPaymentMethod;
