import React, {useEffect, useState} from 'react'
import {
  Box,
  Grid, Card, CardContent,
  Typography, TableCell, CircularProgress
} from '@mui/material';

import {useLocation, useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import OrderDetailView from "../../components/OrderDetailView";
import OrderPackingDetailView from "../../components/OrderPackingDetailView";
import OrderShippingDetailView from "../../components/OrderShippingDetailView";
import OrderPaymentDetailView from "../../components/OrderPaymentDetailView";
import FormButton from "../../components/FormButton";
import FormTextArea from "../../components/FormTextArea";
import AlertMsg from "../../components/AlertMsg";
import {cancellationPeriod, unshippedOrderStatus} from "../../assets/functions/common";

import styles from "./OrderDetail.module.css";

import {connect} from "react-redux";
import {getOrderDetail, cancelOrder, updateApiStatus, getCurrencyRateList} from "../../redux/actions/userDataActions";

const OrderDetail = (props) => {
  const [orderUid, setOrderUid] = useState('');
  const [pageType, setPageType] = useState('detail');
  const [pageHeading, setPageHeading] = useState('Order Details');
  const [reason, setReason] = useState('');
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    props?.getCurrencyRateList()
  }, [])

  useEffect(() => {
    if (props.apiStatus == 'CANCEL_ORDER_SUCCESS') {
      props.updateApiStatus('');
      navigate('/order')
    } else if (props.apiStatus == 'GET_ORDER_DETAIL_FAIL') {
      props.updateApiStatus('');
      navigate('/order')
    }
  }, [props.apiStatus])

  useEffect(() => {
    var str = location.pathname;
    let page_type = str.split("/").pop();

    let order_uid = '';
    if (page_type == 'cancel') {
      setPageType('cancel');
      var arr = str.split('/');
      order_uid = arr[arr.length - 2];
    } else {
      setPageType('detail');
      order_uid = str.split("/").pop()
    }
    setOrderUid(order_uid);

    let data = {
      orderUid: order_uid,
    };
    props.getOrderDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    if (props.orderDetail != null && pageType) {
      let data = props.orderDetail;
      if (pageType == 'cancel') {
        // if (data?.unshipped_timestamp) {
        //   order_status = unshippedOrderStatus(data?.unshipped_timestamp);
        // }

        let is_cancellation_period = false;
        if (data?.unshipped_timestamp) {
          is_cancellation_period = cancellationPeriod(data?.unshipped_timestamp);
        }
        if (data?.order_status == 'pending' || (data?.order_status == 'unshipped' && is_cancellation_period)) {
        } else {
          navigate('/order')
        }
      }

      let page_heading = 'Order Details';
      if (pageType == 'cancel') {
        page_heading = 'Cancel Order'
      } else {
        if (data?.order_status == 'cancelled') {
          page_heading = 'Cancelled Order'
        } else {
          page_heading = 'Order Details'
        }
      }
      setPageHeading(page_heading)
    }
  }, [props.orderDetail, pageType])

  const onConfirmClick = () => {
    if (pageType == 'cancel') {
      let errorText = {};
      if (reason == '') {
        errorText = {reason: 'Reason is required'};
      }
      if (Object.keys(errorText).length != 0) {
        setError(errorText);
        return;
      }

      let data = {
        orderUid: orderUid,
        reason: reason
      };
      props.cancelOrder(JSON.stringify(data))
    }
  }

  if (props.orderLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Container page={"OrderDetail"} title={pageHeading}
                  subTitle={pageHeading}>

      <Grid item md={12} xs={12}
            display={"flex"}
            flexDirection={'column'}
            alignItems={"center"} justifyContent={"center"}
      >

        <Grid container item md={11.6} xs={11.6} mt={3}>
          <Typography
            className={styles.mainLabelText}>{pageHeading}</Typography>
        </Grid>

        <Grid container item md={11.6} xs={11.6} mt={2}>
          <FormButton
            title={'Back to Orders'}
            btnStyleView={style.btnStyleView}
            bgColorStyleView={'#000'}
            onSubmit={() => navigate('/order')}
          />
        </Grid>

        {pageType == 'cancel' ?
          <Grid container item md={11.6} xs={11.6} mt={2}>
            <Typography className={styles.labelText}>Order Cancellation Form</Typography>
          </Grid>
          : null}

        {pageType == 'cancel' ?
          <Grid container item md={11.6} xs={11.6} mt={2}
                display={'flex'}
                flexDirection={'column'}
                className={styles.cancelBorderView} p={2}>
            <Typography className={styles.warningText}>Warning!</Typography>
            <Typography className={styles.warningDescText} mt={2}>You can cancel orders within 24 hours from the time of
              confirmation of order.</Typography>
            <Typography className={styles.warningDescText} mt={0.5}>If you want to cancel orders beyond that time line
              please contact customer support. </Typography>
          </Grid>
          : null}

        <Grid container item md={11.6} xs={11.6} mt={2}
              display={"flex"} flexDirection={'column'}
              justifyContent={'center'}
              className={styles.borderView}>

          <Grid item md={12} xs={12}
                display={'flex'} alignItems={'center'}
                className={styles.labelView}>
            <Typography className={styles.labelText} px={2}>Order Details</Typography>
          </Grid>

          <Grid item md={12} xs={12} p={2}>
            <OrderDetailView
              data={props?.orderDetail}
              currencyRateList={props?.currencyRateList}
            />
          </Grid>
        </Grid>

        <Grid container item md={11.6} xs={11.6} mt={4}
              display={"flex"} flexDirection={'column'}
              justifyContent={'center'}
              className={styles.borderView}>
          <Grid item md={12} xs={12}
                display={'flex'} alignItems={'center'}
                className={styles.labelView}>
            <Typography className={styles.labelText} px={2}>Packing Details</Typography>
          </Grid>

          <Grid item md={12} xs={12} p={2}>
            <OrderPackingDetailView
              data={props?.orderDetail}/>
          </Grid>
        </Grid>

        <Grid container item md={11.6} xs={11.6} mt={4}
              display={"flex"} flexDirection={'column'}
              justifyContent={'center'}
              className={styles.borderView}>
          <Grid item md={12} xs={12}
                display={'flex'} alignItems={'center'}
                className={styles.labelView}>
            <Typography className={styles.labelText} px={2}>Shipping Details</Typography>
          </Grid>

          <Grid item md={12} xs={12} p={2}>
            <OrderShippingDetailView
              data={props?.orderDetail}/>
          </Grid>
        </Grid>

        <Grid container item md={11.6} xs={11.6} mt={4}
              display={"flex"} flexDirection={'column'}
              justifyContent={'center'}
              className={styles.borderView}>
          <Grid item md={12} xs={12}
                display={'flex'} alignItems={'center'}
                className={styles.labelView}>
            <Typography className={styles.labelText} px={2}>Payment Details</Typography>
          </Grid>

          <Grid item md={12} xs={12} p={2}>
            <OrderPaymentDetailView
              data={props?.orderDetail}/>
          </Grid>
        </Grid>

        {pageType == 'cancel' || (props?.orderDetail?.order_status == 'cancelled') ?
          <>
            <Grid container item md={11.6} xs={11.6} mt={4}
                  display={'flex'}
                  flexDirection={'column'}
                  className={styles.reasonBorderView} p={2}>
              <Grid display={"flex"}
                    flexDirection={"row"} item md={12} xs={12} mb={2}
                    container>
                <Typography className={styles.labelText}>Reason for cancellation</Typography>
                {props?.orderDetail?.order_status != 'cancelled' ?
                  <Box pl={0.3}>
                    <Typography className={styles.requiredText}>*</Typography>
                  </Box>
                  : null}
              </Grid>

              <Grid container item md={12} xs={12}
                    display={'flex'}
                    alignItems={'center'} justifyContent={'flex-start'}>
                <Grid container item md={7} xs={7}>
                  <FormTextArea
                    disabled={props?.orderDetail?.order_status == 'cancelled'}
                    page={'OrderDetail'}
                    placeholder={'Reason'}
                    value={props?.orderDetail?.order_status == 'cancelled' ? props?.orderDetail?.cancelled_reason : reason}
                    setValue={setReason}
                    textInputViewStyle={style.textInputViewStyle}
                    error={error?.reason}
                  />
                </Grid>
              </Grid>
            </Grid>

            {props?.orderDetail?.order_status != 'cancelled' ?
              <Grid container item md={11.6} xs={11.6} mt={3}
                    display={'flex'}
                    alignItems={'center'} justifyContent={'flex-end'}
              >
                <FormButton
                  title={'Confirm'}
                  btnStyleView={style.confirmBtnStyleView}
                  bgColorStyleView={'#EE2525'}
                  onSubmit={() => onConfirmClick()}
                />
              </Grid>
              : null}
          </>
          : null}

      </Grid>

      <AlertMsg/>

    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    orderDetail: state.userData.orderDetail,
    apiStatus: state.userData.apiStatus,
    orderLoading: state.userData.orderLoading,
    currencyRateList: state.userData.currencyRateList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderDetail: data => dispatch(getOrderDetail(data)),
    cancelOrder: data => dispatch(cancelOrder(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);

const style = {
  btnStyleView: {
    backgroundColor: '#000',
    color: '#fff',
    fontFamily: 'InterMedium',
    fontSize: 14,
    minWidth: '10%',
    height: 30,
    borderRadius: 5
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #d3d3d3',
  },
  confirmBtnStyleView: {
    backgroundColor: '#EE2525',
  }
}
