import React, {useEffect, useState} from 'react'
import {
  Grid,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";
import LandingContent from "../../components/LandingContent";
import LandingContent2 from "../../components/LandingContent2";
import HomeProduct from "../../components/HomeProduct";

import {connect} from "react-redux";
import {addToCart, getHomePage, getCurrencyRateList, updateApiStatus} from "../../redux/actions/userDataActions";

import LandingImage from "../../assets/img/landing.png"

import {THEME_COLOR, THEME_COLOR2, THEME_COLOR5, THEME_COLOR7} from "../../constants";

import styles from './Index.module.css'

import '../../assets/css/custom.css';

const Index = (props) => {
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();

  useEffect(() => {
    props?.getCurrencyRateList()
  }, [])

  useEffect(() => {
    props.getHomePage();
  }, []);


  useEffect(() => {
    if (props?.apiStatus == 'GET_HOME_PAGE_SUCCESS') {
      setLoading(false);
      props?.updateApiStatus('')
    }
  }, [props?.apiStatus])


  const onExploreProduct = () => {
    navigate('/product')
  };

  const onRegisterNowToImport = () => {
    navigate('/register')
  };

  if (loading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Container page={"home"}>
      <Grid item md={12} xs={12} display={"flex"} flexDirection={"column"}
            justifyContent={"center"}
            alignItems={'center'}
            mt={2}>

        <Grid item md={10} xs={10}
              container
              display={"flex"}
              flexDirection={{md: "column", xs: "column"}}
        >
          <Grid container item md={12} xs={12} mt={{md:1, xs: 8}}
                alignItems={'center'}
                justifyContent={'space-between'}>
            <Grid item md={4.6} xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
              <Typography className={styles.landingTitleText}>Open your door to the global <span
                style={style.landingTextColor}>agri market</span></Typography>
              <Typography className={styles.landingDescText} mt={2}>Ditch the middleman and harvest a world of
                opportunity. Our global B2B marketplace connects agri-exporters and importers for fast, secure,
                and transparent trading. Streamline your operations, find the perfect match for your needs, and
                unlock a world of agricultural commerce – all in one place</Typography>
              <Grid item md={12} xs={12} mt={4} display={'flex'} flexDirection={'row'}
                    justifyContent={'space-between'}>
                <Grid item md={6} xs={6}
                      display={'flex'}
                      alignItems={'flex-start'}
                      justifyContent={'center'}>
                  <FormButton
                    title={'Explore products'}
                    buttonView={{
                      minWidth: '80%',
                      color: '#000',
                      border: '1px solid #000',
                      backgroundColor: THEME_COLOR7
                    }}
                    bgColorStyleView={THEME_COLOR7}
                    onSubmit={() => onExploreProduct()}
                  />
                </Grid>
                {props.userToken && props?.tgbin ? null :
                  <Grid item md={6} xs={6}
                        display={'flex'}
                        alignItems={'flex-start'}
                        justifyContent={'center'}>
                    <FormButton
                      title={'Register now to import'}
                      buttonView={{
                        minWidth: '80%',
                        color: '#000',
                        border: '1px solid #000',
                        backgroundColor: THEME_COLOR5
                      }}
                      bgColorStyleView={THEME_COLOR5}
                      onSubmit={() => onRegisterNowToImport()}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item md={6.6} xs={12} display={{md: 'flex', xs: 'none'}} justifyContent={'center'}>
              <Box className={styles.landingImageView}>
                <img alt="" src={LandingImage} className={styles.landingImageStyle}/>
              </Box>
            </Grid>
          </Grid>
          <LandingContent/>
        </Grid>

        {/*products*/}
        <HomeProduct
          homeData={props?.homeData}
          currencyRateList={props?.currencyRateList}
        />

        <Grid item md={10} xs={10}
              container
              display={"flex"}
              flexDirection={{md: "column", xs: "column"}}
        >
          <LandingContent2/>
        </Grid>

      </Grid>

      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    homeData: state.userData.homeData,
    currencyRateList: state.userData.currencyRateList,
    apiStatus: state.userData.apiStatus,
    userToken: state.userData.userToken,
    tgbin: state.userData.tgbin,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getHomePage: data => dispatch(getHomePage(data)),
    addToCart: data => dispatch(addToCart(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

const style = {
  landingTextColor: {
    color: THEME_COLOR7
  }
};

