import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import OrderTabList from "./OrderTabList";
import Page from "./Page";

import {connect} from "react-redux";
import {listOrder} from "../redux/actions/userDataActions";

import styles from "./OrderTab.module.css"

import {ReactComponent as InfoIcon} from "../assets/img/infoCircle.svg";

const OrderTab = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [orderMessageVal, setOrderMessageVal] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (props?.orderMessage != null) {
      setOrderMessageVal(props?.orderMessage)
    }
  }, [props?.orderMessage]);

  useEffect(() => {
    getOrderList(1);
  }, []);

  const getOrderList = (page) => {
    let data = {
      page: page,
      status: props?.tab
    };
    props.listOrder(JSON.stringify(data))
    setPageNum(page)
  };

  const onPageSelect = (page) => {
    getOrderList(page);
  };

  const onOrderPayment = (order_uid) => {
    navigate('/order_payment/' + order_uid)
  };

  const onOrderDetail = (order_uid) => {
    navigate('/order_detail/' + order_uid)
  };

  const onOrderCancel = (order_uid) => {
    navigate('/order_detail/' + order_uid + '/cancel')
  };

  const onOrderPaymentStatement = (order_uid) => {
    navigate('/order_payment/' + order_uid + '/statement')
  };

  if (props.orderLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <>
      {props?.tab == 'pending' && (props?.orderList?.length > 0) ?
        <Grid item md={12} xs={12}
              className={styles.descView} mb={4}
              display={"flex"}
              flexDirection={'column'}
              alignItems={"flex-start"} justifyContent={"center"} p={2}>
          <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <InfoIcon height={15} width={15}/>
            <Typography className={styles.descTitleText} ml={0.2}>
              Order Pending
            </Typography>
          </Grid>
          <Typography className={styles.descText} mt={1}>
            Pending status might indicate an issue with the Buyer's payment. Once the payment is completed, order will
            be confirmed.
          </Typography>
        </Grid>
        : (
          props?.tab == 'unshipped' && (props?.orderList?.length > 0) ?
            <Grid item md={12} xs={12}
                  className={styles.descView} mb={4}
                  display={"flex"}
                  flexDirection={'column'}
                  alignItems={"flex-start"} justifyContent={"center"} p={2}>
              <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <InfoIcon height={15} width={15}/>
                <Typography className={styles.descTitleText} ml={0.2}>
                  Cancellation
                </Typography>
              </Grid>
              <Typography className={styles.descText} mt={1}>
                Orders can be cancelled within 24 hours from the time of confirmation of order.
              </Typography>
            </Grid>
            : (
              props?.tab == 'cancelled' && (props?.orderList?.length > 0) ?
                orderMessageVal?.type == 'order_cancelled' ?
                  <Grid item md={12} xs={12}
                        className={styles.descView} mb={4}
                        display={"flex"}
                        flexDirection={'column'}
                        alignItems={"flex-start"} justifyContent={"center"} p={2}>
                    <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <InfoIcon height={15} width={15}/>
                      <Typography className={styles.descTitleItalicText} ml={0.2}>
                        Order cancelled successfully
                      </Typography>
                    </Grid>
                    <Typography className={styles.descItalicText} mt={1}>
                      Order no #{orderMessageVal?.orderUid} has been cancelled successfully.
                    </Typography>
                  </Grid>
                  : null
                : null
            )
        )}

      <TableContainer
        className={'tableContainer'}>
        <Table stickyHeader
               aria-label="sticky table">
          <TableHead
            className={'table'}>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.labelText}>SKU</Typography>
                <Typography className={styles.subLabelText} ml={1}>Product ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Product</Typography>
                <Typography className={styles.subLabelText} ml={1}>HS Code</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Seller</Typography>
                <Typography className={styles.subLabelText} ml={1}>TGSIN</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Order ID</Typography>
                <Typography className={styles.subLabelText} ml={1}>Order Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Order Quantity</Typography>
                <Typography className={styles.subLabelText} ml={1}>No. of Packages</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Total EXW Price</Typography>
                <Typography className={styles.subLabelText} ml={1}>Shipping Cost</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Order Total</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Price/Kg</Typography>
                <Typography className={styles.subLabelText}>Price/Package</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>M. Shipping</Typography>
                <Typography className={styles.subLabelText}>Incoterm</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Delivery Date</Typography>
                <Typography className={styles.subLabelText}>Ship by Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Order Status</Typography>
                <Typography className={styles.subLabelText}>Payment Status</Typography>
              </TableCell>
              {props?.tab == 'cancelled' ?
                <TableCell>
                  <Typography className={styles.labelText}>Reason</Typography>
                </TableCell>
                :
                <TableCell>
                  <Typography className={styles.labelText}>Action</Typography>
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.orderList?.length > 0 ?
              props?.orderList?.map((item, index) => {
                return (
                  <TableRow key={index} className={styles.tableRow}>
                    <OrderTabList
                      tab={props?.tab}
                      currencyRateList={props?.currencyRateList}
                      key={index}
                      index={index}
                      data={item}
                      onOrderPayment={onOrderPayment}
                      onOrderDetail={onOrderDetail}
                      onOrderCancel={onOrderCancel}
                      onOrderPaymentStatement={onOrderPaymentStatement}
                    />
                  </TableRow>
                )
              }) : null}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
        {props.orderTotalPages > 0 ?
          <Page
            totalPages={props.orderTotalPages}
            pageNum={pageNum}
            onSelectPage={onPageSelect}/>
          : null}
      </Box>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    orderTotalPages: state.userData.orderTotalPages,
    orderList: state.userData.orderList,
    orderLoading: state.userData.orderLoading,
    orderMessage: state.userData.orderMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listOrder: data => dispatch(listOrder(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTab);
