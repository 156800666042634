import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, CircularProgress} from '@mui/material';
import {jsPDF} from "jspdf";
import html2canvas from 'html2canvas';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";

import {connect} from "react-redux";
import {
  getAdminBeneficiaryDetail
} from "../redux/actions/userDataActions";

import styles from './AdminBeneficiaryDetails.module.css'

const AdminBeneficiaryDetails = (props) => {
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [bankAddress, setBankAddress] = useState('');

  useEffect(() => {
    props.getAdminBeneficiaryDetail()
  }, []);

  useEffect(() => {
    if (props.adminBeneficiaryDetail != null) {
      let data = props.adminBeneficiaryDetail;
      setAccountName(data?.accountName)
      setAccountNumber(data?.accountNumber)
      setBankName(data?.bankName)
      setSwiftCode(data?.swiftCode)
      setBankAddress(data?.bankAddress)
    }
  }, [props.adminBeneficiaryDetail])

  const onDownloadClick = () => {
    const input = document.getElementById('divToDownload');
    html2canvas(input, {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById('divToDownload').style.display = 'block';
      }
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 0, 0);
      pdf.save("trebbs_beneficiary.pdf");
    })
  };

  return (
    <Grid container
          item md={11} xs={11}
          display={'flex'} flexDirection={'column'}>

      <Grid item md={6} xs={12}
            container>
        <Typography className={styles.mainLabelText}>Beneficiary Details</Typography>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}>
          <Typography className={styles.labelText}>Account Name</Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            disabled={true}
            page={'AdminBeneficiaryDetails'}
            value={accountName}
            setValue={setAccountName}
            textInputViewStyle={style.textInputViewStyle}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}>
          <Typography className={styles.labelText}>Account Number</Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            disabled={true}
            page={'AdminBeneficiaryDetails'}
            value={accountNumber}
            setValue={setAccountNumber}
            textInputViewStyle={style.textInputViewStyle}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}>
          <Typography className={styles.labelText}>Bank Name</Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            disabled={true}
            page={'AdminBeneficiaryDetails'}
            value={bankName}
            setValue={setBankName}
            textInputViewStyle={style.textInputViewStyle}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}>
          <Typography className={styles.labelText}>Swift Code</Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            disabled={true}
            page={'AdminBeneficiaryDetails'}
            value={swiftCode}
            setValue={setSwiftCode}
            textInputViewStyle={style.textInputViewStyle}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}>
          <Typography className={styles.labelText}>Bank Address</Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            disabled={true}
            page={'AdminBeneficiaryDetails'}
            value={bankAddress}
            setValue={setBankAddress}
            textInputViewStyle={style.textInputViewStyle}
          />
        </Grid>
      </Grid>

      {props.adminBeneficiaryDetail != null ?
        <Grid item md={6} xs={12} mt={3}
              display={"flex"}
              justifyContent={'flex-end'}
              container>
          <FormButton
            title={'Download'}
            onSubmit={() => onDownloadClick()}
          />
        </Grid>
        : null}


      <Grid item md={12} xs={12} id="divToDownload" p={6}
            style={{display: 'none'}}
      >
        <Grid item md={6} xs={12}
              container>
          <Typography className={styles.mainLabelText}>Beneficiary Details</Typography>
        </Grid>

        <Grid item md={6} xs={12} mt={3}
              display={"flex"}
              flexDirection={'row'}
              alignItems={'center'}
              container>
          <Grid item md={4} xs={12}>
            <Typography className={styles.labelText}>Account Name</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography className={styles.labelText}>{accountName}</Typography>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} mt={3}
              display={"flex"}
              flexDirection={'row'}
              alignItems={'center'}
              container>
          <Grid item md={4} xs={12}>
            <Typography className={styles.labelText}>Account Number</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography className={styles.labelText}>{accountNumber}</Typography>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} mt={3}
              display={"flex"}
              flexDirection={'row'}
              alignItems={'center'}
              container>
          <Grid item md={4} xs={12}>
            <Typography className={styles.labelText}>Bank Name</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography className={styles.labelText}>{bankName}</Typography>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} mt={3}
              display={"flex"}
              flexDirection={'row'}
              alignItems={'center'}
              container>
          <Grid item md={4} xs={12}>
            <Typography className={styles.labelText}>Swift Code</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography className={styles.labelText}>{swiftCode}</Typography>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} mt={3}
              display={"flex"}
              flexDirection={'row'}
              alignItems={'center'}
              container>
          <Grid item md={4} xs={12}>
            <Typography className={styles.labelText}>Bank Address</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography className={styles.labelText}>{bankAddress}</Typography>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    adminBeneficiaryDetail: state.userData.adminBeneficiaryDetail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdminBeneficiaryDetail: data => dispatch(getAdminBeneficiaryDetail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBeneficiaryDetails);

const style = {
  textInputViewStyle: {
    backgroundColor: '#eee',
    border: '1px solid #e4ecf2',
  },
}
