import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormSelectBox from "./FormSelectBox";

import styles from "./ReviewShipping.module.css"

const sgsPreShipmentInspectionList = [
  {
    value : 1,
    label: 'Yes'
  },
  {
    value : 0,
    label: 'No'
  }
]

const ReviewShipping = (props) => {
  const [error, setError] = useState(null);

  return (
    <Grid item md={12} xs={12}>
      <Grid container item md={10} xs={10} className={styles.mainView}
            display={"flex"} flexDirection={"row"} justifyContent={"space-between"}
            mt={3}>
        <Grid item md={12} xs={12} mt={2}>
          <FormTextInput
            disabled={true}
            page={'ReviewShipping'}
            value={props?.shippingMode}
            setValue={props?.setShippingMode}
            label={'Mode of Shipping'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
            error={error?.shippingMode}
          />
        </Grid>

        <Grid item md={12} xs={12} mt={2}>
          <FormSelectBox
            page={'ReviewShipping'}
            value={props?.incoterm}
            setValue={props?.setIncoterm}
            data={props.data?.incotermList?.length > 0 ? props.data.incotermList : []}
            label={"Incoterm"}
            error={error?.incoterm}
            textInputViewStyle={style.textInputViewStyle}
            selectedItemViewStyle={style.selectedItemViewStyle}
          />
        </Grid>

        <Grid item md={12} xs={12} mt={2}>
          <FormSelectBox
            page={'ReviewShipping'}
            value={props?.containerType}
            setValue={props?.setContainerType}
            data={props.data?.containerTypeList?.length > 0  ? props.data.containerTypeList : []}
            label={"Select Type of Container"}
            error={error?.containerType}
            textInputViewStyle={style.textInputViewStyle}
            selectedItemViewStyle={style.selectedItemViewStyle}
          />
        </Grid>

        <Grid item md={12} xs={12} mt={2}>
          <FormSelectBox
            page={'ReviewShipping'}
            value={props?.sgsPreShipmentInspection}
            setValue={props?.setSgsPreShipmentInspection}
            data={sgsPreShipmentInspectionList}
            label={"Would you like to add SGS pre-shipment inspection? (charges will apply)"}
            error={error?.containerType}
            textInputViewStyle={style.textInputViewStyle}
            selectedItemViewStyle={style.selectedItemViewStyle}
          />
        </Grid>

      </Grid>
    </Grid>
  )
};

export default ReviewShipping;

const style = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #d3d3d3',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
    "& .MuiSelect-select:focus": {
      backgroundColor: '#FFF',
    },
  },
};
