import React, {useEffect, useState} from 'react'
import {
  Grid, Typography, Box, CircularProgress,
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import AccountDetails from "../../components/AccountDetails";
import AddContactDetails from "../../components/AddContactDetails";
import AddProductDetails from "../../components/AddProductDetails";
import AlertMsg from "../../components/AlertMsg";

import {connect} from "react-redux";
import {
  getCategoryList, getUserDetail, updateUserContactDetail
} from "../../redux/actions/userDataActions";

import styles from './Account.module.css';

import {ReactComponent as DashboardIcon} from '../../assets/img/home.svg'
import {ReactComponent as AccountDetailIcon} from '../../assets/img/user_fill.svg'
import {ReactComponent as ContactDetailIcon} from '../../assets/img/contact.svg'
import {ReactComponent as ProductDetailIcon} from '../../assets/img/category.svg'
import {ReactComponent as LogOutIcon} from '../../assets/img/logout.svg'

const menuList = [
  {
    name: 'Dashboard',
    value: 'dashboard',
    icon: <DashboardIcon height={17} width={17}/>,
  },
  {
    name: 'Account Details',
    value: 'account_details',
    icon: <AccountDetailIcon height={15} width={15}/>,
  },
  {
    name: 'Contact Details',
    value: 'contact_details',
    icon: <ContactDetailIcon height={15} width={15}/>,
  },
  {
    name: 'Product Details',
    value: 'product_details',
    icon: <ProductDetailIcon height={15} width={15}/>,
  },
  {
    name: 'Log Out',
    value: 'logout',
    icon: <LogOutIcon height={18} width={18}/>,
  },
];

const Account = (props) => {
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState('dashboard');
  const [orderPageNum, setOrderPageNum] = useState(1);
  const [userUid, setUserUid] = useState('');
  const [contactDetail, setContactDetail] = useState(null);
  const [productDetail, setProductDetail] = useState([]);

  useEffect(() => {
    props.getUserDetail();
    props.getCategoryList();
  }, [])

  useEffect(() => {
    if (props?.userDetail != null) {
      setContactDetail(props?.userDetail?.contactDetails ? props?.userDetail.contactDetails : null)
      setProductDetail(props?.userDetail?.productDetails ? props?.userDetail.productDetails : null)
      setUserUid(props?.userDetail?.userUid ? props?.userDetail.userUid : '')
    }
  }, [props?.userDetail])

  const onSelectMenuClick = (item) => {
    setSelectedMenu(item.value);
    if (item.value == 'logout') {
      navigate('/logout');
    }
  };

  const onLogOutClick = () => {
    navigate('/logout')
  };

  const onAddContactDetailClick = (data) => {
    setContactDetail(data)
    let data1 = {
      userId: data.userId,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      designation: data.designation,
      businessAddress: data.businessAddress,
      city: data.city,
      stateProvince: data.stateProvince.name,
      zipCode: data.zipCode,
      email: data.email,
      mobileNo: data.mobileNo,
      mobileNoCode: data.mobileNoCode.phone,
      mobileNoCountry: data.mobileNoCode.code,
      whatsAppNo: data.whatsAppNo,
      whatsAppNoCode: data.whatsAppNoCode.phone,
      whatsAppNoCountry: data.whatsAppNoCode.code,
    }

    if (data?.userId) {
      props.updateUserContactDetail(JSON.stringify(data1))
    }
  };

  return (
    <Container page={"Account"} title={'My Account'} subTitle={'My Account'}>
      <Grid container item md={12} xs={12} mt={5} mb={5}
            display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Grid item md={10} xs={10} display={"flex"} flexDirection={{md: "row", xs: "column"}}
              justifyContent={{md: "space-between", xs: "center"}}>
          <Grid item md={3} xs={12} className={styles.menuMainBox}>
            {menuList.map((item, index) => {
              return (
                <Grid key={index} item md={12} xs={12}
                      onClick={() => onSelectMenuClick(item)}
                      className={[(menuList.length - 1) == index ? "" : styles.menuBorderBox, styles.menuBox, selectedMenu == item.value ? styles.selectedMenuBox : styles.nonSelectedMenuBox]}
                      p={2}
                      display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                  <Grid item md={11} xs={11}>
                    <Typography
                      className={selectedMenu == item.value ? styles.selectedMenuText : styles.nonSelectedMenuText}>{item.name}</Typography>
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <Box
                      className={selectedMenu == item.value ? styles.selectedMenuIcon : styles.nonSelectedMenuIcon}>{item.icon}</Box>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>

          {props.accountLoading ?
            <Grid Grid item md={8.5} xs={12}
                  sx={{minHeight: window.innerHeight - 500}}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}>
              <CircularProgress
                color={'success'}/>
            </Grid>
            : (selectedMenu == 'dashboard' ?
              <Grid item md={8.5} xs={12} mt={{md: 0, xs: 4}}>
                <Box className={styles.dashboardBox} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                  <Typography
                    className={styles.dashboardUserText}>Hello <b>{props.userName}</b> (not <b>{props.userName}</b>?
                    <span className={styles.dashboardLogoutText}
                          onClick={() => onLogOutClick()}> Log out</span>)</Typography>
                </Box>
                <Box className={styles.dashboardBox} mt={4}>
                  <Typography className={styles.dashboardDescText}>From your account dashboard you can view your recent
                    orders, manage your shipping and billing
                    addresses, and edit your password and account details.</Typography>
                </Box>
              </Grid>
              : (selectedMenu == 'account_details' ?
                <Grid item md={8.5} xs={12} mt={{md: 0, xs: 4}}>
                  <AccountDetails/>
                </Grid>
                : (selectedMenu == 'contact_details' && (props?.userStatus == 'active') ?
                  <Grid item md={8.5} xs={12} mt={{md: -3, xs: 4}}>
                    <AddContactDetails
                      data={contactDetail}
                      userUid={userUid}
                      btnText={'Submit'}
                      onAddContactDetailClick={onAddContactDetailClick}
                    />
                  </Grid>
                  : (selectedMenu == 'product_details' && (props?.userStatus == 'active') ?
                    <Grid item md={8.5} xs={12} mt={{md: -3, xs: 4}}>
                      <AddProductDetails
                        data={productDetail}
                        navigation={true}
                      />
                    </Grid>
                    : null))))
          }

        </Grid>
      </Grid>
      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    userName: state.userData.userName,
    accountLoading: state.userData.accountLoading,
    userDetail: state.userData.userDetail,
    userStatus: state.userData.userStatus,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getUserDetail: data => dispatch(getUserDetail(data)),
    getCategoryList: data => dispatch(getCategoryList(data)),
    updateUserContactDetail: data => dispatch(updateUserContactDetail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
