import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

import {COUNTRIES} from "../constants/countries";

const FormCountrySelectBox = (props) => {

  return (
    <Grid item md={12} xs={12}
          container>
      <Grid item md={12} xs={12}
            container display={"flex"} flexDirection={"row"}>
        {props?.label ?
          <Grid display={"flex"}
                flexDirection={"row"} item md={12} xs={12}
                container>
            <Typography sx={styles.labelText}>{props.label}</Typography>
            {props?.required ?
              <Box pl={1}>
                <Typography sx={styles.requiredText}>*</Typography>
              </Box>
              : null}
          </Grid>
          : null}
      </Grid>
      <Grid item md={12} xs={12}
            container sx={[styles.textInputView, props?.textInputViewStyle]}>
        <FormControl style={{width: '100%'}}>

          <Autocomplete
            disabled={props?.disabled ? props?.disabled : false}
            loading={props?.loading ? props?.loading : false}
            disableClearable
            options={props?.data ? props?.data : (props.page == 'Checkout' ? [] : COUNTRIES)}
            autoHighlight
            value={props.value != '' ? props.value : null}
            onChange={(event, value) => props.setValue(value)}
            getOptionLabel={(option) =>
              props.type == 'dial_code' ? '+' + option.phone + ' (' + option.code + ')' : option.label}
            renderOption={(props1, option) => (
              <Box component="li"
                   sx={{'& > img': {mr: 2, flexShrink: 0}, fontSize: 12, fontFamily: 'InterBold'}}
                   {...props1}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {props.type == 'dial_code' ? '+' + option.phone + ' (' + option.code + ')' : option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={props?.disabled ? props?.disabled : false}
                required={props?.required ? true : false}
                placeholder={props.placeholder}
                fullWidth
                variant={props?.variant ? props?.variant : "standard"}
                value={props.value}
                sx={{
                  '& .MuiInput-root': {
                    '&:before, :after, :hover:not(.Mui-disabled):before': {
                      borderBottom: 0,
                    },
                  },
                  input: {
                    "&::placeholder": {
                      color: '#BBB',
                      fontSize: 14,
                      fontFamily: 'InterMedium',
                      opacity: 1,
                      paddingLeft: 0.8
                    },
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off',
                  sx: styles.textInputValue
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      {props.error ?
        <Box mt={1} ml={0.5} styles={styles.errorView}>
          <Typography sx={styles.errorText}>{props.error}</Typography>
        </Box>
        : null}
    </Grid>
  )
}

export default FormCountrySelectBox;

const styles = {
  labelText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  textInputView: {
    backgroundColor: '#f0efef',
    alignItems: 'center',
    marginTop: 1,
    py: 0.8,
    paddingLeft: 1,
    height: 55,
  },
  textInputValue: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
    px: 1.5,
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 12
  }
};
