import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';

import FormButton from "./FormButton";
import {getCurrencyConversion, getCurrencyConversionCalc} from "../assets/functions/common";

import styles from './ItemCartList.module.css'

import {connect} from "react-redux";
import {updateCartList} from "../redux/actions/userDataActions";

import {THEME_COLOR5, THEME_COLOR3} from "../constants";
import {COUNTRIES} from "../constants/countries";

const ItemCartList = (props) => {
  const [productCount, setProductCount] = useState(0);
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [sellerTotalPrice, setSellerTotalPrice] = useState(0);
  const [sellerPrice, setSellerPrice] = useState(0);
  const [sellerPricePerKg, setSellerPricePerKg] = useState(0);
  const [sellerCurrencySymbol, setSellerCurrencySymbol] = useState('')
  const [showError, setShowError] = useState('');

  useEffect(() => {
    if (props.data) {
      setShowError(props.data.error);
    }
  }, [props.data.error])

  useEffect(() => {
    if (props.data) {
      setProductCount(props.data.quantity);
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {

        let price_val = 0;
        let total_price = 0;
        let price_per_kg = 0;
        let order_quantity = 0;
        let seller_price_val = 0;
        let seller_total_price = 0;
        let seller_price_per_kg = 0;

        let package_net_weight = props?.data?.package_net_weight;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: 0,
          seller_currency: props?.data?.seller_currency,
          user_currency: props?.data?.user_currency,
          main_currency: props?.data?.main_currency,
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          order_quantity = props.data.quantity * package_net_weight;
          price_val = data?.converted_price;
          price_per_kg = data?.converted_price_per_kg;
          total_price = data?.converted_total_price;
          seller_price_val = data?.seller_price;
          seller_price_per_kg = data?.seller_price_per_kg;
          seller_total_price = data?.seller_total_price;
          setCurrencySymbol(data?.user_currency_symbol)
          setSellerCurrencySymbol(data?.seller_currency_symbol)
        }

        setPrice(price_val);
        setTotalPrice(total_price);
        setPricePerKg(price_per_kg);
        setOrderQuantity(order_quantity);
        setSellerPrice(seller_price_val);
        setSellerTotalPrice(seller_total_price)
        setSellerPricePerKg(seller_price_per_kg);

      }
    }
  }, [props.data, props?.currencyRateList])

  const addSubProduct = (type) => {
    setShowError('');
    let count = productCount;
    if (count != '') {
      if (type == 'add') {
        count = parseInt(count) + 1;
      } else if (type == 'sub') {
        // if (count > props.data.moq) {
        //   count = parseInt(count) - 1;
        // } else {
        //   setShowError('MOQ is ' + props.data.moq);
        // }

        if (count > 1) {
          count = parseInt(count) - 1;
        } else {
          setShowError('Packages Required');
        }

      }
      setProductCount(count);
      let order_quantity = 0;
      if (props?.data?.package_net_weight != '') {
        order_quantity = count * props?.data?.package_net_weight;
      }
      setOrderQuantity(order_quantity)
      setTotalPrice(count * price)
      setSellerTotalPrice(count * sellerPrice)
    }

    updateCart(count);
  };

  const onChangeProductCount = (value) => {
    setProductCount(value);
    if (value == '') {
      setTotalPrice(0);
      setSellerTotalPrice(0);
    } else {
      let order_quantity = 0;
      if (props?.data?.package_net_weight != '') {
        order_quantity = value * props?.data?.package_net_weight;
      }

      setOrderQuantity(order_quantity)
      setTotalPrice(value * price)
      setSellerTotalPrice(value * sellerPrice)
    }

    updateCart(value);
  };

  const updateCart = (count) => {
    let cartProductList = [...props.cart];
    let cartProductIndex = cartProductList.findIndex((x) => x.cart_uid == props.data.cart_uid);
    // let cartProductIndex = cartProductList.findIndex((x) => (x.uid == props.data.uid && x.product_detail_variety_uid == props.data?.product_detail_variety_uid && x.product_detail_variety_size_uid == props.data?.product_detail_variety_size_uid));
    if (cartProductIndex >= 0) {
      cartProductList[cartProductIndex]['quantity'] = count;
      if (cartProductList[cartProductIndex]['status'] == 'removed') {
        cartProductList[cartProductIndex]['error'] = 'Not Available';
      } else if (orderQuantity < cartProductList[cartProductIndex]['moq']) {
        // } else if (cartProductList[cartProductIndex]['quantity'] < cartProductList[cartProductIndex]['moq']) {
        cartProductList[cartProductIndex]['error'] = 'MOQ is ' + cartProductList[cartProductIndex]['moq'];
      }
    }
    props.updateCartList(cartProductList);
  };

  const onDeleteCartClick = (cart_uid) => {
    props.onDelete(cart_uid)
  };

  const onUpdateCartClick = (cart_uid) => {
    props.onUpdateCartClick(cart_uid, productCount)
  };

  const onProceedToReview = (cart_uid) => {
    props.onProceedToReview(cart_uid)
  };

  let item = props.data;

  return (


    <>
      <TableCell>
        <Typography className={styles.productNameText}>#{item.cart_uid}</Typography>
        {showError ?
          <Box mt={1}>
            <Typography className={styles.moqErrorText}>{showError}</Typography>
          </Box>
          : null}
      </TableCell>
      <TableCell className={styles.dataView}>
        {item.product_image ?
          <Grid item md={4} xs={12} className={styles.imgView}>
            <img src={item.product_image} className={styles.imgStyle}/>
          </Grid>
          : null}
        <Typography className={styles.productNameTitleText}>
          {(item?.variety ? item.variety : 'NIL Variety')
          + ', ' + (item?.name ? item.name : 'NIL')
          + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
          + ', ' + (item?.package_net_weight ?
            (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
            : 'NIL Packing')
          + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
        </Typography>
        <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          (<Typography className={styles.subPricePerKgText}>{currencySymbol}{pricePerKg?.toFixed(2)} / Kg</Typography>
          <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
        </Box>
        <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          (<Typography className={styles.subPricePerKgText}>{currencySymbol}{price?.toFixed(2)} / Package</Typography>
          <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
        </Box>
        <Typography className={styles.hsCodeText} mt={1}>HS Code: {item?.hs_code ? item.hs_code : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.nameEntityText}>{item?.seller_name_entity ? item?.seller_name_entity : ''}</Typography>
        <Typography
          className={styles.productNameText}>{item?.seller_country ? '(' +  COUNTRIES.filter(
            (x) => x.code == item?.seller_country
          )?.[0]?.label + ')' : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.orderQuantityText} mb={1}>Required Packages:</Typography>
        <Grid item md={10} xs={10} className={styles.addToCartView}>
          <Grid item md={3} xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}
                className={styles.pointerView} onClick={() => addSubProduct('sub')}>
            <Typography className={styles.plusMinusNoText}>-</Typography>
          </Grid>
          <Grid item md={6} xs={6} display={"flex"} flexDirection={"row"}>
            <Grid item md={0.5} xs={4} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
              <Box className={styles.borderRightLine}/>
            </Grid>
            <Grid item md={11} xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <TextField
                fullWidth
                variant={"standard"}
                value={productCount}
                type={'text'}
                onChange={e => onChangeProductCount(e.target.value.replace(/[^0-9]/g, ''))}
                sx={{
                  '& .MuiInput-root': {
                    '&:before, :after, :hover:not(.Mui-disabled):before': {
                      borderBottom: 0,
                    },
                  },
                }}
                inputProps={{
                  autoComplete: 'none',
                  sx: {
                    color: '#000',
                    fontWeight: 'bold',
                    fontSize: 14,
                    textAlign: 'center'
                  },
                }}
                defaultValue={productCount}
              />
            </Grid>
            <Grid item md={0.5} xs={4} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
              <Box className={styles.borderLeftLine}/>
            </Grid>
          </Grid>
          <Grid item md={3} xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}
                className={styles.pointerView} onClick={() => addSubProduct('add')}>
            <Typography className={styles.plusMinusNoText}>+</Typography>
          </Grid>
        </Grid>
        <Typography className={styles.orderQuantityText} mt={1}>Order Quantity
          : {productCount * item?.package_net_weight} kg</Typography>
      </TableCell>
      <TableCell>
        <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'}
              alignItems={'center'}>

          <Typography
            className={styles.subPriceText} mt={1}>
            {sellerCurrencySymbol} {sellerTotalPrice?.toFixed(2)}
          </Typography>
          <Typography className={styles.subPricePerKgText} pl={3}>{sellerCurrencySymbol}{sellerPricePerKg?.toFixed(2)} /
            kg</Typography>

          <Typography
            className={styles.subPriceText}>
            ({currencySymbol} {totalPrice?.toFixed(2)})
          </Typography>
          <Typography className={styles.subPricePerKgText} pl={3}>({currencySymbol}{pricePerKg?.toFixed(2)} / kg)</Typography>

        </Grid>
      </TableCell>
      <TableCell>
        <Box className={styles.pointerView}>
          <FormButton
            title={'Delete'}
            buttonView={{
              width: '100%',
              backgroundColor: '#fff',
              color: THEME_COLOR3,
              border: '1px solid #aaa'
            }}
            bgColorStyleView={'#fff'}
            onSubmit={() => onDeleteCartClick(item.cart_uid)}
          />
        </Box>
        <Box className={styles.pointerView} mt={1}>
          <FormButton
            title={'Update Cart'}
            btnStyleView={{
              width: '100%',
              opacity: 0.65,
              backgroundColor: '#071c1f',
            }}
            bgColorStyleView={'#071c1f'}
            onSubmit={() => onUpdateCartClick(item.cart_uid)}
          />
        </Box>
        <Box className={styles.pointerView} mt={1}>
          <FormButton
            title={'Proceed to Review'}
            buttonView={{
              width: '100%',
              backgroundColor: THEME_COLOR5,
            }}
            bgColorStyleView={THEME_COLOR5}
            onSubmit={() => onProceedToReview(item.cart_uid)}
          />
        </Box>
      </TableCell>

    </>
  )
};

const mapStateToProps = (state) => {
  return {
    cart: state.userData.cart,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateCartList: data => dispatch(updateCartList(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCartList);
