import React, {useEffect, useState} from 'react'
import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import Container from "../../components/Container";
import OrderTab from "../../components/OrderTab";
import AlertMsg from "../../components/AlertMsg";

import styles from "./Payment.module.css";
import {getCurrencyRateList} from "../../redux/actions/userDataActions";
import {connect} from "react-redux";

const Payment = (props) => {

  useEffect(() => {
    props?.getCurrencyRateList()
  },[])

  return (
    <Container page={"Statement"} title={'Statement'} subTitle={'Statement'}>

      <Grid container item md={12} xs={12} mt={2}
            display={"flex"} alignItems={"center"} justifyContent={"center"}>

        <Grid container item md={11.6} xs={11.6}>
          <Typography className={styles.paymentTitleText}>Payments</Typography>

          <Typography className={styles.paymentDescText} mt={1}>This report provides payment information for
            pending,credited and debited payments including Trebbs
            fee details. This report includes all payments from beginning, regardless of their status. This report
            enables
            you to monitor and analyse performance of your seller business.</Typography>

          <Typography className={styles.allStatementText} mt={2}>All Statements</Typography>
        </Grid>


        <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine}/>

        <Grid container item md={11.6} xs={11.6} mt={2}>
          <OrderTab
            tab={'all'}
            currencyRateList={props?.currencyRateList}
          />
        </Grid>

      </Grid>
      <AlertMsg/>

    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    currencyRateList: state.userData.currencyRateList,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
