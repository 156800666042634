import * as React from 'react';
import {Box, Grid, CircularProgress} from '@mui/material';

import HomeHeader from "../components/HomeHeader";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import {connect} from "react-redux";

function Container(props) {

  return (
    <Grid container item md={12} xs={12} display={"flex"} flexDirection={"column"}
          style={styles.mainNav}
      // alignItems={"center"}
    >

      {props?.tgbin && (props?.page != 'home') ?
        <Navbar/>
        : null}

      <Grid container item md={12} xs={12}>
        <HomeHeader
          page={props?.page ? props?.page : ""}
          showHeaderData={props?.showHeaderData ? props?.showHeaderData : 'true'}
          showHeaderAllData={props?.showHeaderAllData ? props?.showHeaderAllData : 'true'}
        />
      </Grid>

      {props.listLoading ?
        <Box style={styles.contentView}
             display={"flex"}
             alignItems={"center"}
             justifyContent={"center"}>
          <CircularProgress
            color={'success'}/>
        </Box>
        :
        <Box>
          {props.children}
        </Box>}

      {/*footer*/}
      {props?.showFooter === 'false' ?
        null :
        <Footer/>}

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    listLoading: state.userData.listLoading,
    userToken: state.userData.userToken,
    tgbin: state.userData.tgbin,
  };
};

export default connect(mapStateToProps, null)(Container);

const styles = {
  mainNav: {
    backgroundColor: '#fff'
  },
  contentView: {
    minHeight: window.innerHeight - 140,
  }
};
