import React from 'react';
import {Button, Box} from '@mui/material';

import {THEME_COLOR} from "../constants";

const FormButton = (props) => {

  const onAdd = () => {
    props.onSubmit();
  };

  let btnStyle = props?.buttonView ? props.buttonView : styles.buttonView;
  let bgColor = props?.bgColorStyleView ? props?.bgColorStyleView : THEME_COLOR;

  return (
    <Button
      disableElevation={true}
      disabled={props?.loading}
      sx={[btnStyle, styles.btnView, props?.btnStyleView, {
        ':hover': {
          backgroundColor: bgColor,
        }
      }]}
      onClick={() => onAdd()}
      variant="contained"
      size="medium">
      {props.leftIcon ?
        <Box mr={1} mt={0.5}>
          {props.leftIcon}
        </Box>
        : null}
      {props?.loading ? 'Please Wait' : props.title}
    </Button>
  )
};

export default FormButton;

const styles = {
  buttonView: {
    backgroundColor: THEME_COLOR,
    color: '#FFF',
    borderRadius: 0
  },
  btnView: {
    fontFamily: 'InterBold',
    fontSize: 12,
    textTransform: 'capitalize'
  }
};
