import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Divider, Grid, MenuItem, Typography, Menu, Button} from "@mui/material";
import List from '@mui/material/List';
import ListItem, {listItemClasses} from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiDrawer from '@mui/material/Drawer';
import {styled, useTheme} from '@mui/material/styles';

import {connect} from "react-redux";

import {THEME_COLOR, THEME_COLOR2, MENU_LISTS, SIGN_IN_MENU_LIST, SIGN_OUT_MENU_LIST} from "../constants";
import {COUNTRIES} from "../constants/countries";

import {alertMsgEmpty, updateAlertMsg} from "../redux/actions/userDataActions";

import {ReactComponent as CloseIcon} from "../assets/img/close.svg";
import {ReactComponent as MenuIcon} from "../assets/img/menu.svg";

const menuLists = MENU_LISTS;
const signInMenuList = SIGN_IN_MENU_LIST;
const signOutMenuList = SIGN_OUT_MENU_LIST;

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: 0,
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Navbar = (props) => {
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState(menuLists);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.userToken) {
      setMenuList(signOutMenuList)
    } else {
      setMenuList(signInMenuList)
    }
  }, [props.userToken]);


  const onMenuClick = (item) => {
    navigate(item.navigation)
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const onCloseNav = () => {
    setOpen(false);
  };

  return (
    <Grid container item md={10} xs={12} display={"flex"} flexDirection={"row"}
          alignItems={"center"} style={styles.mainNav}>

      <Box onClick={handleDrawerToggle} sx={{cursor: 'pointer'}} ml={1}>
        <MenuIcon height={30} width={30}/>
      </Box>

      <Box style={styles.leftMainLineText} ml={1}/>

      <Typography style={styles.trebbsBuyerText} pl={3}>Trebbs Buyer</Typography>
      {props?.userToken ?
        <>
          <Typography style={styles.userCountryText} pl={3}>
            {COUNTRIES.filter(
              (x) => x.code == props?.userCountry
            )?.[0]?.label}
          </Typography>
          <Box style={styles.leftLineText} ml={3}/>
          <Box style={styles.userNameEntityBox} ml={3} mr={3} px={2}>
            <Typography style={styles.userNameEntityText}>{props?.userNameEntity}</Typography>
          </Box>
        </>
        : null}

      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={styles.headerView}>
          <ListItem
            disablePadding sx={{display: 'block'}}>
            <ListItemButton
              sx={{
                justifyContent: 'initial',
                ml: -1
              }}>
              {/*<ListItemIcon*/}
              {/*  sx={{*/}
              {/*    minWidth: 0,*/}
              {/*    mr: 1,*/}
              {/*    justifyContent: 'center',*/}
              {/*    height: 40,*/}
              {/*    width: 40,*/}
              {/*  }}>*/}
              {/*  <img alt="" src={LogoIcon} style={styles.imgStyle}/>*/}
              {/*</ListItemIcon>*/}
              <ListItemText primary={'Trebbs'}
                            sx={{
                              '& .MuiTypography-root': {
                                fontSize: 30,
                                color: '#000',
                                fontFamily: 'InterBold',
                              }, opacity: 1,
                            }}/>
              <ListItemIcon
                onClick={() => onCloseNav()}
                sx={{
                  minWidth: 0,
                  ml: 1,
                  justifyContent: 'center',
                }}>
                <CloseIcon height={20} width={20}/>
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </DrawerHeader>

        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Divider style={{backgroundColor: '#EEE', width: '90%'}}/>
        </Box>
        <List
          style={styles.listView}>
          {menuList?.filter((x) => !(x.label == 'Register' && props?.userStatus == 'active')).map((item, index) => (
            <ListItem
              key={item?.label}
              disablePadding
              sx={{
                display: 'block',
                width: '90%',
                "&.MuiListItem-root.Mui-selected": {
                  backgroundColor: '#FFF',
                  borderRadius: 2,
                },
              }}
              onClick={() => onMenuClick(item)}
            >
              <ListItemButton
                sx={{
                  minHeight: 45,
                  justifyContent: 'center',
                }}>
                <ListItemText primary={item?.label}
                              sx={{
                                '& .MuiTypography-root': {
                                  fontSize: 14,
                                  fontFamily: 'InterMedium',
                                  color: '#777',
                                },
                                opacity: 1
                              }}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.userData.userToken,
    userCountry: state.userData.userCountry,
    userNameEntity: state.userData.userNameEntity,
    userStatus: state.userData.userStatus
  }
};

const mapDispatchToProps = dispatch => {
  return {
    alertMsgEmpty: data => dispatch(alertMsgEmpty(data)),
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

const styles = {
  mainNav: {
    minHeight: 50
  },
  headerView: {
    backgroundColor: '#FFF',
    height: 120
  },
  listView: {
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  },
  trebbsBuyerText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 17,
  },
  userCountryText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 11,
  },
  leftMainLineText: {
    borderLeft: '1px solid #000',
    height: 50,
  },
  leftLineText: {
    borderLeft: '1px solid #aaa',
    height: 40,
  },
  userNameEntityBox: {
    border: '1px solid #bbb',
    backgroundColor: THEME_COLOR2,
    borderRadius: 2,
    boxShadow: '0px 2px 3px #aaa',
  },
  userNameEntityText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
    textTransform: 'capitalize'
  },
};
