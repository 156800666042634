import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Grid, Typography} from "@mui/material";
import {motion} from "framer-motion";

import {ReactComponent as LocationIcon} from "../assets/img/location.svg";
import {ReactComponent as PhoneIcon} from "../assets/img/phone.svg";
import {ReactComponent as EmailIcon} from "../assets/img/email.svg";

import {THEME_COLOR, THEME_COLOR7} from "../constants";

const companyFooter = [
  {
    label: 'About',
    value: 'about',
    navigation: true,
    title: 'company'
  },
  // {
  //   label: 'Contact',
  //   value: 'contact',
  //   navigation: false,
  //   title: 'company'
  // },
  // {
  //   label: 'Support',
  //   value: 'support',
  //   navigation: false,
  //   title: 'company'
  // },
  {
    label: 'User terms and conditions',
    value: 'user_terms_and_conditions',
    navigation: true,
    title: 'company'
  },
  {
    label: 'Privacy Policy',
    value: 'privacy_policy',
    navigation: true,
    title: 'company'
  },
  {
    label: 'Payment Policy',
    value: 'payment_policy',
    navigation: true,
    title: 'company'
  },
];

const Footer = (props) => {
  const [hover, setHover] = useState(false);
  const [selected, setSelected] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');

  const navigate = useNavigate();

  const onEnterFooter = (item) => {
    setSelectedTitle(item.title);
    setSelected(item.value);
    setHover(true);
  };

  const onLeaveFooter = () => {
    setSelectedTitle('')
    setSelected('');
    setHover(false);
  };

  return (
    <Grid sx={{backgroundColor: '#00000040', minHeight: 400}} container item md={12} xs={12} mt={4}
          display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"} pt={10}>
      <Grid container item md={10.5} xs={11} display={"flex"} flexDirection="row"
            justifyContent={"space-between"}>
        <Grid container item md={5.8} xs={12} display={"flex"} flexDirection="column">
          <Typography style={styles.trebbsText}>Trebbs</Typography>
          <Typography style={styles.tradeText} pl={0.5}>Trade beyond <span
            style={styles.textColor}>borders</span></Typography>
          <Box display={"flex"} flexDirection="row" alignItems={"center"} mt={3}>
            <LocationIcon height={20} width={20}/>
            <Typography style={styles.footerText} ml={1.6}>Kochi, Kerala, India</Typography>
          </Box>
          {/*<Box display={"flex"} flexDirection="row" alignItems={"center"} mt={2}>*/}
          {/*  <PhoneIcon height={20} width={20}/>*/}
          {/*  <Typography style={styles.footerText} ml={1.6}>+0123-456789</Typography>*/}
          {/*</Box>*/}
          <Box display={"flex"} flexDirection="row" alignItems={"center"} mt={2}>
            <EmailIcon height={20} width={20}/>
            <Typography style={styles.footerText} ml={1.6}>contact@trebbs.com</Typography>
          </Box>
        </Grid>
        <Grid container item md={5.8} xs={12} display={"flex"} flexDirection="column">
          <Typography sx={styles.footerTitleText}>Company</Typography>

          {companyFooter.map((item, index) => {
            return (
              <motion.div
                key={index}
                whileHover={{
                  x: 40,
                  opacity: 1,
                  scale: 1.1,
                  transition: {ease: "easeOut", duration: 0.3},
                }}>
                <Typography sx={styles.footerSlideText} mt={2}
                            onClick={() => item.navigation ? navigate('/' + item.value) : null}
                            onMouseEnter={() => onEnterFooter(item)}
                            onMouseLeave={() => onLeaveFooter()}>{hover ? ((item.title == selectedTitle && item.value == selected) ? '// ' : '') : ''}{item.label}</Typography>
              </motion.div>
            )
          })}
        </Grid>
      </Grid>
      <Box style={styles.bottomLine} mt={10} mb={4}/>
      <Grid container item md={11} xs={11} display={"flex"} flexDirection="row"
            justifyContent={{md: "space-between", xs: "center"}} mb={4}>
        <Box>
          <Typography style={styles.footerText}>All Rights Reserved @ Trebbs 2023</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;

const styles = {
  trebbsText: {
    fontFamily: 'InterBold',
    fontSize: 25,
    color: THEME_COLOR7
  },
  tradeText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 11,
    marginTop: -6
  },
  textColor: {
    color: THEME_COLOR7
  },
  bottomLine: {
    borderBottom: '1px solid #DDD',
    width: '100%'
  },
  footerTitleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 20,
    marginTop: {md: 0, xs: 5}
  },
  footerSlideText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15,
    cursor: 'pointer',
    ":hover": {
      color: THEME_COLOR,
    }
  },
  footerText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
    cursor: 'pointer',
  },
};
