import React, {useEffect, useState, useRef} from 'react'
import {
  Grid, Typography, Box, Slider, CircularProgress
} from '@mui/material';
import {useLocation, useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import ItemProductList from "../../components/ItemProductList";
import ProductDetailModal from "../../components/ProductDetailModal";
import AlertMsg from "../../components/AlertMsg";
import Page from "../../components/Page";
import ItemProductListMenuGrid from "../../components/ItemProductListMenuGrid";

import {connect} from "react-redux";
import {
  getProduct,
  getCategoryProductCount,
  addToCart,
  getCurrencyRateList,
  getExportCountryList,
  updateApiStatus
} from "../../redux/actions/userDataActions";

import FormButton from "../../components/FormButton";

import {ReactComponent as MenuGridIcon} from "../../assets/img/menu_grid.svg";
import {ReactComponent as GridIcon} from "../../assets/img/grid.svg";
import {ReactComponent as Checkbox} from "../../assets/img/radio_checked.svg";
import {ReactComponent as UnCheckbox} from "../../assets/img/radio_unchecked.svg";
import {ReactComponent as TickIcon} from "../../assets/img/tick.svg";

import {THEME_COLOR, COUNTRY_FLAG_URL, THEME_COLOR2, THEME_COLOR4, THEME_COLOR6, THEME_COLOR3} from "../../constants";

import '../../assets/css/custom.css';

import {COUNTRIES} from "../../constants/countries";

const Product = (props) => {
  const [loading, setLoading] = useState(true)
  const [productDetailModalVisible, setShowProductDetailModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [gridType, setGridType] = useState(0)
  const [pageNum, setPageNum] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [sellerUid, setSellerUid] = useState("");
  const [pageType, setPageType] = useState("product");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (props?.apiStatus == 'GET_PRODUCT_FAIL') {
      if(pageType == 'product') {
        navigate('/home')
      } else {
        setPageType('product')
        setSellerUid('')
        navigate('/product');
      }
    }
  }, [props?.apiStatus])

  useEffect(() => {
    props?.getCurrencyRateList();
  }, [])

  useEffect(() => {
    if (props.product.length > 0 && selectedProduct != null) {
      let data_list = [...props.product];
      if (data_list.length > 0) {
        let productIndex = data_list.findIndex(x => x.product_uid == selectedProduct.product_uid);
        if (productIndex >= 0) {
          setSelectedProduct(data_list[productIndex]);
        }
      }
    }
    setLoading(false)
  }, [props.product]);

  useEffect(() => {
    var str = location.pathname;
    let page_type = str.split("/").pop();

    let seller_uid = '';
    if (page_type == 'product') {
      setPageType('product')
    } else {
      seller_uid = page_type;
      setPageType('seller')
    }

    if (seller_uid) {
      setSellerUid(seller_uid);
    } else {
      setSellerUid('');
    }
  }, [location.pathname]);

  useEffect(() => {
    let data = {
      sellerUid: sellerUid
    };
    props.getExportCountryList(JSON.stringify(data));
  }, [sellerUid])

  useEffect(() => {
    let data = {
      sellerUid: sellerUid
    };
    props.getCategoryProductCount(JSON.stringify(data));
  }, [sellerUid])

  const getProductList = (page) => {
    let data = {
      page: page,
      category: selectedCategory,
      country: selectedCountry,
      sellerUid: sellerUid
    };
    setPageNum(page);
    props.getProduct(JSON.stringify(data))
  };

  useEffect(() => {
    getProductList(1);
  }, [selectedCategory, selectedCountry, sellerUid]);

  const getProductListPage = (page) => {
    getProductList(page);
  };

  const onCategoryClick = (categoryUid) => {
    setSelectedCategory(categoryUid)
  };

  const onModalCloseClick = () => {
    setShowProductDetailModalVisible(false)
  };

  const onModalAddToCartClick = (data) => {
    props.addToCart(JSON.stringify(data));
  };

  const onProductClick = (item) => {
    window.location.href = "/product_detail?id=" + item.product_uid
  };

  const onProductDataClick = (item, type) => {
    if (type == 'view') {
      window.location.href = "/product_detail?id=" + item.product_uid
    } else if (type == 'cart') {
      setSelectedProduct(item);
      setShowProductDetailModalVisible(true)
    }
  };

  const onCountryFilter = (item) => {
    setSelectedCountry(item)
  };

  const onClearAll = () => {
    setSelectedCategory('')
    setSelectedCountry('')
  };

  const onRefreshCategory = () => {
    setSelectedCategory('')
  };

  const onRefreshCountry = () => {
    setSelectedCountry('')
  };


  if (loading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Container page={"Product"} title={'Products'} subTitle={'Products'}>

      <Grid container item md={12} xs={12} display={"flex"} flexDirection={"column"}
            justifyContent={{md: "center", xs: "none"}} alignItems={{md: "none", xs: "center"}} mt={2}>

        <Grid container item md={10} xs={10} display={"flex"}
              flexDirection={{md: "row", xs: "column"}}
              justifyContent={"space-between"} alignItems={"flex-start"}>

          <Grid item md={3.5} xs={12} display={"flex"}
                flexDirection={"column"} sx={styles.leftMainView}>
            <Grid
              item md={12} xs={12}
              display={"flex"}
              flexDirection={"column"}
              sx={styles.leftView} p={2}>
              <Grid item md={12} xs={12} mb={3} display={'flex'}
                    flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={styles.leftText}>Category</Typography>
                <Typography sx={styles.refreshText} onClick={onRefreshCategory}>Refresh</Typography>
              </Grid>
              <Grid display={"flex"} flexDirection={"column"} style={styles.listSection}>
                {props?.categoryProductCountList?.length > 0 ?
                  props?.categoryProductCountList?.map((item, index) => {
                    return (
                      <Grid
                        className={'categoryFilterView'}
                        onClick={() => onCategoryClick(item.category_uid)}
                        key={index} mb={2} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Box pr={1}>
                          {selectedCategory == item.category_uid ?
                            <Checkbox height={13} width={13}/>
                            :
                            <UnCheckbox height={13} width={13}/>
                          }
                        </Box>
                        <Typography
                          sx={styles.leftValueText}>
                          {item.label} ({item?.product_count ? item?.product_count : 0})
                        </Typography>
                      </Grid>
                    )
                  }) : null}
              </Grid>
            </Grid>

            <Grid
              item md={12} xs={12}
              display={"flex"}
              flexDirection={"column"}
              sx={styles.leftView} mt={2} p={2}>
              <Grid item md={12} xs={12} mb={3} display={'flex'}
                    flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={styles.leftText}>Origin Country</Typography>
                <Typography sx={styles.refreshText} onClick={onRefreshCountry}>Refresh</Typography>
              </Grid>
              <Grid display={"flex"} flexDirection={"column"} style={styles.listSection}>
                {props?.exportCountryList?.length > 0 ?
                  props?.exportCountryList?.map((item, index) => {
                    return (
                      <Grid
                        className={'categoryFilterView'}
                        onClick={() => onCountryFilter(item)}
                        key={index} mb={2} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Box pr={1}>
                          {selectedCountry == item ?
                            <Checkbox height={13} width={13}/>
                            :
                            <UnCheckbox height={13} width={13}/>
                          }
                        </Box>
                        <Typography pr={0.6}>
                          <img
                            loading="lazy"
                            width="20"
                            src={COUNTRY_FLAG_URL + `${item?.toLowerCase()}.png`}
                          />
                        </Typography>
                        <Typography
                          sx={styles.leftValueText}>
                          {COUNTRIES.filter(
                            (x) => x.code == item
                          )?.[0]?.label}
                        </Typography>
                      </Grid>
                    )
                  }) : null}
              </Grid>
            </Grid>

            <Grid item md={12} xs={12}
                  display={"flex"} alignItems={'center'} justifyContent={'center'} mt={2}>
              <FormButton
                title={'Clear All'}
                btnStyleView={styles.clearAllBtnStyleView}
                bgColorStyleView={THEME_COLOR6}
                onSubmit={onClearAll}
              />
            </Grid>
          </Grid>

          <Grid item md={8.3} xs={12} display={"flex"} flexDirection={"column"} mt={{md: 0, xs: 4}}>

            {pageType == 'seller' ?
              props?.product?.length > 0 ?
                <Grid display={"flex"} flexDirection={{md: "column", xs: "column"}} item md={11.6} xs={12} mb={1}
                      style={styles.topView} p={1}>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    {props?.product?.[0]?.seller_country ?
                      <>
                        <Typography pr={0.4}>
                          <img
                            loading="lazy"
                            width="20"
                            src={COUNTRY_FLAG_URL + `${props?.product?.[0]?.seller_country?.toLowerCase()}.png`}
                          />
                        </Typography>
                        <Typography sx={styles.sellerCountryText}>
                          {COUNTRIES.filter(
                            (x) => x.code == props?.product?.[0]?.seller_country
                          )?.[0]?.label}
                        </Typography>
                      </>
                      : null}
                  </Box>
                  <Box display={'flex'} flexDirection={'row'} mt={0.5}>
                    {props?.product?.[0]?.business_logo ?
                      <Box style={styles.logoImgView} mr={0.4}>
                        <img src={props?.product?.[0]?.business_logo} style={styles.logoImgStyle}/>
                      </Box>
                      : null}
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                      <Box>
                        <Typography sx={styles.sellerCompanyNameText}>{props?.product?.[0]?.name_entity}</Typography>
                      </Box>
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={0.5}>
                        <TickIcon height={15} width={15}/>
                        <Typography sx={styles.verifiedProfileText} pl={0.4}>Verified Profile</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                : null
              : null}
            <Grid display={"flex"} flexDirection={{md: "row", xs: "column"}} item md={11.6} xs={12} mb={2}
                  style={pageType == 'product' ? styles.topView : styles.sellerTopView} p={2}>
              <Grid item={12} xs={12} display={"flex"} flexDirection={'row'} alignItems={"center"}
                    justifyContent={{md: "flex-start", xs: "unset"}} mt={{md: 0, xs: 2}}>
                <Typography
                  sx={styles.productCountValueText}>
                  {props?.productTotalCount > 0 ? props?.productTotalCount : 0}
                </Typography>
                <Typography
                  sx={styles.productTitleText} pl={0.6}>
                  {props?.productTotalCount > 1 ? 'Products' : 'Product'}
                </Typography>
              </Grid>

              {/*<Grid item={3} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}*/}
              {/*      justifyContent={{md: "flex-end", xs: "unset"}}>*/}
              {/*  <Box*/}
              {/*    onClick={() => setGridType(0)}*/}
              {/*    sx={[{*/}
              {/*      path: {*/}
              {/*        fill: gridType == 0 ? THEME_COLOR : '#000',*/}
              {/*      }*/}
              {/*    }, styles.gridIconView]}>*/}
              {/*    <GridIcon height={35} width={35}/>*/}
              {/*  </Box>*/}
              {/*  <Box*/}
              {/*    onClick={() => setGridType(1)}*/}
              {/*    pl={2} sx={[{*/}
              {/*    svg: {*/}
              {/*      fill: gridType == 1 ? THEME_COLOR : '#000',*/}
              {/*    }*/}
              {/*  }, styles.menuGridIconView]}>*/}
              {/*    <MenuGridIcon height={37} width={37}/>*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
            </Grid>

            {gridType == 0 ?
              props.productLoading ?
                <Box style={styles.contentView}
                     display={"flex"}
                     alignItems={"center"}
                     justifyContent={"center"}>
                  <CircularProgress
                    color={'success'}/>
                </Box>
                :
                <Grid>
                  <Grid display={"flex"} flexDirection={"row"}
                        flexWrap={"wrap"}
                        justifyContent={{
                          md: "unset",
                          xs: "space-between"
                        }}>
                    {props?.product?.length > 0 ?
                      props?.product?.map((item, index) => {
                        return (
                          <Grid key={index} item md={3.8} xs={5.6} mr={{md: 1, xs: 0}} mt={2}>
                            <ItemProductList
                              type={'productList'}
                              data={item}
                              // onProduct={onProductClick}
                              onProduct={() => null}
                              currencyRateList={props?.currencyRateList}
                              onSubmit={onProductDataClick}
                            />
                          </Grid>
                        )
                      }) : null}
                  </Grid>

                  <Grid display={"flex"} justifyContent={"center"} flex={1} mt={4}>
                    {props.productTotalPages > 0 ?
                      <Page
                        totalPages={props.productTotalPages}
                        pageNum={pageNum}
                        onSelectPage={getProductListPage}/>
                      : null}
                  </Grid>
                </Grid>
              :
              props.productLoading ?
                <Box style={styles.contentView}
                     display={"flex"}
                     alignItems={"center"}
                     justifyContent={"center"}>
                  <CircularProgress
                    color={'success'}/>
                </Box>
                :
                <Grid>
                  {props?.product?.length > 0 ?
                    props?.product?.map((item, index) => {
                      return (
                        <Grid key={index} item md={12} xs={12} mt={2}>
                          <ItemProductListMenuGrid
                            data={item}
                            // onProduct={onProductClick}
                            onProduct={() => null}
                            currencyRateList={props?.currencyRateList}
                            onSubmit={onProductDataClick}
                          />
                        </Grid>
                      )
                    }) : null}

                  <Grid display={"flex"} justifyContent={"center"} flex={1} mt={4}>
                    {props.productTotalPages > 0 ?
                      <Page
                        totalPages={props.productTotalPages}
                        pageNum={pageNum}
                        onSelectPage={getProductListPage}/>
                      : null}
                  </Grid>
                </Grid>}

          </Grid>
        </Grid>

      </Grid>

      {selectedProduct != null ?
        <ProductDetailModal
          modalVisible={productDetailModalVisible}
          data={selectedProduct}
          currencyRateList={props?.currencyRateList}
          onClose={onModalCloseClick}
          onAddToCart={onModalAddToCartClick}/>
        : null}

      <AlertMsg/>
    </Container>
  )
};


const mapStateToProps = (state) => {
  return {
    product: state.userData.product,
    productLoading: state.userData.productLoading,
    productTotalPages: state.userData.productTotalPages,
    categoryProductCountList: state.userData.categoryProductCountList,
    currencyRateList: state.userData.currencyRateList,
    exportCountryList: state.userData.exportCountryList,
    productTotalCount: state.userData.productTotalCount,
    apiStatus: state.userData.apiStatus,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: data => dispatch(addToCart(data)),
    getProduct: data => dispatch(getProduct(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
    getExportCountryList: data => dispatch(getExportCountryList(data)),
    getCategoryProductCount: data => dispatch(getCategoryProductCount(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);

const styles = {
  leftMainView: {
    height: 'fit-content'
  },
  leftView: {
    backgroundColor: THEME_COLOR2,
    border: '1px solid ' + THEME_COLOR2,
    borderRadius: 1,
    boxShadow: '0px 2px 3px #aaa',
  },
  leftLineView: {
    borderBottom: '4px solid ' + THEME_COLOR,
    width: '100%',
  },
  leftText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 16,
  },
  refreshText: {
    color: THEME_COLOR3,
    fontFamily: 'InterMedium',
    fontSize: 13,
    cursor: 'pointer'
  },
  listSection: {
    height: 300,
    overflowY: 'auto'
  },
  leftValueText: {
    textTransform: 'capitalize',
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  priceRangeSliderView: {
    '& .MuiSlider-thumb': {
      color: THEME_COLOR
    },
    '& .MuiSlider-track': {
      color: THEME_COLOR
    },
    '& .MuiSlider-rail': {
      color: "black"
    },
  },
  selectedCategoryText: {
    color: THEME_COLOR
  },
  notSelectedCategoryText: {
    color: '#000',
  },
  rangeText: {
    color: '#666',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  gridIconView: {
    cursor: 'pointer',
    ":hover": {
      path: {
        fill: THEME_COLOR,
      }
    }
  },
  menuGridIconView: {
    cursor: 'pointer',
    ":hover": {
      svg: {
        fill: THEME_COLOR,
      }
    }
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e4ecf2',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
    "& .MuiSelect-select:focus": {
      backgroundColor: '#FFF',
    },
  },
  productCountValueText: {
    color: THEME_COLOR4,
    fontFamily: 'InterBold',
    fontSize: {md: 16, xs: 13},
  },
  productTitleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: {md: 16, xs: 13},
  },
  contentView: {
    minHeight: window.innerHeight - 140,
  },
  topView: {
    backgroundColor: THEME_COLOR2,
    border: '1px solid ' + THEME_COLOR2,
    borderRadius: 1,
    boxShadow: '0px 2px 3px #aaa',
  },
  sellerTopView: {
    borderBottom: '1px solid #000',
  },
  sellerCountryText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  logoImgView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    overflow: 'hidden',
    border: '1px solid #aaa',
    borderRadius: 100,
    height: 25,
    width: 25,
  },
  logoImgStyle: {
    height: '45%',
    width: '45%',
  },
  sellerCompanyNameText: {
    color: THEME_COLOR3,
    fontFamily: 'InterBold',
    fontSize: 14,
    textTransform: 'capitalize'
  },
  verifiedProfileText: {
    color: THEME_COLOR,
    fontFamily: 'InterMedium',
    fontSize: 13,
  },
  clearAllBtnStyleView: {
    fontSize: 12,
    width: '40%',
    backgroundColor: THEME_COLOR6,
    color: '#fff',
  }
};

