import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import moment from 'moment';

import FormButton from "./FormButton";
import {getCurrencyConversion, getCurrencyConversionCalc} from "../assets/functions/common";

import {ReactComponent as CheckBoxIcon} from "../assets/img/checkbox.svg";
import {ReactComponent as UnCheckBoxIcon} from "../assets/img/uncheckbox.svg";

import styles from "./CartCheckoutPendingList.module.css"

import {THEME_COLOR3} from "../constants";
import {COUNTRIES} from "../constants/countries";

const CartCheckoutPendingList = (props) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')

  const [sellerTotalPrice, setSellerTotalPrice] = useState(0);
  const [sellerPrice, setSellerPrice] = useState(0);
  const [sellerPricePerKg, setSellerPricePerKg] = useState(0);
  const [sellerShippingCost, setSellerShippingCost] = useState('')
  const [sellerCurrencySymbol, setSellerCurrencySymbol] = useState('')

  const [checked, setChecked] = useState(false);
  const [showError, setShowError] = useState('');

  useEffect(() => {
    if (props.data) {
      setShowError(props.data?.error);
    }
  }, [props.data?.error])

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {

        let price_val = 0;
        let total_price = 0;
        let price_per_kg = 0;
        let shipping_cost = 0;

        let seller_price_val = 0;
        let seller_total_price = 0;
        let seller_price_per_kg = 0;
        let seller_shipping_cost = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: props.data?.shipping_details?.shipping_cost ? props.data?.shipping_details?.shipping_cost : 0,
          seller_currency: props?.data?.seller_currency,
          user_currency: props?.data?.user_currency,
          main_currency: props?.data?.main_currency,
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          price_val = data?.converted_price;
          price_per_kg = data?.converted_price_per_kg;
          total_price = data?.converted_total_price;
          shipping_cost = data?.converted_shipping_cost;
          seller_price_val = data?.seller_price;
          seller_price_per_kg = data?.seller_price_per_kg;
          seller_total_price = data?.seller_total_price;
          seller_shipping_cost = data?.seller_shipping_cost;
          setCurrencySymbol(data?.user_currency_symbol)
          setSellerCurrencySymbol(data?.seller_currency_symbol)
        }

        setPrice(price_val);
        setTotalPrice(total_price);
        setPricePerKg(price_per_kg);
        setShippingCost(shipping_cost)

        setSellerPrice(seller_price_val);
        setSellerTotalPrice(seller_total_price)
        setSellerPricePerKg(seller_price_per_kg);
        setSellerShippingCost(seller_shipping_cost)
      }
    }
  }, [props.data, props?.currencyRateList])

  useEffect(() => {
    let list = [...props?.checkedData];
    let index = list.findIndex((x) => x?.cart_uid == props?.data?.cart_uid);
    if (index >= 0) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [props?.checkedData])


  const onCheckboxClick = (item) => {
    // props.onCheck(item?.cart_uid)
    props.onCheck(item)
  };

  const onProceedToCheckout = (item) => {
    props.proceedToCheckout(item)
  }

  let item = props.data;

  return (
    <>
      <TableCell
        onClick={() => onCheckboxClick(item)}
        className={styles.checkboxView}>
        {checked ?
          <CheckBoxIcon height={25} width={25}/>
          : <UnCheckBoxIcon height={25} width={25}/>}
      </TableCell>
      <TableCell>
        <Typography className={styles.productNameText}>#{item.cart_uid}</Typography>
        {showError ?
          <Box mt={1}>
            <Typography className={styles.moqErrorText}>{showError}</Typography>
          </Box>
          : null}
      </TableCell>
      <TableCell className={styles.dataView}>
        {item.product_image ?
          <Grid item md={4} xs={12} className={styles.imgView}>
            <img src={item.product_image} className={styles.imgStyle}/>
          </Grid>
          : null}
        <Typography className={styles.productNameTitleText}>
          {(item?.variety ? item.variety : 'NIL Variety')
          + ', ' + (item?.name ? item.name : 'NIL')
          + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
          + ', ' + (item?.package_net_weight ?
            (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
            : 'NIL Packing')
          + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
        </Typography>
        <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          (<Typography className={styles.subPricePerKgText}>{currencySymbol}{pricePerKg?.toFixed(2)} / Kg</Typography>
          <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
        </Box>
        <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          (<Typography className={styles.subPricePerKgText}>{currencySymbol}{price?.toFixed(2)} / Package</Typography>
          <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
        </Box>
        <Typography className={styles.hsCodeText} mt={1}>HS Code: {item?.hs_code ? item.hs_code : ''}</Typography>
        <Typography className={styles.nameEntityText}>{item?.seller_name_entity ? item?.seller_name_entity : ''}</Typography>
        <Typography
          className={styles.productNameText}>{item?.seller_country ? '(' + COUNTRIES.filter(
          (x) => x.code == item?.seller_country
        )?.[0]?.label + ')' : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.deliveryAddressText}>
          {item?.delivery_address?.state_province ?
            (item?.delivery_address?.address_line_1
              + ',' + item?.delivery_address?.address_line_2
              + ',' + item?.delivery_address?.city
              + ',' + item?.delivery_address?.state_province
              + ',' + item?.delivery_address?.zip_code
            )
            : '-'}
        </Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.shippingDetailText}>
          Total number of packages : {item?.quantity ? item?.quantity : 0}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Total product quantity
          : {item?.package_net_weight ? (item?.quantity * item?.package_net_weight + (item?.unit ? item.unit : '')) : 0}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Shipping method : {item?.shipping_details?.shipping_mode ? item?.shipping_details?.shipping_mode : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Container type : {item?.shipping_details?.container_type ? item?.shipping_details?.container_type : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Cargo ID : {item?.shipping_details?.cargo_id ? item?.shipping_details?.cargo_id : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Shipping date
          : {item?.shipping_details?.shipping_date ? moment(item?.shipping_details?.shipping_date).format('DD-MM-YYYY') : '-'}
        </Typography>
        {item?.shipping_details?.delivery_date ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Delivery date
            : {item?.shipping_details?.delivery_date ? moment(item?.shipping_details?.delivery_date).format('DD-MM-YYYY') : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.carrier ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Carrier: {item?.shipping_details?.carrier ? item?.shipping_details?.carrier : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.loading_port ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Port of loading: {item?.shipping_details?.loading_port ? item?.shipping_details?.loading_port : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.discharge_port ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Port of discharge: {item?.shipping_details?.discharge_port ? item?.shipping_details?.discharge_port : '-'}
          </Typography>
          : null}
      </TableCell>
      <TableCell>
        {item?.shipping_details?.payment_term ?
          <>
            <Typography
              className={styles.paymentTermText}>{item?.shipping_details?.payment_term}</Typography>
            <Typography
              className={styles.paymentTermDescText}>({item?.shipping_details?.payment_term_description})</Typography>
          </>
          : '-'}
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography
          className={styles.subPriceText} mt={1}>
          {sellerCurrencySymbol} {sellerTotalPrice?.toFixed(2)}
        </Typography>
        <Typography className={styles.subPricePerKgText} pl={3}>{sellerCurrencySymbol}{sellerPricePerKg?.toFixed(2)} /
          kg</Typography>
        <Typography
          className={styles.subPriceText}>
          ({currencySymbol} {totalPrice?.toFixed(2)})
        </Typography>
        <Typography className={styles.subPricePerKgText} pl={3}>({currencySymbol}{pricePerKg?.toFixed(2)} /
          kg)</Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.calculationPendingText}>
          {sellerCurrencySymbol} {sellerShippingCost ? sellerShippingCost?.toFixed(2) : '0'}
        </Typography>
        <Typography className={styles.calculationPendingText} pl={3}>
          {currencySymbol} {shippingCost ? shippingCost?.toFixed(2) : '0'}
        </Typography>
        <Typography className={styles.incotermText}>
          ({item?.shipping_details?.incoterm})
        </Typography>
      </TableCell>
      <TableCell className={styles.btnView}>
        <FormButton
          title={'Proceed to Checkout'}
          buttonView={{
            width: '100%',
            backgroundColor: THEME_COLOR3
          }}
          bgColorStyleView={THEME_COLOR3}
          onSubmit={() => onProceedToCheckout(item)}
        />
      </TableCell>

    </>
  )
};

export default CartCheckoutPendingList;
