import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import moment from "moment";

import styles from './OrderDetailView.module.css'

import {getCurrencyConversionCalc, priceCalculation, unshippedOrderStatus} from "../assets/functions/common";

import {COUNTRIES} from "../constants/countries";

const OrderDetailView = (props) => {
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingCostPerPackage, setShippingCostPerPackage] = useState(0);
  const [shippingCostPerKg, setShippingCostPerKg] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [sellerPrice, setSellerPrice] = useState(0);
  const [sellerPricePerKg, setSellerPricePerKg] = useState(0);
  const [sellerTotalPrice, setSellerTotalPrice] = useState(0);
  const [sellerShippingCost, setSellerShippingCost] = useState('')
  const [sellerShippingCostPerPackage, setSellerShippingCostPerPackage] = useState(0);
  const [sellerShippingCostPerKg, setSellerShippingCostPerKg] = useState(0);
  const [sellerCargoPrice, setSellerCargoPrice] = useState(0);
  const [sellerCurrencySymbol, setSellerCurrencySymbol] = useState('')
  const [orderStatus, setOrderStatus] = useState('');

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {
        let user_currency_symbol = '';
        let converted_price = 0;
        let converted_price_per_kg = 0;
        let converted_total_price = 0;
        let converted_shipping_cost = 0;
        let converted_shipping_cost_per_package = 0;
        let converted_shipping_cost_per_kg = 0;
        let converted_cargo_price = 0;
        let seller_currency_symbol = '';
        let seller_price = 0;
        let seller_price_per_kg = 0;
        let seller_total_price = 0;
        let seller_shipping_cost = 0;
        let seller_shipping_cost_per_package = 0;
        let seller_shipping_cost_per_kg = 0;
        let seller_cargo_price = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: props?.data?.shipping_cost ? props?.data?.shipping_cost : 0,
          seller_currency: props?.data?.seller_currency,
          user_currency: props?.data?.user_currency,
          main_currency: props?.data?.main_currency,
          rate: props?.data?.rate,
          usd_rate: props?.data?.usd_rate
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          user_currency_symbol = data?.user_currency_symbol;
          converted_price = data?.converted_price;
          converted_price_per_kg = data?.converted_price_per_kg;
          converted_total_price = data?.converted_total_price;
          converted_shipping_cost = data?.converted_shipping_cost;
          converted_shipping_cost_per_package = data?.converted_shipping_cost_per_package;
          converted_shipping_cost_per_kg = data?.converted_shipping_cost_per_kg;
          converted_cargo_price = data?.converted_cargo_price;
          seller_currency_symbol = data?.seller_currency_symbol;
          seller_price = data?.seller_price;
          seller_price_per_kg = data?.seller_price_per_kg;
          seller_total_price = data?.seller_total_price;
          seller_shipping_cost = data?.seller_shipping_cost;
          seller_shipping_cost_per_package = data?.shipping_cost_per_package;
          seller_shipping_cost_per_kg = data?.shipping_cost_per_kg;
          seller_cargo_price = data?.seller_cargo_price;
        }

        setPrice(converted_price);
        setTotalPrice(converted_total_price);
        setPricePerKg(converted_price_per_kg);
        setCargoPrice(converted_cargo_price)
        setShippingCost(converted_shipping_cost)
        setShippingCostPerPackage(converted_shipping_cost_per_package)
        setShippingCostPerKg(converted_shipping_cost_per_kg)
        setCurrencySymbol(user_currency_symbol)

        setSellerPrice(seller_price);
        setSellerTotalPrice(seller_total_price)
        setSellerPricePerKg(seller_price_per_kg);
        setSellerCargoPrice(seller_cargo_price)
        setSellerShippingCost(seller_shipping_cost)
        setSellerShippingCostPerPackage(seller_shipping_cost_per_package)
        setSellerShippingCostPerKg(seller_shipping_cost_per_kg)
        setSellerCurrencySymbol(seller_currency_symbol)

      }

      if (props?.data?.order_status == 'unshipped') {
        // if (props?.data?.unshipped_timestamp) {
        //   let order_status = unshippedOrderStatus(props?.data?.unshipped_timestamp);
        //   setOrderStatus(order_status);
        // }
        if(props?.data?.payment_status == 'paid') {
          setOrderStatus('Pickup Scheduled');
        } else {
          setOrderStatus('Confirmed')
        }
      } else {
        setOrderStatus(props?.data?.order_status)
      }

    }
  }, [props.data, props?.currencyRateList])

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>SKU</Typography>
                <Typography className={styles.subTitleText} ml={1}>Product ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Product</Typography>
                <Typography className={styles.subTitleText} ml={1}>HS Code</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Seller</Typography>
                <Typography className={styles.subTitleText} ml={1}>TGSIN</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order ID</Typography>
                <Typography className={styles.subTitleText} ml={1}>Order Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Quantity</Typography>
                <Typography className={styles.subTitleText}>No of Packages</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Total EXW Price</Typography>
                <Typography className={styles.subTitleText} ml={1}>Shipping Cost</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Total</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Price/Kg</Typography>
                <Typography className={styles.subTitleText} ml={1}>Price/Package</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Status</Typography>
                <Typography className={styles.subTitleText} ml={1}>Payment Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography
                  className={styles.valueText}>{item?.sku ? item.sku : '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.name ? item.name : '-'}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  Hs code: {item?.hs_code ? (item.hs_code + ' - ' + item.hs_code_title) : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  Seller: {item?.seller_name_entity ? item?.seller_name_entity : ''}
                  {item?.seller_country ? '(' + COUNTRIES.filter(
                    (x) => x.code == item?.seller_country
                  )?.[0]?.label + ')' : '-'}</Typography>
                <Typography className={styles.subValueText} mt={1}>
                  TGSIN: {item?.tgsin ? item.tgsin : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>{item?.order_uid ? '#' + item.order_uid : '-'}</Typography>
                <Typography
                  className={styles.subValueText}
                  mt={1}>{item?.order_timestamp ? item.order_timestamp : '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.quantity ? item?.package_net_weight * item?.quantity + item?.unit : 0}
                </Typography>
                <Typography className={styles.valueText}>
                  No. of Packages: {item?.quantity ? item?.quantity : 0}
                </Typography>
              </TableCell>
              <TableCell>
                {sellerTotalPrice ?
                  <Typography className={styles.valueText}>
                    {sellerCurrencySymbol}{sellerTotalPrice ? sellerTotalPrice?.toFixed(2) : 0}
                  </Typography>
                  : null}
                <Typography className={styles.valueText} pl={3}>
                  ({currencySymbol}{totalPrice ? totalPrice?.toFixed(2) : 0})
                </Typography>
                {sellerShippingCost ?
                  <Typography className={styles.valueText} mt={1}>
                    Shipping Cost: {sellerCurrencySymbol}{sellerShippingCost ? sellerShippingCost?.toFixed(2) : 0}
                  </Typography>
                  : null}
                <Typography className={styles.subValueText} pl={3}>
                  ({currencySymbol}{shippingCost ? shippingCost?.toFixed(2) : 0})
                </Typography>
              </TableCell>
              <TableCell>
                {sellerCargoPrice ?
                  <Typography className={styles.valueText}>
                    {sellerCurrencySymbol}{sellerCargoPrice ? sellerCargoPrice?.toFixed(2) : 0}
                  </Typography>
                  : null}
                <Typography className={styles.valueText} pl={3}>
                  ({currencySymbol}{cargoPrice ? cargoPrice?.toFixed(2) : 0})
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>Price/Kg: </Typography>
                {sellerPricePerKg ?
                  <Typography className={styles.valueText}>{sellerCurrencySymbol}{(parseFloat(sellerCargoPrice) / parseFloat(item?.package_net_weight * item?.quantity))?.toFixed(7)} / Kg</Typography>
                  : null}
                <Typography className={styles.valueText} pl={3}>({currencySymbol}{(parseFloat(cargoPrice) / parseFloat(item?.package_net_weight * item?.quantity))?.toFixed(7)} / Kg)</Typography>

                <Typography className={styles.subValueText} mt={1}>Price/Package:</Typography>
                {sellerPrice ?
                  <Typography className={styles.subValueText}>{sellerCurrencySymbol}{(parseFloat(sellerCargoPrice) / parseFloat(item?.quantity))?.toFixed(6)} / Package</Typography>
                  : null}
                <Typography className={styles.subValueText}  pl={3}>({currencySymbol}{(parseFloat(cargoPrice) / parseFloat(item?.quantity))?.toFixed(6)} / Package)</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  Order Status: {orderStatus ? orderStatus?.replaceAll("_", " ") : '-'}
                </Typography>
                <Typography className={styles.subPayValueText} mt={1}>
                  Payment
                  Status: {item?.payment_status ? (item.payment_status == 'half_paid' ? '50% Paid' : item.payment_status) : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default OrderDetailView;
