import React, {useRef, useEffect} from 'react'
import {
  Grid, Typography, Box, Card, CardActions, CardContent, Checkbox, Divider,
} from '@mui/material';
import {Link} from "react-router-dom";

import Container from "../../components/Container";

import styles from './UserTermsAndConditions.module.css';

const terms_and_conditions = [
  {
    number: 1,
    title: `Conditions for Registration`,
    desc: `Please note: As a seller, you will be able to upload items for sale while you share
your corporate documents, however, your products will not be viewable by
potential buyers until your documents have been verified by Trebbs.com.`,
    data: [
      {
        number: 1.1,
        content: `You may not access or use Trebbs.com and may not accept these terms if you
are not at least 18 years or are not permitted to receive any services under the
laws of the Republic of India or any jurisdiction in which you are a resident or
citizen.`
      },
      {
        number: 1.2,
        content: `You must register as a buyer, seller (or both) and have a user account on
Trebbs.com to use its services.`
      },
      {
        number: 1.3,
        content: `Whether you are registering as a buyer or seller (or both), you must own a
licensed business.`
      },
      {
        number: 1.4,
        content: `To become verified as a buyer or seller (or both), you must provide the
following documentation:`,
        subdata: [
          {
            number: `a`,
            content: `Trade License (or Commercial Register, as applicable)`
          },
          {
            number: `b`,
            content: `Export Import License`
          },
          {
            number: `c`,
            content: `Tax Registration Certificate`
          },
          {
            number: `d`,
            content: <span>Bank account details <span style={{borderBottom: '2px solid #000'}}>(only applicable to sellers)</span></span>
          },
          {
            number: `e`,
            content: <span>Export promotion Body registration Certificate <span
              style={{borderBottom: '2px solid #000'}}>(only applicable to sellers)</span></span>
          },
          {
            number: `f`,
            content: <span>FSSAI License Certificate <span style={{borderBottom: '2px solid #000'}}>(only applicable to sellers)</span></span>
          },
        ]
      },
      {
        number: 1.5,
        content: `Trebbs.com has the right to reject the creation of a user account for any
reason`
      },
      {
        number: 1.6,
        content: `When you use any Trebbs.com service or send e-mails to us, you
communicate with us electronically. We will communicate with you electronically
in a variety of ways, such as by e-mail, text, in-app push notices or by posting email messages or communications on Trebbs.com or through our Message
Center. You agree that all agreements, notices, disclosures and other
communications that we provide you electronically satisfy any legal requirement
that such communications be in writing, unless applicable laws specifically require
a different form of communication.`
      },
      {
        number: 1.7,
        content: `By posting or displaying any product, information, or content (“Content”) on
Trebbs.com, you grant an irrevocable, perpetual, worldwide, royalty-free, and
sub-licensable license to Trebbs.com to use the Content in any form, media, or
technology in any manner and for any purpose.`
      },
      {
        number: 1.8,
        content: `By registering as a user, you acknowledge that Trebbs.com may launch,
change, upgrade, impose conditions on, suspend or stop any services or features
without prior notice.`
      }
    ]
  },
  {
    number: 2,
    title: `User Accounts`,
    data: [
      {
        number: 2.1,
        content: `You may only have one main user account per business at any one time.
Trebbs.com may suspend or terminate user accounts, if it has reason to suspect
that the same business registered, or is in control of, two or more of the same
type accounts.`
      },
      {
        number: 2.2,
        content: `Upon creation of a user account, Trebbs.com will assign you an account and
request that you create a password.`
      },
      {
        number: 2.3,
        content: `You will be solely responsible for maintaining the confidentiality and security
of your account number and password. You may not share, assign, or permit the
use of your user account or password with another person, even those within
your own business. You agree to notify Trebbs.com immediately if you become
aware of any unauthorized use of your user account or password.`
      },
      {
        number: 2.4,
        content: `You agree that all use of Trebbs.com, and all activities that occur under your
account(s) will be considered authorized by you, and that you bear the
responsibility for the consequences of the misuse of your account and
subaccounts.`
      },
      {
        number: 2.5,
        content: `Trebbs.com may limit, suspend or withdraw your user account at any time,
without notice, for breach of these Terms, or for any reason.`
      },
    ]
  },
  {
    number: 3,
    title: `User's Responsibilities`,
    data: [
      {
        number: 3.1,
        content: `You represent and agree that:`,
        subdata: [
          {
            number: `a`,
            content: `you have full power and authority to accept the Terms and to fulfill your
obligations under these terms;`
          },
          {
            number: `b`,
            content: `the information and documents submitted during the registration process
are true, accurate, current and complete, and you will maintain and update
all information and documents to keep them so while your Trebbs.com user
account is active;`
          },
          {
            number: `c`,
            content: `you consent to Trebbs.com including information about you or your
business in our database and authorize Trebbs.com and our affiliates to
share or use the information as described in our Privacy Policy.`
          },
          {
            number: `d`,
            content: `you are solely responsible for obtaining all required licenses, permits and
certifications for the Content that that you submit, post or display;`
          },
          {
            number: `e`,
            content: `any Content that you submit, post or display is not counterfeit or stolen
and does not otherwise violate any of the copyright, patent, trademark,
trade name, trade secrets or any rights of any third party.`
          },
          {
            number: `f`,
            content: `you have the right and authority to sell, trade, distribute or export the
products listed on Trebbs.com, and the listing of these products does not
violate any third-party rights (for example, exclusive agency or distribution
agreements); and`
          },
          {
            number: `g`,
            content: `Neither you, your company, its directors, officers nor affiliates are the
subject of any trade restrictions, sanctions or other legal restrictions of any
country, international organization or other jurisdiction.`
          },
        ]
      },
      {
        number: 3.2,
        content: `You further agree that the Content that you submit, post or display will:`,
        subdata: [
          {
            number: `a`,
            content: `be true, accurate, complete and legal.`
          },
          {
            number: `b`,
            content: `not contain information that is defamatory, libelous, threatening,
harassing, obscene, explicit, offensive, or otherwise objectionable`
          },
          {
            number: `c`,
            content: `not contain information that is discriminatory or promotes discrimination`
          },
          {
            number: `d`,
            content: `not violate the Product Listing Policy, the Terms or any other additional
agreements with Trebbs.com.`
          },
          {
            number: `e`,
            content: `not violate any applicable laws and regulations or promote any activities
which may violate them; and`
          },
          {
            number: `f`,
            content: `not post any link directly or indirectly to other websites which include
Content that violates the Terms`
          },
        ]
      },
      {
        number: 3.3,
        content: `Additionally, you agree that you will:`,
        subdata: [
          {
            number: `a`,
            content: `carry on activities on Trebbs.com in compliance with any applicable laws
and regulations`
          },
          {
            number: `b`,
            content: ` conduct your business transactions with other Trebbs.com users in good
faith;`
          },
          {
            number: `c`,
            content: `carry on your activities according to these Terms, and any other applicable
agreements;`
          },
          {
            number: `d`,
            content: `upload all requested order data for every transaction to the Trebbs.com
platform;`
          },
          {
            number: `e`,
            content: `not use Trebbs.com to defraud any person or entity;`
          },
          {
            number: `f`,
            content: `not impersonate any person or entity, misrepresent yourself or your
affiliation with any person or entity;`
          },
          {
            number: `g`,
            content: `not manipulate feedback through multiple user accounts or third parties by
leaving positive feedback for yourself or unjustified negative feedback for
another user;`
          },
          {
            number: `h`,
            content: `not copy, reproduce, download, republish, sell or distribute any
information, text, images, graphics, video clips, sound, directories, files,
databases or listings, available on or through Trebbs.com for any purpose;`
          },
          {
            number: `i`,
            content: `not engage in spamming, phishing or spreading viruses;`
          },
          {
            number: `j`,
            content: `not use Trebbs.com solely to collect information about other users;`
          },
          {
            number: `k`,
            content: `not use information provided to you during a transaction on Trebbs.com to
solicit additional sales offline; and`
          },
          {
            number: `l`,
            content: `not engage in any activities that would create liability for Trebbs.com.`
          },
        ]
      },
      {
        number: 3.4,
        content: `In its sole discretion, Trebbs.com has the right to remove a posting or listing or
suspend or terminate any user account, if it learns of a violation or suspected
violation of any of the points under 3.1-3.3, or for any reason it considers
appropriate.`,
      }
    ]
  },
  {
    number: 4,
    title: `Transactions Between Buyers and Sellers`,
    data: [
      {
        number: 4.1,
        content: `Through its website and related applications, Trebbs.com provides a platform
for buyers and sellers to interact with one another for the sale and purchase of
products. Trebbs.com does not represent either buyers or sellers in any
transaction and is not a party to any agreement between them. The Seller is
primarily responsible for fulfilling the promise to provide the purchased products;
this includes responsibility for the acceptability of the products (for example,
responsibility for the good or service meeting customer’s reasonable
expectations, or when the customer has a right to return the products, as per the
applicable Return & Refund Policy).`
      },
      {
        number: 4.2,
        content: `Buyers agree and understand that they are purchasing products under their
own risk and agree that Trebbs.com is not liable for: (i) any act or omission of a
Seller and/or (ii) any damage or loss that arises in connection with the ordering,
purchase and/or provision of a Seller’s goods or service.`
      },
      {
        number: 4.3,
        content: `Trebbs.com does not control and is not responsible for the quality, legality,
safety, or availability of the products offered for sale on Trebbs.com, the ability of
sellers to complete a sale or the ability of buyers to complete a purchase.
Trebbs.com acts solely as an agent whose obligation is to provide a platform and
arrange for the provision of the Seller’s products. Trebbs.com does not control
the Seller’s products before they are transferred to the Buyer.`
      },
      {
        number: 4.4,
        content: `By accessing or using Trebbs.com, you accept the risks of conducting any
transaction, as well as any related activities, such as payment, storage, shipment
and return of products.`
      },
      {
        number: 4.5,
        content: `Trebbs.com is not liable for unsatisfactory or delayed performance, losses,
damages or business interruptions because of products which are unavailable,
damaged or not delivered.`
      },
      {
        number: 4.6,
        content: `Trebbs.com may, but is not required to, mediate or attempt to resolve any
dispute or disagreement between buyers and sellers, but will not be responsible
for the resolution of such dispute.`
      },
      {
        number: 4.7,
        content: `Products sold by Trebbs.com are sold in Trebbs.com's name and on its own
behalf to Buyers via Trebbs.com’s platform. For the avoidance of doubt, under its
retail model, Trebbs.com is and shall remain the seller in respect of any sales
made to Buyers. Trebbs.com shall be primarily responsible for fulfilling the
promise to provide the purchased products to Buyers; this includes responsibility
for the acceptability of the Products and is subject to the applicable Returns and
Refund Policy.`
      },
      {
        number: 4.8,
        content: `Trebbs.com shall issue invoices to Buyers in respect of products purchased by
Buyers via Trebbs.com’s platform.`
      },
    ]
  },
  {
    number: 5,
    title: `Third-Party Sites or Services`,
    data: [
      {
        number: 5.1,
        content: `Trebbs.com may give you access to third-party sites or services through
hyperlinks, API or other means. You should review the third-party terms and
conditions, as Trebbs.com has no control over and does not monitor such sites.
Trebbs.com will not be liable to you for the content of these sites or your use of
them.`
      },
      {
        number: 5.2,
        content: `Trebbs.com may offer you third-party services, such as payment gateways,
supply chain finance solutions and delivery services. These services may be
subject to a separate agreement between you and the service provider. Under no
circumstances Trebbs.com will be liable to you for the use of such services.`
      },
      {
        number: 5.3,
        content: `Third-party services may be subject to separate fees. You will be informed of
such fees prior to the performance of the services.`
      },
    ]
  },
  {
    number: 6,
    title: `Liability`,
    data: [
      {
        number: 6.1,
        content: `The services provided by Trebbs.com and the products displayed on
Trebbs.com are provided "as is", "as available" and "with all faults", and all
warranties are excluded.`
      },
      {
        number: 6.2,
        content: `You agree to defend Trebbs.com, our affiliates, directors, officers and
employees against all third-party losses, claims and liabilities (including legal
costs) which may result from your violation of these Terms, use of Trebbs.com or
its services (or third-party services or sites offered through Trebbs.com). You
agree to fully compensate Trebbs.com for such losses.`
      },
      {
        number: 6.3,
        content: `Trebbs.com has the right to take control of any legal matter subject to Clause
6.2.`
      },
      {
        number: 6.4,
        content: `Trebbs.com will not be liable to pay any consequential, indirect, punitive or
incidental damages (including but not limited to damages for loss of profits or
savings, business interruption, loss of information), resulting from your use of
Trebbs.com, or any third-party services or sites offered through Trebbs.com.`
      },
      {
        number: 6.5,
        content: `Regardless of the above clauses, the total liability of Trebbs.com, our
employees, agents, affiliates or representatives for any or all your claims during
any calendar year is limited to USD $100.`
      },
      {
        number: 6.6,
        content: `The above clauses will apply whether Trebbs.com has been advised of, or
should have been aware of, the possibility of any losses.`
      },
    ]
  },
  {
    number: 7,
    title: `Cooperation with Authorities`,
    data: [
      {
        number: 7.1,
        content: `Trebbs.com has the right to cooperate fully with governmental or regulatory
authorities or law enforcement in the investigation of any suspected criminal or
civil wrongdoing. As permitted by applicable laws and policies, Trebbs.com may
disclose a user's identity and contact information regarding a user's account,
transactions or activities carried on Trebbs.com. Trebbs.com will not be liable for
damages due to such disclosure, and you agree not to bring any claim against
Trebbs.com for the disclosure.`
      },
    ]
  },
  {
    number: 8,
    title: `Force Majeure`,
    data: [
      {
        number: 8.1,
        content: `Neither you nor Trebbs.com will be liable for failing to fulfill any obligation
under these Terms that result from circumstances beyond either party’s
reasonable control, such as natural disasters, pandemics or war.`
      },
    ]
  },
  {
    number: 9,
    title: `Intellectual Property`,
    data: [
      {
        number: 9.1,
        content: `“Trebbs.com” and its related logos and words on Trebbs.com are registered
trademarks of Trebbs.com and are protected by international trademark and
other intellectual property rights and laws. Trebbs.com’s trademarks may not be
used in connection with any service or products other than Trebbs.com’s, without
its written consent. All other trademarks not owned by Trebbs.com that appear
on Trebbs.com are the property of their respective owners, who may or may not
be affiliated with Trebbs.com.`
      },
      {
        number: 9.2,
        content: `Trebbs.com is the sole owner or lawful licensee of all the rights and interests
in Trebbs.com and its Content. Trebbs.com and its content contains trade secrets
and other intellectual property rights protected under worldwide copyright and
other laws. All title, ownership and intellectual property rights in Trebbs.com and
its Content belong to Trebbs.com. All other rights are reserved.`
      },
      {
        number: 9.3,
        content: <span>If you believe that any Content on Trebbs.com violates your intellectual
property or other third-party rights, please <Link
            to='javascript:void(0)'
            onClick={() => window.location = 'mailto:legal@trebbs.com'}
          >
            legal@trebbs.com
        </Link>.</span>
      },
    ]
  },
  {
    number: 10,
    title: `Notices`,
    data: [
      {
        number: 10.1,
        content: `All notices or demands to Trebbs.com must be sent to: legal@trebbs.com.`
      },
      {
        number: 10.2,
        content: `All legal notices or demands on a user will be delivered to the last known
email address provided by Trebbs.com, unless otherwise required by specific laws
or regulations`
      },
    ]
  },
  {
    number: 11,
    title: `General Clauses`,
    data: [
      {
        number: 11.1,
        content: `Unless you have any additional agreements with Trebbs.com, these Terms
represent the entire agreement between you and Trebbs.com as to your use of
the website and any related applications or services, overriding any prior written
or verbal agreements that may have been made.`
      },
      {
        number: 11.2,
        content: `These Terms do not create an agency, partnership, joint venture,
employment or franchise relationship between you and Trebbs.com.`
      },
      {
        number: 11.3,
        content: `If any clause of these Terms is considered invalid or unenforceable, such
clause will be deleted and the remaining clauses will remain valid and
enforceable.`
      },
      {
        number: 11.4,
        content: `If Trebbs.com does not enforce its rights or act against any breach by you
under the Terms, it does not mean that Trebbs.com is waiving its rights to enforce
actions against you in the future.`
      },
      {
        number: 11.5,
        content: `Trebbs.com has the right to assign the Terms to any person or entity. You
may not assign these terms to any person or entity without written consent from
Trebbs.com.`
      },
      {
        number: 11.6,
        content: `These Terms are governed by and construed according to the laws and
regulations of the Republic of India. Any dispute, difference, controversy or claim
arising out of or in connection with these Terms, including any question regarding
its existence, validity, interpretation, performance, discharge and applicable
remedies, will be subject to the exclusive jurisdiction of the Indian Courts. If you
have any questions regarding these Terms, please Contact Us.`
      },
    ]
  },
]

const UserTermsAndConditions = (props) => {
  const termConditionRef = useRef(null);

  useEffect(() => {
    if (termConditionRef?.current) {
      termConditionRef?.current?.scrollIntoView({behavior: "smooth"});
    }
  }, []);

  return (
    <Container page={"UserTermsAndConditions"} title={'Terms & Conditions'} subTitle={'User Terms & Conditions'} showHeaderData={'false'}>
      <Grid container item md={12} xs={12} display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={5} mb={6}
            ref={termConditionRef}>
        <Grid container item md={8} xs={10} display={"flex"}
              alignItems={"center"}>
          <Grid item md={12} xs={12} mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography className={styles.mainTitleText}>User Terms And Conditions</Typography>
          </Grid>

          <Box mt={2}>
            <Typography className={styles.contentText}>Welcome to Trebbs.com! </Typography>
          </Box>

          <Typography className={styles.contentText} mt={2}>
            These are the User Terms and Conditions (“Terms”) that apply to your access and
            use of the Trebbs.com website and any mobile applications operated by
            Trebbs.com TREBBS TRADE SOLUTIONS PRIVATE LIMITED, India and its affiliates
            (“Trebbs.com”). <span className={styles.titleText}>This document is a legally binding agreement between you and
            Trebbs.com. Please read these Terms carefully before using Trebbs.com. By
            using the website or related mobile applications, you agree to be bound by
            these Terms.</span>
          </Typography>

          <Typography className={styles.contentText} mt={2}>
            In addition to these Terms, you should also review and accept Trebbs.com’s <span
            className={styles.titleText}>Privacy Policy, Seller Agreement (for sellers), Buyer Agreement (for buyers),
Product Listing Policy (for sellers) and International Payment Policy,</span> as they are
            incorporated by reference in these Terms. Trebbs.com may update these Terms
            at any time. Any changes to the Terms will be posted on this page. Once posted,
            the updated Terms will be effective immediately. It is your responsibility to review
            the Terms on a regular basis to keep yourself informed of changes. By continuing
            to access the Trebbs.com site or use its services, you agree to be bound by the
            updated Terms.
          </Typography>

          <Box>
            {terms_and_conditions.map((item, index) => {
              return (
                <Grid item md={12} xs={12} key={index}>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={3}>
                    <Typography className={styles.titleText}>{item?.number}.</Typography>
                    <Typography pl={1} className={styles.titleText}>{item?.title}</Typography>
                  </Box>
                  {item?.desc ?
                    <Box mt={2}>
                      <Typography className={styles.titleText}>{item?.desc}</Typography>
                    </Box>
                    : null}
                  {item?.data?.length > 0 ?
                    item?.data?.map((item1, index1) => {
                      return (
                        <Grid item md={12} xs={12} key={index1}>
                          <Box mt={2} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                            <Typography className={styles.contentText}>{item1?.number}</Typography>
                            <Typography pl={1} className={styles.contentText}>{item1?.content}</Typography>
                          </Box>
                          {item1?.subdata?.length > 0 ?
                            item1?.subdata?.map((item2, index2) => {
                              return (
                                <Grid item md={12} xs={12} key={index2} pl={3}>
                                  <Box mt={1} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                                    <Typography className={styles.contentText}>({item2?.number})</Typography>
                                    <Typography pl={1} className={styles.contentText}>{item2?.content}</Typography>
                                  </Box>
                                </Grid>
                              )
                            })
                            : null}
                        </Grid>
                      )
                    })
                    : null}
                </Grid>
              )
            })}
          </Box>

        </Grid>
      </Grid>

    </Container>
  )
}

export default UserTermsAndConditions
