import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import moment from 'moment';

import FormButton from "./FormButton";
import {getCurrencyConversion, getCurrencyConversionCalc} from "../assets/functions/common";

import styles from "./CartShippingCostPendingList.module.css"

import {COUNTRIES} from "../constants/countries";

const CartShippingCostPendingList = (props) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [showError, setShowError] = useState('');
  const [pricePerKg, setPricePerKg] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [sellerTotalPrice, setSellerTotalPrice] = useState(0);
  const [sellerPrice, setSellerPrice] = useState(0);
  const [sellerPricePerKg, setSellerPricePerKg] = useState(0);
  const [sellerCurrencySymbol, setSellerCurrencySymbol] = useState('')

  useEffect(() => {
    if (props.data) {
      setShowError(props.data?.error);
    }
  }, [props.data?.error])

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {

        let price_val = 0;
        let total_price = 0;
        let price_per_kg = 0;
        let seller_price_val = 0;
        let seller_total_price = 0;
        let seller_price_per_kg = 0;

        // let price_per_package = props?.data?.price_per_package;
        // let package_net_weight = props?.data?.package_net_weight;
        // let referral_fee = props?.data?.referral_fee;
        // let rate_list = props?.data?.rate_list;
        // let seller_currency = props?.data?.seller_currency;
        // let user_currency = props?.data?.user_currency;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: 0,
          seller_currency: props?.data?.seller_currency,
          user_currency: props?.data?.user_currency,
          main_currency: props?.data?.main_currency,
          // rate: props?.data?.rate,
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        // let data = getCurrencyConversion(price_per_package, package_net_weight, referral_fee, rate_list, seller_currency, user_currency)
        if (data != null) {
          price_val = data?.converted_price;
          price_per_kg = data?.converted_price_per_kg;
          total_price = data?.converted_total_price;
          seller_price_val = data?.seller_price;
          seller_price_per_kg = data?.seller_price_per_kg;
          seller_total_price = data?.seller_total_price;
          setCurrencySymbol(data?.user_currency_symbol)
          setSellerCurrencySymbol(data?.seller_currency_symbol)

          // price_val = data?.converted_price;
          // price_per_kg = price_val / package_net_weight;
          // total_price = props.data?.quantity * price_val
          // seller_price_val = data?.seller_price;
          // seller_price_per_kg = seller_price_val / package_net_weight;
          // seller_total_price = props.data.quantity * seller_price_val;
          // setCurrencySymbol(data?.currency_symbol)
          // setSellerCurrencySymbol(data?.seller_currency_symbol)
        }

        setPrice(price_val);
        setTotalPrice(total_price);
        setPricePerKg(price_per_kg);
        setSellerPrice(seller_price_val);
        setSellerTotalPrice(seller_total_price)
        setSellerPricePerKg(seller_price_per_kg);
      }
    }
  }, [props.data, props?.currencyRateList])


  const onDeleteCartClick = (cart_uid) => {
    props.onDelete(cart_uid)
  };

  let item = props.data;

  return (


    <>

      <TableCell>
        <Typography className={styles.productNameText}>#{item.cart_uid}</Typography>
        <Typography className={styles.shippingCostPendingTimestampText}>{item?.shipping_cost_pending_timestamp ? item?.shipping_cost_pending_timestamp : ''}</Typography>
        {showError ?
          <Box mt={1}>
            <Typography className={styles.moqErrorText}>{showError}</Typography>
          </Box>
          : null}
      </TableCell>
      <TableCell className={styles.dataView}>
        {item.product_image ?
          <Grid item md={4} xs={12} className={styles.imgView}>
            <img src={item.product_image} className={styles.imgStyle}/>
          </Grid>
          : null}
        <Typography className={styles.productNameTitleText}>
          {(item?.variety ? item.variety : 'NIL Variety')
          + ', ' + (item?.name ? item.name : 'NIL')
          + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
          + ', ' + (item?.package_net_weight ?
            (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
            : 'NIL Packing')
          + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
        </Typography>
        <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          (<Typography className={styles.subPricePerKgText}>{currencySymbol}{pricePerKg?.toFixed(2)} / Kg</Typography>
          <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
        </Box>
        <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          (<Typography className={styles.subPricePerKgText}>{currencySymbol}{price?.toFixed(2)} / Package</Typography>
          <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
        </Box>
        <Typography className={styles.hsCodeText} mt={1}>HS Code: {item?.hs_code ? item.hs_code : ''}</Typography>
        <Typography className={styles.nameEntityText}>{item?.seller_name_entity ? item?.seller_name_entity : ''}</Typography>
        <Typography
          className={styles.productNameText}>{item?.seller_country ? '(' +  COUNTRIES.filter(
          (x) => x.code == item?.seller_country
        )?.[0]?.label + ')' : ''}</Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.deliveryAddressText}>
          {item?.delivery_address?.state_province ?
            (item?.delivery_address?.address_line_1
              + ',' + item?.delivery_address?.address_line_2
              + ',' + item?.delivery_address?.city
              + ',' + item?.delivery_address?.state_province
              + ',' + item?.delivery_address?.zip_code
            )
            : '-'}
        </Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.shippingDetailText}>
          Total number of packages : {item?.quantity ? item?.quantity : 0}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Total product quantity
          : {item?.package_net_weight ? (item?.quantity * item?.package_net_weight + (item?.unit ? item.unit : '')) : 0}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Shipping method : {item?.shipping_details?.shipping_mode ? item?.shipping_details?.shipping_mode : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Container type : {item?.shipping_details?.container_type ? item?.shipping_details?.container_type : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Cargo ID : {item?.shipping_details?.cargo_id ? item?.shipping_details?.cargo_id : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Shipping date
          : {item?.shipping_details?.shipping_date ? moment(item?.shipping_details?.shipping_date).format('DD-MM-YYYY') : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        {item?.shipping_details?.payment_term ?
          <>
            <Typography
              className={styles.paymentTermText}>{item?.shipping_details?.payment_term}</Typography>
            <Typography
              className={styles.paymentTermDescText}>({item?.shipping_details?.payment_term_description})</Typography>
          </>
          : '-'}
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography
          className={styles.subPriceText}>
          {sellerCurrencySymbol} {sellerTotalPrice?.toFixed(2)}
        </Typography>
        <Typography className={styles.subPricePerKgText} pl={3}>{sellerCurrencySymbol}{sellerPricePerKg?.toFixed(2)} /
          kg</Typography>

        <Typography
          className={styles.subPriceText} mt={1}>
          ({currencySymbol} {totalPrice?.toFixed(2)})
        </Typography>
        <Typography className={styles.subPricePerKgText} pl={3}>({currencySymbol}{pricePerKg?.toFixed(2)} / kg)</Typography>

      </TableCell>
      <TableCell>
        <Typography className={styles.calculationPendingText}>
          Calculation Pending
        </Typography>
        <Typography className={styles.incotermText}>
          ({item?.shipping_details?.incoterm})
        </Typography>
      </TableCell>
      <TableCell>
        <Box className={styles.pointerView}>
          <FormButton
            title={'Delete'}
            buttonView={{
              width: '100%',
              backgroundColor: 'red'
            }}
            bgColorStyleView={'red'}
            onSubmit={() => onDeleteCartClick(item.cart_uid)}
          />
        </Box>
      </TableCell>

    </>
  )
};

export default CartShippingCostPendingList;
