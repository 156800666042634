import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import './../assets/css/custom.css';

import FormButton from "./FormButton";
import ImageUpload from "./ImageUpload";

import {connect} from "react-redux";
import {addUserDocuments, updateUserDocuments, updateApiStatus} from "../redux/actions/userDataActions";

const UploadDocuments = (props) => {
  const [signatureImage, setSignatureImage] = useState(null);
  const [businessLogoImage, setBusinessLogoImage] = useState(null);
  const [displaySignatureImage, setDisplaySignatureImage] = useState('');
  const [displayBusinessLogoImage, setDisplayBusinessLogoImage] = useState('');

  const [error, setError] = useState(null);

  useEffect(() => {
    if(props?.apiStatus == 'ADD_USER_DOCUMENTS_SUCCESS'
      || props?.apiStatus == 'UPDATE_USER_DOCUMENTS_SUCCESS') {
      setSignatureImage(null);
      setBusinessLogoImage(null);
      props?.updateApiStatus('')
    }
  },[props?.apiStatus]);

  useEffect(() => {
    if (props.data != null) {
      setSignatureImage(null);
      setBusinessLogoImage(null);
      setDisplaySignatureImage(props.data.displaySignatureImage);
      setDisplayBusinessLogoImage(props.data.displayBusinessLogoImage);
    }
  }, [props.data]);

  const onSaveClick = () => {
    let errorText = {};
    if (signatureImage == null && displaySignatureImage == '') {
      errorText = {signatureImage: 'Signature Image is required'};
    }
    if (businessLogoImage == null && displayBusinessLogoImage == '') {
      errorText = {...errorText, businessLogoImage: 'Business Logo Image is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let formData = new FormData();
    formData.append('userId', props?.data?.userId);

    if (props.data?.userId) {
      if (signatureImage != null) {
        formData.append('signatureImage', signatureImage);
      }
      if (businessLogoImage != null) {
        formData.append('businessLogoImage', businessLogoImage);
      }
    } else {
      formData.append('signatureImage', signatureImage);
      formData.append('businessLogoImage', businessLogoImage);
    }

    if (props.data?.userId) {
      if (signatureImage != null || businessLogoImage != null) {
        props.updateUserDocuments(formData)
      }
    } else {
      props.addUserDocuments(formData)
    }
  };

  const onRemoveImage = (type) => {
    if (type == 'signature') {
      setSignatureImage(null);
      setDisplaySignatureImage('');
    } else if (type == 'businessLogo') {
      setBusinessLogoImage(null);
      setDisplayBusinessLogoImage('');
    }
  };

  const onChangeImage = (fileList, type) => {
    if (type == 'signature') {
      setSignatureImage(fileList[0].file);
      setDisplaySignatureImage(fileList[0].image);
    } else if (type == 'businessLogo') {
      setBusinessLogoImage(fileList[0].file);
      setDisplayBusinessLogoImage(fileList[0].image);
    }
  };

  return (
    <Grid container
          item md={12} xs={12}
          display={"flex"}
          direction="column"
          mt={4}>

      <Typography style={styles.titleText}>Upload Files</Typography>


      <Grid display={"flex"} flexDirection={"column"}
            item md={12} xs={12}
            container>

        <Grid item md={12} xs={12}
              container
              display={"flex"}
              direction="column"
              mt={3}
             >
          <Box sx={styles.titleView}>
            <Typography sx={styles.labelText}>Signature of authorized person*</Typography>
          </Box>
          <Box mt={2}>
            <ImageUpload
              type={'signature'}
              maxFiles={1}
              fileType={'image'}
              image={displaySignatureImage}
              error={error?.signatureImage}
              onRemove={onRemoveImage}
              onChange={onChangeImage}/>
          </Box>
        </Grid>

        <Grid item md={12} xs={12}
              container
              display={"flex"}
              direction="column"
              mt={5}
             >
          <Box sx={styles.titleView}>
            <Typography sx={styles.labelText}>Upload business logo*</Typography>
          </Box>
          <Box mt={2}>
            <ImageUpload
              type={'businessLogo'}
              maxFiles={1}
              fileType={'image'}
              image={displayBusinessLogoImage}
              error={error?.businessLogoImage}
              onRemove={onRemoveImage}
              onChange={onChangeImage}/>
          </Box>
        </Grid>

      </Grid>

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="right" mt={4}>
        {/*<Box mr={2}>*/}
        {/*  <FormButton*/}
        {/*    title={'PREV'}*/}
        {/*    btnStyleView={styles.btnStyleView}*/}
        {/*    onSubmit={onPrevClick}*/}
        {/*  />*/}
        {/*</Box>*/}
        <Box>
          <FormButton
            title={'Save'}
            btnStyleView={styles.btnStyleView}
            onSubmit={onSaveClick}
          />
        </Box>
      </Grid>

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addUserDocuments: data => dispatch(addUserDocuments(data)),
    updateUserDocuments: data => dispatch(updateUserDocuments(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);

const styles = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e4ecf2',
  },
  btnStyleView: {
    height: 40,
  },
  labelText: {
    color: '#999',
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
  titleView: {
  },
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
};

