import React, {useEffect, useState} from 'react'
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment'

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import ConfirmAlert from "../../components/ConfirmAlert";
import CartReviewPending from "../../components/CartReviewPending";
import CartShippingCostPending from "../../components/CartShippingCostPending";
import CartCheckoutPending from "../../components/CartCheckoutPending";

import {connect} from "react-redux";
import {
  getCart,
  updateCart,
  updateCartList,
  deleteCart,
  updateAlertMsg,
  getCurrencyRateList,
  updateApiStatus
} from "../../redux/actions/userDataActions";

import {ReactComponent as DeleteIcon} from "../../assets/img/delete.svg";

import {THEME_COLOR3, THEME_COLOR4, THEME_COLOR2} from "../../constants";

import styles from './Cart.module.css'
import {ReactComponent as InfoIcon} from "../../assets/img/infoCircle.svg";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Cart = (props) => {
  const [cartTotalItem, setCartTotalItem] = useState(0);
  const [showConfirmAlertVisible, setShowConfirmAlertVisible] = useState(false);
  const [selectedCartUid, setSelectedCartUid] = useState([]);
  const [currentCartStatus, setCurrentCartStatus] = useState('');
  const [value, setValue] = useState(0);
  const [reviewPendingList, setReviewPendingList] = useState([]);
  const [shippingCostPendingList, setShippingCostPendingList] = useState([]);
  const [checkoutPendingList, setCheckoutPendingList] = useState([]);
  const [cartMessageVal, setCartMessageVal] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (props?.cartMessage != null) {
      setCartMessageVal(props?.cartMessage)
    }
  }, [props?.cartMessage]);

  useEffect(() => {
    props?.getCurrencyRateList()
  }, [])

  useEffect(() => {
    props?.getCart();
  }, [])

  useEffect(() => {
    if (props.reviewPendingCount || props.shippingCostPendingCount || props.checkoutPendingCount) {
      let total = parseInt(props.reviewPendingCount) + parseInt(props.shippingCostPendingCount) + parseInt(props.checkoutPendingCount)
      setCartTotalItem(total)
    }
  }, [props.reviewPendingCount, props.shippingCostPendingCount, props.checkoutPendingCount])

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let cart_type = params?.get('type');
    if (cart_type) {
      if (cart_type == 'review_pending') {
        setValue(0);
      } else if (cart_type == 'shipping_cost_pending') {
        setValue(1);
      } else if (cart_type == 'checkout_pending') {
        setValue(2);
      }
    } else {
      setValue(0);
    }
  }, [])

  useEffect(() => {
    if (props?.apiStatus == 'GET_CART_SUCCESS') {
      if (props?.cart?.length > 0) {
        let review_pending_list = props?.cart?.filter((x) => x.cart_status == 'review_pending');
        let shipping_cost_pending_list = props?.cart?.filter((x) => x.cart_status == 'shipping_cost_pending');
        let checkout_pending_list = props?.cart?.filter((x) => x.cart_status == 'checkout_pending');
        setReviewPendingList(review_pending_list);
        setShippingCostPendingList(shipping_cost_pending_list);
        setCheckoutPendingList(checkout_pending_list);
        props.updateApiStatus('')
      } else {
        props.updateAlertMsg({open: true, message: 'Cart Empty', msgType: 'error'});
        props.updateApiStatus('')
        navigate('/home')
      }
    } else if (props?.apiStatus == 'GET_CART_FAIL') {
      props.updateApiStatus('')
      navigate('/home')
    } else if (props?.apiStatus == 'DELETE_CART_SUCCESS') {
      let review_pending_list = props?.cart?.filter((x) => x.cart_status == 'review_pending');
      let shipping_cost_pending_list = props?.cart?.filter((x) => x.cart_status == 'shipping_cost_pending');
      let checkout_pending_list = props?.cart?.filter((x) => x.cart_status == 'checkout_pending');
      setReviewPendingList(review_pending_list);
      setShippingCostPendingList(shipping_cost_pending_list);
      setCheckoutPendingList(checkout_pending_list);
      props.updateApiStatus('')
    }
  }, [props?.cart, props?.apiStatus]);

  const onUpdateCartClick = (cart_uid, productCount) => {
    let data = {
      cartUid: cart_uid,
      quantity: productCount
    };
    props.updateCart(JSON.stringify(data));
  };

  const onDeleteCart = (cart_uid, status) => {
    setSelectedCartUid(cart_uid);
    setCurrentCartStatus(status);
    setShowConfirmAlertVisible(true);
  };

  const onConfirmDeleteCart = () => {
    let data = {
      cartUid: JSON.stringify(selectedCartUid),
      currentStatus: currentCartStatus
    };
    props.deleteCart(JSON.stringify(data));
    setSelectedCartUid([]);
    setCurrentCartStatus('');
    setShowConfirmAlertVisible(false);
  };

  const onCancelDeleteCart = () => {
    setSelectedCartUid([]);
    setCurrentCartStatus('');
    setShowConfirmAlertVisible(false);
  };

  const onProceedToReview = (cart_uid) => {

    navigate('/review?id=' + cart_uid)

    // setCartUpdateType('checkout')
    // let data = {
    //   cartList: JSON.stringify(props.cart),
    // page: 'checkout'
    // };
    // props.updateCart(JSON.stringify(data));
  };

  // const proceedToCheckoutClick = () => {
  //   setCartUpdateType('checkout')
  //   let data = {
  //     cartList: JSON.stringify(props.cart),
  //     page: 'checkout'
  //   };
  //   props.updateCart(JSON.stringify(data));
  // };

  const handleChange = (event, newValue) => {
    setCartMessageVal('');
    setValue(newValue);
  };

  return (
    <Container page={"Cart"} title={'Cart'} subTitle={'Cart'}>

      <Grid container item md={12} xs={12} mt={2}
            display={"flex"} alignItems={"center"} justifyContent={"center"}>

        <Grid item md={11.6} xs={11.6}>
          <Typography className={styles.yourCartText} mb={2}>Your Cart
            ({cartTotalItem + (cartTotalItem > 1 ? ' Items' : ' Item')})</Typography>
        </Grid>

        <Grid item md={11.6} xs={11.6}>
          <Typography className={styles.yourCartDescText} mb={2}>The price and availability of items at Trebbs.com are
            subject to change. The shopping cart is a temporary place to store a list of your items and reflects each
            item's most recent price.</Typography>
        </Grid>

        <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine} mt={1} mb={1}/>

        <Grid item md={11.6} xs={11.6}>
          <Tabs scrollButtons="on" variant="scrollable" value={value} onChange={handleChange}
                sx={{
                  "& .MuiTab-root": {
                    color: THEME_COLOR3,
                    fontSize: 15,
                    fontFamily: 'InterBold',
                    textTransform: 'capitalize',
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: THEME_COLOR4,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: THEME_COLOR4,
                    fontSize: 15,
                    fontFamily: 'InterBold',
                    textTransform: 'capitalize'
                  }
                }}
                aria-label="basic tabs example">
            <Tab label={props.reviewPendingCount + ' Review Pending'} {...a11yProps(0)} />
            <Tab label={props.shippingCostPendingCount + ' Shipping Cost Pending'} {...a11yProps(1)} />
            <Tab label={props.checkoutPendingCount + ' Checkout Pending'} {...a11yProps(2)} />
          </Tabs>
        </Grid>

        <Grid item md={11.6} xs={11.6} className={styles.borderBottomLine} mt={1} mb={1}/>

        {value == 1 && (shippingCostPendingList?.length > 0) ?
          cartMessageVal ?
            cartMessageVal?.type == 'cart_review' ?
              <Grid item md={11.6} xs={11.6}
                    className={styles.descView} sx={{backgroundColor: THEME_COLOR2}} mt={2}
                    display={"flex"}
                    flexDirection={'column'}
                    alignItems={"flex-start"} justifyContent={"center"} p={2}>
                <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <InfoIcon height={15} width={15}/>
                  <Typography className={styles.descTitleItalicText} ml={0.2}>
                    Your Logistics Cost request has been submitted.
                  </Typography>
                </Grid>
                <Typography className={styles.descItalicText} mt={1}>
                  Logistics cost request has been successfully submitted for your cart ID
                  #{cartMessageVal?.cartUid} on {moment().format('DD/MM/YYYY, HH:mm')} hrs. please
                  wait while we are calculating the best logistics cost for you.
                </Typography>
              </Grid>
              : null
            : null
          : null}

      </Grid>

      <TabPanel value={value} index={0}>
        {(reviewPendingList?.length > 0) ?
          <CartReviewPending
            list={reviewPendingList}
            onUpdateCartClick={onUpdateCartClick}
            onDeleteCart={onDeleteCart}
            onProceedToReview={onProceedToReview}
            currencyRateList={props?.currencyRateList}
          />
          : null}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {(shippingCostPendingList?.length > 0) ?
          <CartShippingCostPending
            list={shippingCostPendingList}
            onDeleteCart={onDeleteCart}
            currencyRateList={props?.currencyRateList}
          />
          : null}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {(checkoutPendingList?.length > 0) ?
          <CartCheckoutPending
            list={checkoutPendingList}
            onDeleteCart={onDeleteCart}
            currencyRateList={props?.currencyRateList}
          />
          : null}
      </TabPanel>

      <AlertMsg/>

      <ConfirmAlert
        btnLoading={props.confirmBtnLoading}
        confirmBox={showConfirmAlertVisible}
        type={'delete'}
        icon={<DeleteIcon height={80} width={80}/>}
        content={'Are you sure you would like to Delete?'}
        firstBtn={'Yes, Confirm'}
        secondBtn={'No, Cancel'}
        onConfirmClick={onConfirmDeleteCart}
        onCancelClick={onCancelDeleteCart}
      />
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    cart: state.userData.cart,
    cartBtnLoading: state.userData.cartBtnLoading,
    confirmBtnLoading: state.userData.confirmBtnLoading,
    reviewPendingCount: state.userData.reviewPendingCount,
    shippingCostPendingCount: state.userData.shippingCostPendingCount,
    checkoutPendingCount: state.userData.checkoutPendingCount,
    currencyRateList: state.userData.currencyRateList,
    apiStatus: state.userData.apiStatus,
    cartMessage: state.userData.cartMessage,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getCart: data => dispatch(getCart(data)),
    updateCart: data => dispatch(updateCart(data)),
    updateCartList: data => dispatch(updateCartList(data)),
    deleteCart: data => dispatch(deleteCart(data)),
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
