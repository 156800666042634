import React, {useRef, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AppBar, Box, Grid, Menu, MenuItem, Toolbar, Typography} from '@mui/material';

import FormButton from '../components/FormButton';

import {THEME_COLOR2, THEME_COLOR, THEME_COLOR5, THEME_COLOR7} from "../constants";

import '../assets/css/custom.css';

import {ReactComponent as CartIcon} from "../assets/img/cart_green.svg";
import {ReactComponent as LockIcon} from "../assets/img/lock.svg";
import {ReactComponent as LogOutIcon} from "../assets/img/logout.svg";
import {ReactComponent as HomeIcon} from "../assets/img/home.svg";

import {connect} from "react-redux";
import {alertMsgEmpty, getCartCount, getOrderCount, updateAlertMsg} from "../redux/actions/userDataActions";

const HomeHeader = (props) => {
  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);

  useEffect(() => {
    if (props.userToken) {
      props.getCartCount()
      props.getOrderCount()
    }
  }, [props.userToken]);

  useEffect(() => {
    if (props.userToken) {
      if (props.reviewPendingCount || props.shippingCostPendingCount || props.checkoutPendingCount) {
        let total = parseInt(props.reviewPendingCount) + parseInt(props.shippingCostPendingCount) + parseInt(props.checkoutPendingCount)
        setCartCount(total)
      }
    }
  }, [props.reviewPendingCount, props.shippingCostPendingCount, props.checkoutPendingCount])

  useEffect(() => {
    if (props.userToken) {
      if (props.pendingOrderCount || props.unshippedOrderCount || props.shippedOrderCount || props.cancelledOrderCount || props.deliveredOrderCount) {
        let total = parseInt(props.pendingOrderCount) + parseInt(props.unshippedOrderCount) + parseInt(props.shippedOrderCount) + parseInt(props.deliveredOrderCount) + parseInt(props.cancelledOrderCount)
        setOrderCount(total)
      }
    }
  }, [props.pendingOrderCount, props.unshippedOrderCount, props.shippedOrderCount, props.deliveredOrderCount, props.cancelledOrderCount])


  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    scrollTop >= 250 ? setIsFixed(true) : setIsFixed(false);
  };

  const onCartIconClick = () => {
    props.alertMsgEmpty();
    if (props.userToken) {
      navigate('/cart')
    } else {
      props.updateAlertMsg({open: true, message: 'Account not activated / Not Logged', msgType: 'error'});
    }
  };

  const onOrderIconClick = () => {
    props.alertMsgEmpty();
    if (props.userToken) {
      navigate('/order')
    } else {
      props.updateAlertMsg({open: true, message: 'Account not activated / Not Logged', msgType: 'error'});
    }
  };

  const onRegisterNowToImport = () => {
    if (props?.tgbin) {
      navigate('/home')
    } else {
      navigate('/register')
    }
  };

  const onUserLogin = () => {
    navigate('/login')
  };

  const onUserLogout = () => {
    navigate('/logout')
  };

  const onHomeClick = () => {
    if (props?.tgbin) {
      navigate('/home')
    } else {
      navigate('/')
    }
  };

  return (
    <Grid container item md={12} xs={12}>
      <AppBar
        className={isFixed ? "headerBarFixed" : "headerBar"}
        sx={{
          boxShadow: '0px 10px 30px 0px rgba(82, 63, 105, 0.05)',
          zIndex: 5,
        }}>
        <Toolbar sx={styles.mainNav}>
          {isFixed && (props.userToken && props?.tgbin) && (props?.page != 'home') ?
            <Grid
              sx={styles.logoMainView}
              onClick={() => navigate('/index')}
              container item md={6} xs={12} display={{md: "flex", xs: 'none'}} flexDirection={"row"}>
              <Typography style={styles.titleText}>Trebbs</Typography>
            </Grid>
            : null}

          {props.userToken && props?.tgbin && (props?.page != 'home') ?
            <Grid container item md={isFixed ? 5.6 : 11} xs={isFixed ? 12 : 12} display={"flex"} flexDirection={"row"}
                  alignItems={"center"} justifyContent={"flex-end"}>
              <>
                <Box
                  display={"flex"} flexDirection={"row"}
                  alignItems={"center"}
                  sx={styles.orderCountView}
                  onClick={() => onOrderIconClick()}
                  ml={2}>
                  <Typography sx={styles.orderCountText}>{orderCount}</Typography>
                  <Typography sx={styles.orderText} pl={1}>Orders</Typography>
                </Box>
                <Box
                  display={"flex"} flexDirection={"row"}
                  alignItems={"center"}
                  sx={styles.cartIconView}
                  onClick={() => onCartIconClick()}
                  ml={4}>
                  <Typography sx={styles.cartText}>Cart</Typography>
                  <Box display={"flex"} flexDirection={"column"}
                       justifyContent={"center"} alignItems={'center'} pl={1}>
                    <Typography sx={styles.cartCountText} mb={-1.6} pl={1.5}>{cartCount}</Typography>
                    <CartIcon height={38} width={38}/>
                  </Box>
                </Box>
              </>
            </Grid>
            :
            <Grid container item md={10} xs={12} alignItems={'center'}>
              <Grid item xs={8}
                    display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                <Typography style={styles.trebbsText}>Trebbs</Typography>
                <Typography style={styles.tradeText}>Trade beyond <span
                  style={styles.textColor}>borders</span></Typography>
              </Grid>
              {props?.showHeaderAllData === 'true' ?
                props?.showHeaderData === 'false' ?
                  <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-end'}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}
                         style={styles.userLoginView}
                         onClick={() => onHomeClick()}>
                      <HomeIcon height={15} width={15}/>
                      <Typography style={styles.userLoginText} pl={0.3}>Home</Typography>
                    </Box>
                  </Grid> :
                  <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-end'}>
                    {!props?.userToken || (!props?.tgbin) ?
                      <Box display={{md: 'flex', xs: 'none'}}>
                        <FormButton
                          title={'Register now to import'}
                          buttonView={{
                            color: '#000',
                            border: '1px solid #000',
                            backgroundColor: THEME_COLOR5
                          }}
                          bgColorStyleView={THEME_COLOR5}
                          onSubmit={() => onRegisterNowToImport()}
                        />
                      </Box>
                      : <Box display={'flex'} flexDirection={'row'} alignItems={'center'}
                             style={styles.userLoginView}
                             onClick={() => onHomeClick()}>
                        <HomeIcon height={15} width={15}/>
                        <Typography style={styles.userLoginText} pl={0.3}>Home</Typography>
                      </Box>}
                    {!props?.userToken ?
                      <Box pl={3} display={'flex'} flexDirection={'row'} alignItems={'center'}
                           style={styles.userLoginView}
                           onClick={() => onUserLogin()}>
                        <LockIcon height={15} width={15}/>
                        <Typography style={styles.userLoginText} pl={0.3}>Sign in</Typography>
                      </Box>
                      : <Box pl={3} display={'flex'} flexDirection={'row'} alignItems={'center'}
                             style={styles.userLoginView}
                             onClick={() => onUserLogout()}>
                        <LogOutIcon height={15} width={15}/>
                        <Typography style={styles.userLoginText} pl={0.3}>Sign Out</Typography>
                      </Box>}
                  </Grid>
                : null}
            </Grid>
          }

        </Toolbar>

      </AppBar>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.userData.userToken,
    tgbin: state.userData.tgbin,
    reviewPendingCount: state.userData.reviewPendingCount,
    shippingCostPendingCount: state.userData.shippingCostPendingCount,
    checkoutPendingCount: state.userData.checkoutPendingCount,
    pendingOrderCount: state.userData.pendingOrderCount,
    unshippedOrderCount: state.userData.unshippedOrderCount,
    shippedOrderCount: state.userData.shippedOrderCount,
    deliveredOrderCount: state.userData.deliveredOrderCount,
    cancelledOrderCount: state.userData.cancelledOrderCount,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    alertMsgEmpty: data => dispatch(alertMsgEmpty(data)),
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
    getCartCount: data => dispatch(getCartCount(data)),
    getOrderCount: data => dispatch(getOrderCount(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);

const styles = {
  logoMainView: {
    cursor: 'pointer'
  },
  mainNav: {
    backgroundColor: THEME_COLOR2,
    boxShadow: '0px 2px 3px #aaa',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: 'center'
  },
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 30,
  },
  orderCountView: {
    cursor: 'pointer',
  },
  orderCountText: {
    color: THEME_COLOR5,
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  orderText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  cartIconView: {
    cursor: 'pointer',
    position: 'relative'
  },
  cartCountText: {
    color: THEME_COLOR5,
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  cartText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  trebbsText: {
    fontFamily: 'InterBold',
    fontSize: 22,
    color: THEME_COLOR7
  },
  tradeText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 11,
    marginTop: -6
  },
  textColor: {
    color: THEME_COLOR7
  },
  userLoginView: {
    cursor: 'pointer'
  },
  userLoginText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 13
  }
};
