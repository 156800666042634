import React, {useEffect, useState} from 'react'
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";
import ReviewDeliveryAddress from "../../components/ReviewDeliveryAddress";
import ReviewShipping from "../../components/ReviewShipping";
import ReviewScheduleOrder from "../../components/ReviewScheduleOrder";
import ReviewPaymentMethod from "../../components/ReviewPaymentMethod";
import ReviewItem from "../../components/ReviewItem";

import {connect} from "react-redux";
import {
  getReviewData,
  addDeliveryAddress,
  updateApiStatus,
  getCartDetail,
  updateCartDetail
} from "../../redux/actions/userDataActions";

import styles from './Review.module.css';

import {THEME_COLOR} from "../../constants";
import {createRandomString} from "../../assets/functions/common";
import moment from "moment";
import dayjs from "dayjs";

const Review = (props) => {
  // const [shipping, setShipping] = useState(null);
  // const [packages, setPackages] = useState('');
  const [cartUid, setCartUid] = useState('');
  const [shippingMode, setShippingMode] = useState('Sea');
  const [incoterm, setIncoterm] = useState('');
  const [containerType, setContainerType] = useState('');
  const [sgsPreShipmentInspection, setSgsPreShipmentInspection] = useState(0);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState('');
  const [cargoId, setCargoId] = useState('')
  const [shippingDate, setShippingDate] = useState(dayjs());
  const [selectedShippingDate, setSelectedShippingDate] = useState(dayjs());
  const [productCount, setProductCount] = useState(0)
  const [checkoutBtn, setCheckoutBtn] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    let cargo_id = createRandomString(8);
    setCargoId(cargo_id);
    props?.getReviewData();
  }, [])

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let cart_id = params.get('id');
    if (cart_id) {
      setCartUid(cart_id)
      let data = {
        cart_uid: cart_id,
      };
      props.getCartDetail(JSON.stringify(data));
    }
  }, [])

  useEffect(() => {
    if (props.apiStatus == "ADD_DELIVERY_ADDRESS_SUCCESS") {
      props.updateApiStatus("");
    } else if (props.apiStatus == 'UPDATE_CART_DETAIL_SUCCESS') {
      navigate('/cart?type=shipping_cost_pending')
      props.updateApiStatus("");
    } else if (props.apiStatus == 'UPDATE_CART_DETAIL_SUCCESS_CHECKOUT') {
      navigate('/cart?type=checkout_pending')
      props.updateApiStatus("");
    }
  }, [props.apiStatus])

  useEffect(() => {
    if (props.cartDetail != null) {
      let days = 0;
      let total_days = 0;
      let date = dayjs();
      if (props.cartDetail?.product_processing_time != '') {
        days = props.cartDetail?.product_processing_time;
        total_days = parseFloat(days) + parseFloat(2);
        date = dayjs().add(total_days, 'days')
        setShippingDate(date);
        setSelectedShippingDate(date);
      }
      if (props.cartDetail?.quantity != '') {
        setProductCount(props.cartDetail?.quantity);
      }
    }
  }, [props.cartDetail])

  useEffect(() => {
    if (incoterm) {
      let inco_list = [...props?.reviewDataList?.incotermList];
      let inco_index = inco_list.findIndex((x) => x.value == incoterm);
      let incoterm_label = '';
      if (inco_index >= 0) {
        incoterm_label = inco_list[inco_index]['label']
      }
      if (incoterm_label == 'EXW' && sgsPreShipmentInspection == 0) {
        setCheckoutBtn(true);
      } else {
        setCheckoutBtn(false);
      }
    }
  }, [incoterm, sgsPreShipmentInspection])

  const onSaveDeliveryAddress = (data) => {
    props.addDeliveryAddress(JSON.stringify(data));
  }

  const onFindShippingCost = () => {
    let errorText = '';
    if (props?.deliveryAddressId == '') {
      errorText = 'Delivery Address is required';
      setError(errorText);
      return;
    }
    if (shippingMode == '') {
      errorText = 'Mode of Shipping is required';
      setError(errorText);
      return;
    }
    if (incoterm == '') {
      errorText = 'Incoterm is required';
      setError(errorText);
      return;
    }
    if (containerType == '') {
      errorText = 'Type of Container is required';
      setError(errorText);
      return;
    }
    if ((sgsPreShipmentInspection == '') && (sgsPreShipmentInspection != 0)) {
      errorText = 'SGS pre-shipment inspection is required';
      setError(errorText);
      return;
    }
    if (cargoId == '') {
      errorText = 'Cargo ID is required';
      setError(errorText);
      return;
    }

    if (selectedShippingDate == '' || selectedShippingDate == null || shippingDate == '' || shippingDate == null) {
      errorText = 'Shipping date is required';
      setError(errorText);
      return;
    }

    let shipping_date_val = dayjs(shippingDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    let selected_shipping_date_val = dayjs(selectedShippingDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    if (selected_shipping_date_val < shipping_date_val) {
      errorText = 'Please choose date greater than ' + dayjs(shippingDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
      setError(errorText);
      return;
    }
    if (productCount == '' && productCount == 0) {
      errorText = 'Quantity is required';
      setError(errorText);
      return;
    } else {
      let order_quantity = 0;
      if (props?.cartDetail?.package_net_weight != '') {
        order_quantity = productCount * props?.cartDetail?.package_net_weight;
        if (order_quantity < props.cartDetail?.minimum_order_quantity) {
          errorText = 'MOQ is ' + props.cartDetail?.minimum_order_quantity;
          setError(errorText)
          return;
        }
      } else {
        errorText = 'Package net weight not found';
        setError(errorText);
        return;
      }
    }
    if (selectedPaymentTerm == '') {
      errorText = 'Payment Method is required';
      setError(errorText);
      return;
    }

    let data = {
      cartUid: cartUid,
      deliveryAddressId: props?.deliveryAddressId ? props.deliveryAddressId : '',
      shippingMode: shippingMode,
      incoterm: incoterm,
      containerType: containerType,
      sgsPreShipmentInspection: sgsPreShipmentInspection,
      cargoId: cargoId,
      shippingDate: selected_shipping_date_val,
      productCount: productCount,
      paymentTerm: selectedPaymentTerm,
      checkout: checkoutBtn
    };
    props.updateCartDetail(JSON.stringify(data))
  };

  return (
    <Container page={"Review"} title={'Review'} subTitle={'Review'}>

      <Grid container
            item md={12} xs={12}
            display={"flex"} alignItems={"center"} justifyContent={"center"} mt={3}
      >

        <Grid container
              item md={10} xs={11}
              display={"flex"} alignItems={"center"} justifyContent={"center"}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>1.Delivery address</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewDeliveryAddress
              onSaveDeliveryAddress={onSaveDeliveryAddress}
              cartUid={cartUid}
            />
          </Grid>

          <Grid item md={12} xs={12} mt={2} mb={2}>
            {/*<Box className={styles.borderBottomLine}/>*/}
          </Grid>
        </Grid>

        <Grid container
              item md={10} xs={11}
              display={"flex"} alignItems={"center"} justifyContent={"center"} mt={4}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>2.Shipping</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewShipping
              data={props?.reviewDataList}
              shippingMode={shippingMode}
              setShippingMode={setShippingMode}
              incoterm={incoterm}
              setIncoterm={setIncoterm}
              containerType={containerType}
              setContainerType={setContainerType}
              sgsPreShipmentInspection={sgsPreShipmentInspection}
              setSgsPreShipmentInspection={setSgsPreShipmentInspection}
            />
          </Grid>

          <Grid item md={12} xs={12} mt={2} mb={2}>
            {/*<Box className={styles.borderBottomLine}/>*/}
          </Grid>
        </Grid>

        <Grid container
              item md={10} xs={11}
              display={"flex"} alignItems={"center"} justifyContent={"center"}
              mt={4}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>3.Schedule your order</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewScheduleOrder
              data={props?.reviewDataList}
              detail={props?.cartDetail}
              containerType={containerType}
              cargoId={cargoId}
              shippingDate={shippingDate}
              selectedShippingDate={selectedShippingDate}
              setSelectedShippingDate={setSelectedShippingDate}
              productCount={productCount}
              setProductCount={setProductCount}
            />
          </Grid>

          <Grid item md={12} xs={12} mt={2} mb={2}>
            {/*<Box className={styles.borderBottomLine}/>*/}
          </Grid>
        </Grid>

        <Grid container
              item md={10} xs={11}
              display={"flex"} alignItems={"center"} justifyContent={"center"}
              mt={4}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>4.Payment Method</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewPaymentMethod
              data={props?.reviewDataList}
              selectedPaymentTerm={selectedPaymentTerm}
              setSelectedPaymentTerm={setSelectedPaymentTerm}
            />
          </Grid>

          <Grid item md={12} xs={12} mt={2} mb={2}>
            {/*<Box className={styles.borderBottomLine}/>*/}
          </Grid>
        </Grid>

        <Grid container
              item md={10} xs={11}
              display={"flex"} alignItems={"center"} justifyContent={"center"}
              mt={4}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>5.Review Item</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewItem
              data={props?.cartDetail}
              productCount={productCount}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} mt={4}/>

        {error ?
          <Grid item md={12} xs={12}
                display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography className={styles.moqErrorText}>{error}</Typography>
          </Grid>
          : null}

        <Grid item md={3} xs={11} mt={2}>
          <FormButton
            title={checkoutBtn ? 'Proceed to Checkout' : 'Find Shipping Cost'}
            buttonView={{
              width: '100%',
              backgroundColor: THEME_COLOR,
            }}
            onSubmit={() => onFindShippingCost()}
          />
        </Grid>

      </Grid>
      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    reviewDataList: state.userData.reviewDataList,
    cartDetail: state.userData.cartDetail,
    apiStatus: state.userData.apiStatus,
    deliveryAddressId: state.userData.deliveryAddressId,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getReviewData: data => dispatch(getReviewData(data)),
    addDeliveryAddress: data => dispatch(addDeliveryAddress(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    getCartDetail: data => dispatch(getCartDetail(data)),
    updateCartDetail: data => dispatch(updateCartDetail(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
