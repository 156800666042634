import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormButton from "./FormButton";

import AddImportLicenseDetails from "./AddImportLicenseDetails";
import UploadDocuments from "./UploadDocuments";

import './../assets/css/custom.css';

const AddImporterDetails = (props) => {
  const [error, setError] = useState(null);

  const onContinueClick = () => {
    props.onContinue()
  };

  return (
    <Grid
      container
      item xs={8} md={8}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
    >


      <AddImportLicenseDetails
        data={props?.data?.importLicenseDetails ? props.data.importLicenseDetails : null}
      />

      <UploadDocuments
        data={props?.data?.documentDetails ? props.data.documentDetails : null}
      />


      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="center" mt={4}>
          <FormButton
            title={'Continue'}
            btnStyleView={styles.btnStyleView}
            onSubmit={onContinueClick}
          />
      </Grid>

    </Grid>
  );
}

export default AddImporterDetails;

const styles = {
  btnStyleView: {
    height: 40,
    width: '50%',
  },
};
