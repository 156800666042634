import React, {useEffect} from 'react'
import {useState} from 'react';
import Box from '@mui/material/Box';
import {Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import FormButton from "../components/FormButton";
import FormTextInput from '../components/FormTextInput';

import {THEME_COLOR6} from "../constants";

const SendMailModal = (props) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!props.open) {
      setEmail('');
      setError(null)
    }
  }, [props.open]);

  const onSubmitBtnClick = () => {
    let errorText = {};
    if (email == '') {
      errorText = {...errorText, mail: 'Email is required'};
    } else if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(email)) {
      errorText = {...errorText, mail: 'Invalid Email'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      email: email
    };

    props.onSubmit(data);
  };

  const onCancel = () => {
    props?.onCancel()
  };


  return (
    <Modal
      open={props.open}
      style={styles.modelView}
      hideBackdrop={true}>
      <Box sx={styles.modalSize}>
        <Box sx={styles.headerView}>
          <Typography align="center" sx={styles.headerTitle}>
            {props?.type == 'username' ? 'Forgot Username' : 'Forgot Password'}
          </Typography>
        </Box>

        <Grid mt={3}>
          <FormTextInput
            page={'sendMailModal'}
            value={email}
            setValue={setEmail}
            label={'Mail'}
            error={error?.mail}
            required={true}
          />
        </Grid>
        <Box display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box mr={2}>
            <FormButton
              btnType={'cancel'}
              title={"Cancel"}
              btnStyleView={styles.btnStyleView}
              bgColorStyleView={THEME_COLOR6}
              onSubmit={onCancel}
            />
          </Box>
          <Box>
            <FormButton
              loading={props?.btnLoading}
              btnType={'btn5'}
              title={"Submit"}
              btnStyleView={{height: 35}}
              onSubmit={onSubmitBtnClick}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default SendMailModal

const styles = {
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalSize: {
    backgroundColor: '#FFF',
    width: {md: '30%', xs: '90%'},
    borderRadius: 2,
    border:'unset',
    padding: 4,
  },
  headerView: {
    position: 'relative',
  },
  headerTitle: {
    fontFamily: 'InterBold',
    fontSize: 20
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  labelText: {
    color: '#000',
    fontSize: 15,
    fontFamily: 'InterMedium',
  },
  btnStyleView: {
    backgroundColor: THEME_COLOR6,
    color: '#fff',
    fontSize: 14,
    fontFamily: 'InterMedium',
    width: '100%',
    height: 35,
  }
};
