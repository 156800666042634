import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import CartCheckoutPendingList from "./CartCheckoutPendingList";
import FormButton from "./FormButton";

import styles from "./CartCheckoutPending.module.css"

import {THEME_COLOR3} from "../constants";

import {connect} from "react-redux";
import {updateCheckoutData} from "../redux/actions/userDataActions";

import {ReactComponent as CheckBoxIcon} from "../assets/img/checkbox.svg";
import {ReactComponent as UnCheckBoxIcon} from "../assets/img/uncheckbox.svg";
import ItemProductList from "./ItemProductList";

const CartCheckoutPending = (props) => {
  const [checkedData, setCheckedData] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)
  const [showError, setShowError] = useState('');

  const navigate = useNavigate();

  const onDeleteCartClick = () => {
    let checked_list = [...checkedData];
    let checked_ids = [];
    checked_list.map((item, index) => {
      checked_ids.push(item?.cart_uid)
    });

    if (checked_ids?.length == 0) {
      setShowError('Choose items to delete');
      return;
    } else {
      setShowError('')
    }

    props.onDeleteCart(checked_ids, 'checkout_pending');
  };

  // const onProceedToCheckout = () => {
  //   let checked_list = [...checkedData];
  //   let checked_ids = [];
  //   checked_list.map((item, index) => {
  //     checked_ids.push(item?.cart_uid)
  //   });
  //
  //   if (checked_ids?.length == 0) {
  //     setShowError('Choose items to proceed');
  //     return;
  //   } else {
  //     setShowError('')
  //   }
  //
  //   props?.updateCheckoutData(checked_ids)
  //   navigate('/checkout')
  // };

  const proceedToCheckout = (item) => {
    navigate("/checkout/" + item?.cart_uid);
  };

  const onCheck = (item) => {
    let list = [...checkedData];
    let index = list.findIndex((x) => x?.cart_uid == item?.cart_uid);
    if (index >= 0) {
      list.splice(index, 1)
      setCheckedData(list);
      if (list.length == 0) {
        setCheckedAll(false)
      }
    } else {
      setCheckedData([...list, item]);
    }
  };

  const onCheckAll = () => {
    if (!checkedAll == true) {
      if (props?.list?.length > 0) {
        let list = [...props?.list];
        setCheckedData(list)
        setCheckedAll(!checkedAll);
      }
    } else {
      setCheckedData([])
      setCheckedAll(!checkedAll);
    }
  };

  return (
    <>
      <Grid item md={12} xs={12}
            display={"flex"}
            alignItems={"center"} justifyContent={"center"}
      >
        <Grid container item md={11.6} xs={11.6}
              display={"flex"} alignItems={"center"} justifyContent={"center"}
        >
          <Grid item md={12} xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <TableContainer
              className={'tableContainer'}>
              <Table  stickyHeader
                      aria-label="sticky table">
                <TableHead
                  className={'table'}>
                  <TableRow>
                    <TableCell>
                      {checkedAll ?
                        <Box onClick={() => onCheckAll()}>
                          <CheckBoxIcon height={25} width={25}/>
                        </Box> :
                        <Box onClick={() => onCheckAll()}>
                          <UnCheckBoxIcon height={25} width={25}/>
                        </Box>}
                    </TableCell>
                    <TableCell>Cart ID</TableCell>
                    <TableCell className={styles.dataView}>Product Details</TableCell>
                    <TableCell>Delivery Address</TableCell>
                    <TableCell className={styles.dataView}>Shipping Details</TableCell>
                    <TableCell>Payment</TableCell>
                    <TableCell className={styles.dataView}>
                      <Typography className={styles.productPriceText}>Total Product Price</Typography>
                      <Typography className={styles.productPriceExwText} pl={2}>(EXW)</Typography>
                    </TableCell>
                    <TableCell className={styles.dataView}>Total Shipping Cost</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.list?.length > 0 ?
                    props?.list?.map((item, index) => {
                      return (
                        <TableRow key={index} className={styles.tableRow}>
                          <CartCheckoutPendingList
                            key={index}
                            index={index}
                            data={item}
                            onCheck={onCheck}
                            checkedData={checkedData}
                            setCheckedData={setCheckedData}
                            proceedToCheckout={proceedToCheckout}
                            currencyRateList={props?.currencyRateList}
                          />
                          <Grid className={styles.borderBottomLine}/>
                        </TableRow>
                      )
                    }) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>


        </Grid>

      </Grid>


      <Grid item md={12} xs={12}
            display={"flex"}
            alignItems={"center"} justifyContent={"center"}
            mt={4}>
        <Grid item md={11} xs={11} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
          <Typography className={styles.errorText}>{showError}</Typography>
        </Grid>
      </Grid>

      {props?.list?.length > 0 ?
        <Grid item md={12} xs={12}
              display={"flex"}
              alignItems={"center"} justifyContent={"center"}
              mt={2}>
          <Grid item md={11} xs={11} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
            <FormButton
              title={'Delete'}
              buttonView={{
                width: '15%',
                backgroundColor: 'red'
              }}
              bgColorStyleView={'red'}
              onSubmit={() => onDeleteCartClick()}
            />
          </Grid>
        </Grid>
        : null}
    </>
  )
};

const matchDispatchToProps = (dispatch) => {
  return {
    updateCheckoutData: data => dispatch(updateCheckoutData(data)),
  }
};

export default connect(null, matchDispatchToProps)(CartCheckoutPending);
