//home
export const GET_HOME_PAGE = 'GET_HOME_PAGE';
export const GET_HOME_PAGE_SUCCESS = 'GET_HOME_PAGE_SUCCESS';
export const GET_HOME_PAGE_FAIL = 'GET_HOME_PAGE_FAIL';

//login
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const FORGOT_USER_NAME = 'FORGOT_USER_NAME';
export const FORGOT_USER_NAME_SUCCESS = 'FORGOT_USER_NAME_SUCCESS';
export const FORGOT_USER_NAME_FAIL = 'FORGOT_USER_NAME_FAIL';
export const FORGOT_USER_PASSWORD = 'FORGOT_USER_PASSWORD';
export const FORGOT_USER_PASSWORD_SUCCESS = 'FORGOT_USER_PASSWORD_SUCCESS';
export const FORGOT_USER_PASSWORD_FAIL = 'FORGOT_USER_PASSWORD_FAIL';
export const UPDATE_FORGOT_USER_PASSWORD = 'UPDATE_FORGOT_USER_PASSWORD';
export const UPDATE_FORGOT_USER_PASSWORD_SUCCESS = 'UPDATE_FORGOT_USER_PASSWORD_SUCCESS';
export const UPDATE_FORGOT_USER_PASSWORD_FAIL = 'UPDATE_FORGOT_USER_PASSWORD_FAIL';
export const GET_FORGOT_USER_PASSWORD = 'GET_FORGOT_USER_PASSWORD';
export const GET_FORGOT_USER_PASSWORD_SUCCESS = 'GET_FORGOT_USER_PASSWORD_SUCCESS';
export const GET_FORGOT_USER_PASSWORD_FAIL = 'GET_FORGOT_USER_PASSWORD_FAIL';

//register
export const GET_REGISTER_DATA = 'GET_REGISTER_DATA';
export const GET_REGISTER_DATA_SUCCESS = 'GET_REGISTER_DATA_SUCCESS';
export const GET_REGISTER_DATA_FAIL = 'GET_REGISTER_DATA_FAIL';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const UPDATE_REGISTER = 'UPDATE_REGISTER';
export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAIL = 'GET_STATES_FAIL';
export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAIL = 'GET_CITIES_FAIL';
export const UPDATE_CITIES = 'UPDATE_CITIES';

//product
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAIL = 'GET_PRODUCT_DETAIL_FAIL';
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAIL = 'GET_CATEGORY_LIST_FAIL';
export const GET_CATEGORY_PRODUCT_COUNT = 'GET_CATEGORY_PRODUCT_COUNT';
export const GET_CATEGORY_PRODUCT_COUNT_SUCCESS = 'GET_CATEGORY_PRODUCT_COUNT_SUCCESS';
export const GET_CATEGORY_PRODUCT_COUNT_FAIL = 'GET_CATEGORY_PRODUCT_COUNT_FAIL';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAIL = 'ADD_TO_CART_FAIL';
export const UPDATE_CART_BTN_TEXT = 'UPDATE_CART_BTN_TEXT';

//cart
export const GET_CART = 'GET_CART';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAIL = 'GET_CART_FAIL';
export const UPDATE_CART_LIST = 'UPDATE_CART_LIST';
export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAIL = 'UPDATE_CART_FAIL';
export const DELETE_CART = 'DELETE_CART';
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS';
export const DELETE_CART_FAIL = 'DELETE_CART_FAIL';
export const ADD_DELIVERY_ADDRESS = 'ADD_DELIVERY_ADDRESS';
export const ADD_DELIVERY_ADDRESS_SUCCESS = 'ADD_DELIVERY_ADDRESS_SUCCESS';
export const ADD_DELIVERY_ADDRESS_FAIL = 'ADD_DELIVERY_ADDRESS_FAIL';
export const GET_REVIEW_DATA = 'GET_REVIEW_DATA';
export const GET_REVIEW_DATA_SUCCESS = 'GET_REVIEW_DATA_SUCCESS';
export const GET_REVIEW_DATA_FAIL = 'GET_REVIEW_DATA_FAIL';
export const GET_CART_DETAIL = 'GET_CART_DETAIL';
export const GET_CART_DETAIL_SUCCESS = 'GET_CART_DETAIL_SUCCESS';
export const GET_CART_DETAIL_FAIL = 'GET_CART_DETAIL_FAIL';
export const UPDATE_CART_DETAIL = 'UPDATE_CART_DETAIL';
export const UPDATE_CART_DETAIL_SUCCESS = 'UPDATE_CART_DETAIL_SUCCESS';
export const UPDATE_CART_DETAIL_FAIL = 'UPDATE_CART_DETAIL_FAIL';
export const UPDATE_CHECKOUT_DATA = 'UPDATE_CHECKOUT_DATA';
export const GET_CART_COUNT = 'GET_CART_COUNT';
export const GET_CART_COUNT_SUCCESS = 'GET_CART_COUNT_SUCCESS';
export const GET_CART_COUNT_FAIL = 'GET_CART_COUNT_FAIL';

//account
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAIL = 'GET_USER_DETAIL_FAIL';
export const GET_USER_MAIL_VERIFY = 'GET_USER_MAIL_VERIFY';
export const GET_USER_MAIL_VERIFY_SUCCESS = 'GET_USER_MAIL_VERIFY_SUCCESS';
export const GET_USER_MAIL_VERIFY_FAIL = 'GET_USER_MAIL_VERIFY_FAIL';
export const SEND_MAIL = 'SEND_MAIL';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_FAIL = 'SEND_MAIL_FAIL';
export const MAIL_VERIFICATION = 'MAIL_VERIFICATION';
export const MAIL_VERIFICATION_SUCCESS = 'MAIL_VERIFICATION_SUCCESS';
export const MAIL_VERIFICATION_FAIL = 'MAIL_VERIFICATION_FAIL';
export const ADD_USER_CONTACT_DETAILS = 'ADD_USER_CONTACT_DETAILS';
export const ADD_USER_CONTACT_DETAILS_SUCCESS = 'ADD_USER_CONTACT_DETAILS_SUCCESS';
export const ADD_USER_CONTACT_DETAILS_FAIL = 'ADD_USER_CONTACT_DETAILS_FAIL';
export const UPDATE_USER_CONTACT_DETAILS = 'UPDATE_USER_CONTACT_DETAILS';
export const UPDATE_USER_CONTACT_DETAILS_SUCCESS = 'UPDATE_USER_CONTACT_DETAILS_SUCCESS';
export const UPDATE_USER_CONTACT_DETAILS_FAIL = 'UPDATE_USER_CONTACT_DETAILS_FAIL';
export const ADD_USER_IMPORT_LICENSE_DETAILS = 'ADD_USER_IMPORT_LICENSE_DETAILS';
export const ADD_USER_IMPORT_LICENSE_DETAILS_SUCCESS = 'ADD_USER_IMPORT_LICENSE_DETAILS_SUCCESS';
export const ADD_USER_IMPORT_LICENSE_DETAILS_FAIL = 'ADD_USER_IMPORT_LICENSE_DETAILS_FAIL';
export const UPDATE_USER_IMPORT_LICENSE_DETAILS = 'UPDATE_USER_IMPORT_LICENSE_DETAILS';
export const UPDATE_USER_IMPORT_LICENSE_DETAILS_SUCCESS = 'UPDATE_USER_IMPORT_LICENSE_DETAILS_SUCCESS';
export const UPDATE_USER_IMPORT_LICENSE_DETAILS_FAIL = 'UPDATE_USER_IMPORT_LICENSE_DETAILS_FAIL';
export const ADD_USER_DOCUMENTS = 'ADD_USER_DOCUMENTS';
export const ADD_USER_DOCUMENTS_SUCCESS = 'ADD_USER_DOCUMENTS_SUCCESS';
export const ADD_USER_DOCUMENTS_FAIL = 'ADD_USER_DOCUMENTS_FAIL';
export const UPDATE_USER_DOCUMENTS = 'UPDATE_USER_DOCUMENTS';
export const UPDATE_USER_DOCUMENTS_SUCCESS = 'UPDATE_USER_DOCUMENTS_SUCCESS';
export const UPDATE_USER_DOCUMENTS_FAIL = 'UPDATE_USER_DOCUMENTS_FAIL';
export const ADD_USER_PRODUCT_CATEGORY = 'ADD_USER_PRODUCT_CATEGORY';
export const ADD_USER_PRODUCT_CATEGORY_SUCCESS = 'ADD_USER_PRODUCT_CATEGORY_SUCCESS';
export const ADD_USER_PRODUCT_CATEGORY_FAIL = 'ADD_USER_PRODUCT_CATEGORY_FAIL';
export const UPDATE_SHOW_TGBIN = 'UPDATE_SHOW_TGBIN';
export const GET_LOGIN_USER_DETAIL = 'GET_LOGIN_USER_DETAIL';
export const GET_LOGIN_USER_DETAIL_SUCCESS = 'GET_LOGIN_USER_DETAIL_SUCCESS';
export const GET_LOGIN_USER_DETAIL_FAIL = 'GET_LOGIN_USER_DETAIL_FAIL';

export const GET_CONTACT_ADDRESS = 'GET_CONTACT_ADDRESS';
export const GET_CONTACT_ADDRESS_SUCCESS = 'GET_CONTACT_ADDRESS_SUCCESS';
export const GET_CONTACT_ADDRESS_FAIL = 'GET_CONTACT_ADDRESS_FAIL';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_USER_NAME_SUCCESS = 'UPDATE_USER_NAME_SUCCESS';
export const UPDATE_USER_NAME_FAIL = 'UPDATE_USER_NAME_FAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

//checkout
export const GET_CHECKOUT = 'GET_CHECKOUT';
export const GET_CHECKOUT_SUCCESS = 'GET_CHECKOUT_SUCCESS';
export const GET_CHECKOUT_FAIL = 'GET_CHECKOUT_FAIL';

export const PLACE_ORDER = 'PLACE_ORDER';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAIL = 'PLACE_ORDER_FAIL';

//order
export const LIST_ORDER = 'LIST_ORDER';
export const LIST_ORDER_SUCCESS = 'LIST_ORDER_SUCCESS';
export const LIST_ORDER_FAIL = 'LIST_ORDER_FAIL';
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_FAIL = 'GET_ORDER_DETAIL_FAIL';
export const GET_ADMIN_BENEFICIARY_DETAIL = 'GET_ADMIN_BENEFICIARY_DETAIL';
export const GET_ADMIN_BENEFICIARY_DETAIL_SUCCESS = 'GET_ADMIN_BENEFICIARY_DETAIL_SUCCESS';
export const GET_ADMIN_BENEFICIARY_DETAIL_FAIL = 'GET_ADMIN_BENEFICIARY_DETAIL_FAIL';
export const UPLOAD_PAYMENT_DETAIL = 'UPLOAD_PAYMENT_DETAIL';
export const UPLOAD_PAYMENT_DETAIL_SUCCESS = 'UPLOAD_PAYMENT_DETAIL_SUCCESS';
export const UPLOAD_PAYMENT_DETAIL_FAIL = 'UPLOAD_PAYMENT_DETAIL_FAIL';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL';
export const GET_ORDER_COUNT = 'GET_ORDER_COUNT';
export const GET_ORDER_COUNT_SUCCESS = 'GET_ORDER_COUNT_SUCCESS';
export const GET_ORDER_COUNT_FAIL = 'GET_ORDER_COUNT_FAIL';

//export country
export const GET_EXPORT_COUNTRY_LIST = 'GET_EXPORT_COUNTRY_LIST';
export const GET_EXPORT_COUNTRY_LIST_SUCCESS = 'GET_EXPORT_COUNTRY_LIST_SUCCESS';
export const GET_EXPORT_COUNTRY_LIST_FAIL = 'GET_EXPORT_COUNTRY_LIST_FAIL';
export const CLEAR_CART = 'CLEAR_CART';
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAIL = 'CLEAR_CART_FAIL';

export const GET_CURRENCY_RATE_LIST = 'GET_CURRENCY_RATE_LIST';
export const GET_CURRENCY_RATE_LIST_SUCCESS = 'GET_CURRENCY_RATE_LIST_SUCCESS';
export const GET_CURRENCY_RATE_LIST_FAIL = 'GET_CURRENCY_RATE_LIST_FAIL';

export const UPDATE_API_STATUS = 'UPDATE_API_STATUS';
export const ALERT_MSG_EMPTY = 'ALERT_MSG_EMPTY';
export const UPDATE_ALERT_MSG = 'UPDATE_ALERT_MSG';
export const LOG_OUT = 'LOG_OUT';
