import * as React from 'react';
import {Box, Typography, Dialog, DialogContent, Grid} from '@mui/material';

import FormButton from "./FormButton";

import styles from '../components/ConfirmAlert.module.css'
import Modal from "@mui/material/Modal";

const ConfirmAlert = (props) => {
  const onConfirmClick = () => {
    props.onConfirmClick()
  };

  const onCancelClick = () => {
    props.onCancelClick()
  };

  return (
    <Dialog
      open={props?.confirmBox}
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          width: {md: '90%', xs: '90%'},
          minHeight: {md: '45%', xs: '45%'},
        },
      }}>
      <Box p={2}>
        <DialogContent>
          <Box display={'flex'} justifyContent={"center"}>
            {props?.icon != '' ? props?.icon : ''}
          </Box>
          <Box mt={3}>
            <Typography className={styles.contentText}>{props?.content}</Typography>
          </Box>
        </DialogContent>
        <Grid item md={12} xs={12} container
              mt={{md: 2, xs: 1}}
              display="flex" flexDirection="row" alignItems={"center"} justifyContent="space-evenly">
          <FormButton
            loading={props?.btnLoading}
            title={props?.firstBtn}
            onSubmit={onConfirmClick}
          />
          <FormButton
            btnStyleView={{backgroundColor: '#f9f9f9', color: '#7e8299'}}
            bgColorStyleView={{backgroundColor: '#f9f9f9'}}
            title={props?.secondBtn}
            onSubmit={onCancelClick}
          />
        </Grid>
      </Box>
    </Dialog>
  )
}

export default ConfirmAlert
