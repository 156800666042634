import {put, takeLatest, call} from "redux-saga/effects";

import {
  GET_HOME_PAGE,
  LOGIN_USER,
  GET_REGISTER_DATA,
  REGISTER_USER,
  GET_PRODUCT,
  GET_PRODUCT_DETAIL,
  GET_STATES,
  GET_CITIES,
  GET_CATEGORY_LIST,
  ADD_TO_CART,
  GET_CART,
  UPDATE_CART,
  DELETE_CART,
  ADD_DELIVERY_ADDRESS,
  GET_REVIEW_DATA,
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  GET_CART_COUNT,
  SEND_MAIL,
  MAIL_VERIFICATION,
  GET_USER_DETAIL,
  GET_USER_MAIL_VERIFY,
  ADD_USER_CONTACT_DETAILS,
  UPDATE_USER_CONTACT_DETAILS,
  ADD_USER_IMPORT_LICENSE_DETAILS,
  UPDATE_USER_IMPORT_LICENSE_DETAILS,
  ADD_USER_DOCUMENTS,
  UPDATE_USER_DOCUMENTS,
  ADD_USER_PRODUCT_CATEGORY,
  GET_CONTACT_ADDRESS,
  UPDATE_USER_NAME,
  UPDATE_PASSWORD,
  GET_EXPORT_COUNTRY_LIST,
  CLEAR_CART,
  GET_ORDER_DETAIL,
  GET_CHECKOUT,
  PLACE_ORDER,
  LIST_ORDER,
  GET_ORDER_COUNT,
  GET_ADMIN_BENEFICIARY_DETAIL,
  UPLOAD_PAYMENT_DETAIL,
  CANCEL_ORDER,
  GET_CURRENCY_RATE_LIST,
  GET_LOGIN_USER_DETAIL,
  GET_CATEGORY_PRODUCT_COUNT,
  FORGOT_USER_NAME,
  FORGOT_USER_PASSWORD,
  UPDATE_FORGOT_USER_PASSWORD,
  GET_FORGOT_USER_PASSWORD
} from "../actions/types";
import {
  getHomePageSuccess,
  getHomePageFail,
  loginUserSuccess,
  loginUserFail,
  getRegisterDataSuccess,
  getRegisterDataFail,
  registerUserSuccess,
  registerUserFail,
  getProductSuccess,
  getProductFail,
  getProductDetailSuccess,
  getProductDetailFail,
  getStatesSuccess,
  getStatesFail,
  getCitiesSuccess,
  getCitiesFail,
  getCategoryListSuccess,
  getCategoryListFail,
  addToCartSuccess,
  addToCartFail,
  getCartSuccess,
  getCartFail,
  updateCartSuccess,
  updateCartFail,
  deleteCartSuccess,
  deleteCartFail,
  addDeliveryAddressSuccess,
  addDeliveryAddressFail,
  getReviewDataSuccess,
  getReviewDataFail,
  getCartDetailSuccess,
  getCartDetailFail,
  updateCartDetailSuccess,
  updateCartDetailFail,
  getCartCountSuccess,
  getCartCountFail,
  sendMailSuccess,
  sendMailFail,
  mailVerificationSuccess,
  mailVerificationFail,
  getUserDetailSuccess,
  getUserDetailFail,
  getUserMailVerifySuccess,
  getUserMailVerifyFail,
  addUserContactDetailSuccess,
  addUserContactDetailFail,
  updateUserContactDetailSuccess,
  updateUserContactDetailFail,
  addUserImportLicenseDetailSuccess,
  addUserImportLicenseDetailFail,
  updateUserImportLicenseDetailSuccess,
  updateUserImportLicenseDetailFail,
  addUserDocumentsSuccess,
  addUserDocumentsFail,
  updateUserDocumentsSuccess,
  updateUserDocumentsFail,
  addUserProductCategorySuccess,
  addUserProductCategoryFail,
  getContactAddressSuccess,
  getContactAddressFail,
  updateUserNameSuccess,
  updateUserNameFail,
  updatePasswordSuccess,
  updatePasswordFail,
  placeOrderSuccess,
  placeOrderFail,
  getExportCountryListSuccess,
  getExportCountryListFail,
  updateAlertMsg,
  clearCartSuccess,
  clearCartFail,
  getOrderDetailSuccess,
  getOrderDetailFail,
  getCheckoutSuccess,
  getCheckoutFail,
  listOrderSuccess,
  listOrderFail,
  getOrderCountSuccess,
  getOrderCountFail,
  getAdminBeneficiaryDetailSuccess,
  getAdminBeneficiaryDetailFail,
  uploadPaymentDetailSuccess,
  uploadPaymentDetailFail,
  cancelOrderSuccess,
  cancelOrderFail,
  getCurrencyRateListSuccess,
  getCurrencyRateListFail,
  getLoginUserDetailSuccess,
  getLoginUserDetailFail,
  getCategoryProductCountSuccess,
  getCategoryProductCountFail,
  forgotUserNameSuccess,
  forgotUserNameFail,
  forgotUserPasswordSuccess,
  forgotUserPasswordFail,
  updateForgotUserPasswordSuccess,
  updateForgotUserPasswordFail,
  getForgotUserPasswordSuccess,
  getForgotUserPasswordFail
} from "../actions/userDataActions";

import * as Api from "../../api";

function* onGetHomePage(data) {
  try {
    const response = yield call(Api.getHomePage, data.payload);
    if (response.status == "Success") {
      yield put(getHomePageSuccess(response));
    } else {
      yield put(getHomePageFail(response));
    }
  } catch (error) {
    yield put(getHomePageFail("Failed"));
  }
}

function* onLoginUser(data) {
  try {
    const response = yield call(Api.userLogin, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(loginUserSuccess({...response, userName: payload_data.username}));
    } else {
      yield put(loginUserFail(response));
    }
  } catch (error) {
    yield put(loginUserFail({msg: "Failed"}));
  }
}

function* onGetRegisterData() {
  try {
    const response = yield call(Api.getRegisterData);
    if (response.status == "Success") {
      yield put(getRegisterDataSuccess(response));
    } else {
      yield put(getRegisterDataFail(response));
    }
  } catch (error) {
    yield put(getRegisterDataFail("Failed"));
  }
}

function* onRegisterUser(data) {
  try {
    const response = yield call(Api.userRegister, data.payload);
    if (response.status == "Success") {
      yield put(registerUserSuccess(response));
    } else {
      yield put(registerUserFail(response));
    }
  } catch (error) {
    yield put(registerUserFail({msg: "Failed"}));
  }
}

function* onGetStatesList(data) {
  try {
    const response = yield call(Api.getStatesList, data.payload);
    if (response.status == "Success") {
      yield put(getStatesSuccess(response));
    } else {
      yield put(getStatesFail(response));
    }
  } catch (error) {
    yield put(getStatesFail({msg: "Failed"}));
  }
}

function* onGetCitiesList(data) {
  try {
    const response = yield call(Api.getCitiesList, data.payload);
    if (response.status == "Success") {
      yield put(getCitiesSuccess(response));
    } else {
      yield put(getCitiesFail(response));
    }
  } catch (error) {
    yield put(getCitiesFail({msg: "Failed"}));
  }
}

function* onGetProduct(data) {
  try {
    const response = yield call(Api.getProductList, data.payload);
    if (response.status == "Success") {
      yield put(getProductSuccess(response));
    } else {
      yield put(getProductFail(response));
    }
  } catch (error) {
    yield put(getProductFail("Failed"));
  }
}

function* onGetProductDetail(data) {
  try {
    const response = yield call(Api.getProductDetail, data.payload);
    if (response.status == "Success") {
      yield put(getProductDetailSuccess(response));
    } else {
      yield put(getProductDetailFail(response));
      window.location.href = 'index';
    }
  } catch (error) {
    yield put(getProductDetailFail("Failed"));
    window.location.href = 'index';
  }
}

function* onGetCategoryList(data) {
  try {
    const response = yield call(Api.getCategoryList, data.payload);
    if (response.status == "Success") {
      yield put(getCategoryListSuccess(response));
    } else {
      yield put(getCategoryListFail(response));
    }
  } catch (error) {
    yield put(getCategoryListFail("Failed"));
  }
}

function* onAddToCart(data) {
  try {
    const response = yield call(Api.addToCart, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(addToCartSuccess({
        ...response,
        productUid: payload_data.productUid,
        categoryUid: payload_data.categoryUid
      }));
    } else {
      yield put(addToCartFail(response));
    }
  } catch (error) {
    yield put(addToCartFail("Failed"));
  }
}

function* onGetCartList() {
  try {
    const response = yield call(Api.getCartList);
    if (response.status == "Success") {
      yield put(getCartSuccess(response));
    } else {
      yield put(getCartFail(response));
    }
  } catch (error) {
    yield put(getCartFail("Failed"));
  }
}

function* onUpdateCart(data) {
  try {
    const response = yield call(Api.updateCart, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(updateCartSuccess({
        ...response,
        page: payload_data.page,
      }));
    } else {
      yield put(updateCartFail(response));
    }
  } catch (error) {
    yield put(updateCartFail("Failed"));
  }
}

function* onDeleteCart(data) {
  try {
    const response = yield call(Api.deleteCart, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteCartSuccess({
        ...response,
        cartUid: payload_data.cartUid,
        currentStatus: payload_data.currentStatus,
      }));
    } else {
      yield put(deleteCartFail(response));
    }
  } catch (error) {
    yield put(deleteCartFail("Failed"));
  }
}

function* onAddDeliveryAddress(data) {
  try {
    const response = yield call(Api.addDeliveryAddress, data.payload);
    if (response.status == "Success") {
      yield put(addDeliveryAddressSuccess(response));
    } else {
      yield put(addDeliveryAddressFail(response));
    }
  } catch (error) {
    yield put(addDeliveryAddressFail("Failed"));
  }
}

function* onGetReviewData(data) {
  try {
    const response = yield call(Api.getReviewData, data.payload);
    if (response.status == "Success") {
      yield put(getReviewDataSuccess(response));
    } else {
      yield put(getReviewDataFail(response));
    }
  } catch (error) {
    yield put(getReviewDataFail("Failed"));
  }
}

function* onGetCartDetail(data) {
  try {
    const response = yield call(Api.getCartDetail, data.payload);
    if (response.status == "Success") {
      yield put(getCartDetailSuccess(response));
    } else {
      yield put(getCartDetailFail(response));
      window.location.href = 'cart';
    }
  } catch (error) {
    yield put(getCartDetailFail("Failed"));
    window.location.href = 'cart';
  }
}

function* onUpdateCartDetail(data) {
  try {
    const response = yield call(Api.updateCartDetail, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(updateCartDetailSuccess({
        ...response,
        cartUid: payload_data.cartUid,
        checkout: payload_data.checkout,
      }));
    } else {
      yield put(updateCartDetailFail(response));
      window.location.href = 'cart';
    }
  } catch (error) {
    yield put(updateCartDetailFail("Failed"));
    window.location.href = 'cart';
  }
}

function* onGetCartCount() {
  try {
    const response = yield call(Api.getCartCount);
    if (response.status == "Success") {
      yield put(getCartCountSuccess(response));
    } else {
      yield put(getCartCountFail(response));
    }
  } catch (error) {
    yield put(getCartCountFail("Failed"));
  }
}

function* onSendMail(data) {
  try {
    const response = yield call(Api.sendMail, data.payload);
    if (response.status == "Success") {
      yield put(sendMailSuccess(response));
    } else {
      yield put(sendMailFail(response));
    }
  } catch (error) {
    yield put(sendMailFail("Failed"));
  }
}

function* onMailVerification(data) {
  try {
    const response = yield call(Api.mailVerification, data.payload);
    if (response.status == "Success") {
      yield put(mailVerificationSuccess(response));
    } else {
      yield put(mailVerificationFail(response));
    }
  } catch (error) {
    yield put(mailVerificationFail("Failed"));
  }
}

function* onGetUserDetail(data) {
  try {
    const response = yield call(Api.getUserDetail, data.payload);
    if (response.status == "Success") {
      yield put(getUserDetailSuccess(response));
    } else {
      yield put(getUserDetailFail(response));
    }
  } catch (error) {
    yield put(getUserDetailFail("Failed"));
  }
}

function* onGetUserMailVerify(data) {
  try {
    const response = yield call(Api.getUserMailVerify, data.payload);
    if (response.status == "Success") {
      yield put(getUserMailVerifySuccess(response));
    } else {
      yield put(getUserMailVerifyFail(response));
    }
  } catch (error) {
    yield put(getUserMailVerifyFail("Failed"));
  }
}

function* onAddUserContactDetail(data) {
  try {
    const response = yield call(Api.addUserContactDetail, data.payload);
    if (response.status == "Success") {
      yield put(addUserContactDetailSuccess(response));
    } else {
      yield put(addUserContactDetailFail(response));
    }
  } catch (error) {
    yield put(addUserContactDetailFail("Failed"));
  }
}

function* onUpdateUserContactDetail(data) {
  try {
    const response = yield call(Api.updateUserContactDetail, data.payload);
    if (response.status == "Success") {
      yield put(updateUserContactDetailSuccess(response));
    } else {
      yield put(updateUserContactDetailFail(response));
    }
  } catch (error) {
    yield put(updateUserContactDetailFail("Failed"));
  }
}

function* onAddUserImportLicenseDetail(data) {
  try {
    const response = yield call(Api.addUserImportLicenseDetail, data.payload);
    if (response.status == "Success") {
      yield put(addUserImportLicenseDetailSuccess(response));
    } else {
      yield put(addUserImportLicenseDetailFail(response));
    }
  } catch (error) {
    yield put(addUserImportLicenseDetailFail("Failed"));
  }
}

function* onUpdateUserImportLicenseDetail(data) {
  try {
    const response = yield call(Api.updateUserImportLicenseDetail, data.payload);
    if (response.status == "Success") {
      yield put(updateUserImportLicenseDetailSuccess(response));
    } else {
      yield put(updateUserImportLicenseDetailFail(response));
    }
  } catch (error) {
    yield put(updateUserImportLicenseDetailFail("Failed"));
  }
}

function* onAddUserDocuments(data) {
  try {
    const response = yield call(Api.addUserDocument, data.payload);
    if (response.status == "Success") {
      yield put(addUserDocumentsSuccess(response));
    } else {
      yield put(addUserDocumentsFail(response));
    }
  } catch (error) {
    yield put(addUserDocumentsFail("Failed"));
  }
}

function* onUpdateUserDocuments(data) {
  try {
    const response = yield call(Api.updateUserDocument, data.payload);
    if (response.status == "Success") {
      yield put(updateUserDocumentsSuccess(response));
    } else {
      yield put(updateUserDocumentsFail(response));
    }
  } catch (error) {
    yield put(updateUserDocumentsFail("Failed"));
  }
}

function* onAddUserProductCategory(data) {
  try {
    const response = yield call(Api.addUserProductCategory, data.payload);
    if (response.status == "Success") {
      yield put(addUserProductCategorySuccess(response));
    } else {
      yield put(addUserProductCategoryFail(response));
    }
  } catch (error) {
    yield put(addUserProductCategoryFail("Failed"));
  }
}

function* onGetContactAddress() {
  try {
    const response = yield call(Api.getContactAddress);
    if (response.status == "Success") {
      yield put(getContactAddressSuccess(response));
    } else {
      yield put(getContactAddressFail(response));
    }
  } catch (error) {
    yield put(getContactAddressFail({msg: "Failed"}));
  }
}

function* onUpdateUserName(data) {
  try {
    const response = yield call(Api.updateUserName, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(updateUserNameSuccess({...response, userName: payload_data.username}));
    } else {
      yield put(updateUserNameFail(response));
    }
  } catch (error) {
    yield put(updateUserNameFail({msg: "Failed"}));
  }
}

function* onUpdatePassword(data) {
  try {
    const response = yield call(Api.updatePassword, data.payload);
    if (response.status == "Success") {
      yield put(updatePasswordSuccess(response));
    } else {
      yield put(updatePasswordFail(response));
    }
  } catch (error) {
    yield put(updatePasswordFail({msg: "Failed"}));
  }
}

function* onPlaceOrder(data) {
  try {
    const response = yield call(Api.placeOrder, data.payload);
    if (response.status == "Success") {
      yield put(placeOrderSuccess(response));
    } else {
      yield put(placeOrderFail(response));
    }
  } catch (error) {
    yield put(placeOrderFail('Failed'));
  }
}

function* onGetExportCountryList(data) {
  try {
    const response = yield call(Api.getExportCountryList, data.payload);
    if (response.status == "Success") {
      yield put(getExportCountryListSuccess(response));
    } else {
      yield put(getExportCountryListFail(response));
    }
  } catch (error) {
    yield put(getExportCountryListFail({msg: "Failed"}));
  }
}

function* onClearCart() {
  try {
    const response = yield call(Api.clearCart);
    if (response.status == "Success") {
      yield put(clearCartSuccess(response));
    } else {
      yield put(clearCartFail(response));
    }
  } catch (error) {
    yield put(clearCartFail({msg: "Failed"}));
  }
}

function* onGetCheckout(data) {
  try {
    const response = yield call(Api.getCheckout, data.payload);
    if (response.status == "Success") {
      yield put(getCheckoutSuccess(response));
    } else {
      yield put(getCheckoutFail(response));
    }
  } catch (error) {
    yield put(getCheckoutFail("Failed"));
  }
}

function* onListOrder(data) {
  try {
    const response = yield call(Api.listOrder, data.payload);
    if (response.status == "Success") {
      yield put(listOrderSuccess(response));
    } else {
      yield put(listOrderFail(response));
    }
  } catch (error) {
    yield put(listOrderFail("Failed"));
  }
}

function* onGetOrderCount() {
  try {
    const response = yield call(Api.getOrderCount);
    if (response.status == "Success") {
      yield put(getOrderCountSuccess(response));
    } else {
      yield put(getOrderCountFail(response));
    }
  } catch (error) {
    yield put(getOrderCountFail("Failed"));
  }
}

function* onGetOrderDetail(data) {
  try {
    const response = yield call(Api.getOrderDetail, data.payload);
    if (response.status == "Success") {
      yield put(getOrderDetailSuccess(response));
    } else {
      yield put(getOrderDetailFail(response));
    }
  } catch (error) {
    yield put(getOrderDetailFail({msg: "Failed"}));
  }
}

function* onGetAdminBeneficiaryDetail() {
  try {
    const response = yield call(Api.getAdminBeneficiaryDetail);
    if (response.status == "Success") {
      yield put(getAdminBeneficiaryDetailSuccess(response));
    } else {
      yield put(getAdminBeneficiaryDetailFail(response));
    }
  } catch (error) {
    yield put(getAdminBeneficiaryDetailFail("Failed"));
  }
}

function* onUploadPaymentDetail(data) {
  try {
    const response = yield call(Api.uploadPaymentDetail, data.payload);
    if (response.status == "Success") {
      yield put(uploadPaymentDetailSuccess(response));
    } else {
      yield put(uploadPaymentDetailFail(response));
    }
  } catch (error) {
    yield put(uploadPaymentDetailFail("Failed"));
  }
}

function* onCancelOrder(data) {
  try {
    const response = yield call(Api.cancelOrder, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(cancelOrderSuccess({...response, orderUid: payload_data.orderUid}));
    } else {
      yield put(cancelOrderFail(response));
    }
  } catch (error) {
    yield put(cancelOrderFail("Failed"));
  }
}

function* onGetCurrencyRateList() {
  try {
    const response = yield call(Api.getCurrencyRateList);
    if (response.status == "Success") {
      yield put(getCurrencyRateListSuccess(response));
    } else {
      yield put(getCurrencyRateListFail(response));
    }
  } catch (error) {
    yield put(getCurrencyRateListFail("Failed"));
  }
}

function* onGetLoginUserDetail() {
  try {
    const response = yield call(Api.getLoginUserDetail);
    if (response.status == "Success") {
      yield put(getLoginUserDetailSuccess(response));
    } else {
      yield put(getLoginUserDetailFail(response));
    }
  } catch (error) {
    yield put(getLoginUserDetailFail({msg: "Failed"}));
  }
}

function* onGetCategoryProductCount(data) {
  try {
    const response = yield call(Api.getCategoryProductCount, data.payload);
    if (response.status == "Success") {
      yield put(getCategoryProductCountSuccess(response));
    } else {
      yield put(getCategoryProductCountFail(response));
    }
  } catch (error) {
    yield put(getCategoryProductCountFail("Failed"));
  }
}

function* onForgotUsername(data) {
  try {
    const response = yield call(Api.forgotUserName, data.payload);
    if (response.status == "Success") {
      yield put(forgotUserNameSuccess(response));
    } else {
      yield put(forgotUserNameFail(response));
    }
  } catch (error) {
    yield put(forgotUserNameFail("Failed"));
  }
}

function* onForgotUserPassword(data) {
  try {
    const response = yield call(Api.forgotUserPassword, data.payload);
    if (response.status == "Success") {
      yield put(forgotUserPasswordSuccess(response));
    } else {
      yield put(forgotUserPasswordFail(response));
    }
  } catch (error) {
    yield put(forgotUserPasswordFail("Failed"));
  }
}

function* onUpdateForgotUserPassword(data) {
  try {
    const response = yield call(Api.updateForgotUserPassword, data.payload);
    if (response.status == "Success") {
      yield put(updateForgotUserPasswordSuccess(response));
    } else {
      yield put(updateForgotUserPasswordFail(response));
    }
  } catch (error) {
    yield put(updateForgotUserPasswordFail("Failed"));
  }
}

function* onGetForgotUserPassword(data) {
  try {
    const response = yield call(Api.getForgotUserPassword, data.payload);
    if (response.status == "Success") {
      yield put(getForgotUserPasswordSuccess(response));
    } else {
      yield put(getForgotUserPasswordFail(response));
    }
  } catch (error) {
    yield put(getForgotUserPasswordFail("Failed"));
  }
}

function* userDataSaga() {
  yield takeLatest(GET_HOME_PAGE, onGetHomePage);
  yield takeLatest(LOGIN_USER, onLoginUser);
  yield takeLatest(GET_REGISTER_DATA, onGetRegisterData);
  yield takeLatest(REGISTER_USER, onRegisterUser);
  yield takeLatest(GET_STATES, onGetStatesList);
  yield takeLatest(GET_CITIES, onGetCitiesList);
  yield takeLatest(GET_PRODUCT, onGetProduct);
  yield takeLatest(GET_PRODUCT_DETAIL, onGetProductDetail);
  yield takeLatest(GET_CATEGORY_LIST, onGetCategoryList);
  yield takeLatest(ADD_TO_CART, onAddToCart);
  yield takeLatest(GET_CART, onGetCartList);
  yield takeLatest(UPDATE_CART, onUpdateCart);
  yield takeLatest(DELETE_CART, onDeleteCart);
  yield takeLatest(ADD_DELIVERY_ADDRESS, onAddDeliveryAddress);
  yield takeLatest(GET_REVIEW_DATA, onGetReviewData);
  yield takeLatest(GET_CART_DETAIL, onGetCartDetail);
  yield takeLatest(UPDATE_CART_DETAIL, onUpdateCartDetail);
  yield takeLatest(GET_CART_COUNT, onGetCartCount);
  yield takeLatest(SEND_MAIL, onSendMail);
  yield takeLatest(MAIL_VERIFICATION, onMailVerification);
  yield takeLatest(GET_USER_DETAIL, onGetUserDetail);
  yield takeLatest(GET_USER_MAIL_VERIFY, onGetUserMailVerify);
  yield takeLatest(ADD_USER_CONTACT_DETAILS, onAddUserContactDetail);
  yield takeLatest(UPDATE_USER_CONTACT_DETAILS, onUpdateUserContactDetail);
  yield takeLatest(ADD_USER_IMPORT_LICENSE_DETAILS, onAddUserImportLicenseDetail);
  yield takeLatest(UPDATE_USER_IMPORT_LICENSE_DETAILS, onUpdateUserImportLicenseDetail);
  yield takeLatest(ADD_USER_DOCUMENTS, onAddUserDocuments);
  yield takeLatest(UPDATE_USER_DOCUMENTS, onUpdateUserDocuments);
  yield takeLatest(ADD_USER_PRODUCT_CATEGORY, onAddUserProductCategory);
  yield takeLatest(GET_CONTACT_ADDRESS, onGetContactAddress);
  yield takeLatest(UPDATE_USER_NAME, onUpdateUserName);
  yield takeLatest(UPDATE_PASSWORD, onUpdatePassword);
  yield takeLatest(PLACE_ORDER, onPlaceOrder);
  yield takeLatest(GET_EXPORT_COUNTRY_LIST, onGetExportCountryList);
  yield takeLatest(CLEAR_CART, onClearCart);
  yield takeLatest(GET_ORDER_DETAIL, onGetOrderDetail);
  yield takeLatest(GET_CHECKOUT, onGetCheckout);
  yield takeLatest(LIST_ORDER, onListOrder);
  yield takeLatest(GET_ORDER_COUNT, onGetOrderCount);
  yield takeLatest(GET_ADMIN_BENEFICIARY_DETAIL, onGetAdminBeneficiaryDetail);
  yield takeLatest(UPLOAD_PAYMENT_DETAIL, onUploadPaymentDetail);
  yield takeLatest(CANCEL_ORDER, onCancelOrder);
  yield takeLatest(GET_CURRENCY_RATE_LIST, onGetCurrencyRateList);
  yield takeLatest(GET_LOGIN_USER_DETAIL, onGetLoginUserDetail);
  yield takeLatest(GET_CATEGORY_PRODUCT_COUNT, onGetCategoryProductCount);
  yield takeLatest(FORGOT_USER_NAME, onForgotUsername);
  yield takeLatest(FORGOT_USER_PASSWORD, onForgotUserPassword);
  yield takeLatest(UPDATE_FORGOT_USER_PASSWORD, onUpdateForgotUserPassword);
  yield takeLatest(GET_FORGOT_USER_PASSWORD, onGetForgotUserPassword);
}

export default userDataSaga;
