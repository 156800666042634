import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';

import AddToCartBox from "./AddToCartBox";

import styles from "./ReviewShipping.module.css";
import {getCurrencyConversion, getCurrencyConversionCalc} from "../assets/functions/common";
import FormDatePicker from "./FormDatePicker";

const ReviewScheduleOrder = (props) => {
  const [cartDetail, setCartDetail] = useState(null);
  const [containerType, setContainerType] = useState('');
  const [totalPrice, setTotalPrice] = useState(0)
  const [currencySymbol, setCurrencySymbol] = useState('')

  useEffect(() => {
    if (props.detail != null) {
      setCartDetail(props.detail)
      let price_val = 0;
      let total_price_val = 0;

      let price_per_package = props?.detail?.price_per_package;
      let package_net_weight = props?.detail?.package_net_weight;
      let referral_fee = props?.detail?.referral_fee;
      let rate_list = props?.detail?.rate_list;
      let seller_currency = props?.detail?.seller_currency;
      let user_currency = props?.detail?.user_currency;

      // let data_list = {
      //   price_per_package: props?.detail?.price_per_package,
      //   package_net_weight: props?.detail?.package_net_weight,
      //   referral_fee: props?.detail?.referral_fee,
      //   quantity: props?.productCount ? props?.productCount : 0,
      //   shipping_cost: 0,
      //   seller_currency: props?.detail?.seller_currency,
      //   user_currency: props?.detail?.user_currency,
      //   rate_list: props?.detail?.rate_list
      // }

      // let data = getCurrencyConversionCalc(data_list)
      let data = getCurrencyConversion(price_per_package, package_net_weight, referral_fee, rate_list, seller_currency, user_currency)
      if(data != null) {
        price_val = data?.converted_price;
        if(props?.productCount != '') {
          total_price_val = price_val * props?.productCount
          setTotalPrice(total_price_val)
        } else {
          setTotalPrice(0)
        }

        // setTotalPrice(price_val)
        // setCurrencySymbol(data?.user_currency_symbol)
        setCurrencySymbol(data?.currency_symbol)
      } else {
        setTotalPrice(0)
      }

    }
  }, [props.detail, props?.productCount])

  useEffect(() => {
    if (props?.data?.containerTypeList?.length > 0 && props?.containerType != '') {
      let list = [...props?.data?.containerTypeList];
      let index = list?.findIndex((x) => x.value == props?.containerType);
      let label = '';
      if (index >= 0) {
        label = list?.[index]?.['label'];
        setContainerType(label);
      }
    }
  }, [props.data, props?.containerType])

  return (
    <Grid item md={12} xs={12}>
      <Grid container item md={12} xs={12} className={styles.mainView}
            display={"flex"} flexDirection={"row"} justifyContent={"space-between"}
            mt={3}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cargo ID</TableCell>
              <TableCell>Date of shipping</TableCell>
              <TableCell>Type of Container</TableCell>
              <TableCell>Total product quantity (kg)</TableCell>
              <TableCell>Total cargo weight (kg)</TableCell>
              <TableCell>Number of packages</TableCell>
              <TableCell>
                Total cargo volume <Typography  className={styles.volumeTitleText}>(m<sup>3</sup>)</Typography>
              </TableCell>
              <TableCell>Total product price (EXW)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{props?.cargoId ? '#' + props?.cargoId : '-'}</TableCell>
              <TableCell>
                <FormDatePicker
                  page={"ReviewScheduleOrder"}
                  value={props?.selectedShippingDate}
                  inputFormat="DD-MM-YYYY"
                  onChange={(newValue) => {
                    props?.setSelectedShippingDate(newValue);
                  }}
                  minDate={props?.shippingDate}
                  textInputViewStyle={style.textInputViewStyle}
                />
              </TableCell>
              <TableCell>{containerType ? containerType : '-'}</TableCell>
              <TableCell>{cartDetail?.package_net_weight ? (cartDetail?.package_net_weight * props?.productCount + ' ' + cartDetail?.unit) : '-'}</TableCell>
              <TableCell>{cartDetail?.package_gross_weight ? (cartDetail?.package_gross_weight * props?.productCount + ' ' + cartDetail?.unit) : '-'}</TableCell>
              <TableCell>
               <AddToCartBox
               data={cartDetail}
               productCount={props?.productCount}
               setProductCount={props?.setProductCount}
               />
              </TableCell>
              <TableCell>
                {cartDetail?.length ? (((cartDetail?.length * cartDetail?.width * cartDetail?.height) / 1000000) * props?.productCount)?.toFixed(2) : '0'}   m<sup>3</sup>
              </TableCell>
              <TableCell>
                {currencySymbol} {totalPrice?.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </Grid>
    </Grid>
  )
};

export default ReviewScheduleOrder;

const style = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e4ecf2',
  },
}
