import React, {useState, useEffect} from 'react'
import {
  Grid, Typography, Box,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import AccountCreated from "../../components/AccountCreated";
import MailVerification from "../../components/MailVerification";
import BusinessDetails from "../../components/BusinessDetails";
import AlertMsg from "../../components/AlertMsg";

import {connect} from "react-redux";
import {mailVerification, updateApiStatus, sendMail, getUserMailVerify, getRegisterData, getUserDetail, getCategoryList} from "../../redux/actions/userDataActions";

import {THEME_COLOR} from "../../constants";

const CreateAccount = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isUserVerified, setIsUserVerified] = useState(false);

  const navigate = useNavigate();

  const steps = [
    'Account Creation',
    'Mail Verification',
    'Business Details',
  ];

  useEffect(() => {
    props.getRegisterData();
    props.getUserDetail();
    props.getUserMailVerify()
    props.getCategoryList();
  }, [])

  useEffect(() => {
    if (props.isUserVerified == 1 && !isUserVerified) {
      setActiveTab(2);
    }
  }, [props?.isUserVerified])

  useEffect(() => {
    if (props.apiStatus == "SEND_MAIL_SUCCESS") {
      setActiveTab(1);
      props.updateApiStatus("");
    } else if (props.apiStatus == "MAIL_VERIFICATION_SUCCESS") {
      setIsUserVerified(true);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  const onSendMailBtnClick = (data) => {
    props.sendMail(JSON.stringify(data))
  };

  const onVerifyMailOtp = (data) => {
    props.mailVerification(JSON.stringify(data))
  };

  const onUpdateBusinessDetails = () => {
    setActiveTab(2);
  }

  const onSignInClick = () => {
    navigate('/login')
  };

  return (
    <Container page={"CreateAccount"} title={'My Account'} subTitle={'CreateAccount'} showHeaderData={'false'}>
      <Grid item md={12} xs={12}
            display={"flex"} flexDirection={"column"}
            mt={10}>
        <Typography sx={styles.registerText}>Create Your Account</Typography>

        <Grid item md={12} xs={12} mt={5}
        >
          <Stepper activeStep={activeTab} alternativeLabel>
            {steps.map((label) => (
              <Step key={label} sx={styles.mainStep}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>

        {(activeTab == 0 ?
            (
              <Grid
                item md={12} xs={12}
                display={'flex'}
                alignItems={"center"}
                justifyContent={"center"}
                mt={4}
              >
                <AccountCreated
                  onSendMailBtnClick={onSendMailBtnClick}
                />
              </Grid>
            )
            : (activeTab == 1 ?
                (<Grid
                  item md={12} xs={12}
                  display={'flex'}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mt={4}
                >
                  <MailVerification
                    onVerifyMailOtp={onVerifyMailOtp}
                    onUpdateBusinessDetails={onUpdateBusinessDetails}
                    onResendOtpClick={onSendMailBtnClick}
                  />
                </Grid>)
                : (<Grid
                  item md={12} xs={12}
                  display={'flex'}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mt={4}
                >
                  <BusinessDetails
                  />
                </Grid>)
            )
        )}

        {/*<Box*/}
        {/*  mt={5}*/}
        {/*  display={"flex"}*/}
        {/*  flexDirection={"row"}*/}
        {/*  alignItems={"center"}*/}
        {/*  justifyContent={"center"}>*/}
        {/*  <Typography style={styles.notMemberText}>ALREADY HAVE AN ACCOUNT?</Typography>*/}
        {/*  <Typography ml={1} style={styles.signInText} onClick={() => onSignInClick()}>SIGN IN</Typography>*/}
        {/*</Box>*/}

        {/*  </Grid>*/}
      </Grid>
      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
    isUserVerified: state.userData.isUserVerified,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mailVerification: data => dispatch(mailVerification(data)),
    sendMail: data => dispatch(sendMail(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    getUserMailVerify: data => dispatch(getUserMailVerify(data)),
    getRegisterData: data => dispatch(getRegisterData(data)),
    getUserDetail: data => dispatch(getUserDetail(data)),
    getCategoryList: data => dispatch(getCategoryList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);

const styles = {
  registerText: {
    textAlign: 'center',
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 35
  },
  mainStep: {
    '& .MuiStepLabel-label': {
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 8}
    },
    '& .MuiStepLabel-iconContainer.Mui-disabled .MuiStepIcon-root': {
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-root .Mui-active': {
      color: THEME_COLOR, // circle color (ACTIVE)
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
      {
        color: '#000', // Just text label (ACTIVE)
        fontFamily: 'InterBold',
        fontSize: {md: 13, xs: 8}
      },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
      fill: '#FFF', // circle's number (ACTIVE)
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 18}
    },
    '& .MuiStepLabel-root .Mui-completed': {
      color: THEME_COLOR, // circle color (COMPLETED)
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
      {
        color: '#000', // Just text label (COMPLETED)
        fontFamily: 'InterBold',
        fontSize: {md: 13, xs: 8}
      },
    '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
      fill: '#FFF', // circle's number (COMPLETED)
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 18}
    },
  },
  notMemberText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15
  },
  signInText: {
    cursor: 'pointer',
    color: THEME_COLOR,
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
};

