import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";

import './../assets/css/custom.css';

import {connect} from "react-redux";
import {addUserImportLicenseDetail, updateUserImportLicenseDetail} from "../redux/actions/userDataActions";

const AddImportLicenseDetails = (props) => {
  const [importerName, setImporterName] = useState('');
  const [importLicenseNo, setImportLicenseNo] = useState('');
  const [reEnterImportLicenseNo, setReEnterImportLicenseNo] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.data != null) {
      setImporterName(props.data?.importerName);
      setImportLicenseNo(props.data?.importLicenseNo);
      setReEnterImportLicenseNo(props.data?.importLicenseNo);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.apiStatus == "ADD_USER_IMPORT_LICENSE_DETAILS_SUCCESS"
      || props.apiStatus == "UPDATE_USER_IMPORT_LICENSE_DETAILS_SUCCESS") {
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  const onSaveClick = () => {
    let errorText = {};
    if (importerName == '' || importerName == null) {
      errorText = {importerName: 'Importer Name is required'};
    }
    if (importLicenseNo == '' || importLicenseNo == null) {
      errorText = {...errorText, importLicenseNo: 'Import License No is required'};
    }
    if (reEnterImportLicenseNo == '' || reEnterImportLicenseNo == null) {
      errorText = {...errorText, reEnterImportLicenseNo: 'Re-enter Import License No is required'};
    }
    if (importLicenseNo != reEnterImportLicenseNo) {
      errorText = {...errorText, reEnterImportLicenseNo: 'Import License No not match'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let formData = new FormData();
    formData.append('userId', props?.data?.userId);
    formData.append('importerName', importerName);
    formData.append('importLicenseNo', importLicenseNo);

    if(props.data?.userId) {
      props.updateUserImportLicenseDetail(formData)
    } else {
      props.addUserImportLicenseDetail(formData)
    }
  };

  return (
    <Grid item container xs={12} md={12}
          // alignItems={"flex-start"}
          // justifyContent={"space-between"}
          mt={4}>

      <Typography style={styles.titleText}>Import License Details</Typography>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          disabled={true}
          page={'AddImportLicenseDetails'}
          value={importerName}
          setValue={setImporterName}
          label={'Importer Name as on Import License'}
          error={error?.importerName}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddImportLicenseDetails'}
          value={importLicenseNo}
          setValue={setImportLicenseNo}
          label={'Import License number'}
          error={error?.importLicenseNo}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddImportLicenseDetails'}
          value={reEnterImportLicenseNo}
          setValue={setReEnterImportLicenseNo}
          label={'Re-enter Import License number'}
          error={error?.reEnterImportLicenseNo}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>


      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="right" mt={4}>
        <Box>
          <FormButton
            title={'Save'}
            onSubmit={onSaveClick}
          />
        </Box>
      </Grid>

    </Grid>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    addUserImportLicenseDetail: data => dispatch(addUserImportLicenseDetail(data)),
    updateUserImportLicenseDetail: data => dispatch(updateUserImportLicenseDetail(data)),
  };
};

export default connect(null, mapDispatchToProps)(AddImportLicenseDetails);

const styles = {
  labelText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  }
};

