import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import countryToCurrency from "country-to-currency";

import Index from "./containers/Home/Index";
import Register from "./containers/Auth/Register";
import Login from "./containers/Auth/Login";
import Verification from "./containers/Auth/Verification";
import LogOut from "./containers/Auth/LogOut";
import ProductDetail from "./containers/Product/ProductDetail";
import Product from "./containers/Product/Product";
import Cart from "./containers/Cart/Cart";
import Review from "./containers/Cart/Review";
import Account from "./containers/Account/Account";
import Checkout from "./containers/Checkout/Checkout";
import CreateAccount from "./containers/Auth/CreateAccount";
import BuyerAgreement from "./containers/Auth/BuyerAgreement";
import Orders from "./containers/Order/Orders";
import OrderPayment from "./containers/Order/OrderPayment";
import OrderDetail from "./containers/Order/OrderDetail";
import Payment from "./containers/Payment/Payment";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import About from "./containers/About/About";
import Contact from "./containers/Contact/Contact";
import UserTermsAndConditions from "./containers/UserTermsAndConditions/UserTermsAndConditions";
import PaymentPolicy from "./containers/PaymentPolicy/PaymentPolicy";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import Support from "./containers/Support/Support";
import Dashboard from "./containers/Dashboard/Dashboard";

import {connect} from "react-redux";
import {getLoginUserDetail} from "./redux/actions/userDataActions";

import './assets/css/custom.css';

const App = props => {

  useEffect(() => {
    if (props.userToken) {
      props.getLoginUserDetail()
    }
  }, [])

  let routes = (
    <Routes>
      <Route exact path="/index" element={<Index {...props} />}/>
      <Route exact path="/" element={<Navigate replace to="/index"/>}/>
      <Route path="*" element={<Navigate replace to="/"/>}/>
      <Route exact path="/login" element={<Login {...props} />}/>
      <Route exact path="/register" element={<Register {...props} />}/>
      {/*<Route path="*" element={<Navigate replace to="/login"/>}/>*/}
      {/*<Route exact path="/" element={<Navigate replace to="/login"/>}/>*/}
      <Route exact path="/forgot_password/:id" element={<ForgotPassword {...props} />}/>
      <Route exact path="/product_detail" element={<ProductDetail {...props} />}/>
      <Route exact path="/product" element={<Product {...props} />}/>
      <Route exact path="/seller/:id" element={<Product {...props} />}/>
      <Route exact path="/about" element={<About {...props} />}/>
      {/*<Route exact path="/contact" element={<Contact {...props} />}/>*/}
      {/*<Route exact path="/support" element={<Support {...props} />}/>*/}
      <Route exact path="/user_terms_and_conditions" element={<UserTermsAndConditions {...props} />}/>
      <Route exact path="/privacy_policy" element={<PrivacyPolicy {...props} />}/>
      <Route exact path="/payment_policy" element={<PaymentPolicy {...props} />}/>
    </Routes>
  );

  if (props.userToken && (props.userStatus == 'pending')) {
    routes = (
      <Routes>
        <Route exact path="/create_account" element={<CreateAccount {...props} />}/>
        <Route exact path="/logout" element={<LogOut {...props} />}/>
        <Route exact path="/index" element={<Index {...props} />}/>
        <Route exact path="/" element={<Navigate replace to="/index"/>}/>
        <Route path="*" element={<Navigate replace to="/"/>}/>
        <Route exact path="/product_detail" element={<ProductDetail {...props} />}/>
        <Route exact path="/product" element={<Product {...props} />}/>
        <Route exact path="/seller/:id" element={<Product {...props} />}/>
        <Route exact path="/about" element={<About {...props} />}/>
        {/*<Route exact path="/contact" element={<Contact {...props} />}/>*/}
        {/*<Route exact path="/support" element={<Support {...props} />}/>*/}
        <Route exact path="/user_terms_and_conditions" element={<UserTermsAndConditions {...props} />}/>
        <Route exact path="/privacy_policy" element={<PrivacyPolicy {...props} />}/>
        <Route exact path="/payment_policy" element={<PaymentPolicy {...props} />}/>
        <Route exact path="/buyer_agreement" element={<BuyerAgreement {...props} />}/>
        <Route path="/register" element={<Navigate replace to="/create_account"/>}/>
        {/*<Route path="*" element={<Navigate replace to="/"/>}/>*/}
        {/*<Route path="/" element={<Navigate replace to="/create_account"/>}/>*/}
      </Routes>
    );
  }

  if (props.userToken && (props.userStatus == 'pending_verification')) {
    routes = (
      <Routes>
        <Route exact path="/verification" element={<Verification {...props} />}/>
        <Route path="/register" element={<Navigate replace to="/verification"/>}/>
        <Route exact path="/logout" element={<LogOut {...props} />}/>
        {/*<Route path="*" element={<Navigate replace to="/"/>}/>*/}
        {/*<Route path="/" element={<Navigate replace to="/verification"/>}/>*/}
        <Route exact path="/index" element={<Index {...props} />}/>
        <Route exact path="/" element={<Navigate replace to="/index"/>}/>
        <Route path="*" element={<Navigate replace to="/verification"/>}/>
        <Route exact path="/product_detail" element={<ProductDetail {...props} />}/>
        <Route exact path="/product" element={<Product {...props} />}/>
        <Route exact path="/seller/:id" element={<Product {...props} />}/>
        <Route exact path="/about" element={<About {...props} />}/>
        {/*<Route exact path="/contact" element={<Contact {...props} />}/>*/}
        {/*<Route exact path="/support" element={<Support {...props} />}/>*/}
        <Route exact path="/user_terms_and_conditions" element={<UserTermsAndConditions {...props} />}/>
        <Route exact path="/privacy_policy" element={<PrivacyPolicy {...props} />}/>
        <Route exact path="/payment_policy" element={<PaymentPolicy {...props} />}/>
      </Routes>
    );
  }

  if (props.userToken && (props.userStatus == 'active')) {
    routes = (
      <Routes>
        <Route exact path="/home" element={<Dashboard {...props} />}/>
        <Route exact path="/index" element={<Index {...props} />}/>
        <Route exact path="/" element={<Navigate replace to="/index"/>}/>
        <Route exact path="/logout" element={<LogOut {...props} />}/>
        {/*<Route exact path="/create_account" element={<CreateAccount {...props} />}/>*/}
        <Route path="/register" element={<Navigate replace to="/create_account"/>}/>
        <Route exact path="/product_detail" element={<ProductDetail {...props} />}/>
        <Route exact path="/product" element={<Product {...props} />}/>
        <Route exact path="/seller/:id" element={<Product {...props} />}/>
        <Route exact path="/account" element={<Account {...props} />}/>
        <Route exact path="/cart" element={<Cart {...props} />}/>
        <Route exact path="/review" element={<Review {...props} />}/>
        <Route exact path="/checkout/:id" element={<Checkout {...props} />}/>
        <Route exact path="/order" element={<Orders {...props} />}/>
        <Route exact path="/order_payment/:id" element={<OrderPayment {...props} />}/>
        <Route exact path="/order_payment/:id/statement" element={<OrderPayment {...props} />}/>
        <Route exact path="/order_detail/:id" element={<OrderDetail {...props} />}/>
        <Route exact path="/order_detail/:id/cancel" element={<OrderDetail {...props} />}/>
        <Route exact path="/payment" element={<Payment {...props} />}/>
        <Route exact path="/about" element={<About {...props} />}/>
        {/*<Route exact path="/contact" element={<Contact {...props} />}/>*/}
        {/*<Route exact path="/support" element={<Support {...props} />}/>*/}
        <Route exact path="/user_terms_and_conditions" element={<UserTermsAndConditions {...props} />}/>
        <Route exact path="/privacy_policy" element={<PrivacyPolicy {...props} />}/>
        <Route exact path="/payment_policy" element={<PaymentPolicy {...props} />}/>
        <Route path="*" element={<Navigate replace to="/"/>}/>
        <Route path="/" element={<Navigate replace to="/"/>}/>
      </Routes>
    );
  }

  return (
    <BrowserRouter>
      {routes}
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    userToken: state.userData.userToken,
    userStatus: state.userData.userStatus,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getLoginUserDetail: data => dispatch(getLoginUserDetail(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
