import React, {useEffect} from 'react';
import {Box, Typography, Grid, FormControl, Autocomplete, Chip, TextField} from "@mui/material";

import {ReactComponent as CloseIcon} from "../assets/img/close_black.svg";

const FormMultiSelectBox = (props) => {

  return (
    <Grid item md={12} xs={12}
          container
          display={"flex"}>
      {props?.label ?
        <Grid display={"flex"}
              flexDirection={"row"} item md={12} xs={12}
              container>
          <Typography sx={styles.labelText}>{props.label}</Typography>
          {props?.required ?
            <Box pl={1}>
              <Typography sx={styles.requiredText}>*</Typography>
            </Box>
            : null}
        </Grid>
        : null}
      <Grid item md={12} xs={12}
            container sx={[styles.textInputView, props?.textInputViewStyle]}>
        <FormControl style={{width: '100%'}}>

          <Autocomplete
            disabled={props?.disabled ? props?.disabled : false}
            value={props.value}
            disableClearable
            autoHighlight
            multiple={props.multiple}
            size="small"
            loading={props?.loading ? props?.loading : false}
            options={props.data}
            clearIcon={false}
            onChange={(event, value) => props.setValue(value)}
            getOptionLabel={(option) => props.type == 'state' ? option.name : (props.type == 'city' ? option : option.label)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  sx={styles.chipView}
                  size="small"
                  variant="outlined"
                  key={props.type == 'state' ? option.name : (props.type == 'city' ? option : option.value)}
                  label={props.type == 'state' ? option.name : (props.type == 'city' ? option : option.label)}
                  deleteIcon={<CloseIcon height={13} width={13}/>}
                  {...getTagProps({index})}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={props?.disabled ? props?.disabled : false}
                placeholder={props?.placeholder}
                required={props?.required ? true : false}
                fullWidth
                variant={props?.variant ? props?.variant : "standard"}
                value={props.value}
                sx={styles.textFieldView}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off',
                  sx: styles.textInputValue
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      {props?.error ?
        <Box mt={1} ml={0.5} styles={styles.errorView}>
          <Typography sx={styles.errorText}>{props.error}</Typography>
        </Box>
        : null}
    </Grid>
  )
}

export default FormMultiSelectBox;

const styles = {
  labelText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  textInputView: {
    backgroundColor: '#f0efef',
    alignItems: 'center',
    marginTop: 1,
    py: 0.8,
    paddingLeft: 1,
    height: 55,
  },
  chipView: {
    backgroundColor: '#fff',
    fontFamily: 'InterBold',
    border: '1px solid #000',
    borderRadius: 15,
    height: 20,
    fontSize: 11,
  },
  textInputValue: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
    px: 1.5,
  },
  textFieldView: {
    '& .MuiInput-root': {
      '&:before, :after, :hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
    },
    input: {
      "&::placeholder": {
        color: '#BBB',
        fontFamily: 'InterMedium',
        fontSize: 14,
        opacity: 1,
        paddingLeft: 0.8
      },
    },
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 12
  }
};
