import React, {useRef, useEffect} from 'react'
import {
  Grid, Typography, Box, Card, CardActions, CardContent, Checkbox, Divider,
} from '@mui/material';
import {Link} from "react-router-dom";

import Container from "../../components/Container";

import styles from './BuyerAgreement.module.css';

const terms_and_conditions = [
  {
    number: 1,
    title: `DEFINITIONS`,
    data: [
      {
        number: `a`,
        content: `"Buyer" means any person or entity that purchases agricultural products
through the Platform.`
      },
      {
        number: `b`,
        content: `"Seller" means any person or entity that sells agricultural products through
the Platform.`
      },
      {
        number: `c`,
        content: `"Agreement" means this Buyer Agreement.`
      },
      {
        number: `d`,
        content: `"Agricultural products" means any agricultural commodity or product,
including but not limited to grains, fruits, vegetables, livestock, and
seafood.`
      },
      {
        number: `e`,
        content: `"Order" means a purchase order submitted by the Buyer through the
Platform for the purchase of agricultural products.`
      },
    ]
  },
  {
    number: 2,
    title: `USE OF PLATFORM`,
    data: [
      {
        number: `a`,
        content: `The Buyer shall use the Platform only for the purpose of purchasing
agricultural products.`
      },
      {
        number: `b`,
        content: `The Buyer shall be solely responsible for ensuring that its use of the
Platform complies with all applicable laws and regulations.`
      },
      {
        number: `c`,
        content: `The Buyer shall not use the Platform to engage in any fraudulent or illegal
activity.`
      },
      {
        number: `d`,
        content: `The Buyer shall not use the Platform to harass, defame, or harm any person
or entity.`
      },
      {
        number: `e`,
        content: `The Buyer shall not use the Platform to transmit any viruses, malware, or
other harmful code.`
      },
    ]
  },
  {
    number: 3,
    title: `PURCHASES THROUGH THE PLATFORM`,
    data: [
      {
        number: `a`,
        content: `The Buyer shall submit an Order through the Platform for the purchase of
agricultural products.`
      },
      {
        number: `b`,
        content: `The Buyer shall provide all necessary information for the Order, including
quantity, quality, and delivery details.`
      },
      {
        number: `c`,
        content: `The Platform shall transmit the Order to potential Sellers who meet the
Buyer's requirements.`
      },
      {
        number: `d`,
        content: `The Buyer may select a Seller from the list of potential Sellers provided by
the Platform.`
      },
      {
        number: `e`,
        content: `The Buyer shall be responsible for negotiating the terms of the purchase
with the Seller, including price and delivery.`
      },
      {
        number: `f`,
        content: `The Buyer shall be responsible for paying for the platform for the
purchased agricultural products.`
      },
      {
        number: `g`,
        content: `The Buyer shall be responsible for arranging for the transportation of the
purchased agricultural products.`
      },
    ]
  },
  {
    number: 4,
    title: `WARRANTIES AND REPRESENTATIONS`,
    data: [
      {
        number: `a`,
        content: `The Buyer warrants and represents that it has the legal capacity and
authority to enter into this Agreement and to purchase agricultural
products through the Platform.`
      },
      {
        number: `b`,
        content: `The Buyer warrants and represents that it will comply with all applicable
laws and regulations in connection with its use of the Platform and its
purchase of agricultural products.`
      },
      {
        number: `c`,
        content: `The Platform makes no warranties or representations with respect to the
agricultural products offered for sale through the Platform, and the Buyer
acknowledges that it is solely responsible for verifying the quality and
suitability of the agricultural products prior to purchasing them.`
      },
    ]
  },
  {
    number: 5,
    title: `LIMITATION OF LIABILITY`,
    data: [
      {
        number: `a`,
        content: `The Platform shall not be liable to the Buyer for any direct, indirect,
incidental, special, or consequential damages arising out of or in connection
with the Buyer's use of the Platform or the purchase of agricultural
products through the Platform`
      },
      {
        number: `b`,
        content: `The Platform's total liability to the Buyer under this Agreement shall be
limited to the amount of fees paid by the Buyer to the Platform for the use
of the Platform.`
      },
    ]
  },
  {
    number: 6,
    title: `INDEMNIFICATION`,
    desc: `The Buyer agrees to indemnify, defend, and hold harmless the Platform and its
officers, directors, employees, agents, and affiliates from and against any and all
claims, damages, liabilities, costs, and expenses (including reasonable attorneys'
fees) arising out of or in connection with the Buyer's use of the Platform or the
purchase of agricultural products through the Platform.`
  },
  {
    number: 7,
    title: `TERMINATION`,
    desc: `The Platform may terminate this Agreement and the Buyer's access to the
Platform at any time in its sole discretion, without notice or liability.`
  },
  {
    number: 8,
    title: `GOVERNING LAW AND JURISDICTION`,
    data: [
      {
        number: `a`,
        content: `This Agreement shall be governed by and construed in accordance with the
laws of The Republic of India.`
      },
      {
        number: `b`,
        content: `Any disputes arising from this Agreement shall be subject to the exclusive
jurisdiction of the courts of India.`
      },
    ]
  },
  {
    number: 9,
    title: `AMENDMENTS`,
    desc: `The Trading Platform reserves the right to amend this Agreement at any time by
posting the amended terms and conditions on the Trading Platform.`
  },
  {
    number: 10,
    title: `INTELLECTUAL PROPERTY`,
    data: [
      {
        number: `a`,
        content: `“Trebbs.com” and its related logos and words on Trebbs.com are registered
trademarks of Trebbs.com and are protected by international trademark
and other intellectual property rights and laws. Trebbs.com’s trademarks
may not be used in connection with any service or products other than
Trebbs.com’s, without its written consent. All other trademarks not owned
by Trebbs.com that appear on Trebbs.com are the property of their
respective owners, who may or may not be affiliated with Trebbs.com.`
      },
      {
        number: `b`,
        content: `Trebbs.com is the sole owner or lawful licensee of all the rights and
interests in Trebbs.com and its content. Trebbs.com and its content
contains trade secrets and other intellectual property rights protected
under worldwide copyright and other laws. All title, ownership and
intellectual property rights in Trebbs.com and its content belong to
Trebbs.com. All other rights are reserved.`
      },
      {
        number: `c`,
        content: `If you believe that any content on Trebbs.com violates your intellectual
property or other third-party rights, please contact legal@trebbs.com.`
      },
    ]
  },
  {
    number: 11,
    title: `NOTICES`,
    data: [
      {
        number: `a`,
        content: ` All notices or demands to Trebbs.com must be sent by email to:
legal@trebbs.com.`
      },
      {
        number: `b`,
        content: `All legal notices or demands on a seller will be delivered to the last known
email address provided by Trebbs.com, unless otherwise required by
specific laws or regulations.`
      },
    ]
  },
  {
    number: 12,
    title: `GENERAL CLAUSES`,
    data: [
      {
        number: `a`,
        content: `Unless you have any additional agreements with Trebbs.com, this
Agreement represents the entire agreement between you and Trebbs.com
as to your use of the website and any related applications or services,
overriding any prior written or verbal agreements that may have been
made.`
      },
      {
        number: `b`,
        content: `This Agreement does not create an agency, partnership, joint venture,
employment or franchise relationship between you and Trebbs.com.`
      },
      {
        number: `c`,
        content: `If any clause of this Agreement is considered invalid or unenforceable, such
clause will be deleted and the remaining clauses will remain valid and
enforceable.`
      },
      {
        number: `d`,
        content: `If Trebbs.com does not enforce its rights or act against any breach by you
under this Agreement, it does not mean that Trebbs.com is waiving its
rights to enforce actions against you in the future.`
      },
      {
        number: `e`,
        content: `Trebbs.com has the right to assign this Agreement to any person or entity.
You may not assign this Agreement to any person or entity, without written
consent from Trebbs.com.`
      },
      {
        number: `f`,
        content: `This Agreement is governed by and construed according to the laws and
regulations of the Republic of India. Any dispute, difference, controversy or
claim arising out of or in connection with this Agreement, including any
question regarding its existence, validity, interpretation, performance,
discharge and applicable remedies, will be subject to the exclusive
jurisdiction of Indian Courts. If you have any questions regarding this
Agreement, please Contact Us.`
      },
    ]
  },
]

const BuyerAgreement = (props) => {
  const termConditionRef = useRef(null);

  useEffect(() => {
    if (termConditionRef?.current) {
      termConditionRef?.current?.scrollIntoView({behavior: "smooth"});
    }
  }, []);

  return (
    <Container page={"BuyerAgreement"} title={'Buyer Agreement'} subTitle={'Buyer Agreement'} showHeaderData={'false'}>
      <Grid container item md={12} xs={12} display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={5} mb={6}
            ref={termConditionRef}>
        <Grid container item md={8} xs={10} display={"flex"}
              alignItems={"center"}>
          <Grid item md={12} xs={12} mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography className={styles.mainTitleText}>Buyer Agreement</Typography>
          </Grid>

          <Typography className={styles.contentText} mt={2}>
            This Buyer Agreement (the "Agreement") is entered into between the Buyer (as
            defined below) and Trebbs.com (the "Platform") and governs the terms and
            conditions of the Buyer's use of the Platform for purchasing agricultural products.
            By accessing or using the Platform, the Buyer agrees to be bound by the terms
            and conditions of this Agreement.
          </Typography>

          <Box>
            {terms_and_conditions.map((item, index) => {
              return (
                <Grid item md={12} xs={12} key={index}>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={3}>
                    <Typography className={styles.titleText}>{item?.number}.</Typography>
                    <Typography pl={1} className={styles.titleText}>{item?.title}</Typography>
                  </Box>
                  {item?.desc ?
                    <Box mt={2}>
                      <Typography className={styles.contentText}>{item?.desc}</Typography>
                    </Box>
                    : null}
                  {item?.data?.length > 0 ?
                    item?.data?.map((item1, index1) => {
                      return (
                        <Grid item md={12} xs={12} key={index1} pl={3}>
                          <Box mt={2} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                            <Typography className={styles.contentText}>({item1?.number})</Typography>
                            <Typography pl={1} className={styles.contentText}>{item1?.content}</Typography>
                          </Box>
                        </Grid>
                      )
                    })
                    : null}
                </Grid>
              )
            })}
          </Box>

        </Grid>
      </Grid>

    </Container>
  )
}

export default BuyerAgreement
