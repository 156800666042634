import React, {useEffect, useState} from 'react';
import {Grid, Box, Stepper, Step, StepLabel, Card, CircularProgress} from '@mui/material';

import AddCorporateDetails from "../components/AddCorporateDetails";
import AddContactDetails from "../components/AddContactDetails";
import AddImporterDetails from "../components/AddImporterDetails";
import AddProductDetails from "../components/AddProductDetails";

import {connect} from "react-redux";
import {
  addUserContactDetail,
  updateUserContactDetail,
  updateApiStatus,
  // updateShowTgbin
} from "../redux/actions/userDataActions";

import './../assets/css/custom.css';

import {THEME_COLOR} from "../constants";

const BusinessDetails = (props) => {
  const [userUid, setUserUid] = useState('');
  const [corporateDetail, setCorporateDetail] = useState(null);
  const [contactDetail, setContactDetail] = useState(null);
  const [importerDetail, setImporterDetail] = useState(null);
  const [productDetail, setProductDetail] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [agreeBox, setAgreeBox] = useState(false);
  const [error, setError] = useState(null);

  const steps = [
    'Corporate details',
    'Contact details',
    'Importer details',
    'Product details',
  ];

  useEffect(() => {
    if (props?.userDetail != null) {
      setCorporateDetail(props?.userDetail?.corporateDetails ? props?.userDetail.corporateDetails : null);
      setContactDetail(props?.userDetail?.contactDetails ? props?.userDetail.contactDetails : null)

      setImporterDetail({
        importLicenseDetails: props?.userDetail?.importLicenseDetails ? props?.userDetail.importLicenseDetails : null,
        documentDetails: props?.userDetail?.documentDetails ? props?.userDetail.documentDetails : null
      })

      setProductDetail(props?.userDetail?.productDetails ? props?.userDetail.productDetails : null)
      setUserUid(props?.userDetail?.userUid ? props?.userDetail.userUid : '')
      if (props?.userDetail?.contactDetails) {
        if (props?.userDetail?.contactDetails?.userId == '') {
          setAgreeBox(false)
        } else {
          setAgreeBox(true)
        }
      }
    }

  }, [props?.userDetail])

  useEffect(() => {
    if (props.apiStatus == "ADD_USER_CONTACT_DETAILS_SUCCESS"
      || props.apiStatus == "UPDATE_USER_CONTACT_DETAILS_SUCCESS") {
      setActiveTab(2);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  // useEffect(() => {
  //   if(activeTab == 3) {
  //     props.updateShowTgbin(false)
  //   }
  // },[activeTab])

  const onAddCorporateDetailClick = (data) => {
    setCorporateDetail(data);
    setActiveTab(1);
  };

  const onAddContactDetailClick = (data) => {

    setContactDetail(data)
    let data1 = {
      userId: data.userId,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      designation: data.designation,
      businessAddress: data.businessAddress,
      city: data.city,
      stateProvince: data.stateProvince.name,
      zipCode: data.zipCode,
      email: data.email,
      mobileNo: data.mobileNo,
      mobileNoCode: data.mobileNoCode.phone,
      mobileNoCountry: data.mobileNoCode.code,
      whatsAppNo: data.whatsAppNo,
      whatsAppNoCode: data.whatsAppNoCode.phone,
      whatsAppNoCountry: data.whatsAppNoCode.code,
    }

    if (data?.userId) {
      props.updateUserContactDetail(JSON.stringify(data1))
    } else {
      props.addUserContactDetail(JSON.stringify(data1))
    }
  };

  const onContinueImporterDetailClick = () => {
    setActiveTab(3);
  };

  const onTabClick = (index) => {
    if ((index == 1 || index == 2 || index == 3) && corporateDetail?.corporateCountry == '' || !agreeBox) {
      let errorText = {};
      if (!agreeBox) {
        errorText = {agreeTerms: 'Please Agree the terms & conditions'};
        if (Object.keys(errorText).length != 0) {
          setError(errorText);
          return;
        }
      } else {
        setError(null)
      }
      return;
    } else if ((index == 2 || index == 3) && contactDetail?.userId == '') {
      return;
    } else if ((index == 3) && (importerDetail?.importLicenseDetails?.userId == ''
      || importerDetail?.documentDetails?.userId == '')) {
      return;
    }
    setError(null)
    setActiveTab(index)
  };

  if (props.detailLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Grid
      container
      item xs={8} md={8}
      display={'flex'} flexDirection={'column'}
    >

      <Box sx={{width: {md: '90%', xs: '100%'}}}>
        <Stepper activeStep={activeTab} alternativeLabel>
          {steps?.map((label, index) => (
            <Step key={label}
              // onClick={() => index == 4 ? null : setActiveTab(index)}
                  onClick={() => onTabClick(index)}
                  sx={styles.mainStep}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {(activeTab == 0 ?
        (<Grid
          item md={12} xs={12}
          display={'flex'}
          alignItems={"center"}
          justifyContent={"center"}
          mt={4}>
          <AddCorporateDetails
            data={corporateDetail}
            agreeBox={agreeBox}
            setAgreeBox={setAgreeBox}
            error={error}
            setError={setError}
            entityTypeList={props.getRegisterDataList?.entityTypeList ? props.getRegisterDataList.entityTypeList : []}
            onAddCorporateDetailClick={onAddCorporateDetailClick}/>
        </Grid>)
        : (activeTab == 1 ?
          (<Grid
            item md={12} xs={12}
            display={'flex'}
            alignItems={"center"}
            justifyContent={"center"}
            mt={4}>
            <AddContactDetails
              data={contactDetail}
              userUid={userUid}
              onAddContactDetailClick={onAddContactDetailClick}
            />
          </Grid>) : (activeTab == 2 ?
              (<Grid
                item md={12} xs={12}
                display={'flex'}
                alignItems={"center"}
                justifyContent={"center"}
                mt={4}>
                <AddImporterDetails
                  data={importerDetail}
                  onContinue={onContinueImporterDetailClick}
                />
              </Grid>) :
              (<Grid
                item md={12} xs={12}
                display={'flex'}
                alignItems={"center"}
                justifyContent={"center"}
                mt={4}>
                <AddProductDetails
                  data={productDetail}
                />
              </Grid>)
          )))}

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    getRegisterDataList: state.userData.getRegisterDataList,
    userDetail: state.userData.userDetail,
    detailLoading: state.userData.detailLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addUserContactDetail: data => dispatch(addUserContactDetail(data)),
    updateUserContactDetail: data => dispatch(updateUserContactDetail(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    // updateShowTgbin: data => dispatch(updateShowTgbin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetails);

const styles = {
  mainStep: {
    '& .MuiStepLabel-label': {
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 8}
    },
    '& .MuiStepLabel-iconContainer.Mui-disabled .MuiStepIcon-root': {
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-root .Mui-active': {
      color: THEME_COLOR, // circle color (ACTIVE)
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
      {
        color: '#000', // Just text label (ACTIVE)
        fontFamily: 'InterBold',
        fontSize: {md: 13, xs: 8}
      },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
      fill: '#FFF', // circle's number (ACTIVE)
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 18}
    },
    '& .MuiStepLabel-root .Mui-completed': {
      color: THEME_COLOR, // circle color (COMPLETED)
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
      {
        color: '#000', // Just text label (COMPLETED)
        fontFamily: 'InterBold',
        fontSize: {md: 13, xs: 8}
      },
    '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
      fill: '#FFF', // circle's number (COMPLETED)
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 18}
    },
  },

  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  }
};

