import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box} from '@mui/material';

import {getCurrencyConversion, getCurrencyConversionCalc} from "../assets/functions/common";

import {THEME_COLOR} from "../constants";

import {ReactComponent as EyeIcon} from "../assets/img/eye.svg";
import {ReactComponent as CartIcon} from "../assets/img/cart.svg";
import {ReactComponent as LikeIcon} from "../assets/img/like.svg";
import {ReactComponent as ImgIcon} from "../assets/img/img.svg";

import './../assets/css/custom.css';

const ItemProductList = (props) => {
  const [price, setPrice] = useState(0)
  const [currencySymbol, setCurrencySymbol] = useState('')

  useEffect(() => {
    if (props?.data) {

      // let price_per_package = props?.data?.price_per_package;
      // let package_net_weight = props?.data?.package_net_weight;
      // let referral_fee = props?.data?.referral_fee;
      // let rate_list = props?.data?.rate_list;
      // let seller_currency = props?.data?.seller_currency;
      // let user_currency = props?.data?.user_currency;

      let data_list = {
        price_per_package: props?.data?.price_per_package,
        package_net_weight: props?.data?.package_net_weight,
        referral_fee: props?.data?.referral_fee,
        quantity: 0,
        shipping_cost: 0,
        seller_currency: props?.data?.seller_currency,
        user_currency: props?.data?.user_currency,
        // rate: props?.data?.rate,
      }

      // let data = getCurrencyConversion(price_per_package, package_net_weight, referral_fee, rate_list, seller_currency, user_currency)
      let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)

      if(data != null) {
        setPrice(data?.converted_price)
        // setCurrencySymbol(data?.currency_symbol)
        setCurrencySymbol(data?.user_currency_symbol)
      }
    }
  }, [props?.data])

  const onIconClick = (event, item, type) => {
    event.stopPropagation();
    props.onSubmit(item, type);
  };

  const onProductClick = (event, item) => {
    event.stopPropagation();
    props.onProduct(item)
  };

  let item = props.data;

  return (
    <Grid
      onClick={(event) => onProductClick(event, item)}
      sx={styles.mainView}
      container item
      md={12}
      xs={12}
      display={"flex"} flexDirection={{md: "row", xs: "column"}}>
      <Grid item md={4} xs={12} style={styles.imgMainView}>
        {item.product_image ?
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
               style={{height: 120, width: '100%'}}>
            <img src={item.product_image} style={styles.imgStyle}/>
          </Box>
          :
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
               style={{height: 120, width: '100%'}}>
            <ImgIcon height={'100%'}/>
          </Box>
        }
      </Grid>
      <Grid item md={7} xs={11} mt={{xs: 2, md: 0}} p={{md: 4, xs: 2}} sx={styles.detailView}>
        <Typography sx={styles.productName}>{item.product_name}</Typography>
        <Box mt={2} mb={2}>
          <Typography
            sx={styles.productPrice}>
            {currencySymbol} {price}
          </Typography>
        </Box>
        <Typography sx={styles.descText}>{item.product_description}</Typography>

        <Box style={styles.iconMainView} mt={2}>
          <Box
            onClick={(event) => onIconClick(event, item, 'view')}
            sx={styles.iconView}>
            <EyeIcon height={18} width={18}/>
          </Box>
          <Box
            onClick={(event) => onIconClick(event, item, 'cart')}
            sx={[styles.iconView, {
              ":hover": {
                path: {
                  fill: "#FFF",
                }
              }
            }]} ml={1.2} mr={1.2}>
            <CartIcon height={18} width={18}/>
          </Box>
          {/*<Box sx={styles.iconView}>*/}
          {/*  <LikeIcon height={18} width={18}/>*/}
          {/*</Box>*/}
        </Box>

      </Grid>
    </Grid>
  )
};

export default ItemProductList;

const styles = {
  mainView: {
    border: '0.1px solid #EEE',
    minHeight: window.innerHeight - 500,
    cursor: 'pointer',
  },
  imgMainView: {
    backgroundColor: '#fafafa',
    height: 300,
    width: 300,
  },
  imgStyle: {
    objectFit: 'contain',
    height: '100%',
    width: '100%'
  },
  detailView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  productName: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 19
  },
  productPrice: {
    color: THEME_COLOR,
    fontFamily: 'InterBold',
    fontSize: 17
  },
  descText: {
    color: '#444',
    fontFamily: 'InterMedium',
    fontSize: 15,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    textAlign: 'justify',
    lineHeight: 1.6
  },
  iconMainView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconView: {
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: {md: 24, xs: 10},
    height: {md: 48, xs: 20},
    width: {md: 48, xs: 20},
    padding: {md: 0, xs: 1},
    boxShadow: '0 3px 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    ":hover": {
      backgroundColor: THEME_COLOR,
      path: {
        stroke: "#FFF",
      }
    }
  },
};
