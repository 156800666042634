import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField} from '@mui/material';

import {getCurrencyConversion} from "../assets/functions/common";

import {COUNTRIES} from "../constants/countries";

import styles from "./ReviewItem.module.css"

const ReviewItem = (props) => {
  const [cartDetail, setCartDetail] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')

  useEffect(() => {
    if (props.data != null) {
      setCartDetail(props.data)
      let total_price = 0;
      let price = 0;

      let price_per_package = props?.data?.price_per_package;
      let package_net_weight = props?.data?.package_net_weight;
      let referral_fee = props?.data?.referral_fee;
      let rate_list = props?.data?.rate_list;
      let seller_currency = props?.data?.seller_currency;
      let user_currency = props?.data?.user_currency;

      let data = getCurrencyConversion(price_per_package, package_net_weight, referral_fee, rate_list, seller_currency, user_currency)
      if (data != null) {
        price = data?.converted_price;
        if (props?.productCount != '') {
          total_price = price * props?.productCount
        } else {
          total_price = 0;
        }
        setCurrencySymbol(data?.currency_symbol)
      } else {
        total_price = 0;
      }

      setTotalPrice(total_price)
    }
  }, [props.data, props?.productCount])

  return (
    <Grid item md={12} xs={12}>
      <Grid container item md={10} xs={10} className={styles.mainView}
            display={'flex'} flexDirection={'column'}
            mt={3}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          {cartDetail?.image ?
            <Box className={styles.imgView}>
              <img src={cartDetail?.image} className={styles.imgStyle}/>
            </Box>
            : null}
          <Box pl={2}>
            <Typography className={styles.productDescText}>
              {(cartDetail?.variety ? cartDetail?.variety : 'NIL Variety')
              + ', ' + (cartDetail?.name ? cartDetail?.name : '-')
              + ', ' + (cartDetail?.variety_size ? cartDetail?.variety_size : 'NIL Size')
              + ', ' + (cartDetail?.package_net_weight ?
                (cartDetail?.package_net_weight + cartDetail?.unit + ' ' + cartDetail?.packing_material)
                : 'NIL Packing')
              + ', ' + (cartDetail?.pickup_location ? cartDetail?.pickup_location : 'NIL Location')}
            </Typography>
          </Box>
        </Box>

        <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={2}>
          <Grid item md={2} xs={12}>
            <Typography className={styles.hsCodeText}>HS Code:</Typography>
          </Grid>
          <Grid item md={10} xs={12}>
            <Typography className={styles.hsCodeText}>{cartDetail?.hs_code ? cartDetail.hs_code : ''}</Typography>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'}>
          <Grid item md={2} xs={12}>
            <Typography className={styles.sellerTitleText}>Seller:</Typography>
          </Grid>
          <Grid item md={10} xs={12} display={'flex'} flexDirection={'column'}>
            <Typography
              className={styles.sellerText}>{cartDetail?.seller_name_entity ? cartDetail.seller_name_entity : ''}</Typography>
            <Typography className={styles.countryText}>{cartDetail?.seller_country ? COUNTRIES.filter(
              (x) => x.code == cartDetail?.seller_country
            )?.[0]?.label : ''}</Typography>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={1}>
          <Grid item md={2} xs={12}>
            <Typography className={styles.quantityText}>Quantity:</Typography>
          </Grid>
          <Grid item md={10} xs={12}>
            <Typography
              className={styles.quantityText}>{props?.productCount ? props?.productCount * props?.data?.package_net_weight : 0}kg</Typography>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={1}>
          <Grid item md={2} xs={12}>
            <Typography className={styles.totalPriceText}>Order Total:</Typography>
          </Grid>
          <Grid item md={10} xs={12}>
            <Typography className={styles.totalPriceText}>{currencySymbol} {totalPrice?.toFixed(2)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default ReviewItem
