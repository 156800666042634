import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, styled, Menu} from '@mui/material';
import {motion} from "framer-motion"
import {useNavigate} from "react-router-dom";

import {getCurrencyConversion, getCurrencyConversionCalc} from '../assets/functions/common'

import {THEME_COLOR, THEME_COLOR2, THEME_COLOR3} from "../constants";
import {COUNTRIES} from "../constants/countries";

import {ReactComponent as EyeIcon} from "../assets/img/eye.svg";
import {ReactComponent as CartIcon} from "../assets/img/cart.svg";
import {ReactComponent as LikeIcon} from "../assets/img/like.svg";
import {ReactComponent as ImgIcon} from "../assets/img/img.svg";

import './../assets/css/custom.css';
import FormButton from "./FormButton";

const ItemProductList = (props) => {
  const [price, setPrice] = useState(0)
  const [pricePerKg, setPricePerKg] = useState(0)
  const [currencySymbol, setCurrencySymbol] = useState('')

  const navigate = useNavigate();

  useEffect(() => {
    if (props?.data) {
      let data_list = {
        price_per_package: props?.data?.price_per_package,
        package_net_weight: props?.data?.package_net_weight,
        referral_fee: props?.data?.referral_fee,
        quantity: 0,
        shipping_cost: 0,
        seller_currency: props?.data?.seller_currency,
        user_currency: props?.data?.user_currency,
      }

      let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
      if (data != null) {
        setPrice(data?.converted_price)
        setPricePerKg(data?.converted_price_per_kg)
        setCurrencySymbol(data?.user_currency_symbol)
      }
    }
  }, [props?.data, props?.currencyRateList])

  const onIconClick = (event, item, type) => {
    event.stopPropagation();
    props.onSubmit(item, type);
  };

  const onProductClick = (event, item) => {
    event.stopPropagation();
    props.onProduct(item)
  };

  const onAddToCartClick = (item) => {
    props.onSubmit(item, 'view');
  };

  const onCompanyClick = (seller_uid) => {
    window.location.href = "/seller/" + seller_uid;
  };

  let item = props.data;

  return (
    <Grid
      onClick={(event) => onProductClick(event, item)}
      sx={styles.mainView}
      container item
      md={12}
      xs={12}
      display={"flex"} flexDirection={"column"}
      justifyContent={'space-between'}
      className={'productList'} pt={2}>
      <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems='center'
            justifyContent={'space-between'} px={2}>
        <Grid item md={6} xs={6} display={'flex'} justifyContent={'flex-start'}>
          <Typography sx={styles.dataText}>{
            COUNTRIES.filter(
              (x) => x.code == item?.seller_country
            )?.[0]?.label
          }</Typography>
        </Grid>
        <Grid item md={6} xs={6} display={'flex'} justifyContent={'flex-end'}>
          <Typography sx={styles.companyNameText} onClick={() => onCompanyClick(item?.seller_uid)}>{item?.name_entity}</Typography>
        </Grid>
      </Grid>
      <Grid container item md={12} xs={12}
            display={'flex'} alignItems={'center'} justifyContent={'center'}
            sx={styles.imgView} pt={1}>
        {item.product_image ?
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
               style={{height: 120, width: '100%'}}>
            <img src={item.product_image} style={styles.imgStyle}/>
          </Box>
          :
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
               style={{height: 120, width: '100%'}}>
            <ImgIcon height={'100%'}/>
          </Box>
        }

        <Box style={styles.iconMainView} className={'productListIcon'}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={{
              visible: {y: 0, opacity: 1, scale: 1, transition: {duration: 0.3, ease: "easeInOut"}},
              hidden: {y: 70, opacity: 0, scale: 1},
            }}>
            <Box
              onClick={(event) => onIconClick(event, item, 'view')}
              sx={styles.iconView} className={'productListEyeIconBox'}>
              <EyeIcon height={18} width={18}/>
            </Box>
          </motion.div>
          {/*<motion.div*/}
          {/*  initial="hidden"*/}
          {/*  whileInView="visible"*/}
          {/*  variants={{*/}
          {/*    visible: {y: 0, opacity: 1, scale: 1, transition: {duration: 0.3, ease: "easeInOut"}},*/}
          {/*    hidden: {y: 70, opacity: 0, scale: 1},*/}
          {/*  }}>*/}
          {/*  <Box*/}
          {/*    onClick={(event) => onIconClick(event, item, 'cart')}*/}
          {/*    sx={[styles.iconView, {*/}
          {/*      ":hover": {*/}
          {/*        path: {*/}
          {/*          fill: "#FFF",*/}
          {/*        }*/}
          {/*      }*/}
          {/*    }]} ml={1.2} mr={1.2} className={'productListCartIconBox'}>*/}
          {/*    <CartIcon height={18} width={18}/>*/}
          {/*  </Box>*/}
          {/*</motion.div>*/}
        </Box>
      </Grid>
      <Grid container item md={12} xs={12} mt={4}
            display={'flex'} alignItems={'center'}
            justifyContent={'center'} px={2}>
        <Typography sx={styles.nameText}>
          {(item?.variety ? item?.variety : 'NIL Variety')
          + ' | ' + (item?.product_name ? item?.product_name : 'NIL Product')
          + ' | ' + (item?.size ? item?.size : 'NIL Size')
          + ' | ' + (item?.package_net_weight ?
            (item?.package_net_weight + ' ' + item?.packing_material)
            : 'NIL Packing')
          + ' | ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
        </Typography>
      </Grid>
      <Grid container item md={12} xs={12} mt={1} pb={2} display={'flex'} flexDirection={'row'} alignItems={'center'}
            justifyContent={'center'}>
        <Typography
          style={styles.pricePerKgText}>
          {currencySymbol} {pricePerKg?.toFixed(2)}/Kg
        </Typography>
        <Typography
          pl={0.6}
          style={styles.priceText}>
          ({currencySymbol} {price?.toFixed(2)})
        </Typography>
      </Grid>
      <Grid container item md={12} xs={12}>
        <FormButton
          // loading={props?.cartBtnLoading}
          btnStyleView={styles.btnStyleView}
          bgColorStyleView={THEME_COLOR2}
          title={'ADD TO CART'}
          onSubmit={() => onAddToCartClick(item)}
        />
      </Grid>
    </Grid>
  )
};

export default ItemProductList;

const styles = {
  mainView: {
    border: '0.1px solid #ccc',
    borderRadius: 1,
    minHeight: window.innerHeight - 400,
    width: {md: window.innerWidth / 5, xs: window.innerWidth / 2},
    // cursor: 'pointer',
  },
  imgView: {
    position: 'relative',
    height: {md: 260, xs: 110},
    width: '100%',
  },
  imgStyle: {
    objectFit: 'contain',
    height: '100%',
    width: '100%'
  },
  iconMainView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  iconView: {
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: {md: 24, xs: 10},
    height: {md: 48, xs: 20},
    width: {md: 48, xs: 20},
    padding: {md: 0, xs: 1},
    boxShadow: '0 3px 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    ":hover": {
      path: {
        stroke: "#FFF",
      }
    }
  },
  nameText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    textAlign: 'center',
    color: '#000',
    fontFamily: "InterMedium",
    fontSize: 14,
  },
  pricePerKgText: {
    color: THEME_COLOR,
    fontFamily: "InterBold",
    fontSize: 18,
  },
  priceText: {
    color: '#000',
    fontFamily: "InterMedium",
    fontSize: 13,
  },
  dataText: {
    color: '#000',
    fontFamily: "InterMedium",
    fontSize: 15,
    textTransform: 'capitalize'
  },
  companyNameText: {
    color: THEME_COLOR3,
    fontFamily: "InterMedium",
    fontSize: 15,
    textTransform: 'capitalize',
    cursor: 'pointer'
  },
  btnStyleView: {
    fontSize: {md: 12, xs: 11},
    height: 43,
    width: '100%',
    backgroundColor: THEME_COLOR2,
    color: '#000',
    overflow: 'hidden',
    border: '2px solid #ddd',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
};
