import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography, TextField, TableCell} from '@mui/material';

import styles from "./AddToCartBox.module.css";

const AddToCartBox = (props) => {
  // const [productCount, setProductCount] = useState(0)
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [showError, setShowError] = useState('');

  useEffect(() => {
    if (props?.data) {
      props?.setProductCount(props.data.quantity);
      if (props.data?.quantity == '') {
        setTotalPrice(0);
      } else {
        let price_val = 0;
        let price_per_kg = 0;
        let order_quantity = 0;
        if (props?.data?.price_per_package != '' && props?.data?.package_net_weight != '') {
          price_val = (parseFloat((((props?.data?.price_per_package / props?.data?.package_net_weight) * props?.data?.referral_fee) / 100) + (props?.data?.price_per_package / props?.data?.package_net_weight)) * props?.data?.package_net_weight)?.toFixed(2)
          order_quantity = props.data.quantity * props?.data?.package_net_weight;
          price_per_kg = price_val / props?.data?.package_net_weight;
        }

        setPrice(price_val);
        setTotalPrice(props.data.quantity * price_val);
        setPricePerKg(price_per_kg);
        setOrderQuantity(order_quantity);
      }
    }
  }, [props?.data]);

  const addSubProduct = (type) => {
    setShowError('');
    // let count = productCount;
    let count = props?.productCount;
    if (count != '') {
      if (type == 'add') {
        count = parseInt(count) + 1;
      } else if (type == 'sub') {
        if (count > 1) {
          count = parseInt(count) - 1;
        } else {
          setShowError('Packages Required');
        }

      }

      let order_quantity = 0;
      if (props?.data?.package_net_weight != '') {
        order_quantity = count * props?.data?.package_net_weight;
      }

      props?.setProductCount(count);
      setOrderQuantity(order_quantity)
      setTotalPrice(count * price)

      if (order_quantity < props.data?.minimum_order_quantity) {
        setShowError('MOQ is ' + props.data?.minimum_order_quantity)
        return;
      } else {
        setShowError('');
      }

    }

  };

  const onChangeProductCount = (value) => {
    props?.setProductCount(value);

    if (value == '') {
      setShowError('MOQ is ' + props.data?.minimum_order_quantity)
      setTotalPrice(0);
      return
    } else {
      let order_quantity = 0;
      if (props?.data?.package_net_weight != '') {
        order_quantity = value * props?.data?.package_net_weight;
      }

      setOrderQuantity(order_quantity)
      setTotalPrice(value * price)

      if (order_quantity < props.data?.minimum_order_quantity) {
        setShowError('MOQ is ' + props.data?.minimum_order_quantity)
        return;
      } else {
        setShowError('');
      }

    }

  };


  return (
    <>
      <Grid item md={10} xs={10} className={styles.addToCartView}>
        <Grid item md={3} xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}
              className={styles.pointerView} onClick={() => addSubProduct('sub')}>
          <Typography className={styles.plusMinusNoText}>-</Typography>
        </Grid>
        <Grid item md={6} xs={6} display={"flex"} flexDirection={"row"}>
          <Grid item md={0.5} xs={4} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
            <Box className={styles.borderRightLine}/>
          </Grid>
          <Grid item md={11} xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <TextField
              fullWidth
              variant={"standard"}
              value={props?.productCount}
              type={'text'}
              onChange={e => onChangeProductCount(e.target.value.replace(/[^0-9]/g, ''))}
              sx={{
                '& .MuiInput-root': {
                  '&:before, :after, :hover:not(.Mui-disabled):before': {
                    borderBottom: 0,
                  },
                },
              }}
              inputProps={{
                autoComplete: 'none',
                sx: {
                  color: '#000',
                  fontWeight: 'bold',
                  fontSize: 14,
                  textAlign: 'center'
                },
              }}
              defaultValue={props?.productCount}
            />
          </Grid>
          <Grid item md={0.5} xs={4} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
            <Box className={styles.borderLeftLine}/>
          </Grid>
        </Grid>
        <Grid item md={3} xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}
              className={styles.pointerView} onClick={() => addSubProduct('add')}>
          <Typography className={styles.plusMinusNoText}>+</Typography>
        </Grid>
      </Grid>
      <Typography className={styles.orderQuantityText} mt={1}>Order Quantity
        : {props?.productCount * props?.data?.package_net_weight} kg</Typography>
      {showError ?
        <Box mt={1}>
          <Typography className={styles.moqErrorText}>{showError}</Typography>
        </Box>
        : null}
    </>
  );
};

export default AddToCartBox;
