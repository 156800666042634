import React, {useEffect, useState} from 'react'
import {
  Box,
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography
} from '@mui/material';
import {useNavigate, useLocation} from "react-router-dom";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";
import CheckoutList from "../../components/CheckoutList";
import ConfirmAlert from "../../components/ConfirmAlert";

import styles from './Checkout.module.css';

import {connect} from "react-redux";
import {
  getCheckout, updateApiStatus, deleteCart, placeOrder
} from "../../redux/actions/userDataActions";

import {ReactComponent as CheckBoxIcon} from "../../assets/img/checkbox.svg";
import {ReactComponent as UnCheckBoxIcon} from "../../assets/img/uncheckbox.svg";
import {THEME_COLOR3} from "../../constants";

import {ReactComponent as DeleteIcon} from "../../assets/img/delete.svg";


const Checkout = (props) => {
  // const [checkedData, setCheckedData] = useState([])
  // const [checkedAll, setCheckedAll] = useState(false)
  const [showError, setShowError] = useState('');
  const [cartUid, setCartUid] = useState('');
  const [showConfirmAlertVisible, setShowConfirmAlertVisible] = useState(false);
  const [cargoPrice, setCargoPrice] = useState(0)
  const [sellerCargoPrice, setSellerCargoPrice] = useState(0)
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [sellerCurrencySymbol, setSellerCurrencySymbol] = useState('')
  const [rate, setRate] = useState(0)

  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   if (props?.checkoutData?.length > 0) {
  //     let data = {
  //       cartUid: JSON.stringify(props.checkoutData)
  //     };
  //     props.getCheckout(JSON.stringify(data));
  //   } else {
  //     navigate('/cart');
  //   }
  // }, [props?.checkoutData]);

  useEffect(() => {
    let cart_uid = location.pathname.split("/").pop();
    let data = {
      cartUid: cart_uid,
    };
    setCartUid(cart_uid)
    props.getCheckout(JSON.stringify(data));
  }, [])

  useEffect(() => {
    if (props.apiStatus == 'DELETE_CART_SUCCESS') {
      setShowConfirmAlertVisible(false);
      // setCheckedData([]);
      // setCheckedAll(false)
      props.updateApiStatus('')
      // if (props?.checkoutDetail?.length == 0) {
      navigate('/')
      // }
    } else if (props.apiStatus == 'PLACE_ORDER_SUCCESS') {
      props.updateApiStatus('')
      navigate('/order')
    } else if (props.apiStatus == 'PLACE_ORDER_FAIL' || props.apiStatus == 'DELETE_CART_FAIL') {
      props.updateApiStatus('')
      navigate('/cart')
    }
  }, [props.apiStatus])

  // const onCheck = (item) => {
  //   let list = [...checkedData];
  //   let index = list.findIndex((x) => x?.cart_uid == item?.cart_uid);
  //   if (index >= 0) {
  //     list.splice(index, 1)
  //     setCheckedData(list);
  //     if (list.length == 0) {
  //       setCheckedAll(false)
  //     }
  //   } else {
  //     setCheckedData([...list, item]);
  //   }
  // };

  const onDeleteCartClick = () => {
    // let checked_list = [...checkedData];
    // let checked_ids = [];
    // checked_list.map((item, index) => {
    //   checked_ids.push(item?.cart_uid)
    // });
    //
    // if (checked_ids?.length == 0) {
    //   setShowError('Choose items to delete');
    //   return;
    // } else {
    //   setShowError('')
    // }

    setShowConfirmAlertVisible(true);
  };

  const onConfirmDeleteCart = () => {
    // let checked_list = [...checkedData];
    // let checked_ids = [];
    // checked_list.map((item, index) => {
    //   checked_ids.push(item?.cart_uid)
    // });
    //
    // let data = {
    //   cartUid: JSON.stringify(checked_ids)
    // };
    let cart_uid = [cartUid]

    let data = {
      currentStatus: 'checkout_pending',
      cartUid: JSON.stringify(cart_uid),
    };
    props.deleteCart(JSON.stringify(data));
  };

  const onCancelDeleteCart = () => {
    setShowConfirmAlertVisible(false);
  };

  const onPlaceOrderClick = () => {
    // let checked_ids = [];
    // let checked_list = [...checkedData];
    // checked_list.map((item, index) => {
    //   checked_ids.push(item?.cart_uid)
    // });
    //
    // if (checked_ids?.length == 0) {
    //   setShowError('Choose items to order');
    //   return;
    // } else {
    //   setShowError('')
    // }

    if(rate == 0) {
      setShowError('Failed to order. Rate not found')
    } else {
      setShowError('')
    }


    let data = {
      cartUid: cartUid,
      rate: rate
    };
    props.placeOrder(JSON.stringify(data))
  };

  // const onCheckAll = () => {
  //   if (!checkedAll == true) {
  //     if (props?.checkoutDetail?.length > 0) {
  //       let list = [...props?.checkoutDetail];
  //       setCheckedData(list)
  //       setCheckedAll(!checkedAll);
  //     }
  //   } else {
  //     setCheckedData([])
  //     setCheckedAll(!checkedAll);
  //   }
  // };

  return (
    <Container page={"Checkout"} title={'Checkout'} subTitle={'Checkout'}>

      <>
        <Grid item md={12} xs={12}
              display={"flex"}
              alignItems={"center"} justifyContent={"center"}
        >
          <Grid container item md={11.6} xs={11.6} mt={4}
                display={"flex"} alignItems={"center"} justifyContent={"center"}
          >
            <Grid item md={12} xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/*<TableCell>*/}
                      {/*  {checkedAll ?*/}
                      {/*    <Box onClick={() => onCheckAll()}>*/}
                      {/*      <CheckBoxIcon height={25} width={25}/>*/}
                      {/*    </Box> :*/}
                      {/*    <Box onClick={() => onCheckAll()}>*/}
                      {/*      <UnCheckBoxIcon height={25} width={25}/>*/}
                      {/*    </Box>}*/}
                      {/*</TableCell>*/}
                      <TableCell>Cart ID</TableCell>
                      <TableCell>Cargo ID</TableCell>
                      <TableCell className={styles.dataView}>Product Details</TableCell>
                      <TableCell className={styles.dataView}>Delivery Address</TableCell>
                      <TableCell className={styles.dataView}>Shipping Details</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.productPriceText}>Total Product Price</Typography>
                        <Typography className={styles.productPriceExwText} pl={2}>(EXW)</Typography>
                      </TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.productPriceText}>Total Shipping Cost</Typography>
                        {props?.checkoutDetail?.length > 0 ?
                          props?.checkoutDetail?.[0]?.shipping_details?.incoterm ?
                            <Typography className={styles.productPriceExwText} pl={2}>({props?.checkoutDetail?.[0]?.shipping_details?.incoterm})</Typography>
                            : null
                          : null}
                      </TableCell>
                      <TableCell className={styles.dataView}>
                        <Typography className={styles.productPriceText}>Total Cargo Cost</Typography>
                        {props?.checkoutDetail?.length > 0 ?
                          props?.checkoutDetail?.[0]?.shipping_details?.incoterm ?
                            <Typography className={styles.productPriceExwText} pl={2}>({props?.checkoutDetail?.[0]?.shipping_details?.incoterm})</Typography>
                            : null
                          : null}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props?.checkoutDetail?.length > 0 ?
                      props?.checkoutDetail?.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <CheckoutList
                              key={index}
                              index={index}
                              data={item}
                              currencyRateList={props?.currencyRateList}
                              // onCheck={onCheck}
                              // checkedData={checkedData}
                              // setCheckedData={setCheckedData}
                              // cargoPrice={cargoPrice}
                              setCargoPrice={setCargoPrice}
                              setSellerCargoPrice={setSellerCargoPrice}
                              setCurrencySymbol={setCurrencySymbol}
                              setSellerCurrencySymbol={setSellerCurrencySymbol}
                              setRate={setRate}
                            />
                          </TableRow>
                        )
                      }) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>


          </Grid>

        </Grid>


        {props?.checkoutDetail?.length > 0 ?
          props?.checkoutDetail?.[0]?.shipping_details?.payment_term_amount == 50 ?
            <>
              <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>

              <Grid item md={12} xs={12}
                    display={"flex"}
                    alignItems={"center"} justifyContent={"center"}
                    mt={4}>
                <Grid item md={11} xs={11} display={"flex"} flexDirection={'row'} alignItems={"center"}
                      className={styles.paymentMainDescView} p={2}>
                  <Typography className={styles.paymentMainDescText}>Since you have opted for 50% Advance Payment & 50%
                    before shipping the amount to be paid to place this order
                    has been calculated below</Typography>
                </Grid>
              </Grid>
            </>
            : null
          : null}

        <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>

        {props?.checkoutDetail?.length > 0 ?
          <Grid item md={12} xs={12}
                display={"flex"}
                alignItems={"center"} justifyContent={"center"}
                mt={4}>
            <Grid item md={11} xs={11} display={"flex"} flexDirection={'row'} alignItems={"center"}
                  justifyContent={"flex-end"}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box>
                  <Typography className={styles.totalCargoLabelText}>Pay</Typography>
                </Box>
                <Box pl={1}>
                  {props?.checkoutDetail?.[0]?.shipping_details?.payment_term_amount == 50 ?
                    <Typography className={styles.paymentAmtText}>
                      (50%)
                    </Typography>
                    : null}
                </Box>
              </Box>
              <Box pl={4}>
                <Typography className={styles.cargoPriceText}>
                  {props?.checkoutDetail?.[0]?.shipping_details?.payment_term_amount == 50 ?
                  sellerCargoPrice ? (sellerCurrencySymbol + (sellerCargoPrice * (50 / 100))?.toFixed(2)) : ''
                  : sellerCargoPrice ? (sellerCurrencySymbol + sellerCargoPrice?.toFixed(2)) : ''}
                </Typography>
                <Typography className={styles.cargoPriceText} pl={3}>
                  {props?.checkoutDetail?.[0]?.shipping_details?.payment_term_amount == 50 ?
                  cargoPrice ? '(' + currencySymbol + (cargoPrice * (50 / 100))?.toFixed(2) + ')' : ''
                  : cargoPrice ?  '(' + currencySymbol + cargoPrice?.toFixed(2) + ')' : ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          : null}

        <Grid item md={12} xs={12}
              display={"flex"}
              alignItems={"center"} justifyContent={"center"}
              mt={4}>
          <Grid item md={11} xs={11} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
            <Typography className={styles.errorText}>{showError}</Typography>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>

        {props?.checkoutDetail?.length > 0 ?
          <Grid item md={12} xs={12}
                display={"flex"}
                alignItems={"center"} justifyContent={"center"}
                mt={4}>
            <Grid item md={11} xs={11} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
              <Box className={styles.btnView}>
                <FormButton
                  title={'Delete'}
                  buttonView={{
                    width: '100%',
                    backgroundColor: 'red'
                  }}
                  bgColorStyleView={'red'}
                  onSubmit={() => onDeleteCartClick()}
                />
              </Box>
              <Box className={styles.btnView} ml={1}>
                <FormButton
                  title={'Place Order'}
                  buttonView={{
                    width: '100%',
                    backgroundColor: THEME_COLOR3
                  }}
                  bgColorStyleView={THEME_COLOR3}
                  onSubmit={() => onPlaceOrderClick()}
                />
              </Box>
            </Grid>
          </Grid>
          : null}

      </>

      <AlertMsg/>

      <ConfirmAlert
        btnLoading={props.confirmBtnLoading}
        confirmBox={showConfirmAlertVisible}
        type={'delete'}
        icon={<DeleteIcon height={80} width={80}/>}
        content={'Are you sure you would like to Delete?'}
        firstBtn={'Yes, Confirm'}
        secondBtn={'No, Cancel'}
        onConfirmClick={onConfirmDeleteCart}
        onCancelClick={onCancelDeleteCart}
      />
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    // checkoutData: state.userData.checkoutData,
    checkoutDetail: state.userData.checkoutDetail,
    confirmBtnLoading: state.userData.confirmBtnLoading,
    apiStatus: state.userData.apiStatus,
    currencyRateList: state.userData.currencyRateList,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getCheckout: data => dispatch(getCheckout(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    deleteCart: data => dispatch(deleteCart(data)),
    placeOrder: data => dispatch(placeOrder(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
