export const ENV = 'PROD';
export const AUTHENTICATION_FAILED = 'Authentication Failed';
export const API_KEY = '';
export const THEME_COLOR = '#80B500';
// export const THEME_COLOR2 = '#d3ffcb';
export const THEME_COLOR2 = '#D8F0D4';
// export const THEME_COLOR3 = '#009ef7';
export const THEME_COLOR3 = '#315CCB';
export const THEME_COLOR4 = '#e80707';
// export const THEME_COLOR5 = 'orange';
export const THEME_COLOR5 = '#EA910D';
export const THEME_COLOR6 = '#999';
export const THEME_COLOR7 = '#128807';
export const COUNTRY_FLAG_URL = 'https://flagcdn.com/w20/';

export const MENU_LISTS = [
  {
    label:'Register',
    navigation: '/register',
  },
  {
    label:'Home',
    navigation: '/home'
  },
  {
    label:'Marketplace',
    navigation: '/product'
  },
];


export const SIGN_IN_MENU_LIST = [
  {
    label:'Sign In',
    navigation: '/login'
  },
  ...MENU_LISTS
];

export const SIGN_OUT_MENU_LIST = [
  ...MENU_LISTS,
  {
    label:'Cart',
    navigation: '/cart'
  },
  {
    label:'Orders',
    navigation: '/order'
  },
  {
    label:'Payments',
    navigation: '/payment'
  },
  {
    label:'My Account',
    navigation: '/account'
  },
  {
    label:'Sign Out',
    navigation: '/logout'
  },
];
