import React, {useEffect, useState} from 'react'
import {
  Grid,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";

import {connect} from "react-redux";
import {getHomePage, getCurrencyRateList, updateApiStatus} from "../../redux/actions/userDataActions";

import {ReactComponent as BoxIcon} from "../../assets/img/box.svg";

import {THEME_COLOR, THEME_COLOR2, THEME_COLOR5, THEME_COLOR7} from "../../constants";

import styles from './Dashboard.module.css'

import '../../assets/css/custom.css';
import HomeProduct from "../../components/HomeProduct";

const Dashboard = (props) => {
  const [loading, setLoading] = useState(true)
  const [dashboardDetail, setDashboardDetail] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    props?.getCurrencyRateList()
  }, [])

  useEffect(() => {
    props.getHomePage();
  }, []);

  useEffect(() => {
    if (props.homeData != null) {
      setDashboardDetail(props?.homeData?.dashboardDetail)
    } else {
      setDashboardDetail(null)
    }
  }, [props.homeData]);

  useEffect(() => {
    if (props?.apiStatus == 'GET_HOME_PAGE_SUCCESS') {
      setLoading(false);
      props?.updateApiStatus('')
    }
  }, [props?.apiStatus])


  if (loading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Container page={"dashboard"}>
      <Grid item md={12} xs={12} display={"flex"} flexDirection={"column"}
            justifyContent={"center"}
            alignItems={'center'}
            mt={5}>

        {dashboardDetail?.totalOrders == 0 || dashboardDetail == null ?
          <Grid item md={10} xs={10}
                container
                display={"flex"}
                flexDirection={{md: "column", xs: "column"}}
          >
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mb={1}>
              <Box>
                <Typography className={styles.userTitleText}>Dear Buyer,</Typography>
              </Box>
              <Box mt={0.6} pl={0.5}>
                <Typography className={styles.welcomeAboardText}>Welcome aboard !!!!!</Typography>
              </Box>
            </Box>
            <Box className={styles.borderBottomView}/>
            <Typography className={styles.congratulationTitleText} mt={2}>Congratulations !</Typography>

            {props?.tgbin ?
              <>
                <Typography className={styles.tgbinTitleText} mt={1}>
                  Your Trebbs Global Buyer account has been activated.</Typography>
                <Typography className={styles.tgbinTitleText} mt={1}>
                  Your Trebbs
                  Global
                  Buyer identification number
                  (TGBIN) is {props?.tgbin}, which is also sent to your email.</Typography>
              </>
              : null}

            <Box mt={4}>
              <Typography className={styles.viewDashboardText}>View products and start ordering !</Typography>

              <Grid item md={12} xs={12} mt={3}>
                <Box className={styles.boxView}
                     display={'flex'}
                     flexDirection={'column'}
                     alignItems={'center'} justifyContent={'center'}
                     pt={2} pb={1}>
                  <BoxIcon height={100} width={100}/>
                  <Box className={styles.borderBottomView} mt={3} mb={1}/>
                  <FormButton
                    buttonView={style.buttonView}
                    bgColorStyleView={style.bgColorStyleView}
                    title={'View Products'}
                    width={'60%'}
                    height={'20%'}
                    onSubmit={() => navigate('/product')}
                  />
                </Box>
              </Grid>


            </Box>
          </Grid>
          :
          <>
            <Grid container item md={10} xs={10}>
              <Typography className={styles.dashboardText}>Home</Typography>

              <Grid item md={12} xs={12} className={styles.countBorderBottomView} mt={1} mb={5}/>
            </Grid>

            <Grid container item md={10} xs={10}>

              <Grid item md={3} xs={12} mb={2}>
                <Grid item md={11} xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      className={styles.cardDiv}
                      onClick={() => navigate('/order')}>
                  <Typography className={styles.titleText}>{dashboardDetail?.totalOrders} Orders</Typography>
                </Grid>
              </Grid>

              <Grid item md={3} xs={12} mb={2}>
                <Grid item md={11} xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      className={styles.cardDiv}
                      onClick={() => navigate('/order?status=pending')}>
                  <Typography className={styles.titleText}>{dashboardDetail?.totalPendingOrders} Pending
                    Orders</Typography>
                </Grid>
              </Grid>

              <Grid item md={3} xs={12} mb={2}>
                <Grid item md={11} xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      className={styles.cardDiv}
                      onClick={() => navigate('/order?status=unshipped')}>
                  <Typography className={styles.titleText}>{dashboardDetail?.totalUnshippedOrders} Unshipped
                    Orders</Typography>
                </Grid>
              </Grid>

              <Grid item md={3} xs={12} mb={2}>
                <Grid item md={11} xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      className={styles.cardDiv}
                      onClick={() => navigate('/order?status=shipped')}>
                  <Typography className={styles.titleText}>{dashboardDetail?.totalShippedOrders} Shipped
                    Orders</Typography>
                </Grid>
              </Grid>

              <Grid item md={3} xs={12} mb={2}>
                <Grid item md={11} xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      className={styles.cardDiv}
                      onClick={() => navigate('/order?status=delivered')}>
                  <Typography className={styles.titleText}>{dashboardDetail?.totalDeliveredOrders} Delivered
                    Orders</Typography>
                </Grid>
              </Grid>

              <Grid item md={3} xs={12} mb={2}>
                <Grid item md={11} xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      className={styles.cardDiv}
                      onClick={() => navigate('/order?status=cancelled')}>
                  <Typography className={styles.titleText}>{dashboardDetail?.totalCancelledOrders} Cancelled
                    Orders</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        }

        {/*products*/}
        <HomeProduct
          homeData={props?.homeData}
          currencyRateList={props?.currencyRateList}
        />

      </Grid>

      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    homeData: state.userData.homeData,
    currencyRateList: state.userData.currencyRateList,
    tgbin: state.userData.tgbin,
    apiStatus: state.userData.apiStatus,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getHomePage: data => dispatch(getHomePage(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const style = {
  buttonView: {
    backgroundColor: THEME_COLOR2,
    color: '#000',
  },
  bgColorStyleView: {
    backgroundColor: THEME_COLOR2,
  },
};

