import React from 'react';
import Pagination from '@mui/material/Pagination';
import {createTheme, ThemeProvider} from "@mui/material/styles";

import {THEME_COLOR} from "../constants";

const Page = (props) => {

  const theme = createTheme({
    palette: {
      theme_color: {
        main: THEME_COLOR,
      },
    },
  });

  const handleChange = (event, value) => {
    props.onSelectPage(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Pagination
        sx={styles.pageView}
        count={props.totalPages}
        page={props.pageNum}
        onChange={handleChange}
        color="theme_color"
        shape={"rounded"}
      />
    </ThemeProvider>
  );
};

export default Page;

const styles = {
  pageView: {
    '& .MuiPaginationItem-root.Mui-selected': {
      color: '#FFF',
      fontFamily: 'InterBold',
      fontSize: 16,
      borderRadius: 0,
      height: 42,
      width: 42,
      ":hover": {
        backgroundColor: THEME_COLOR,
        color: "#FFF"
      },
      mt: {md: 0, xs: 2}
    },
    '& .MuiPaginationItem-root': {
      color: '#000',
      fontFamily: 'InterBold',
      fontSize: 16,
      border: '1px solid #DDD',
      borderRadius: 0,
      height: 42,
      width: 42,
      ":hover": {
        backgroundColor: THEME_COLOR,
        color: "#FFF"
      },
      mt: {md: 0, xs: 2}
    },
    '& .MuiPagination-ul li': {
      marginLeft: 0.5,
      marginRight: 0.5
    }
  }
}
