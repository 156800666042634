import * as React from 'react';
import {Box, Typography, Button, Dialog, DialogContent, Grid, DialogTitle} from '@mui/material';

import {ReactComponent as CloseIcon} from "../assets/img/close_black.svg";

import FormButton from "./FormButton";

const AlertDialogBox = (props) => {
  const onConfirmClick = () => {
    props.onConfirmClick(props.type)
  };

  const onCancelClick = () => {
    props.onCancelClick()
  };

  return (
    <Dialog
      open={props.confirmBox}
      onClose={onCancelClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          alignItems: props?.alignItems ? props?.alignItems : 'center',
          justifyContent: 'center',
          borderRadius: props?.type == 'passwordReq' ? 0 : 2,
          minHeight: {md: props?.type == 'passwordReq' ? '28%' : (props?.height ? props?.height : '38%'), xs: '38%'},
          width: {
            md: props?.type == 'passwordReq' ? '45%' : (props?.width ? props?.width : '25%'), xs: '90%'
          }
        }
      }}>
      <Box style={{padding: props?.type == 'passwordReq' ? 0 : (props?.padding ? props?.padding : 12)}}>

        {props?.title ?
          <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
            <Typography sx={styles.titleText}>
              {props?.title}
            </Typography>
          </DialogTitle>
          : null}

        {props?.closeIcon ?
          <Box
            sx={styles.closeIconView}
            aria-label="close"
            onClick={onCancelClick}
          >
            <CloseIcon height={20} width={20}/>
          </Box>
          : null}

        {(props?.title || props?.closeIcon) ?
          <Box sx={styles.borderBottomLine}/>
          : null}

        <DialogContent>
          {props?.icon ?
            <Box display={'flex'} justifyContent={"center"}>
              {props.icon}
            </Box>
            : null}
          <Box mt={props?.type == 'passwordReq' ? 0 : (props?.mt ? props?.mt : 3)}>
            <Typography
              sx={{textAlign: props?.type == 'passwordReq' ? styles.passReqContentText : styles.contentText}}>{props.content}</Typography>
          </Box>
        </DialogContent>
        {(props?.firstBtn || props?.secondBtn) ?
          <Grid item md={12} xs={12} container
                mt={{md: 2, xs: 1}}
                display="flex" flexDirection="row" alignItems={"center"} justifyContent="space-evenly">
            {props?.firstBtn ?
              <FormButton
                loading={props.btnLoading}
                title={props.firstBtn}
                btnType={'btn4'}
                onSubmit={onConfirmClick}
              />
              : null}
            {props?.secondBtn ?
              <FormButton
                title={props.secondBtn}
                btnType={'btn5'}
                onSubmit={onCancelClick}
              />
              : null}
          </Grid>
          : null}
      </Box>
    </Dialog>
  )
}

export default AlertDialogBox

const styles = {
  titleText: {
    fontSize: 14,
    color: '#000',
    fontFamily: 'InterBold'
  },
  closeIconView: {
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    top: 12,
  },
  contentText: {
    textAlign: 'center',
    fontSize: 15,
    color: '#000',
    fontFamily: 'InterMedium'
  },
  borderBottomLine: {
    border: '1px solid #ddd'
  },
  passReqContentText: {
    fontSize: 13,
    color: '#000',
    whiteSpace: 'pre-line',
    fontFamily: 'InterMedium'
  },
}
