import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";

import FormButton from "./FormButton";
import FormMultiSelectBox from "./FormMultiSelectBox";

import {connect} from "react-redux";
import {getCategoryList, addUserProductCategory, updateApiStatus} from "../redux/actions/userDataActions";

import './../assets/css/custom.css';
import {THEME_COLOR} from "../constants";

const AddProductDetails = (props) => {
  const [categoryList, setCategoryList] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == "ADD_USER_PRODUCT_CATEGORY_SUCCESS") {
      props.updateApiStatus("");
      if (props?.tgbin) {
        if (props?.navigation) {
        } else {
          navigate('/')
        }
      } else {
        navigate('/verification')
      }
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props?.data != null) {
      if (props.data?.categoryList?.length > 0) {
        setCategoryList(props.data?.categoryList);
      }
    }
  }, [props.data]);

  const onLaunchClick = () => {
    let errorText = {};
    if (categoryList.length == 0) {
      errorText = {categoryList: 'Please choose category'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      categoryList: JSON.stringify(categoryList),
    };
    props.addUserProductCategory(JSON.stringify(data))
  };

  return (
    <Grid
      container
      item xs={8} md={8}
      flexDirection={'column'}
      justifyContent={'center'}
    >
      <Grid item md={12} xs={12} mt={3}>
        <FormMultiSelectBox
          page={'AddProductsDetails'}
          multiple={true}
          value={categoryList}
          setValue={setCategoryList}
          loading={props.listLoading}
          data={props?.categoryList ? props?.categoryList : []}
          label={"Select Product Category"}
          error={error?.categoryList}
          textInputViewStyle={styles.textInputViewStyle}
          selectedItemViewStyle={styles.selectedItemViewStyle}
          required={true}
        />
      </Grid>

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="center" mt={4}>
        <Box>
          <FormButton
            title={props?.userStatus == 'pending' ? 'Launch your business' : 'Submit'}
            onSubmit={onLaunchClick}
          />
        </Box>
      </Grid>

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    categoryList: state.userData.categoryList,
    listLoading: state.userData.listLoading,
    apiStatus: state.userData.apiStatus,
    tgbin: state.userData.tgbin,
    userStatus: state.userData.userStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addUserProductCategory: data => dispatch(addUserProductCategory(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProductDetails);

const styles = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  selectedCategoryView: {
    border: '1px solid #ddd'
  },
  buyerTitleText: {
    color: THEME_COLOR,
    fontFamily: 'InterBold',
    fontSize: 22
  },
  welcomeTitleText: {
    color: THEME_COLOR,
    fontFamily: 'InterBold',
    fontSize: 19
  },
  borderBottomView: {
    border: '1px solid #ccc'
  },
  congratulationTitleText: {
    color: THEME_COLOR,
    fontFamily: 'InterBold',
    fontSize: 19
  },
  tgbinTitleText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 13
  },
  orderingTitleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 15
  },
  btnStyleView: {
    backgroundColor: THEME_COLOR,
    color: '#fff',
    fontSize: 14,
    fontFamily: 'InterMedium',
    width: '100%',
    height: 40,
  }
};

