import React, {useEffect, useState, useRef} from 'react'
import {
  Grid, Typography, Box, Tabs, Tab, styled, TextField, TableCell, CircularProgress,
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion"
import FileSaver from 'file-saver';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {THEME_COLOR2, COUNTRY_FLAG_URL} from '../../constants'
import {COUNTRIES} from "../../constants/countries";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import ItemProductList from "../../components/ItemProductList";
import ProductItemList from "../../components/ProductItemList";
import ProductDetailModal from "../../components/ProductDetailModal";
import AlertMsg from "../../components/AlertMsg";

import {connect} from "react-redux";
import {
  getProductDetail,
  addToCart,
  updateAlertMsg,
  alertMsgEmpty,
  updateCartBtnText,
  getCurrencyRateList
} from "../../redux/actions/userDataActions";

import FormSelectBox from "../../components/FormSelectBox";
import {getCurrencyConversion} from "../../assets/functions/common";

import {ReactComponent as CartIcon} from "../../assets/img/cart_white.svg";
import {ReactComponent as LeftArrowIcon} from "../../assets/img/long_left_arrow.svg";
import {ReactComponent as RightArrowIcon} from "../../assets/img/long_right_arrow.svg";
import {ReactComponent as ImgIcon} from "../../assets/img/img.svg";
import {ReactComponent as PreShipmentInspectionIcon} from "../../assets/img/pre_shipment_inspection.svg";
import {ReactComponent as MultipleShippingOptionIcon} from "../../assets/img/multiple_shipping_option.svg";
import {ReactComponent as PaymentOptionIcon} from "../../assets/img/2_payment_option.svg";
import {ReactComponent as RefundIcon} from "../../assets/img/refund.svg";
import {ReactComponent as TickIcon} from "../../assets/img/tick.svg";

import {THEME_COLOR, THEME_COLOR3, THEME_COLOR4, THEME_COLOR5} from "../../constants";

import '../../assets/css/custom.css';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    sx={{
      '& .MuiTabs-flexContainer': {
        flexWrap: 'wrap',
      },
    }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: THEME_COLOR,
    borderRadius: 10,
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({theme}) => ({
    textTransform: 'capitalize',
    fontSize: 15,
    marginRight: theme.spacing(1),
    color: '#000',
    fontFamily: 'InterBold',
    '&.Mui-selected': {
      color: THEME_COLOR,
    },
  }),
);

const product_all_tabs = ['Description', 'Details', 'Packing Details', 'Packing Description', 'Shipping Details', 'Product Documents', 'Exporter Details'];
const product_tabs = ['Details', 'Packing Details', 'Packing Description', 'Shipping Details', 'Product Documents', 'Exporter Details'];

const optionList = [
  {
    icon: <PreShipmentInspectionIcon height={17} width={17}/>,
    title: 'Pre-Shipment Inspection'
  },
  {
    icon: <PaymentOptionIcon height={17} width={17}/>,
    title: '2 Payment Options'
  },
  {
    icon: <RefundIcon height={17} width={17}/>,
    title: '100% Refund'
  },
  {
    icon: <MultipleShippingOptionIcon height={17} width={17}/>,
    title: 'Multiple Shipping Options'
  }
]

const ProductDetail = (props) => {
  const [loading, setLoading] = useState(true)
  const [productDetail, setProductDetail] = useState(null);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [selectedProductImage, setSelectedProductImage] = useState(0);
  const [selectedTab, setSelectedTab] = useState('Details');
  const [productDetailModalVisible, setShowProductDetailModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [productTabs, setProductTabs] = useState(product_tabs)
  const [productCount, setProductCount] = useState(1)
  const [showError, setShowError] = useState('');
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [varietySize, setVarietySize] = useState(null);
  const [varietySizeList, setVarietySizeList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')

  const sliderRef = useRef();
  const sliderProductImageRef = useRef();

  useEffect(() => {
    props?.getCurrencyRateList()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    let total_price = 0;
    let price_per_kg = 0;
    let order_quantity = 0;

    let price_per_package = productDetail?.price_per_package;
    let package_net_weight = productDetail?.package_net_weight;
    let referral_fee = productDetail?.referral_fee;
    let rate_list = productDetail?.rate_list;
    let seller_currency = productDetail?.seller_currency;
    let user_currency = productDetail?.user_currency;

    let data = getCurrencyConversion(price_per_package, package_net_weight, referral_fee, rate_list, seller_currency, user_currency)
    if (data != null) {
      total_price = data?.converted_price;
      price_per_kg = total_price / productDetail?.package_net_weight;
      order_quantity = 1 * productDetail?.package_net_weight;
      setCurrencySymbol(data?.currency_symbol)
    }

    setTotalPrice(total_price)
    setPricePerKg(price_per_kg)
    setOrderQuantity(order_quantity)
  }, [productDetail?.price_per_package, productDetail?.package_net_weight, productDetail?.referral_fee]);

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let product_id = params.get('id');
    if (product_id) {
      let data = {
        product_uid: product_id,
      };
      props.getProductDetail(JSON.stringify(data));
    }
  }, [])

  useEffect(() => {
    if (props.productDetail != null) {
      setProductDetail(props.productDetail?.product_data);
      setTopSellingProducts(props.productDetail?.top_selling_product_data);
      setRelatedProducts(props.productDetail?.related_product_data);
      let images = props.productDetail?.product_data?.image ? props.productDetail?.product_data?.image.split(',') : [];
      setProductImages(images);
      if (props.productDetail?.product_data?.description) {
        setSelectedTab('Description');
        setProductTabs(product_all_tabs);
      } else {
        setSelectedTab('Details')
        setProductTabs(product_tabs);
      }

      let variety_size = '';
      let size_list = props.productDetail?.product_data?.product_details?.size_list;

      if (size_list?.length > 0) {
        variety_size = size_list?.[0]
        setVarietySizeList(size_list)
        setVarietySize(variety_size);
      }

    }
  }, [props.productDetail]);

  useEffect(() => {
    if(productDetail != null) {
      setLoading(false)
    }
  },[productDetail])

  const onChangeVarietySize = (val) => {
    let list = [...varietySizeList];
    let index = list.findIndex((x) => x.value == val);
    if (index >= 0) {
      setVarietySize(list[index]);
    }
  }

  const onAddToCartClick = () => {
    if (props.userToken && (props?.userStatus == 'active')) {
      props.alertMsgEmpty();

      if (orderQuantity > 0) {
        if (orderQuantity < props.productDetail?.product_data?.minimum_order_quantity) {
          setShowError('MOQ is ' + props.productDetail?.product_data?.minimum_order_quantity)
          return;
        } else {
          setShowError('');
        }

        let data = {
          productUid: props.productDetail?.product_data?.uid,
          categoryUid: props.productDetail?.product_data?.category_uid ? props.productDetail?.product_data?.category_uid : '',
          quantity: productCount,
          varietyUid: props.productDetail?.product_data?.product_details?.variety_uid,
          varietySizeUid: varietySize?.value,
        };
        props.addToCart(JSON.stringify(data));
      } else {
        setShowError('MOQ is ' + props.productDetail?.product_data?.minimum_order_quantity)
      }

    } else {
      props.updateAlertMsg({open: true, message: 'Account not activated / Not Logged', msgType: 'error'});
    }
  };

  const onSelectProductImage = (index) => {
    if (index >= 0) {
      setSelectedProductImage(index)
    }
  };

  const onNextProductImage = (index) => {
    if (index >= 0) {
      setSelectedProductImage(index)
    }
    sliderProductImageRef.current.slickPrev();
  };

  const onPrevProductImage = (index) => {
    if (index >= 0) {
      setSelectedProductImage(index)
    }
    sliderProductImageRef.current.slickNext();
  };

  const onProductClick = (item) => {
    window.location.href = "/product_detail?id=" + item.product_uid
  };

  const onProductDataClick = (item, type) => {
    if (type == 'view') {
      window.location.href = "/product_detail?id=" + item.product_uid
    } else if (type == 'cart') {
      setSelectedProduct(item);
      setShowProductDetailModalVisible(true)
    }
  };

  const onModalCloseClick = () => {
    setShowProductDetailModalVisible(false)
  };

  const onModalAddToCartClick = (data) => {
    props.addToCart(JSON.stringify(data));
  };

  const addSubProduct = (type) => {
    setShowError('');
    let count = productCount;
    if (count != '') {
      if (type == 'add') {
        count = parseInt(count) + 1;
      } else if (type == 'sub') {
        if (count > 1) {
          count = parseInt(count) - 1;
        } else {
          setShowError('Packages Required');
        }

      }
      setProductCount(count);
      setOrderQuantity(count * props.productDetail?.product_data?.package_net_weight)
    }
  };

  const onChangeProductCount = (value) => {
    setProductCount(value)
    setOrderQuantity(value * props.productDetail?.product_data?.package_net_weight)
  };

  const onDownloadClick = (item) => {
    let doc_file = item?.document;
    let doc_file_name = item?.documentName;
    let doc_file_ext = 'pdf';
    let doc_file_split = doc_file?.split('.');
    if (doc_file_split?.length > 0) {
      doc_file_ext = doc_file_split?.[1];
    }
    let file_name = doc_file_name + '.' + doc_file_ext;
    FileSaver.saveAs(item?.displayDocument, file_name);
  };

  const onCompanyClick = (seller_uid) => {
    window.location.href = "/seller/" + seller_uid;
  };

  const navigate = useNavigate();

  if (loading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Container page={"ProductDetail"} title={'Product Details'} subTitle={'Product Details'}>
      <Grid container item md={12} xs={12} display={"flex"} flexDirection={{md: "column", xs: "column"}}
            justifyContent={{md: "center", xs: "none"}} alignItems={{md: "none", xs: "center"}} mt={2}>

        <Grid container item md={10} xs={10} display={"flex"}
              flexDirection={{md: "row", xs: "column"}}>
          <Grid item md={8.5} xs={12} display={"flex"} flexDirection={"column"}
                pr={{md: 2, xs: 0}}
                mt={4}>
            <Grid
              item md={12} xs={12}
              display={"flex"}
              flexDirection={{md: "row", xs: "column"}}
              sx={styles.mainView}>
              <Grid item md={6} xs={12} style={styles.imgMainView}>
                <Grid>
                  <Grid item md={12} xs={12} sx={styles.imgView}>
                    {productDetail?.image ?
                      productImages.length > 0 ?
                        <img src={productImages[selectedProductImage]} style={styles.imgStyle}/>
                        :
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                             style={{height: '100%', width: '100%'}}>
                          <ImgIcon height={50} width={50}/>
                        </Box>
                      : <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                             style={{height: '100%', width: '100%'}}>
                        <ImgIcon height={50} width={50}/>
                      </Box>}
                  </Grid>
                </Grid>

                {productDetail?.image ?
                  productImages.length > 0 ?
                    <Grid container item md={12} xs={12} display={"flex"} flexDirection={"column"}
                          pt={4}>
                      <Box container item md={12} xs={12} id={'prdtImgSlide'}
                           display={{md: "flex", xs: 'none'}}
                           justifyContent={productImages?.length > 4 ? 'space-around' : 'unset'}>
                        <Slider ref={sliderProductImageRef}
                                dots={false}
                                infinite={false}
                                arrows={false}
                                slidesToShow={productImages?.length >= 4 ? 4 : productImages?.length}
                                slidesToScroll={1}
                                slidesPerRow={1}
                                style={{overflow: 'hidden', width: 0, minWidth: '100%'}}>
                          {productImages.map((item, index) => {
                            return (
                              <Box key={index}
                                   onClick={() => onSelectProductImage(index)}>
                                <Grid container item
                                      md={12} xs={12}
                                      display={"flex"} flexDirection={"row"}
                                      alignItems={"center"} justifyContent={"center"}
                                      mr={productImages?.length > 4 ? 0 : 1.5}
                                      sx={[styles.subImageView, selectedProductImage == index ? {border: '1px solid ' + THEME_COLOR} : '']}>
                                  <img src={item} style={styles.imgStyle}/>
                                </Grid>
                              </Box>
                            )
                          })}
                        </Slider>

                      </Box>

                      <Box
                        container item md={12} xs={12}
                        display={"flex"} flexDirection={"row"} alignItems={"center"} pt={2}>
                        <Box
                          onClick={() => (parseInt(selectedProductImage) - 1) >= 0 ? onNextProductImage(parseInt(selectedProductImage) - 1) : null}
                          sx={styles.iconView}>
                          <LeftArrowIcon height={20} width={20}/>
                        </Box>
                        <Box
                          onClick={() => (((parseInt(selectedProductImage) + 1) >= 0) && ((parseInt(selectedProductImage) + 1) <= (parseInt(productImages.length) - 1))) ? onPrevProductImage(parseInt(selectedProductImage) + 1) : null}
                          sx={styles.iconView} ml={1}>
                          <RightArrowIcon height={20} width={20}/>
                        </Box>
                      </Box>
                    </Grid>
                    : <Grid container item md={12} xs={12} display={"flex"} flexDirection={"column"}
                            pt={4}/>
                  : <Grid container item md={12} xs={12} display={"flex"} flexDirection={"column"}
                          pt={4}/>}

              </Grid>

              <Grid item md={5.8} xs={12} mt={{xs: 4, md: 0}} pl={{md: 4, xs: 0}}>
                <Typography sx={styles.productTopDescText}>
                  {(productDetail?.product_details?.variety ? productDetail?.product_details?.variety : 'NIL Variety')
                  + ', ' + (productDetail?.name ? productDetail?.name : '-')
                  + ', ' + (varietySize?.size ? varietySize?.size : 'NIL Size')
                  + ', ' + (productDetail?.packing_details?.package_net_weight ?
                    (productDetail?.packing_details?.package_net_weight + ' ' + productDetail?.packing_details?.packing_material)
                    : 'NIL Packing')
                  + ', ' + (productDetail?.product_details?.pickup_location ? productDetail?.product_details?.pickup_location : 'NIL Location')}
                </Typography>

                <Box style={styles.borderBottomDarkLine} mb={3} mt={4}/>

                <Grid item md={12} xs={12} display={'flex'}
                      flexDirection={'row'} alignItems={'flex-start'}>
                  <Grid item md={2} xs={2}>
                    <Typography
                      sx={styles.priceText}>
                      Price:
                    </Typography>
                  </Grid>

                  <Grid item md={10} xs={10}>
                    <Box display={'flex'} flexDirection={'row'}
                         alignItems={'center'}>
                      <Typography
                        sx={styles.productPrice}>
                        {currencySymbol} {totalPrice?.toFixed(2)}
                      </Typography>

                      <Typography
                        pl={2}
                        sx={styles.exwText}>
                        (EXW)
                      </Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'}
                         alignItems={'center'}>
                      <Typography mt={0.3}
                                  sx={styles.productPrice}>{currencySymbol} {pricePerKg?.toFixed(2)} / {productDetail?.unit}</Typography>

                      <Typography
                        pl={2}
                        sx={styles.exwText}>
                        (EXW)
                      </Typography>
                    </Box>

                    <Typography sx={styles.valueDescText} mt={0.8}>Excluding Shipping, Customs Duty and other
                      charges.</Typography>
                  </Grid>
                </Grid>

                <Box style={styles.borderBottomDarkLine} mb={3} mt={1}/>

                <Grid
                  item md={12} xs={12}
                  display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  {optionList.map((item, index) => {
                    return (
                      <Grid item md={3} xs={12} key={index}
                            display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Box>
                          {item.icon}
                        </Box>
                        <Box mt={1}>
                          <Typography sx={styles.optionTitleText}>{item.title}</Typography>
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>

                <Box style={styles.borderBottomDarkLine} mb={2} mt={3}/>


                <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}
                      mt={1}>
                  <Grid item md={4} xs={12}>
                    <Typography sx={styles.titleText}>Variety : </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography sx={styles.valueText}>{productDetail?.product_details?.variety}</Typography>
                  </Grid>
                </Grid>
                <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}
                      mt={2}>
                  <Grid item md={4} xs={12}>
                    <Typography sx={styles.titleText}>Pickup Location : </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography sx={styles.valueText}>{productDetail?.product_details?.pickup_location}</Typography>
                  </Grid>
                </Grid>
                <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}
                      mt={2}>
                  <Grid item md={4} xs={12}>
                    <Typography sx={styles.titleText}>Size : </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormSelectBox
                      page={'ProductDetail'}
                      value={varietySize != null ? varietySize?.value : ''}
                      setValue={(val) => onChangeVarietySize(val)}
                      data={varietySizeList}
                      placeholder={'Select'}
                      textInputViewStyle={styles.textInputViewStyle}
                      selectedItemViewStyle={styles.selectedItemViewStyle}
                    />
                  </Grid>
                </Grid>
                <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}
                      mt={2}>
                  <Grid item md={4} xs={12}>
                    <Typography sx={styles.titleText}>Packing : </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography sx={styles.valueText}>{productDetail?.packing_details?.packing_material}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item md={3.5} xs={12}
            mt={4}>
            <Grid item md={12} xs={12}
                  display={"flex"} flexDirection={"column"} mt={2}
                  sx={styles.boxView} p={2}
            >
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                {productDetail?.product_details?.country_of_origin ?
                  <>
                    <Typography pr={0.4}>
                      <img
                        loading="lazy"
                        width="20"
                        src={COUNTRY_FLAG_URL + `${productDetail?.product_details?.country_of_origin?.toLowerCase()}.png`}
                      />
                    </Typography>
                    <Typography sx={styles.sellerCountryText}>
                      {COUNTRIES.filter(
                        (x) => x.code == productDetail?.product_details?.country_of_origin
                      )?.[0]?.label}
                    </Typography>
                  </>
                  : null}
              </Box>
              <Box display={'flex'} flexDirection={'row'} mt={1}>
                {productDetail?.business_logo ?
                  <Box style={styles.logoImgView} mr={0.4}>
                    <img src={productDetail?.business_logo} style={styles.logoImgStyle}/>
                  </Box>
                  : null}
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                  <Box>
                    <Typography sx={styles.sellerCompanyNameText}
                                onClick={() => onCompanyClick(productDetail?.seller_uid)}>{productDetail?.name_entity}</Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={1}>
                    <TickIcon height={15} width={15}/>
                    <Typography sx={styles.verifiedProfileText} pl={0.4}>Verified Profile</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}
                  mt={2}
                  sx={styles.addToCartBoxView}>
              <Box p={2}>
                {productDetail?.minimum_order_quantity ?
                  <Grid container item md={12} xs={12} alignItems={"center"}
                        mt={1}>
                    <Grid item md={7} xs={12}>
                      <Typography sx={styles.titleText}>MOQ : </Typography>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Typography
                        sx={styles.valueText}>{productDetail?.minimum_order_quantity} {productDetail?.unit}</Typography>
                    </Grid>
                  </Grid>
                  : null}
                {productDetail?.supply_ability_per_week ?
                  <Grid container item md={12} xs={12} alignItems={"center"}
                        mt={1}>
                    <Grid item md={7} xs={12}>
                      <Typography sx={styles.titleText}>Maximum supply ability/week : </Typography>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Typography sx={styles.valueText}>{productDetail?.supply_ability_per_week}</Typography>
                    </Grid>
                  </Grid>
                  : null}
                <Grid container item md={12} xs={12} alignItems={"center"}>
                  <Grid item md={7} xs={12} mt={1}>
                    <Typography sx={styles.titleText}>
                      Required Packages
                    </Typography>
                  </Grid>
                  <Grid item md={5} xs={12} sx={styles.addToCartView} mt={1}>
                    <Grid item md={4} xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}
                          sx={styles.pointerView} onClick={() => addSubProduct('sub')}>
                      <Typography sx={styles.plusMinusNoText}>-</Typography>
                    </Grid>
                    <Grid item md={4} xs={4} display={"flex"} flexDirection={"row"}>
                      <Grid item md={0.5} xs={4} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                        <Box sx={styles.borderRightLine}/>
                      </Grid>
                      <Grid item md={11} xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <TextField
                          fullWidth
                          variant={"standard"}
                          value={productCount}
                          type={'text'}
                          onChange={e => onChangeProductCount(e.target.value.replace(/[^0-9]/g, ''))}
                          sx={{
                            '& .MuiInput-root': {
                              '&:before, :after, :hover:not(.Mui-disabled):before': {
                                borderBottom: 0,
                              },
                            },
                          }}
                          inputProps={{
                            autoComplete: 'none',
                            sx: styles.textInputValue,
                          }}
                          defaultValue={productCount}
                        />
                      </Grid>
                      <Grid item md={0.5} xs={4} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                        <Box sx={styles.borderLeftLine}/>
                      </Grid>
                    </Grid>
                    <Grid item md={4} xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}
                          sx={styles.pointerView} onClick={() => addSubProduct('add')}>
                      <Typography sx={styles.plusMinusNoText}>+</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item md={12} xs={12} alignItems={"center"}
                      mt={2}>
                  <Grid item md={7} xs={12}>
                    <Typography sx={styles.titleText}>
                      Order Quantity
                    </Typography>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <Typography sx={styles.valueText}>{orderQuantity} {productDetail?.unit}</Typography>
                  </Grid>
                </Grid>
                {showError ?
                  <Box mt={1}>
                    <Typography sx={styles.moqErrorText}>{showError}</Typography>
                  </Box>
                  : null}
              </Box>
              <Grid item md={12} xs={12} mt={2}>
                <FormButton
                  loading={props?.cartBtnLoading}
                  btnStyleView={styles.btnStyleView}
                  bgColorStyleView={THEME_COLOR5}
                  leftIcon={<CartIcon height={18} width={18}/>}
                  title={'ADD TO CART'}
                  onSubmit={onAddToCartClick}/>
              </Grid>
            </Grid>
          </Grid>

          {/*{topSellingProducts?.length > 0 ?*/}
          {/*  <Grid*/}
          {/*    item md={3.5} xs={12}*/}
          {/*    display={"flex"}*/}
          {/*    flexDirection={"column"}*/}
          {/*    mt={4}*/}
          {/*    sx={styles.topSellingProductsMainView}>*/}
          {/*    <Grid item md={12} xs={12} display={"flex"} flexDirection={"column"} mt={2}>*/}
          {/*      <Box mb={3}>*/}
          {/*        <Grid item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}>*/}
          {/*          <Grid item md={1} xs={1}>*/}
          {/*            <Box sx={styles.sellingLineView}/>*/}
          {/*          </Grid>*/}
          {/*          <Grid item md={0.3} xs={0.3} pl={0.5}>*/}
          {/*            <Box sx={styles.sellingLineView}/>*/}
          {/*          </Grid>*/}
          {/*          <Grid item md={8} xs={8} display={"flex"} pl={2}>*/}
          {/*            <Typography sx={styles.topSellingProducts}>Top Selling Products</Typography>*/}
          {/*          </Grid>*/}
          {/*        </Grid>*/}
          {/*      </Box>*/}
          {/*      {topSellingProducts?.map((item, index) => {*/}
          {/*        return (*/}
          {/*          <Grid key={index}>*/}
          {/*            <ProductItemList*/}
          {/*              data={item}*/}
          {/*              index={index}*/}
          {/*              length={topSellingProducts.length - 1}*/}
          {/*              onProduct={onProductClick}/>*/}
          {/*          </Grid>*/}
          {/*        )*/}
          {/*      })}*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*  : null}*/}

          <Grid item md={12} xs={12} mt={{xs: 4, md: 6}}
                display={"flex"} flexDirection={"row"}>
            <Grid item md={12} xs={12} sx={{height: {md: 100, xs: 'unset'}}}>
              <StyledTabs
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile={true}
                orientation="horizontal"
                value={selectedTab}>
                {productTabs?.map((x, i) => {
                  return (
                    <StyledTab
                      key={i}
                      value={x}
                      label={x}
                      onClick={() => setSelectedTab(x)}
                    />
                  )
                })}
              </StyledTabs>
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} mt={{md: productImages.length > 0 ? -4 : 5, xs: 2}}>
            {selectedTab == 'Description' ?
              <Box sx={styles.detailDescView}>
                {productDetail?.description ?
                  <Typography
                    sx={[styles.titleText, {textAlign: 'justify'}]}>{productDetail?.description}</Typography>
                  : null}
              </Box>
              : (selectedTab == 'Details' ?
                <Box sx={styles.detailView}>
                  {productDetail?.product_details ?
                    Object.keys(productDetail?.product_details).map((key, index) => {
                      return (
                        key != 'variety_uid' ?
                          <Grid container item md={12} xs={12}
                                sx={styles.labelValueView}
                          >
                            <Grid item md={4} xs={12}
                                  sx={styles.columnLabelView}>
                              <Typography
                                sx={[styles.titleText, styles.textCaseText]}>
                                {key == 'size_list' ?
                                  'Product Size Chart' :
                                  key.replaceAll("_", " ")
                                }
                              </Typography>
                            </Grid>
                            <Grid item md={8} xs={12} sx={styles.columnValueView}>
                              <Typography
                                sx={styles.valueText}>
                                {key == 'size_list' ?
                                  productDetail?.product_details[key] ? productDetail?.product_details[key]?.map((item) => item?.size).join(', ') : 'NIL'
                                  :
                                  productDetail?.product_details[key] ? productDetail?.product_details[key] : 'NIL'}
                              </Typography>
                            </Grid>
                          </Grid>
                          :
                          null
                      )
                    }) : null}
                </Box>
                : (selectedTab == 'Packing Details' ?
                  <Box sx={styles.detailView}>
                    {productDetail?.packing_details ?
                      Object.keys(productDetail?.packing_details).map((key, index) => {
                        return (
                          <Grid container item md={12} xs={12} sx={styles.labelValueView}>
                            <Grid item md={4} xs={12}
                                  sx={styles.columnLabelView}>
                              <Typography
                                sx={[styles.titleText, styles.textCaseText]}>{key.replaceAll("_", " ")} </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}
                                  sx={styles.columnValueView}>
                              {key == 'package_volume' ?
                                productDetail?.packing_details[key] ?
                                  <Typography
                                    sx={styles.valueText}>
                                    {productDetail?.packing_details[key] / 1000000} m<sup>3</sup>
                                  </Typography>
                                  :
                                  <Typography
                                    sx={styles.valueText}>
                                    NIL
                                  </Typography>
                                :
                                <Typography
                                  sx={styles.valueText}>{productDetail?.packing_details[key] ? productDetail?.packing_details[key] : 'NIL'}</Typography>
                              }
                            </Grid>
                          </Grid>
                        )
                      }) : null}
                  </Box>
                  :
                  (selectedTab == 'Shipping Details' ?
                      <Box sx={styles.detailView}>
                        {productDetail?.shipping_details ?
                          Object.keys(productDetail?.shipping_details).map((key, index) => {
                            return (
                              <Grid container item md={12} xs={12} sx={styles.labelValueView}>
                                <Grid item md={4} xs={12}
                                      sx={styles.columnLabelView}>
                                  <Typography
                                    sx={[styles.titleText, styles.textCaseText]}>{key.replaceAll("_", " ")} </Typography>
                                </Grid>
                                {key != 'payment_options' ?
                                  <Grid item md={8} xs={12}
                                        sx={styles.columnValueView}>
                                    <Typography
                                      sx={styles.valueText}>{productDetail?.shipping_details[key] ? productDetail?.shipping_details[key] : 'NIL'}</Typography>
                                  </Grid>
                                  :
                                  <Grid item md={8} xs={12} sx={styles.columnValueView}>
                                    {productDetail?.shipping_details[key]?.length > 0 ?
                                      productDetail?.shipping_details[key].map((item, index) => {
                                        return (
                                          <Box key={index} mt={1}>
                                            <Typography
                                              sx={styles.valueText}>{item?.term}</Typography>
                                            <Typography
                                              sx={styles.paymentDescText}>({item?.description})</Typography>
                                          </Box>
                                        )
                                      }) : null}
                                  </Grid>}
                              </Grid>
                            )
                          }) : null}
                      </Box>
                      : (selectedTab == 'Packing Description' ?
                        <Box sx={styles.detailDescView}>
                          {productDetail?.packing_description ?
                            <Typography
                              sx={[styles.titleText, styles.textCaseText, {textAlign: 'justify'}]}>{productDetail?.packing_description}</Typography>
                            : null}
                        </Box>
                        : (selectedTab == 'Product Documents' ?
                          <>
                            {productDetail?.product_documents?.length > 0 ?
                              <Box sx={styles.detailView}>
                                {productDetail?.product_documents?.map((item, index) => {
                                  return (
                                    <Grid container item md={12} xs={12} sx={styles.labelValueView}>
                                      <Grid item md={4} xs={12}
                                            sx={styles.columnLabelView}>
                                        <Typography
                                          sx={[styles.titleText, styles.textCaseText]}>{item['documentName']} </Typography>
                                      </Grid>
                                      <Grid item md={8} xs={12}
                                            sx={styles.columnValueView}>
                                        {item['displayDocument'] ?
                                          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <a href={item['displayDocument']} target="_blank"
                                               rel="noreferrer"
                                               style={styles.exporterDocumentViewText}>
                                              View
                                            </a>

                                            <Box pl={2}>
                                              <Typography
                                                onClick={() => onDownloadClick(item)}
                                                style={styles.exporterDocumentViewText}>
                                                Download
                                              </Typography>
                                            </Box>
                                          </Box>
                                          : null}
                                      </Grid>
                                    </Grid>
                                  )
                                })}
                              </Box>
                              : null}
                          </>
                          :
                          (selectedTab == 'Exporter Details' ?
                            <Box sx={styles.detailView}>
                              {productDetail?.exporter_details ?
                                Object.keys(productDetail?.exporter_details).map((key, index) => {
                                  return (
                                    <Grid container item md={12} xs={12} sx={styles.labelValueView}>
                                      <Grid item md={4} xs={12}
                                            sx={styles.columnLabelView}>
                                        <Typography
                                          sx={[styles.titleText, styles.textCaseText]}>{key.replaceAll("_", " ")} </Typography>
                                      </Grid>
                                      <Grid item md={8} xs={12}
                                            sx={styles.columnValueView}>
                                        {key != 'rcmc_registration_document' ?
                                          productDetail?.exporter_details[key] ?
                                            <a href={productDetail?.exporter_details[key]} target="_blank"
                                               rel="noreferrer"
                                               style={styles.exporterDocumentViewText}>
                                              View
                                            </a>
                                            : null
                                          :
                                          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                            {productDetail?.exporter_details[key]?.map((item, index) => {
                                              return (
                                                <Box key={index} display={'flex'} flexDirection={'row'}
                                                     alignItems={'center'} mt={1}>
                                                  <Grid item md={3} xs={3}>
                                                    <Typography sx={styles.rcmcNoText}
                                                                pr={2}>{item?.rcmc_no}</Typography>
                                                  </Grid>
                                                  <Grid item md={7} xs={7}>
                                                    <a href={item?.rcmc_registration_document} target="_blank"
                                                       rel="noreferrer"
                                                       style={styles.exporterDocumentViewText}>
                                                      View
                                                    </a>
                                                  </Grid>
                                                </Box>
                                              )
                                            })}
                                          </Box>
                                        }
                                      </Grid>
                                    </Grid>
                                  )
                                }) : null}
                            </Box>
                            : null)))
                  )))}
          </Grid>
        </Grid>

        {relatedProducts?.length > 0 ?
          <Grid container item md={12} xs={12} display={"flex"} flexDirection={"column"}
                alignItems={"center"} mt={10}>
            <Grid container item md={10} xs={10} display={"flex"}>
              <Typography sx={styles.relatedProductCategoryText}>// {productDetail?.category}</Typography>
            </Grid>
            <Grid container item md={10} xs={10} display={"flex"}>
              <Typography sx={styles.relatedProductText}>Related Products</Typography>
            </Grid>

            <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"}
                  mt={2} justifyContent={'center'}
                  className={'relatedProductList'}>

              <Box sx={styles.sellingProductArrowIconMainView} className={'productListArrowIcon'}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  variants={{
                    visible: {x: -20, opacity: 1, scale: 1, transition: {duration: 0.3, ease: "easeInOut"}},
                    hidden: {x: 70, opacity: 0, scale: 1},
                  }}>
                  <Box
                    display={{xs: 'none', md: 'flex'}}
                    onClick={() => sliderRef.current.slickPrev()}
                    sx={[styles.sellingProductArrowIconView, {
                      ":hover": {
                        path: {
                          fill: "#FFF",
                        }
                      }
                    }]}
                    className={'productListArrowIconBox'}>
                    <LeftArrowIcon height={18} width={18}/>
                  </Box>
                </motion.div>
              </Box>

              <Grid container item md={10} xs={10}
                    sx={{width: {md: 308, xs: 'unset'}}}>
                <Slider ref={sliderRef}
                        dots={false}
                        infinite={true}
                        arrows={false}
                        slidesToShow={relatedProducts?.length >= 4 ? 4 : relatedProducts?.length}
                        slidesToScroll={1}
                        slidesPerRow={1}
                        responsive={
                          [
                            {
                              breakpoint: 480,
                              settings: {
                                dots: true,
                                arrows: false,
                                slidesToShow: relatedProducts?.length >= 2 ? 2 : relatedProducts?.length,
                                slidesToScroll: 1
                              }
                            }
                          ]
                        }
                        style={{overflow: 'hidden', width: 0, minWidth: '100%'}}>
                  {relatedProducts?.map((item, index) => {
                    return (
                      <Grid key={index} item md={11} xs={11.6} mt={2}>
                        <ItemProductList
                          type={'relatedProducts'}
                          data={item}
                          // onProduct={onProductClick}
                          onProduct={() => null}
                          currencyRateList={props?.currencyRateList}
                          onSubmit={onProductDataClick}/>
                      </Grid>
                    )
                  })}
                </Slider>
              </Grid>

              <Box sx={styles.sellingProductArrowIconMainView} className={'productListArrowIcon'}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  variants={{
                    visible: {x: 20, opacity: 1, scale: 1, transition: {duration: 0.3, ease: "easeInOut"}},
                    hidden: {x: -70, opacity: 0, scale: 1},
                  }}>
                  <Box
                    onClick={() => sliderRef.current.slickNext()}
                    display={{xs: 'none', md: 'flex'}}
                    sx={[styles.sellingProductArrowIconView, {
                      ":hover": {
                        path: {
                          fill: "#FFF",
                        }
                      }
                    }]}
                    className={'productListArrowIconBox'}>
                    <RightArrowIcon height={18} width={18}/>
                  </Box>
                </motion.div>
              </Box>

            </Grid>
          </Grid>
          : null}
      </Grid>

      {selectedProduct != null ?
        <ProductDetailModal
          modalVisible={productDetailModalVisible}
          data={selectedProduct}
          currencyRateList={props?.currencyRateList}
          onClose={onModalCloseClick}
          onAddToCart={onModalAddToCartClick}/>
        : null}

      <AlertMsg/>
    </Container>
  )
};


const mapStateToProps = (state) => {
  return {
    userToken: state.userData.userToken,
    userStatus: state.userData.userStatus,
    productDetail: state.userData.productDetail,
    cartButtonText: state.userData.cartButtonText,
    cartBtnLoading: state.userData.cartBtnLoading,
    exportCountry: state.userData.exportCountry,
    cart: state.userData.cart,
    currencyRateList: state.userData.currencyRateList,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getProductDetail: data => dispatch(getProductDetail(data)),
    addToCart: data => dispatch(addToCart(data)),
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
    alertMsgEmpty: data => dispatch(alertMsgEmpty(data)),
    updateCartBtnText: data => dispatch(updateCartBtnText(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);

const styles = {
  mainView: {
    // minHeight: window.innerHeight - 200,
  },
  imgMainView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgView: {
    backgroundColor: '#fafafa',
    height: {md: 400, xs: 300},
    width: {md: 400, xs: 300},
    padding: 1,
  },
  imgStyle: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  },
  subImageView: {
    cursor: 'pointer',
    backgroundColor: '#fafafa',
    height: 100,
    width: 100,
  },
  iconView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #DDD',
    backgroundColor: '#FFF',
    padding: 0.5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: THEME_COLOR,
      path: {
        fill: '#FFF',
      },
    },
  },
  productName: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 20
  },
  productTopDescText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 16
  },
  productPrice: {
    color: THEME_COLOR4,
    fontFamily: 'InterMedium',
    fontSize: 15
  },
  exwText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 12
  },
  borderBottomLine: {
    borderBottom: '1px solid #DDD'
  },
  detailDescView: {
    paddingTop: 2
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  detailView: {
    border: '1px solid #aaa',
  },
  titleText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: {md: 14, xs: 13},
  },
  textCaseText: {
    textTransform: 'capitalize'
  },
  valueText: {
    color: '#000',
    fontFamily: 'InterSemiBold',
    fontSize: {md: 13, xs: 13}
  },
  priceText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15
  },
  valueDescText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 12
  },
  optionTitleText: {
    textAlign: 'center',
    color: THEME_COLOR3,
    fontFamily: 'InterRegular',
    fontSize: 12,
  },
  exporterDocumentViewText: {
    color: THEME_COLOR3,
    fontFamily: 'InterSemiBold',
    fontSize: 14,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  rcmcNoText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14
  },
  paymentDescText: {
    color: 'red',
    fontFamily: 'InterRegular',
    fontSize: {md: 11, xs: 11},
  },
  addToCartView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    border: '1px solid #DDD',
    height: {md: 50, xs: 40},
  },
  pointerView: {
    cursor: 'pointer'
  },
  plusMinusNoText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: {md: 20, xs: 17}
  },
  borderRightLine: {
    borderRight: '1px solid #DDD',
    height: {md: 50, xs: 40},
  },
  borderLeftLine: {
    borderLeft: '1px solid #DDD',
    height: {md: 50, xs: 40},
  },
  textInputValue: {
    color: '#000',
    fontFamily: 'InterRegular',
    fontSize: 14,
    textAlign: 'center'
  },
  btnStyleView: {
    fontSize: {md: 12, xs: 11},
    height: {md: 50, xs: 40},
    width: '100%',
    backgroundColor: THEME_COLOR5,
    // marginLeft: {md: 2, xs: 0}
  },
  moqErrorText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 12,
  },
  borderBottomDarkLine: {
    borderBottom: '1px solid #AAA'
  },
  shareText: {
    color: '#555',
    fontFamily: 'InterRegular',
    fontSize: {md: 17, xs: 15}
  },
  guaranteedSafeCheckoutText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: {md: 17, xs: 15}
  },
  titleBgColor: {
    backgroundColor: THEME_COLOR2,
  },
  labelValueView: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
  },
  columnLabelView: {
    backgroundColor: THEME_COLOR2,
    borderBottom: '1px solid #aaa',
    borderRight: '1px solid #aaa',
    padding: 1.5
  },
  columnValueView: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #aaa',
    padding: 1.5
  },
  relatedProductCategoryText: {
    textTransform: 'uppercase',
    color: THEME_COLOR,
    fontFamily: 'InterBold',
    fontSize: 15
  },
  relatedProductText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 40
  },
  topSellingProductsMainView: {
    border: '1px solid #DDD',
    padding: 2,
    height: 'fit-content'
  },
  sellingLineView: {
    borderBottom: '4px solid ' + THEME_COLOR,
    width: '100%',
  },
  topSellingProducts: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 15,
  },
  sellingProductArrowIconMainView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sellingProductArrowIconView: {
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: {md: 24, xs: 10},
    height: {md: 48, xs: 20},
    width: {md: 48, xs: 20},
    padding: {md: 0, xs: 1},
    boxShadow: '0 3px 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    ":hover": {
      path: {
        stroke: "#FFF",
      }
    }
  },
  cardDiv: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFF',
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 20
  },
  boxView: {
    backgroundColor: THEME_COLOR2,
    borderRadius: 4,
    boxShadow: '0px 2px 3px #aaa',
  },
  sellerCountryText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
  logoImgView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    overflow: 'hidden',
    border: '1px solid #aaa',
    borderRadius: 100,
    height: 25,
    width: 25,
  },
  logoImgStyle: {
    height: '45%',
    width: '45%',
  },
  sellerCompanyNameText: {
    color: THEME_COLOR3,
    fontFamily: 'InterBold',
    fontSize: 15,
    textTransform: 'capitalize',
    cursor: 'pointer'
  },
  verifiedProfileText: {
    color: THEME_COLOR,
    fontFamily: 'InterMedium',
    fontSize: 13,
  },
  addToCartBoxView: {
    border: '1px solid ' + THEME_COLOR5,
    borderRadius: 4,
    boxShadow: '0px 2px 3px #aaa',
    overflow: 'hidden',
  }
};

