import {AUTHENTICATION_FAILED, ENV} from '../constants';
import axios from 'axios';

import {store} from '../redux/store';
import {logOut} from "../redux/actions/userDataActions";

let apiUrl = '';

if (ENV === 'PROD') {
  apiUrl = 'https://api.trebbs.com/user/';
} else {
  apiUrl = 'http://localhost:8080/user/';
}

function userToken() {
  const state = store.getState();
  let token = state.userData.userToken;
  return token;
}

export async function getHomePage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  if (token) {
    config = {
      ...config,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  }

  return callApi('get_home_page', config);
}

export async function userLogin(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('login', config);
}

export async function getRegisterData() {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_register_data', config);
}

export async function userRegister(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('register', config);
}

export async function getStatesList(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_states_list', config);
}


export async function getCitiesList(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_cities_list', config);
}

export async function getProductList(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  if (token) {
    config = {
      ...config,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  }

  return callApi('get_product_list', config);
}

export async function getProductDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  if (token) {
    config = {
      ...config,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  }

  return callApi('get_product_detail', config);
}

export async function getCategoryList(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_category_list', config);
}

export async function addToCart(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_to_cart', config);
}

export async function getCartList() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_cart_list', config);
}

export async function updateCart(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_cart', config);
}

export async function deleteCart(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_cart', config);
}

export async function addDeliveryAddress(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_delivery_address', config);
}

export async function getReviewData(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_review_data', config);
}

export async function getCartDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_cart_detail', config);
}

export async function updateCartDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_cart_detail', config);
}

export async function getCartCount() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_cart_count', config);
}

export async function sendMail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('send_mail', config);
}

export async function mailVerification(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('mail_verification', config);
}

export async function getUserDetail() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_user_detail', config);
}

export async function getUserMailVerify() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_user_mail_verify', config);
}

export async function addUserContactDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_user_contact_detail', config);
}

export async function updateUserContactDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_user_contact_detail', config);
}

export async function addUserImportLicenseDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_user_import_license_detail', config);
}

export async function updateUserImportLicenseDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_user_import_license_detail', config);
}

export async function addUserDocument(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_user_document', config);
}

export async function updateUserDocument(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_user_document', config);
}

export async function addUserProductCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_user_product_category', config);
}




export async function getContactAddress() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_contact_address', config);
}

export async function updateUserName(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_username', config);
}

export async function updatePassword(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_password', config);
}

export async function placeOrder(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('place_order', config);
}

export async function getExportCountryList(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_export_country_list', config);
}

export async function clearCart(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('clear_cart', config);
}

export async function getCheckout(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_cart_list', config);
}

export async function listOrder(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return callApi('get_orders_list ', config);
}

export async function getOrderDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_order_detail', config);
}

export async function getAdminBeneficiaryDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_admin_beneficiary_detail', config);
}

export async function uploadPaymentDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('upload_payment_detail', config);
}

export async function cancelOrder(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('cancel_order', config);
}

export async function getOrderCount() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_order_count', config);
}

export async function getCurrencyRateList() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    config = {
      ...config,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  }

  return callApi('get_currency_rate_list', config);
}

export async function getLoginUserDetail() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_login_user_detail', config);
}

export async function getCategoryProductCount(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_category_product_count', config);
}

export async function forgotUserName(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('forgot_user_name', config);
}

export async function forgotUserPassword(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('forgot_user_password', config);
}

export async function updateForgotUserPassword(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_forgot_user_password', config);
}

export async function getForgotUserPassword(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_forgot_user_password', config);
}
/**
 * Private functions
 */
async function callApi(endpoint, config) {
  const url = `${apiUrl}${endpoint}`;

  console.log('Url: ', url);
  console.log('Config: ', config);

  return axios({
    ...config,
    url: url,
  })
    .then(async (response) => {
      console.log('-----API RESPONSE-----', response.data);
      if (response.data.msg === AUTHENTICATION_FAILED) {
        await store.dispatch(logOut(''));
        return {status: 'Failed', msg: response.data.msg};
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
      console.log(
        'There has been a problem with your fetch operation: ' + error.message,
      );
      if (error?.message) {
        return {
          status: 'Failed',
          msg: error.message,
        };
      }
      return {
        status: 'Failed',
        msg: 'Api Error',
      };
    });
}
