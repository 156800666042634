import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import moment from "moment";

import styles from './OrderPaymentList.module.css'

const OrderPaymentList = (props) => {

  const onView = (item) => {
    window.open(item?.payment_receipt_file, '_blank')
  }

  return (
    <Grid container
          item md={11.6} xs={11.6}
          display={'flex'} flexDirection={'column'}>

      <Grid item md={6} xs={12}
            container mb={3}>
        <Typography className={styles.mainLabelText}>{props?.pageType == 'payment' ? 'Advance Payment Details' :
          'Payment Details'}</Typography>
      </Grid>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={styles.labelText}>Transaction ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Date of Payment</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Amount Paid</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Receipt</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data?.length > 0 ?
              props?.data.map((item, index) => (
                <TableRow
                  key={index}>
                  <TableCell>{item.transaction_id}</TableCell>
                  <TableCell>{moment(item.payment_date).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>
                    {props?.sellerCurrencySymbol}{item.amount} ({props?.currencySymbol}{item.converted_amount})
                  </TableCell>
                  <TableCell>
                    <Typography onClick={() => onView(item)} className={styles.viewText}>View</Typography>
                  </TableCell>
                </TableRow>
              )) : null}
          </TableBody>
        </Table>
      </TableContainer>

    </Grid>
  );
}

export default OrderPaymentList;
