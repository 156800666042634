import React, {useEffect, useState} from 'react'
import {
  Grid, Typography, Box,
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import AlertDialogBox from "../../components/AlertDialogBox";
import SendMailModal from "../../components/SendMailModal";

import {connect} from "react-redux";
import {loginUser, forgotUserName, forgotUserPassword, updateApiStatus} from "../../redux/actions/userDataActions";

import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";

import {THEME_COLOR3} from '../../constants'

import {ReactComponent as EyeVisibleIcon} from "../../assets/img/eye.svg";
import {ReactComponent as EyeHiddenIcon} from "../../assets/img/eye_slash.svg";

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showMailModalVisible, setShowMailModalVisible] = useState(false);
  const [showPopupVisible, setShowPopupVisible] = useState(false);
  const [forgotType, setForgotType] = useState('');
  const [error, setError] = useState(null);
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == 'FORGOT_USER_NAME_SUCCESS'
    || props.apiStatus == 'FORGOT_USER_PASSWORD_SUCCESS') {
      setShowMailModalVisible(false)
      setShowPopupVisible(true)
      props.updateApiStatus('')
    }
  }, [props.apiStatus]);

  const onSignInClick = () => {
    let errorText = {};
    if (username == '') {
      errorText = {username: 'Username is required'};
    }
    if (password == '') {
      errorText = {...errorText, password: 'Password is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      username: username,
      password: password,
    };
    props.loginUser(JSON.stringify(data));
  };

  const onCreateAccountClick = () => {
    navigate('/register')
  };

  const onForgotUsername = () => {
    setForgotType('username')
    setShowMailModalVisible(true)
  };

  const onSendMail = (data) => {
    if (forgotType == 'username') {
      props?.forgotUserName(JSON.stringify(data));
    } else if (forgotType == 'password') {
      props?.forgotUserPassword(JSON.stringify(data))
    }
  };

  const onCancelMail = () => {
    setForgotType('')
    setShowMailModalVisible(false)
  };

  const onForgotPassword = () => {
    setForgotType('password')
    setShowMailModalVisible(true)
  };

  const onPopupConfirmClick = () => {
    setShowPopupVisible(false)
    setForgotType('')
  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false);
    } else {
      setPasswordEyeVisible(true);
    }
  };

  return (
    <Container page={"Login"} title={'My Account'} subTitle={'Login'} showHeaderData={'false'}>
      <Grid container item md={12} xs={12} display={"flex"} flexDirection={"column"}
            alignItems={"center"} justifyContent={"center"} mt={10}>
        <Typography sx={styles.loginText}>Sign In To Your Account</Typography>
        <Grid container item md={8.5} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}
              justifyContent={{md: "space-between", xs: "center"}} mb={4}>

          <Grid container
                item md={5.2} xs={10}
                display={"flex"}
                direction="column"
                alignItems={"center"}
                justifyContent={"center"}
                mt={4}>

            <Grid item md={12} xs={12} mt={3}
                  container display={'flex'} flexDirection={'row'} alignItems={'center'}
                  justifyContent={'space-between'}>
              <Grid display={"flex"}
                    flexDirection={"row"} item md={6} xs={6}>
                <Typography sx={styles.labelText}>Username</Typography>
                <Box pl={0.2}>
                  <Typography sx={styles.requiredText}>*</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={6} display={'flex'} justifyContent={'flex-end'}
                    onClick={() => onForgotUsername()}>
                <Typography style={styles.forgotText}>Forgot Username</Typography>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} mt={0.2}
                  container>
              <FormTextInput
                page={'Login'}
                value={username}
                setValue={setUsername}
                error={error?.username}
                textInputViewStyle={styles.textInputViewStyle}
                required={true}
              />
            </Grid>

            <Grid item md={12} xs={12} mt={3}
                  container display={'flex'} flexDirection={'row'} alignItems={'center'}
                  justifyContent={'space-between'}>
              <Grid display={"flex"}
                    flexDirection={"row"} item md={6} xs={6}>
                <Typography sx={styles.labelText}>Password</Typography>
                <Box pl={0.2}>
                  <Typography sx={styles.requiredText}>*</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={6} display={'flex'} justifyContent={'flex-end'}
                    onClick={() => onForgotPassword()}>
                <Typography style={styles.forgotText}>Forgot Password</Typography>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} mt={0.2}
                  container>
              <FormTextInput
                page={'Login'}
                value={password}
                setValue={setPassword}
                error={error?.password}
                textInputViewStyle={styles.textInputViewStyle}
                required={true}
                type={passwordEyeVisible ? 'text' : 'password'}
                icon={true}
                rightIcon={passwordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> : <EyeHiddenIcon height={15} width={15}/>}
                onIconClick={onPasswordEyeIconClick}
              />
            </Grid>

            <Grid item md={12} xs={12} container
                  display="flex" flexDirection="row" justifyContent="center" mt={4}>
              <FormButton
                loading={props.loginLoading}
                title={'SIGN IN'}
                btnStyleView={styles.btnStyleView}
                onSubmit={onSignInClick}
              />
            </Grid>

          </Grid>

          <Grid container
                item md={5.2} xs={10}
                display={"flex"}
                direction="column"
                alignItems={"center"}
                justifyContent={"center"}
                mt={4}>
            <Typography sx={styles.accountText}>DON'T HAVE AN ACCOUNT?</Typography>
            <Typography sx={styles.accountDescText} mt={2} mb={3}>Add items to your cart get personalised
              recommendations
              check out more quickly track your orders register</Typography>
            <FormButton
              title={'CREATE ACCOUNT'}
              btnStyleView={styles.accBtnStyleView}
              onSubmit={onCreateAccountClick}
            />
          </Grid>

        </Grid>
      </Grid>

      <AlertDialogBox
        confirmBox={showPopupVisible}
        title={forgotType == 'username' ? 'Trebbs Buyer - Forgot Username' : (forgotType == 'password' ? 'Trebbs Buyer - Forgot Password' : '')}
        content={'Mail Sent. Please check your mail'}
        firstBtn={'Ok'}
        padding={3}
        height={'30%'}
        width={'30%'}
        mt={1}
        alignItems={'unset'}
        onConfirmClick={onPopupConfirmClick}
        onCancelClick={onPopupConfirmClick}
      />

      <SendMailModal
        type={forgotType}
        btnLoading={props?.btnLoading}
        open={showMailModalVisible}
        onCancel={onCancelMail}
        onSubmit={onSendMail}/>

      <AlertMsg/>
    </Container>
  )
};


const mapStateToProps = (state) => {
  return {
    loginLoading: state.userData.loginLoading,
    apiStatus: state.userData.apiStatus,
    btnLoading: state.userData.btnLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: data => dispatch(loginUser(data)),
    forgotUserName: data => dispatch(forgotUserName(data)),
    forgotUserPassword: data => dispatch(forgotUserPassword(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const styles = {
  loginText: {
    textAlign: 'center',
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 35
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e4ecf2',
  },
  labelText: {
    fontSize: 13,
    fontFamily: 'InterMedium',
    color: '#000'
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  forgotText: {
    fontSize: 13,
    fontFamily: 'InterMedium',
    color: THEME_COLOR3,
    cursor: 'pointer'
  },
  btnStyleView: {
    fontSize: 14,
    fontFamily: 'InterMedium',
    width: '100%',
    height: 50,
  },
  accountText: {
    textAlign: 'center',
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 18
  },
  accountDescText: {
    textAlign: 'center',
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15,
    lineHeight: 1.7
  },
  accBtnStyleView: {
    fontSize: 14,
    width: '50%',
    height: 50,
  }
};

