import {COUNTRIES} from "../../constants/countries";

const initialState = {
  listLoading: false,
  detailLoading: false,
  homeData: null,
  getRegisterDataList: null,
  registerLoading: false,
  // register: false,
  tgbin: '',
  showTgbin: false,
  statesList: [],
  citiesList: [],
  stateLoading: false,
  cityLoading: false,
  userName: '',
  userToken: '',
  userContactName: '',
  userEmail: '',
  userCountry: '',
  userNameEntity: '',
  userStatus: 'pending',
  userPassword: '',
  isUserVerified: 0,
  productDetail: null,
  product: [],
  productLoading: false,
  productTotalPages: 0,
  productTotalCount: 0,
  categoryList: [],
  cartButtonText: 'ADD TO CART',
  cartBtnLoading: false,
  confirmBtnLoading: false,
  btnLoading: false,
  cart: [],
  reviewDataList: null,
  cartDetail: null,
  userDetail: null,
  userLoading: false,
  contactAddress: null,
  placeOrderLoading: false,
  exportCountryList: [],
  exportCountry: null,
  cartExportCountry: null,
  order: [],
  orderTotalPages: 0,
  orderDetail: null,
  modalLoading: false,
  modalBtnLoading: false,
  accountLoading: false,
  cartLoading: false,
  loginLoading: false,
  deliveryAddressId: '',
  checkoutData: [],
  checkoutDetail: [],
  checkoutLoading: false,
  pendingOrderCount: 0,
  unshippedOrderCount: 0,
  shippedOrderCount: 0,
  deliveredOrderCount: 0,
  cancelledOrderCount: 0,
  orderLoading: false,
  categoryProductCountList: [],
  adminBeneficiaryDetail: null,
  orderMessage: null,
  cartMessage: null,
  currencyRateList: null,
  msg: {open: false, message: '', msgType: ''},
  apiStatus: ""
};

const userDataReducer = (state = initialState, action) => {

  switch (action.type) {

    case 'GET_HOME_PAGE':
      return {
        ...state,
        listLoading: true,
        loginLoading: false,
        placeOrderLoading: false,
        homeData: null,
        productDetail: null,
        apiStatus: 'GET_HOME_PAGE'
      };

    case 'GET_HOME_PAGE_SUCCESS':
      return {
        ...state,
        homeData: action.payload.data,
        listLoading: false,
        apiStatus: 'GET_HOME_PAGE_SUCCESS'
      };

    case 'GET_HOME_PAGE_FAIL':
      return {
        ...state,
        listLoading: false,
        apiStatus: 'GET_HOME_PAGE_FAIL'
      };

    //login
    case 'LOGIN_USER':
      return {
        ...state,
        loginLoading: true,
      };

    case 'LOGIN_USER_SUCCESS':
      return {
        ...state,
        userName: action.payload.userName,
        userToken: action.payload.userToken,
        userEmail: action.payload.userData.userEmail,
        userContactName: action.payload.userData.userContactName,
        isUserVerified: action.payload.userData.isUserVerified,
        userCountry: action.payload.userData.userCountry,
        userNameEntity: action.payload.userData.userNameEntity,
        userStatus: action.payload.userData.userStatus,
        tgbin: action.payload.userData.tgbin,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        loginLoading: false,
      };

    case 'LOGIN_USER_FAIL':
      return {
        ...state,
        loginLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    case 'FORGOT_USER_NAME':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'FORGOT_USER_NAME'
      };

    case 'FORGOT_USER_NAME_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        apiStatus: 'FORGOT_USER_NAME_SUCCESS'
      };

    case 'FORGOT_USER_NAME_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'FORGOT_USER_NAME_FAIL'
      };

    case 'FORGOT_USER_PASSWORD':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'FORGOT_USER_PASSWORD'
      };

    case 'FORGOT_USER_PASSWORD_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        apiStatus: 'FORGOT_USER_PASSWORD_SUCCESS'
      };

    case 'FORGOT_USER_PASSWORD_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'FORGOT_USER_PASSWORD_FAIL'
      };

    case 'UPDATE_FORGOT_USER_PASSWORD':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'UPDATE_FORGOT_USER_PASSWORD'
      };

    case 'UPDATE_FORGOT_USER_PASSWORD_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        apiStatus: 'UPDATE_FORGOT_USER_PASSWORD_SUCCESS'
      };

    case 'UPDATE_FORGOT_USER_PASSWORD_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'UPDATE_FORGOT_USER_PASSWORD_FAIL'
      };

    case 'GET_FORGOT_USER_PASSWORD':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'GET_FORGOT_USER_PASSWORD'
      };

    case 'GET_FORGOT_USER_PASSWORD_SUCCESS':
      return {
        ...state,
        listLoading: false,
        apiStatus: 'GET_FORGOT_USER_PASSWORD_SUCCESS'
      };

    case 'GET_FORGOT_USER_PASSWORD_FAIL':
      return {
        ...state,
        listLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_FORGOT_USER_PASSWORD_FAIL'
      };

    //register
    case 'GET_REGISTER_DATA':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_REGISTER_DATA_SUCCESS':
      return {
        ...state,
        getRegisterDataList: action.payload.data,
        listLoading: false,
      };

    case 'GET_REGISTER_DATA_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'REGISTER_USER':
      return {
        ...state,
        registerLoading: true,
        // register: false,
        apiStatus: 'REGISTER_USER',
      };

    case 'REGISTER_USER_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        registerLoading: false,
        userName: action.payload.data.userName,
        userEmail: action.payload.data.userEmail,
        userToken: action.payload.data.userToken,
        userCountry: action.payload.data.userCountry,
        userNameEntity: action.payload.data.userNameEntity,
        userStatus: action.payload.data.userStatus,
        userPassword: action.payload.data.userPassword,
        // register: true,
        apiStatus: 'REGISTER_USER_SUCCESS',
      };

    case 'REGISTER_USER_FAIL':
      return {
        ...state,
        registerLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        // register: false,
        apiStatus: 'REGISTER_USER_FAIL',
      };

    case 'UPDATE_REGISTER':
      return {
        ...state,
        register: action.payload.data,
      };

    case 'GET_STATES':
      return {
        ...state,
        statesList: [],
        stateLoading: true,
      };

    case 'GET_STATES_SUCCESS':
      return {
        ...state,
        statesList: action.payload.data,
        stateLoading: false,
      };

    case 'GET_STATES_FAIL':
      return {
        ...state,
        stateLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    case 'GET_CITIES':
      return {
        ...state,
        citiesList: [],
        cityLoading: true,
      };

    case 'GET_CITIES_SUCCESS':
      return {
        ...state,
        citiesList: action.payload.data,
        cityLoading: false,
      };

    case 'GET_CITIES_FAIL':
      return {
        ...state,
        cityLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    case 'UPDATE_CITIES':
      return {
        ...state,
        citiesList: action.payload,
      };

    case 'GET_PRODUCT':
      return {
        ...state,
        productLoading: true,
        productDetail: null,
        apiStatus: 'GET_PRODUCT'
      };

    case 'GET_PRODUCT_SUCCESS':
      return {
        ...state,
        product: action.payload.data,
        productTotalPages: action.payload.total_pages,
        productTotalCount: action.payload.total_count,
        productLoading: false,
        apiStatus: 'GET_PRODUCT_SUCCESS'
      };

    case 'GET_PRODUCT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        productLoading: false,
        apiStatus: 'GET_PRODUCT_FAIL'
      };

    case 'GET_PRODUCT_DETAIL':
      return {
        ...state,
        productDetail: null,
        listLoading: true,
      };

    case 'GET_PRODUCT_DETAIL_SUCCESS':
      return {
        ...state,
        productDetail: action.payload.data,
        listLoading: false,
      };

    case 'GET_PRODUCT_DETAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'GET_CATEGORY_LIST':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_CATEGORY_LIST_SUCCESS':
      return {
        ...state,
        categoryList: action.payload.data,
        listLoading: false,
      };

    case 'GET_CATEGORY_LIST_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'GET_CATEGORY_PRODUCT_COUNT':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_CATEGORY_PRODUCT_COUNT_SUCCESS':
      return {
        ...state,
        categoryProductCountList: action.payload.data,
        listLoading: false,
      };

    case 'GET_CATEGORY_PRODUCT_COUNT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'ADD_TO_CART':
      return {
        ...state,
        cartBtnLoading: true,
      };

    case 'ADD_TO_CART_SUCCESS':
      let product_detail = state.productDetail;
      let product_detail_review_pending_count = state.reviewPendingCount;

      if (product_detail != null) {
        let product_detail = {...state.productDetail};
        if (product_detail.product_data.uid == action.payload.productUid) {
          product_detail.product_data.quantity = action.payload.quantity;
        }
        product_detail_review_pending_count = parseInt(product_detail_review_pending_count) + 1;

        //   let related_product = [...product_detail?.related_product_data]
        //   if (related_product.length > 0) {
        //     let relatedProductIndex = related_product.findIndex(x => x.product_uid == action.payload.productUid);
        //     if (relatedProductIndex >= 0) {
        //       related_product[relatedProductIndex]['quantity'] = action.payload.quantity;
        //     }
        //   }
      }
      // let home_data = state.homeData;
      // if (home_data != null) {
      //   let home_data = {...state.homeData};
      //   let cat_list = [...home_data?.dataList];
      //   if (cat_list.length > 0) {
      //     let categoryIndex = cat_list.findIndex(x => x.cat_uid == action.payload.categoryUid);
      //     if (categoryIndex >= 0) {
      //       let cat_product_list = [...cat_list?.[categoryIndex]?.products];
      //       if (cat_product_list.length > 0) {
      //         let categoryProductIndex = cat_product_list.findIndex(x => x.product_uid == action.payload.productUid);
      //         if (categoryProductIndex >= 0) {
      //           cat_product_list[categoryProductIndex]['quantity'] = action.payload.quantity;
      //         }
      //       }
      //     }
      //   }
      // }
      // let product_data = state.product;
      // if (product_data.length > 0) {
      //   let product_data = [...state.product];
      //   let productIndex = product_data.findIndex(x => x.product_uid == action.payload.productUid);
      //   if (productIndex >= 0) {
      //     product_data[productIndex]['quantity'] = action.payload.quantity;
      //   }
      // }
      // let cart_data = [...state.cart];
      // if (cart_data.length > 0) {
      //   let cartIndex = cart_data.findIndex(x => x.cart_id == action.payload.data.cart_id);
      //   if (cartIndex >= 0) {
      //     cart_data.splice(cartIndex, 1);
      //   }
      // }
      // cart_data = [...cart_data, action.payload.data];
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        cartButtonText: 'GO TO CART',
        productDetail: product_detail,
        reviewPendingCount: product_detail_review_pending_count,
        // homeData: home_data,
        // product: product_data,
        // cart: cart_data,
        cartBtnLoading: false,
      };

    case 'ADD_TO_CART_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        cartButtonText: 'ADD TO CART',
        cartBtnLoading: false,
      };

    //Cart
    case 'GET_CART':
      return {
        ...state,
        cartLoading: true,
        placeOrderLoading: false,
        apiStatus: 'GET_CART'
      };

    case 'GET_CART_SUCCESS':
      let checkout_cancel_pending_count = state?.checkoutPendingCount;
      let auto_cancelled_cart_count = action?.payload?.auto_cancelled_cart_count;

      if(auto_cancelled_cart_count > 0) {
        checkout_cancel_pending_count = checkout_cancel_pending_count > 0 ? (parseInt(checkout_cancel_pending_count) - auto_cancelled_cart_count) : 0
      }

      return {
        ...state,
        cart: action.payload.data,
        cartLoading: false,
        checkoutPendingCount: checkout_cancel_pending_count,
        cartMessage: null,
        apiStatus: 'GET_CART_SUCCESS'
      };

    case 'GET_CART_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        cartLoading: false,
        apiStatus: 'GET_CART_FAIL'
      };

    case 'UPDATE_CART_LIST':
      return {
        ...state,
        cart: action.payload,
      };

    case 'UPDATE_CART':
      return {
        ...state,
        cartLoading: true,
        cartBtnLoading: true,
      };

    case 'UPDATE_CART_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: action.payload?.error == 1 ? 'error' : 'success'},
        // cart: action.payload.data,
        cartLoading: false,
        cartBtnLoading: false,
      };

    case 'UPDATE_CART_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        cartLoading: false,
        cartBtnLoading: false,
      };

    case 'DELETE_CART':
      return {
        ...state,
        cartLoading: true,
        confirmBtnLoading: true,
        apiStatus: 'DELETE_CART'
      };

    case 'DELETE_CART_SUCCESS':
      let cart_list = [...state.cart];
      let cart_uid_list = JSON.parse(action.payload.cartUid);
      cart_list = cart_list.filter(v => !cart_uid_list.includes(v.cart_uid))

      let checkout_list = [...state.checkoutDetail];
      let checkout_uid_list = JSON.parse(action.payload.cartUid);
      checkout_list = checkout_list.filter(v => !checkout_uid_list.includes(v.cart_uid))

      let shipping_cost_pending_count = state.shippingCostPendingCount;
      let checkout_pending_count = state.checkoutPendingCount;
      let review_pending_count = state.reviewPendingCount;
      if (action.payload?.currentStatus == 'review_pending') {
        review_pending_count = review_pending_count > 0 ? parseInt(review_pending_count) - 1 : 0;
      } else if (action.payload?.currentStatus == 'shipping_cost_pending') {
        shipping_cost_pending_count = shipping_cost_pending_count > 0 ? parseInt(shipping_cost_pending_count) - 1 : 0;
      } else if (action.payload?.currentStatus == 'checkout_pending') {
        checkout_pending_count = checkout_pending_count > 0 ? parseInt(checkout_pending_count) - (cart_uid_list?.length) : 0;
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        cart: cart_list,
        checkoutDetail: checkout_list,
        cartLoading: false,
        confirmBtnLoading: false,
        shippingCostPendingCount: shipping_cost_pending_count,
        checkoutPendingCount: checkout_pending_count,
        reviewPendingCount: review_pending_count,
        apiStatus: 'DELETE_CART_SUCCESS'
      };

    case 'DELETE_CART_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        cartLoading: false,
        confirmBtnLoading: false,
        apiStatus: 'DELETE_CART_FAIL'
      };

    case 'ADD_DELIVERY_ADDRESS':
      return {
        ...state,
        cartBtnLoading: true,
        apiStatus: 'ADD_DELIVERY_ADDRESS',
      };

    case 'ADD_DELIVERY_ADDRESS_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        cartBtnLoading: false,
        apiStatus: 'ADD_DELIVERY_ADDRESS_SUCCESS',
        deliveryAddressId: action.payload.data
      };

    case 'ADD_DELIVERY_ADDRESS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        cartBtnLoading: false,
        apiStatus: 'ADD_DELIVERY_ADDRESS_FAIL',
      };

    case 'GET_REVIEW_DATA':
      return {
        ...state,
        cartLoading: true,
      };

    case 'GET_REVIEW_DATA_SUCCESS':
      return {
        ...state,
        reviewDataList: action.payload.data,
        cartLoading: false,
      };

    case 'GET_REVIEW_DATA_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        cartLoading: false,
      };

    case 'GET_CART_DETAIL':
      return {
        ...state,
        cartLoading: true,
      };

    case 'GET_CART_DETAIL_SUCCESS':
      return {
        ...state,
        cartDetail: action.payload.data,
        cartLoading: false,
      };

    case 'GET_CART_DETAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        cartLoading: false,
      };

    case 'UPDATE_CART_DETAIL':
      return {
        ...state,
        cartBtnLoading: true,
        apiStatus: 'UPDATE_CART_DETAIL',
      };

    case 'UPDATE_CART_DETAIL_SUCCESS':

      let cart_message = null;
      if (action?.payload?.checkout) {
      } else {
        cart_message = {
          type: 'cart_review',
          cartUid: action.payload?.cartUid
        }
      }

      return {
        ...state,
        cartDetail: action.payload.data,
        cartBtnLoading: false,
        deliveryAddressId: '',
        cartMessage: cart_message,
        apiStatus: action?.payload?.checkout ? 'UPDATE_CART_DETAIL_SUCCESS_CHECKOUT' : 'UPDATE_CART_DETAIL_SUCCESS',
      };

    case 'UPDATE_CART_DETAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        cartBtnLoading: false,
        apiStatus: 'UPDATE_CART_DETAIL_FAIL',
      };

    case 'UPDATE_CART_BTN_TEXT':
      return {
        ...state,
        cartButtonText: action.payload,
      };

    case 'GET_CART_COUNT':
      return {
        ...state,
        apiStatus: 'GET_CART_COUNT',
      };

    case 'GET_CART_COUNT_SUCCESS':
      return {
        ...state,
        reviewPendingCount: action.payload.data?.review_pending_count,
        shippingCostPendingCount: action.payload.data?.shipping_cost_pending_count,
        checkoutPendingCount: action.payload.data?.checkout_pending_count,
        apiStatus: 'GET_CART_COUNT_SUCCESS',
      };

    case 'GET_CART_COUNT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_CART_COUNT_FAIL',
      };

    //account
    case 'SEND_MAIL':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'SEND_MAIL',
      };

    case 'SEND_MAIL_SUCCESS':
      return {
        ...state,
        userLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        apiStatus: 'SEND_MAIL_SUCCESS',
      };

    case 'SEND_MAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'SEND_MAIL_FAIL',
      };

    case 'MAIL_VERIFICATION':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'MAIL_VERIFICATION',
      };

    case 'MAIL_VERIFICATION_SUCCESS':
      return {
        ...state,
        userLoading: false,
        isUserVerified: action.payload.data.isUserVerified,
        userPassword: '',
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        apiStatus: 'MAIL_VERIFICATION_SUCCESS',
      };

    case 'MAIL_VERIFICATION_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'MAIL_VERIFICATION_FAIL',
      };

    case 'GET_USER_DETAIL':
      return {
        ...state,
        userDetail: null,
        detailLoading: true,
      };

    case 'GET_USER_DETAIL_SUCCESS':
      return {
        ...state,
        userDetail: action.payload.data,
        detailLoading: false,
      };

    case 'GET_USER_DETAIL_FAIL':
      return {
        ...state,
        userDetail: null,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        detailLoading: false,
      };

    case 'GET_USER_MAIL_VERIFY':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_USER_MAIL_VERIFY_SUCCESS':
      return {
        ...state,
        isUserVerified: action.payload.data.isUserVerified,
        listLoading: false,
      };

    case 'GET_USER_MAIL_VERIFY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'ADD_USER_CONTACT_DETAILS':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'ADD_USER_CONTACT_DETAILS',
      };

    case 'ADD_USER_CONTACT_DETAILS_SUCCESS':
      let add_user_contact_data = {...state.userDetail}

      add_user_contact_data = {
        ...add_user_contact_data,
        contactDetails: {
          ...add_user_contact_data?.contactDetails,
          ...action.payload.data,
        },
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        userLoading: false,
        userDetail: add_user_contact_data,
        apiStatus: 'ADD_USER_CONTACT_DETAILS_SUCCESS',
      };

    case 'ADD_USER_CONTACT_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'ADD_USER_CONTACT_DETAILS_FAIL',
      };

    case 'UPDATE_USER_CONTACT_DETAILS':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'UPDATE_USER_CONTACT_DETAILS',
      };

    case 'UPDATE_USER_CONTACT_DETAILS_SUCCESS':
      let update_user_contact_data = {...state.userDetail}

      update_user_contact_data = {
        ...update_user_contact_data,
        contactDetails: {
          ...update_user_contact_data?.contactDetails,
          ...action.payload.data,
        },
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        userLoading: false,
        userDetail: update_user_contact_data,
        apiStatus: 'UPDATE_USER_CONTACT_DETAILS_SUCCESS',
      };

    case 'UPDATE_USER_CONTACT_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'UPDATE_USER_CONTACT_DETAILS_FAIL',
      };

    case 'ADD_USER_IMPORT_LICENSE_DETAILS':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'ADD_USER_IMPORT_LICENSE_DETAILS',
      };

    case 'ADD_USER_IMPORT_LICENSE_DETAILS_SUCCESS':
      let add_user_import_license_data = {...state.userDetail}

      add_user_import_license_data = {
        ...add_user_import_license_data,
        importLicenseDetails: {
          ...add_user_import_license_data?.importLicenseDetails,
          ...action.payload.data,
        },
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        userDetail: add_user_import_license_data,
        userLoading: false,
        apiStatus: 'ADD_USER_IMPORT_LICENSE_DETAILS_SUCCESS',
      };

    case 'ADD_USER_IMPORT_LICENSE_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'ADD_USER_IMPORT_LICENSE_DETAILS_FAIL',
      };

    case 'UPDATE_USER_IMPORT_LICENSE_DETAILS':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'UPDATE_USER_IMPORT_LICENSE_DETAILS',
      };

    case 'UPDATE_USER_IMPORT_LICENSE_DETAILS_SUCCESS':
      let update_user_import_license_data = {...state.userDetail}

      update_user_import_license_data = {
        ...update_user_import_license_data,
        importLicenseDetails: {
          ...update_user_import_license_data?.importLicenseDetails,
          ...action.payload.data,
        },
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        userLoading: false,
        userDetail: update_user_import_license_data,
        apiStatus: 'UPDATE_USER_IMPORT_LICENSE_DETAILS_SUCCESS',
      };

    case 'UPDATE_USER_IMPORT_LICENSE_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'UPDATE_USER_IMPORT_LICENSE_DETAILS_FAIL',
      };

    case 'ADD_USER_DOCUMENTS':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'ADD_USER_DOCUMENTS',
      };

    case 'ADD_USER_DOCUMENTS_SUCCESS':
      let add_user_document_data = {...state.userDetail}

      add_user_document_data = {
        ...add_user_document_data,
        documentDetails: {
          ...add_user_document_data?.documentDetails,
          ...action.payload.data,
        },
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        userLoading: false,
        userDetail: add_user_document_data,
        apiStatus: 'ADD_USER_DOCUMENTS_SUCCESS',
      };

    case 'ADD_USER_DOCUMENTS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'ADD_USER_DOCUMENTS_FAIL',
      };

    case 'UPDATE_USER_DOCUMENTS':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'UPDATE_USER_DOCUMENTS',
      };

    case 'UPDATE_USER_DOCUMENTS_SUCCESS':

      let update_user_document_data = {...state.userDetail}

      update_user_document_data = {
        ...update_user_document_data,
        documentDetails: {
          ...update_user_document_data?.documentDetails,
          ...action.payload.data,
        },
      }

      return {
        ...state,
        userLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        userDetail: update_user_document_data,
        apiStatus: 'UPDATE_USER_DOCUMENTS_SUCCESS',
      };

    case 'UPDATE_USER_DOCUMENTS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'UPDATE_USER_DOCUMENTS_FAIL',
      };

    case 'ADD_USER_PRODUCT_CATEGORY':
      return {
        ...state,
        userLoading: true,
        apiStatus: 'ADD_USER_PRODUCT_CATEGORY',
      };

    case 'ADD_USER_PRODUCT_CATEGORY_SUCCESS':
      let add_user_product_category_data = {...state.userDetail}

      add_user_product_category_data = {
        ...add_user_product_category_data,
        productDetails: {
          ...add_user_product_category_data?.productDetails,
          ...action.payload.data,
        },
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        userLoading: false,
        userDetail: add_user_product_category_data,
        tgbin: action.payload?.data?.tgbin,
        // showTgbin: action.payload?.showTgbin,
        userStatus: action.payload?.data?.status,
        apiStatus: 'ADD_USER_PRODUCT_CATEGORY_SUCCESS',
      };

    case 'ADD_USER_PRODUCT_CATEGORY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        userLoading: false,
        apiStatus: 'ADD_USER_PRODUCT_CATEGORY_FAIL',
      };


    case 'GET_CONTACT_ADDRESS':
      return {
        ...state,
        accountLoading: true,
      };

    case 'GET_CONTACT_ADDRESS_SUCCESS':
      return {
        ...state,
        contactAddress: action.payload.data,
        accountLoading: false,
      };

    case 'GET_CONTACT_ADDRESS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        accountLoading: false,
      };

    case 'UPDATE_USER_NAME':
      return {
        ...state,
        accountLoading: true,
      };

    case 'UPDATE_USER_NAME_SUCCESS':
      return {
        ...state,
        userName: action.payload.userName,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        accountLoading: false,
      };

    case 'UPDATE_USER_NAME_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        accountLoading: false,
      };

    case 'UPDATE_PASSWORD':
      return {
        ...state,
        accountLoading: true,
      };

    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        accountLoading: false,
      };

    case 'UPDATE_PASSWORD_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        accountLoading: false,
      };

    //checkout
    case 'GET_CHECKOUT':
      return {
        ...state,
        checkoutLoading: true,
        checkoutDetail: []
      };

    case 'GET_CHECKOUT_SUCCESS':
      return {
        ...state,
        checkoutDetail: action.payload?.data,
        checkoutLoading: false,
      };

    case 'GET_CHECKOUT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        checkoutLoading: false,
      };

    case 'PLACE_ORDER':
      return {
        ...state,
        placeOrderLoading: true,
        apiStatus: 'PLACE_ORDER'
      };

    case 'PLACE_ORDER_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        placeOrderLoading: false,
        apiStatus: 'PLACE_ORDER_SUCCESS'
      };

    case 'PLACE_ORDER_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        placeOrderLoading: false,
        apiStatus: 'PLACE_ORDER_FAIL'
      };

    //order
    case 'LIST_ORDER':
      return {
        ...state,
        orderLoading: true,
      };

    case 'LIST_ORDER_SUCCESS':
      let pending_order_count = state?.pendingOrderCount;
      let cancelled_order_count = state?.cancelledOrderCount;
      let auto_cancelled_order_count = action?.payload?.auto_cancelled_order_count;
      if (auto_cancelled_order_count > 0) {
        pending_order_count = pending_order_count > 0 ? (parseInt(pending_order_count) - parseInt(auto_cancelled_order_count)) : 0
        cancelled_order_count = parseInt(cancelled_order_count) + parseInt(auto_cancelled_order_count)
      }

      return {
        ...state,
        orderList: action.payload.data,
        orderTotalPages: action.payload?.total_pages,
        pendingOrderCount: pending_order_count,
        cancelledOrderCount: cancelled_order_count,
        orderMessage: null,
        orderLoading: false,
      };

    case 'LIST_ORDER_FAIL':
      return {
        ...state,
        orderLoading: false,
      };
    // case 'LIST_ORDER_PENDING':
    //   return {
    //     ...state,
    //     orderLoading: true,
    //   };
    //
    // case 'LIST_ORDER_PENDING_SUCCESS':
    //   return {
    //     ...state,
    //     orderPendingList: action.payload.data,
    //     orderPendingTotalPages: action.payload?.total_pages,
    //     orderLoading: false,
    //   };
    //
    // case 'LIST_ORDER_PENDING_FAIL':
    //   return {
    //     ...state,
    //     orderLoading: false,
    //   };
    //
    // case 'LIST_ORDER_UNSHIPPED':
    //   return {
    //     ...state,
    //     orderLoading: true,
    //   };
    //
    // case 'LIST_ORDER_UNSHIPPED_SUCCESS':
    //   return {
    //     ...state,
    //     orderUnshippedList: action.payload.data,
    //     orderUnshippedTotalPages: action.payload?.total_pages,
    //     orderLoading: false,
    //   };
    //
    // case 'LIST_ORDER_UNSHIPPED_FAIL':
    //   return {
    //     ...state,
    //     orderLoading: false,
    //   };
    //
    // case 'LIST_ORDER_SHIPPED':
    //   return {
    //     ...state,
    //     orderLoading: true,
    //   };
    //
    // case 'LIST_ORDER_SHIPPED_SUCCESS':
    //   return {
    //     ...state,
    //     orderShippedList: action.payload.data,
    //     orderShippedTotalPages: action.payload?.total_pages,
    //     orderLoading: false,
    //   };
    //
    // case 'LIST_ORDER_SHIPPED_FAIL':
    //   return {
    //     ...state,
    //     orderLoading: false,
    //   };
    //
    // case 'LIST_ORDER_CANCELLED':
    //   return {
    //     ...state,
    //     orderLoading: true,
    //   };
    //
    // case 'LIST_ORDER_CANCELLED_SUCCESS':
    //   return {
    //     ...state,
    //     orderCancelledList: action.payload.data,
    //     orderCancelledTotalPages: action.payload?.total_pages,
    //     orderLoading: false,
    //   };
    //
    // case 'LIST_ORDER_CANCELLED_FAIL':
    //   return {
    //     ...state,
    //     orderLoading: false,
    //   };

    case 'GET_ORDER_DETAIL':
      return {
        ...state,
        orderLoading: true,
        apiStatus: 'GET_ORDER_DETAIL'
      };

    case 'GET_ORDER_DETAIL_SUCCESS':
      return {
        ...state,
        orderDetail: action.payload.data,
        orderLoading: false,
        apiStatus: 'GET_ORDER_DETAIL_SUCCESS'
      };

    case 'GET_ORDER_DETAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        orderLoading: false,
        apiStatus: 'GET_ORDER_DETAIL_FAIL'
      };


    case 'GET_ADMIN_BENEFICIARY_DETAIL':
      return {
        ...state,
        orderLoading: false,
      };

    case 'GET_ADMIN_BENEFICIARY_DETAIL_SUCCESS':
      return {
        ...state,
        adminBeneficiaryDetail: action.payload.data,
        orderLoading: false,
      };

    case 'GET_ADMIN_BENEFICIARY_DETAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        orderLoading: false,
      };

    case 'UPLOAD_PAYMENT_DETAIL':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'UPLOAD_PAYMENT_DETAIL'
      };

    case 'UPLOAD_PAYMENT_DETAIL_SUCCESS':
      return {
        ...state,
        btnLoading: false,
        apiStatus: 'UPLOAD_PAYMENT_DETAIL_SUCCESS',
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
      };

    case 'UPLOAD_PAYMENT_DETAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'UPLOAD_PAYMENT_DETAIL_FAIL'
      };

    case 'CANCEL_ORDER':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'CANCEL_ORDER'
      };

    case 'CANCEL_ORDER_SUCCESS':

      let order_message = {
        type: 'order_cancelled',
        orderUid: action.payload?.orderUid
      }

      return {
        ...state,
        btnLoading: false,
        orderMessage: order_message,
        apiStatus: 'CANCEL_ORDER_SUCCESS',
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
      };

    case 'CANCEL_ORDER_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'CANCEL_ORDER_FAIL'
      };

    case 'GET_ORDER_COUNT':
      return {
        ...state,
        apiStatus: 'GET_ORDER_COUNT'
      };

    case 'GET_ORDER_COUNT_SUCCESS':
      return {
        ...state,
        apiStatus: 'GET_ORDER_COUNT_SUCCESS',
        pendingOrderCount: action.payload.data?.pending_order_count,
        unshippedOrderCount: action.payload.data?.unshipped_order_count,
        shippedOrderCount: action.payload.data?.shipped_order_count,
        deliveredOrderCount: action.payload.data?.delivered_order_count,
        cancelledOrderCount: action.payload.data?.cancelled_order_count,
      };

    case 'GET_ORDER_COUNT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_ORDER_COUNT_FAIL'
      };

    case 'GET_EXPORT_COUNTRY_LIST':
      return {
        ...state,
        modalLoading: true,
      };

    case 'GET_EXPORT_COUNTRY_LIST_SUCCESS':
      return {
        ...state,
        modalLoading: false,
        exportCountryList: action.payload.data
      };

    case 'GET_EXPORT_COUNTRY_LIST_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        modalLoading: false,
      };

    case 'CLEAR_CART':
      return {
        ...state,
        modalBtnLoading: true
      };

    case 'CLEAR_CART_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        cart: [],
        cartExportCountry: null,
        modalBtnLoading: false,
      };

    case 'CLEAR_CART_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        modalBtnLoading: false,
      };

    case 'GET_CURRENCY_RATE_LIST':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_CURRENCY_RATE_LIST_SUCCESS':
      return {
        ...state,
        currencyRateList: action.payload.data,
        listLoading: false,
      };

    case 'GET_CURRENCY_RATE_LIST_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'UPDATE_CHECKOUT_DATA':
      return {
        ...state,
        checkoutData: action.payload,
      };

    case 'UPDATE_SHOW_TGBIN':
      return {
        ...state,
        showTgbin: action.payload,
      };

    case 'GET_LOGIN_USER_DETAIL':
      return {
        ...state,
        listLoading: true
      };

    case 'GET_LOGIN_USER_DETAIL_SUCCESS':
      return {
        ...state,
        userStatus: action.payload.data?.userStatus,
        tgbin: action.payload.data?.tgbin,
        listLoading: false,
      };

    case 'GET_LOGIN_USER_DETAIL_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'ALERT_MSG_EMPTY':
      return {
        ...state,
        msg: {open: false, message: '', msgType: ''},
      };

    case 'UPDATE_ALERT_MSG':
      return {
        ...state,
        msg: {open: action.payload.open, message: action.payload.message, msgType: action.payload.msgType},
      };

    case 'UPDATE_API_STATUS':
      return {
        ...state,
        apiStatus: action.payload,
      };

    case 'LOG_OUT':
      return {
        ...state,
        listLoading: false,
        detailLoading: false,
        getRegisterDataList: null,
        registerLoading: false,
        // register: false,
        tgbin: '',
        showTgbin: false,
        homeData: null,
        statesList: [],
        citiesList: [],
        stateLoading: false,
        cityLoading: false,
        userName: '',
        userToken: '',
        userContactName: '',
        userEmail: '',
        userCountry: '',
        userNameEntity: '',
        userStatus: 'pending',
        userPassword: '',
        isUserVerified: 0,
        productDetail: null,
        product: [],
        productLoading: false,
        productTotalPages: 0,
        productTotalCount: 0,
        categoryList: [],
        cartButtonText: 'ADD TO CART',
        cartBtnLoading: false,
        confirmBtnLoading: false,
        cart: [],
        reviewDataList: null,
        cartDetail: null,
        userDetail: null,
        userLoading: false,
        contactAddress: null,
        accountLoading: false,
        placeOrderLoading: false,
        exportCountryList: [],
        exportCountry: null,
        cartExportCountry: null,
        order: [],
        orderTotalPages: 0,
        orderDetail: null,
        modalLoading: false,
        modalBtnLoading: false,
        btnLoading: false,
        cartLoading: false,
        loginLoading: false,
        deliveryAddressId: '',
        checkoutData: [],
        checkoutDetail: [],
        checkoutLoading: false,
        pendingOrderCount: 0,
        unshippedOrderCount: 0,
        shippedOrderCount: 0,
        deliveredOrderCount: 0,
        cancelledOrderCount: 0,
        adminBeneficiaryDetail: null,
        currencyRateList: null,
        categoryProductCountList: [],
        orderLoading: false,
        orderMessage: null,
        cartMessage: null,
        msg: {open: false, message: '', msgType: ''},
        apiStatus: ""
      };

  }
  return state;
};

export default userDataReducer;
