import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormMultiSelectBox from "./FormMultiSelectBox";

import {connect} from "react-redux";
import {getContactAddress, getStates, getCities, updateApiStatus} from "../redux/actions/userDataActions";

import FormButton from "./FormButton";

import {THEME_COLOR} from "../constants";
import {COUNTRIES} from "../constants/countries";

import styles from "./ReviewDeliveryAddress.module.css"

import {ReactComponent as Checkbox} from "../assets/img/radio_checkbox.svg";
import {ReactComponent as UnCheckbox} from "../assets/img/radio_uncheckbox.svg";

const ReviewDeliveryAddress = (props) => {
  const [newAddress, setNewAddress] = useState(false)
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState(null);
  const [zipCode, setZipCode] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    props.getContactAddress();
  }, [])

  useEffect(() => {
    if (props?.contactAddress?.deliveryAddress?.country) {
      let country_val = COUNTRIES.filter((x) => (x.code === props?.contactAddress?.deliveryAddress?.country));
      setCountry(country_val[0])
      let data = {
        country: country_val[0].label
      };
      props.getStates(JSON.stringify(data));
    }
  }, [props?.contactAddress?.deliveryAddress?.country])

  useEffect(() => {
    if (props.contactAddress != null && (props.statesList.length > 0) && !newAddress) {
      let data = props?.contactAddress?.deliveryAddress;
      let state_val = props.statesList.filter((x) => (x.name === data?.deliveryStateProvince?.toString()));
      setAddressLine1(data?.deliveryBusinessAddress);
      setAddressLine2(data?.deliveryBusinessAddress);
      setCity(data?.deliveryCity);
      setStateProvince(state_val[0]);
      setZipCode(data?.deliveryZipCode);
    }
  }, [props?.contactAddress, props.statesList, newAddress]);

  useEffect(() => {
    if (newAddress) {
      if (stateProvince != null) {
        setCity('');
        let data = {
          country: country?.label,
          state: stateProvince?.name
        };
        props.getCities(JSON.stringify(data));
      }
    }
  }, [stateProvince]);

  // useEffect(() => {
  //   if (props.apiStatus == "ADD_DELIVERY_ADDRESS_SUCCESS") {
  //     setError(null)
  //     props.updateApiStatus("");
  //     props.onSaveDeliveryAddress()
  //   }
  // },[props.apiStatus])

  const onSameAsBuyerAddress = () => {
    setNewAddress(false)
  };

  const onNewAddress = () => {
    setAddressLine1('');
    setAddressLine2('');
    setCity('');
    setStateProvince(null);
    setZipCode('');
    setNewAddress(true)
  };

  const onSaveClick = () => {
    let errorText = {};
    if (addressLine1 == '') {
      errorText = {addressLine1: 'Address Line 1 is required'};
    }
    if (addressLine2 == '') {
      errorText = {...errorText, addressLine2: 'Address Line 2 is required'};
    }
    if (stateProvince == null) {
      errorText = {...errorText, stateProvince: 'State/Province is required'};
    }
    if (city == '') {
      errorText = {...errorText, city: 'City is required'};
    }
    if (zipCode == '') {
      errorText = {...errorText, zipCode: 'Zip Code is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let same_as_buyer = 0;
    if(!newAddress) {
      same_as_buyer = 1
    }

    let data = {
      cartUid: props?.cartUid,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      stateProvince: stateProvince.name,
      city: city,
      zipCode: zipCode,
      sameAsBuyer: same_as_buyer
    };
    // props.addDeliveryAddress(JSON.stringify(data));
    props.onSaveDeliveryAddress(data)

  };

  return (
    <Grid item md={12} xs={12}>
      <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}
            onClick={() => onSameAsBuyerAddress()} mt={2}
            className={styles.addressChooseTitleView}
      >
        {!newAddress ?
          <Checkbox height={16} width={16}/> :
          <UnCheckbox height={16} width={16}/>}
        <Typography pl={1} className={styles.addressChooseTitleText}>Same as Buyer's business address</Typography>
      </Grid>
      <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}
            onClick={() => onNewAddress()} mt={2}
            className={styles.addressChooseTitleView}>
        {newAddress ?
          <Checkbox height={16} width={16}/> :
          <UnCheckbox height={16} width={16}/>}
        <Typography pl={1} className={styles.addressChooseTitleText}>Enter new address</Typography>
      </Grid>


      <Grid container item md={10} xs={10} className={styles.addressMainView}
            display={"flex"} flexDirection={"row"} justifyContent={"space-between"}
            mt={3}>
        <Grid item md={12} xs={12} mt={2}>
          <FormTextInput
            disabled={!newAddress}
            page={'ReviewDeliveryAddress'}
            value={addressLine1}
            setValue={setAddressLine1}
            label={'Delivery address line 1'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
            error={error?.addressLine1}
          />
        </Grid>

        <Grid item md={12} xs={12} mt={3}>
          <FormTextInput
            disabled={!newAddress}
            page={'ReviewDeliveryAddress'}
            value={addressLine2}
            setValue={setAddressLine2}
            label={'Delivery address line 2'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
            error={error?.addressLine2}
          />
        </Grid>

        <Grid item md={5.8} xs={12} mt={3}>
          <FormMultiSelectBox
            disabled={!newAddress}
            type={'state'}
            page={'ReviewDeliveryAddress'}
            multiple={false}
            loading={props?.stateLoading}
            value={stateProvince}
            setValue={setStateProvince}
            data={props?.statesList}
            label={'State/Province'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
            selectedItemViewStyle={{
              backgroundColor: '#fff',
            }}
            error={error?.stateProvince}
          />
        </Grid>

        <Grid item md={5.8} xs={12} mt={3}>
          <FormMultiSelectBox
            disabled={!newAddress}
            type={'city'}
            page={'ReviewDeliveryAddress'}
            multiple={false}
            loading={props?.cityLoading}
            value={city}
            setValue={setCity}
            data={props?.citiesList}
            label={'City'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
            selectedItemViewStyle={{
              backgroundColor: '#fff',
            }}
            error={error?.city}
          />
        </Grid>

        <Grid item md={12} xs={12} mt={3}>
          <FormTextInput
            disabled={!newAddress}
            page={'ReviewDeliveryAddress'}
            value={zipCode}
            setValue={setZipCode}
            label={'ZIP Code'}
            textInputViewStyle={{
              backgroundColor: '#fff',
              border: '1px solid #e4ecf2',
            }}
            error={error?.zipCode}
          />
        </Grid>

      </Grid>

      <Grid item md={10} xs={12} mt={2} display={'flex'} justifyContent={{md: 'right', xs: 'center'}}>
        <FormButton
          btnStyleView={{height: 50, width: {md:'20%',xs:'100%'}}}
          title={'SAVE'}
          onSubmit={onSaveClick}
        />
      </Grid>

    </Grid>
  )
};

const mapStateToProps = (state) => {
  return {
    contactAddress: state.userData.contactAddress,
    statesList: state.userData.statesList,
    stateLoading: state.userData.stateLoading,
    citiesList: state.userData.citiesList,
    cityLoading: state.userData.cityLoading,
    // apiStatus: state.userData.apiStatus,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getContactAddress: data => dispatch(getContactAddress(data)),
    getStates: data => dispatch(getStates(data)),
    getCities: data => dispatch(getCities(data)),
    // addDeliveryAddress: data => dispatch(addDeliveryAddress(data)),
    // updateApiStatus: data => dispatch(updateApiStatus(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDeliveryAddress);
