import React, {useEffect} from 'react';
import {Navigate} from 'react-router-dom';

import {connect} from 'react-redux';

import {logOut} from "../../redux/actions/userDataActions";

const LogOut = (props) => {
  useEffect(() => {
    props.logOut();
  });

  return (
    <Navigate to="/"/>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut())
  }
};

export default connect(null, mapDispatchToProps)(LogOut);
