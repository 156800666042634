import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, CircularProgress} from '@mui/material';
import dayjs from "dayjs";
import {useNavigate} from 'react-router-dom'

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import FormDatePicker from "./FormDatePicker";
import ImageUpload from "./ImageUpload";

import {connect} from "react-redux";
import {
  uploadPaymentDetail,
  updateApiStatus
} from "../redux/actions/userDataActions";

import styles from './UploadPaymentDetails.module.css'

import {THEME_COLOR2} from '../constants'

const UploadPaymentDetails = (props) => {
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [paymentDate, setPaymentDate] = useState(dayjs());
  const [paymentReceipt, setPaymentReceipt] = useState(null);
  const [displayPaymentReceipt, setDisplayPaymentReceipt] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate()

  useEffect(() => {
    if (props.apiStatus == 'UPLOAD_PAYMENT_DETAIL_SUCCESS') {
      navigate('/order');
      props.updateApiStatus('')
    }
  }, [props.apiStatus])

  const onSubmitClick = () => {
    let errorText = {};
    if (accountName == '') {
      errorText = {accountName: 'Account Name is required'};
    }
    if (accountNumber == '') {
      errorText = {...errorText, accountNumber: 'Account Number is required'};
    }
    if (bankName == '') {
      errorText = {...errorText, bankName: 'Bank Name is required'};
    }
    if (transactionId == '') {
      errorText = {...errorText, transactionId: 'Transaction ID is required'};
    }
    if (paymentDate == '' || paymentDate == null) {
      errorText = {...errorText, paymentDate: 'Payment Date is required'};
    }
    if (paymentReceipt == null) {
      errorText = {...errorText, paymentReceipt: 'Payment Receipt is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }


    let paymentTermStatus = 'completed';
    if(props?.paymentTermAmount == 50) {
      if(props?.paymentStatus == 'pending') {
        paymentTermStatus = 'half_paid';
      } else if(props?.paymentStatus == 'half_paid') {
        paymentTermStatus = 'completed';
      }
    }

    let formData = new FormData();
    formData.append('orderUid', props?.orderUid);
    formData.append('totalPrice', props?.totalCargoPrice);
    formData.append('sellerTotalPrice', props?.sellerTotalCargoPrice);
    formData.append('accountName', accountName);
    formData.append('accountNumber', accountNumber);
    formData.append('bankName', bankName);
    formData.append('transactionId', transactionId);
    formData.append('paymentDate', dayjs(paymentDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    formData.append('paymentReceipt', paymentReceipt);
    formData.append('paymentTermStatus', paymentTermStatus);

    props.uploadPaymentDetail(formData);
  };

  const onRemoveReceipt = () => {
    setPaymentReceipt(null);
    setDisplayPaymentReceipt('');
  };

  const onChangeReceipt = (fileList) => {
    setPaymentReceipt(fileList[0].file);
    setDisplayPaymentReceipt(fileList[0].image);
  };

  return (
    <Grid container
          item md={11} xs={11}
          display={'flex'} flexDirection={'column'}>

      <Grid item md={6} xs={12}
            container>
        <Typography className={styles.mainLabelText}>Upload Payment Details</Typography>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}
              display={"flex"}
              flexDirection={"row"} >
          <Typography className={styles.labelText}>Account Name</Typography>
          <Box pl={0.5}>
            <Typography className={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            page={'UploadPaymentDetails'}
            value={accountName}
            setValue={setAccountName}
            textInputViewStyle={style.textInputViewStyle}
            error={error?.accountName}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}
              display={"flex"}
              flexDirection={"row"} >
          <Typography className={styles.labelText}>Account Number</Typography>
          <Box pl={0.5}>
            <Typography className={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            page={'UploadPaymentDetails'}
            value={accountNumber}
            setValue={setAccountNumber}
            textInputViewStyle={style.textInputViewStyle}
            error={error?.accountNumber}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}
              display={"flex"}
              flexDirection={"row"} >
          <Typography className={styles.labelText}>Bank Name</Typography>
          <Box pl={0.5}>
            <Typography className={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            page={'UploadPaymentDetails'}
            value={bankName}
            setValue={setBankName}
            textInputViewStyle={style.textInputViewStyle}
            error={error?.bankName}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}
              display={"flex"}
              flexDirection={"row"} >
          <Typography className={styles.labelText}>Transaction ID</Typography>
          <Box pl={0.5}>
            <Typography className={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormTextInput
            page={'UploadPaymentDetails'}
            value={transactionId}
            setValue={setTransactionId}
            textInputViewStyle={style.textInputViewStyle}
            error={error?.transactionId}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}
              display={"flex"}
              flexDirection={"row"} >
          <Typography className={styles.labelText}>Date of Payment</Typography>
          <Box pl={0.5}>
            <Typography className={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormDatePicker
            page={"UploadPaymentDetails"}
            value={paymentDate}
            inputFormat="DD-MM-YYYY"
            onChange={(newValue) => {
              setPaymentDate(newValue);
            }}
            error={error?.paymentDate}
            textInputViewStyle={style.textInputViewStyle}
          />
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            flexDirection={{md: "row", xs: 'column'}}
            alignItems={'center'}
            container>
        <Grid item md={4} xs={12}
              display={"flex"}
              flexDirection={"row"} >
          <Typography className={styles.labelText}>Payment Receipt</Typography>
          <Box pl={0.5}>
            <Typography className={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <ImageUpload
            showInputType={true}
            type={'PaymentReceipt'}
            maxFiles={1}
            fileType={'all'}
            file={paymentReceipt}
            image={displayPaymentReceipt}
            error={error?.paymentReceipt}
            onRemove={onRemoveReceipt}
            onChange={onChangeReceipt}
          />
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>

      <Grid item md={6} xs={12} mt={3}
            display={"flex"}
            justifyContent={'flex-end'}
            container>
        <FormButton
          loading={props?.btnLoading}
          title={'Submit'}
          onSubmit={() => onSubmitClick()}
        />
      </Grid>

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    btnLoading: state.userData.btnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadPaymentDetail: data => dispatch(uploadPaymentDetail(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPaymentDetails);

const style = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e4ecf2',
  },
}
