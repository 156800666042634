import React from 'react';
import {Box, Grid, Typography} from "@mui/material";

import Dropzone from 'react-dropzone'

import {ReactComponent as ImgIcon} from "../assets/img/img.svg";
import {ReactComponent as CloseIcon} from "../assets/img/close.svg";
import {ReactComponent as PdfIcon} from "../assets/img/pdf.svg";

import {connect} from "react-redux";
import {updateAlertMsg} from "../redux/actions/userDataActions";

import {THEME_COLOR2} from "../constants";

const UploadImage = (props) => {

  const uploadFile = acceptedFiles => {
    let fileList = [];
    acceptedFiles.map(file => {
      let image = URL.createObjectURL(file);
      fileList.push({
        image: image,
        file: file,
      })
    });
    props.onChange(fileList, props.type);
  };

  const uploadError = (err) => {
    if (err.length) {
      if (err[0]['errors'].length)
        if (err[0]['errors'][0]['code'] == 'too-many-files') {
          if (props.maxFiles == 1) {
            props.updateAlertMsg({open: true, message: 'Only 1 image is allowed to upload', msgType: 'error'});
          } else {
            props.updateAlertMsg({
              open: true,
              message: `Only ${props.maxFiles} images are allowed to upload at a time`,
              msgType: 'error'
            });
          }
        } else {
          props.updateAlertMsg({open: true, message: err[0]['errors'][0]['message'], msgType: 'error'});
        }
    }
  };

  const onRemoveImage = () => {
    props.onRemove(props.type);
  };

  return (
    <Box>

      <Dropzone
        maxFiles={props.maxFiles}
        onDropRejected={err => uploadError(err)}
        accept={props?.fileType == 'all' ? ".jpeg,.jpg,.png,.pdf" : (props.fileType == 'pdf' ? 'application/pdf' : {'image/*': ['.jpeg', '.jpg', '.png']})}
        onDrop={uploadFile}>
        {({getRootProps, getInputProps}) => (
          props?.showInputType ?
            <Box sx={[styles.uploadMainImgView, props.image ? null : {cursor: 'pointer'}]}
                 {...(props.image ? null : getRootProps())}>
              {props.image ? null : <input {...getInputProps()} />}

              <Box
                title={'Upload'}
                px={2}
                py={1}
                sx={{
                  backgroundColor: THEME_COLOR2,
                  color: '#000',
                  border: '1px solid #aaa',
                }}
              >
                {props.image ?
                  <Box>
                    <Typography sx={{color: '#000', fontSize: 15, fontFamily: 'InterMedium'}}>{props?.file?.name}</Typography>
                    <Box display={"flex"}
                         alignItems={"center"}
                         justifyContent={"center"}
                         sx={[styles.pencilCloseIconView, styles.closeIconView]}
                         onClick={onRemoveImage}>
                      <CloseIcon height={13} width={13}/>
                    </Box>
                  </Box>
                  :
                  <Typography sx={{color: '#000', fontSize: 15, fontFamily: 'InterMedium'}}>Upload</Typography>}
              </Box>

            </Box>
            :
            <Box sx={[styles.uploadMainImgView, props.image ? null : {cursor: 'pointer'}]}
                 {...(props.image ? null : getRootProps())}>
              {props.image ? null : <input {...getInputProps()} />}
              <Box display={"flex"}
                   alignItems={"center"}
                   justifyContent={"center"}
                   sx={styles.uploadImgView}>
                {props.image ?
                  (props.fileType == 'pdf' ?
                    <PdfIcon height={50} width={50}/> :
                    <img src={props.image} height="100" width="100"/>)
                  :
                  <>
                    <ImgIcon height={50} width={50}/>
                  </>}
              </Box>

              {props.image ?
                <Box display={"flex"}
                     alignItems={"center"}
                     justifyContent={"center"}
                     sx={[styles.pencilCloseIconView, styles.closeIconView]}
                     onClick={onRemoveImage}>
                  <CloseIcon height={13} width={13}/>
                </Box>
                : null}
            </Box>
        )}
      </Dropzone>

      {props?.error ?
        <Box mt={2} ml={0.5} styles={styles.errorView}>
          <Typography sx={styles.errorText}>{props.error}</Typography>
        </Box>
        : null}
    </Box>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
  };
};

export default connect(null, mapDispatchToProps)(UploadImage);

const styles = {
  uploadMainImgView: {
    position: 'relative',
    width: 'fit-content',
  },
  uploadImgView: {
    overflow: 'hidden',
    border: '3px solid #FFF',
    borderRadius: 3,
    boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
    height: 100,
    width: 100,
  },
  pencilCloseIconView: {
    position: 'absolute',
    backgroundColor: '#FFF',
    cursor: 'pointer',
    border: '3px solid #FFF',
    borderRadius: 10,
    right: -10,
    boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
    height: 24,
    width: 24,
  },
  pencilIconView: {
    top: -10,
  },
  closeIconView: {
    bottom: -10,
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 12
  }
};
