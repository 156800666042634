import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import moment from 'moment';

import FormButton from "./FormButton";
import FormSelectBox from "./FormSelectBox";
import {
  getCurrencyConversionCalc,
  cancellationPeriod,
  priceCalculation,
  unshippedOrderStatus
} from '../assets/functions/common'

import styles from "./OrderTabList.module.css"

import {THEME_COLOR3, THEME_COLOR2, THEME_COLOR5, THEME_COLOR4} from "../constants";
import {COUNTRIES} from "../constants/countries";

const OrderTabList = (props) => {
  const [document, setDocument] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [isCancellationPeriod, setIsCancellationPeriod] = useState(false);

  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingCostPerPackage, setShippingCostPerPackage] = useState(0);
  const [shippingCostPerKg, setShippingCostPerKg] = useState(0);

  const [cargoPrice, setCargoPrice] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [sellerPrice, setSellerPrice] = useState(0);
  const [sellerPricePerKg, setSellerPricePerKg] = useState(0);
  const [sellerTotalPrice, setSellerTotalPrice] = useState(0);
  const [sellerShippingCost, setSellerShippingCost] = useState('')
  const [sellerShippingCostPerPackage, setSellerShippingCostPerPackage] = useState(0);
  const [sellerShippingCostPerKg, setSellerShippingCostPerKg] = useState(0);
  const [sellerCargoPrice, setSellerCargoPrice] = useState(0);
  const [sellerCurrencySymbol, setSellerCurrencySymbol] = useState('')

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {
        let user_currency_symbol = '';
        let converted_price = 0;
        let converted_price_per_kg = 0;
        let converted_total_price = 0;
        let converted_shipping_cost = 0;
        let converted_shipping_cost_per_package = 0;
        let converted_shipping_cost_per_kg = 0;
        let converted_cargo_price = 0;
        let seller_currency_symbol = '';
        let seller_price = 0;
        let seller_price_per_kg = 0;
        let seller_total_price = 0;
        let seller_shipping_cost = 0;
        let seller_shipping_cost_per_package = 0;
        let seller_shipping_cost_per_kg = 0;
        let seller_cargo_price = 0;


        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: props?.data?.shipping_cost ? props?.data?.shipping_cost : 0,
          seller_currency: props?.data?.seller_currency,
          user_currency: props?.data?.user_currency,
          main_currency: props?.data?.main_currency,
          rate: props?.data?.rate,
          usd_rate: props?.data?.usd_rate,
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          user_currency_symbol = data?.user_currency_symbol;
          converted_price = data?.converted_price;
          converted_price_per_kg = data?.converted_price_per_kg;
          converted_total_price = data?.converted_total_price;
          converted_shipping_cost = data?.converted_shipping_cost;
          converted_shipping_cost_per_package = data?.converted_shipping_cost_per_package;
          converted_shipping_cost_per_kg = data?.converted_shipping_cost_per_kg;
          converted_cargo_price = data?.converted_cargo_price;
          seller_currency_symbol = data?.seller_currency_symbol;
          seller_price = data?.seller_price;
          seller_price_per_kg = data?.seller_price_per_kg;
          seller_total_price = data?.seller_total_price;
          seller_shipping_cost = data?.seller_shipping_cost;
          seller_shipping_cost_per_package = data?.shipping_cost_per_package;
          seller_shipping_cost_per_kg = data?.shipping_cost_per_kg;
          seller_cargo_price = data?.seller_cargo_price;
        }

        setPrice(converted_price);
        setTotalPrice(converted_total_price);
        setPricePerKg(converted_price_per_kg);
        setCargoPrice(converted_cargo_price)
        setShippingCost(converted_shipping_cost)
        setShippingCostPerPackage(converted_shipping_cost_per_package)
        setShippingCostPerKg(converted_shipping_cost_per_kg)
        setCurrencySymbol(user_currency_symbol)

        setSellerPrice(seller_price);
        setSellerTotalPrice(seller_total_price)
        setSellerPricePerKg(seller_price_per_kg);
        setSellerCargoPrice(seller_cargo_price)
        setSellerShippingCost(seller_shipping_cost)
        setSellerShippingCostPerPackage(seller_shipping_cost_per_package)
        setSellerShippingCostPerKg(seller_shipping_cost_per_kg)
        setSellerCurrencySymbol(seller_currency_symbol)
      }

      if (props?.tab == 'unshipped') {
        // if (props?.data?.unshipped_timestamp) {
        //   let order_status = unshippedOrderStatus(props?.data?.unshipped_timestamp);
        //   setOrderStatus(order_status);
        // }
        if (props?.data?.unshipped_timestamp) {
          let is_cancellation_period = cancellationPeriod(props?.data?.unshipped_timestamp);
          setIsCancellationPeriod(is_cancellation_period);
        }
        if (props?.data?.payment_status == 'paid') {
          setOrderStatus('Pickup Scheduled');
        } else {
          setOrderStatus('Confirmed')
        }
      } else {
        if (props?.tab == 'all') {
          if (props?.data?.order_status == 'unshipped') {
            if (props?.data?.payment_status == 'paid') {
              setOrderStatus('Pickup Scheduled');
            } else {
              setOrderStatus('Confirmed')
            }
          } else {
            setOrderStatus(props?.data?.order_status)
          }
        } else {
          setOrderStatus(props?.data?.order_status)
        }
      }
    }
  }, [props.data, props?.currencyRateList])

  const onViewDetail = (item) => {
    props.onOrderDetail(item?.order_uid)
  };

  const onViewCancelDetail = (item) => {
    props.onOrderDetail(item?.order_uid)
  }

  const onCancel = (item) => {
    props.onOrderCancel(item?.order_uid)
  };

  const onPayNow = (item) => {
    props.onOrderPayment(item?.order_uid)
  };

  const onTrackOrder = (item) => {
    window.open(item?.track_order, '_blank')
  }

  const onDocument = (val) => {
    setDocument(val);
    let list = [...props?.data?.document_list];
    let index = list.findIndex((x) => x.value == val);
    if (index >= 0) {
      window.open(list[index]['document_file'], '_blank')
    }
  };

  const onViewStatementDetail = (item) => {
    props.onOrderPaymentStatement(item?.order_uid)
  };

  let item = props.data;

  return (
    <>
      <TableCell>
        <Typography className={styles.skuText}>{item.sku}</Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        {item?.main_image ?
          <Grid item md={4} xs={12} className={styles.imgView}>
            <img src={item.main_image} className={styles.imgStyle}/>
          </Grid>
          : null}
        <Typography className={styles.productNameTitleText}>
          {(item?.variety ? item.variety : 'NIL Variety')
          + ', ' + (item?.name ? item.name : 'NIL')
          + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
          + ', ' + (item?.package_net_weight ?
            (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
            : 'NIL Packing')
          + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
        </Typography>
        <Typography className={styles.pricePerKgPackageText} mt={1}>({currencySymbol}{pricePerKg?.toFixed(2)} / Kg)
          ({currencySymbol}{price?.toFixed(2)} /
          Package)</Typography>
        <Typography className={styles.hsCodeText} mt={1}>HS
          Code: {item?.hs_code ? (item.hs_code + ' - ' + item.hs_code_title) : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.sellerText}>{item?.seller_name_entity ? item?.seller_name_entity : ''}
          {item?.seller_country ? '(' + COUNTRIES.filter(
            (x) => x.code == item?.seller_country
          )?.[0]?.label + ')' : ''}
        </Typography>
        <Typography
          className={styles.tgsinText} mt={1}>TGSIN: {item?.tgsin ? item?.tgsin : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.orderUidText}>{item?.order_uid ? '#' + item.order_uid : ''}</Typography>
        <Typography
          className={styles.OrderTimestampText} mt={1}>{item?.order_timestamp ? item.order_timestamp : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.orderQuantityText}>
          {item?.quantity ? item?.package_net_weight * item?.quantity + item?.unit : 0}
        </Typography>
        <Typography className={styles.quantityText} mt={1}>
          No. of Packages: {item?.quantity ? item?.quantity : 0}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.totalPriceText}>
          {currencySymbol}{totalPrice ? totalPrice?.toFixed(2) : 0}
        </Typography>
        <Typography className={styles.shippingCostText} mt={1}>
          Shipping Cost: {currencySymbol}{shippingCost ? shippingCost?.toFixed(2) : 0}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.sellerCargoPriceText}>
          {sellerCurrencySymbol}{sellerCargoPrice ? sellerCargoPrice?.toFixed(2) : 0}
        </Typography>
        <Typography className={styles.cargoPriceText} pl={3}>
          {cargoPrice ? '(' + currencySymbol + cargoPrice?.toFixed(2) + ')' : 0}
        </Typography>
      </TableCell>
      <TableCell>
        {/*<Typography*/}
        {/*  className={styles.pricePerKgText}>{currencySymbol}{(parseFloat(pricePerKg) + parseFloat(shippingCostPerKg))?.toFixed(2)} /*/}
        {/*  Kg</Typography>*/}
        {/*<Typography className={styles.pricePerPackageText}*/}
        {/*            mt={1}>{currencySymbol}{(parseFloat(price) + parseFloat(shippingCostPerPackage))?.toFixed(2)} /*/}
        {/*  Package</Typography>*/}

        <Typography
          className={styles.pricePerKgText}>
          {currencySymbol}{(parseFloat(cargoPrice) / parseFloat(item?.package_net_weight * item?.quantity))?.toFixed(7)} / Kg
        </Typography>
        <Typography className={styles.pricePerPackageText}
                    mt={1}>
          {currencySymbol}{(parseFloat(cargoPrice) / parseFloat(item?.quantity))?.toFixed(6)} / Package
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.shippingModeText}>
          Mode of Shipping: {item?.mode_of_shipping ? item.mode_of_shipping : ''}
        </Typography>
        <Typography className={styles.incotermText} mt={1}>
          Incoterm: {item?.incoterm ? item.incoterm : ''}
        </Typography>
      </TableCell>
      <TableCell>
        {item?.delivery_date ?
          <>
            <Typography className={styles.deliveryDateText}>
              Delivery Date:
            </Typography>
            <Typography className={styles.deliveryDateText} mb={1}>
              {item?.delivery_date ? moment(item?.delivery_date).format('DD-MM-YYYY') : '-'}
            </Typography>
          </>
          : null}
        <>
          <Typography className={styles.shippingDateText}>
            Ship by Date:
          </Typography>
          <Typography className={styles.shippingDateText}>
            {item?.shipping_date ? moment(item?.shipping_date).format('DD-MM-YYYY') : '-'}
          </Typography>
        </>
      </TableCell>
      <TableCell>
        <Typography className={styles.orderStatusText}>
          Order Status: {orderStatus ? orderStatus?.replaceAll("_", " ") : ''}
        </Typography>
        <Typography className={styles.paymentStatusText} mt={1}>
          Payment Status: {item?.payment_status ?
          (item.payment_status == 'half_paid' ? '50% Paid' : item.payment_status)
          : ''}
        </Typography>
      </TableCell>
      <TableCell>
        {props?.tab != 'all' ?
          <>
            {props?.tab == 'cancelled' ?
              <Box className={styles.btnView}>
                <FormButton
                  title={'View'}
                  buttonView={{
                    width: '100%',
                    color: '#000',
                    border: '1px solid #aaa',
                    backgroundColor: '#D8F0D4'
                  }}
                  bgColorStyleView={'#D8F0D4'}
                  onSubmit={() => onViewCancelDetail(item)}
                />
              </Box>
              :
              <Box className={styles.btnView}>
                <FormButton
                  title={'View Details'}
                  buttonView={{
                    width: '100%',
                    color: '#000',
                    border: '1px solid #aaa',
                    backgroundColor: '#D8F0D4'
                  }}
                  bgColorStyleView={'#D8F0D4'}
                  onSubmit={() => onViewDetail(item)}
                />
              </Box>}

            {props?.tab == 'shipped' && (item?.track_order) ?
              <Box className={styles.btnView} mt={1}>
                <FormButton
                  title={'Track Order'}
                  buttonView={{
                    width: '100%',
                    color: '#000',
                    border: '1px solid #aaa',
                    backgroundColor: '#D8F0D4'
                  }}
                  bgColorStyleView={'#D8F0D4'}
                  onSubmit={() => onTrackOrder(item)}
                />
              </Box>
              : null}

            {item?.order_booking_details_uid && (props?.tab != 'cancelled') ?
              <Box className={styles.btnView} mt={1}>
                <FormSelectBox
                  page={'OrderPendingList'}
                  placeholder={'View Documents'}
                  value={document}
                  setValue={(val) => onDocument(val)}
                  textInputViewStyle={{
                    backgroundColor: THEME_COLOR2,
                    color: '#000',
                    height: 39,
                    alignItems: 'center',
                    border: '1px solid #aaa',
                  }}
                  selectedItemViewStyle={{
                    backgroundColor: THEME_COLOR2,
                    color: '#000',
                    fontFamily: 'InterMedium',
                    fontSize: 14,
                  }}
                  placeholderColor={'#000'}
                  data={item?.document_list ? item.document_list : []}
                />
              </Box>
              : null}
            {(props?.tab == 'pending') || ((props?.tab == 'unshipped') && (isCancellationPeriod)) ?
              <Box className={styles.btnView} mt={1}>
                <FormButton
                  title={'Cancel Order'}
                  buttonView={{
                    width: '100%',
                    color: '#000',
                    border: '1px solid #aaa',
                    backgroundColor: THEME_COLOR4
                  }}
                  bgColorStyleView={THEME_COLOR4}
                  onSubmit={() => onCancel(item)}
                />
              </Box>
              : null}
            {((item?.order_booking_details_uid) && (!item?.order_payment) && (props?.tab == 'pending')) ?
              <Box className={styles.btnView} mt={1}>
                <FormButton
                  title={'Pay now'}
                  buttonView={{
                    width: '100%',
                    color: '#000',
                    border: '1px solid #aaa',
                    backgroundColor: THEME_COLOR5
                  }}
                  bgColorStyleView={THEME_COLOR5}
                  onSubmit={() => onPayNow(item)}
                />
              </Box>
              : null}
            {(item?.payment_status == 'half_paid') && (props?.tab == 'unshipped') ?
              <Box className={styles.btnView} mt={1}>
                <FormButton
                  title={'Pay Balance'}
                  buttonView={{
                    width: '100%',
                    color: '#000',
                    border: '1px solid #aaa',
                    backgroundColor: THEME_COLOR5
                  }}
                  bgColorStyleView={THEME_COLOR5}
                  onSubmit={() => onPayNow(item)}
                />
              </Box>
              : null}
          </>
          : <Box className={styles.btnView}>
            <FormButton
              title={'View'}
              buttonView={{
                width: '100%',
                color: '#000',
                border: '1px solid #aaa',
                backgroundColor: '#D8F0D4'
              }}
              bgColorStyleView={'#D8F0D4'}
              onSubmit={() => onViewStatementDetail(item)}
            />
          </Box>}
      </TableCell>

    </>
  )
};

export default OrderTabList;
