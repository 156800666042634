import moment from "moment";
import getSymbolFromCurrency from 'currency-symbol-map'

export const createRandomString = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
};

export const priceCalculation = (price_per_package, package_net_weight, referral_fee, quantity, shipping_cost) => {
  let data = null;
  if (price_per_package != '' && package_net_weight != '') {
    let price_val = (parseFloat((((price_per_package / package_net_weight) * referral_fee) / 100) + (price_per_package / package_net_weight)) * package_net_weight)
    let price_per_kg = price_val / package_net_weight;
    let total_price = quantity * price_val;
    let cargo_price = (parseFloat(total_price) + parseFloat(shipping_cost));
    data = {
      price_val: price_val,
      price_per_kg: price_per_kg,
      total_price: total_price,
      cargo_price: cargo_price,
    }
  }
  return data;
};

export const unshippedOrderStatus = (unshipped_timestamp) => {
  let a = moment(unshipped_timestamp, "hh:mm:ss A DD-MM-Y");
  let b = moment();
  let hours = b.diff(a, "hours");
  if (hours >= 24) {
    return 'Pickup Scheduled'
  } else {
    return 'Confirmed'
  }
};

export const cancellationPeriod = (unshipped_timestamp) => {
  let a = moment(unshipped_timestamp, "hh:mm:ss A DD-MM-Y");
  let b = moment();
  let hours = b.diff(a, "hours");
  if (hours >= 24) {
    return false
  } else {
    return true
  }
};

export const getCurrencyConversion = (price_per_package, package_net_weight, referral_fee, rate_list, seller_currency, user_currency) => {
  let data = null;
  if (price_per_package != '' && package_net_weight != '') {
    let price_val = (parseFloat((((price_per_package / package_net_weight) * referral_fee) / 100) + (price_per_package / package_net_weight)) * package_net_weight)
    let seller_price = price_val;
    let converted_price = price_val;
    let currency_val = seller_currency;
    let rate = 0;
    if (rate_list != null) {
      rate = rate_list['rates'][user_currency];
      let total = rate * price_val;
      converted_price = total;
      currency_val = user_currency;
    }
    let currency_symbol = getSymbolFromCurrency(currency_val)
    let seller_currency_symbol = getSymbolFromCurrency(seller_currency)
    data = {
      currency_symbol: currency_symbol,
      converted_price: converted_price,
      seller_currency_symbol: seller_currency_symbol,
      seller_price: seller_price,
      rate: rate
    }
  }
  return data;
};

export const getCurrencyConversionCalc = (data_list, rate_list = null) => {
  let data = null;
  if (data_list?.price_per_package != '' && data_list?.package_net_weight != ''
    && data_list?.seller_currency != '' && data_list?.user_currency != '') {
    let price_val = (parseFloat((((data_list?.price_per_package / data_list?.package_net_weight) * data_list?.referral_fee) / 100) + (data_list?.price_per_package / data_list?.package_net_weight)) * data_list?.package_net_weight)
    let price_per_kg_without_referral_fee = parseFloat(data_list?.price_per_package / data_list?.package_net_weight);
    let price_per_kg_referral_fee = parseFloat((price_per_kg_without_referral_fee * data_list?.referral_fee) / 100);
    let price_per_package_without_referral_fee = data_list?.price_per_package;
    let price_per_package_referral_fee = price_per_kg_referral_fee * data_list?.package_net_weight;
    let currency_val = data_list?.seller_currency;
    let main_currency = data_list?.seller_currency;
    let seller_price = price_val;
    let seller_price_per_kg = price_val / data_list?.package_net_weight;
    let seller_total_price = data_list?.quantity * price_val;
    let seller_shipping_cost = 0;
    let shipping_cost_per_package = data_list?.shipping_cost / data_list?.package_net_weight;
    let shipping_cost_per_kg = shipping_cost_per_package / data_list?.package_net_weight;
    let seller_cargo_price = seller_total_price;
    if (data_list?.shipping_cost != 0) {
    }

    let original_price = price_val;
    let original_price_per_kg_without_referral_fee = price_per_kg_without_referral_fee;
    let original_price_per_kg_referral_fee = price_per_kg_referral_fee;
    let original_price_per_package_without_referral_fee = price_per_package_without_referral_fee;
    let original_price_per_package_referral_fee = price_per_package_referral_fee;
    let original_shipping_cost = seller_shipping_cost;
    let original_shipping_cost_per_package = shipping_cost_per_package;
    let original_shipping_cost_per_kg = shipping_cost_per_kg;
    let original_price_per_kg = seller_price_per_kg;
    let original_cargo_price = seller_cargo_price;
    let original_total_price = seller_total_price;

    let converted_price = price_val;
    let converted_price_per_kg_without_referral_fee = price_per_kg_without_referral_fee;
    let converted_price_per_kg_referral_fee = price_per_kg_referral_fee;
    let converted_price_per_package_without_referral_fee = price_per_package_without_referral_fee;
    let converted_price_per_package_referral_fee = price_per_package_referral_fee;
    let converted_shipping_cost = seller_shipping_cost;
    let converted_shipping_cost_per_package = shipping_cost_per_package;
    let converted_shipping_cost_per_kg = shipping_cost_per_kg;
    let converted_price_per_kg = seller_price_per_kg;
    let converted_cargo_price = seller_cargo_price;
    let converted_total_price = seller_total_price;

    let rate = 0;
    let main_rate = 0;
    let main_rate_row = 0;
    let shipping_main_rate = 0;
    let shipping_cost_inr_to_user_rate = 0;
    if (data_list?.rate) {
      rate = data_list?.rate ? data_list?.rate : 0;
      main_rate_row = data_list?.usd_rate

      if (rate_list != null) {
        if (rate_list?.['rates']) {
          shipping_cost_inr_to_user_rate = 1 / rate_list['rates']['INR'];
          if (data_list?.main_currency) {
            shipping_main_rate = rate_list['rates'][data_list?.main_currency];
          }
        }
      }
    } else {
      if (rate_list == null) {
        if (data_list?.rate_list != null) {
          rate = data_list?.rate_list['rates'][data_list?.user_currency];
          if (data_list?.main_currency) {
            main_rate_row = data_list?.rate_list['rates'][data_list?.main_currency];
          }
        }
      } else {
        if (rate_list?.['rates']) {       // (user currency rate list)
          rate = 1 / rate_list['rates'][data_list?.seller_currency];   //(seller currency) to (user currency) rate
          shipping_cost_inr_to_user_rate = 1 / rate_list['rates']['INR']; //(admin inr currency) to (user currency) rate
          if (data_list?.main_currency) {
            main_rate = rate_list['rates'][data_list?.main_currency]; //(user currency) to (user usd currency) rate
          }
        }
      }
    }

    if (rate != 0) {
      let total = rate * price_val;
      converted_price_per_kg_without_referral_fee = rate * converted_price_per_kg_without_referral_fee;
      converted_price_per_kg_referral_fee = rate * converted_price_per_kg_referral_fee;
      converted_price_per_package_without_referral_fee = rate * converted_price_per_package_without_referral_fee;
      converted_price_per_package_referral_fee = rate * converted_price_per_package_referral_fee;

      converted_price = total;
      if (data_list?.shipping_cost != 0) {
        converted_shipping_cost = shipping_cost_inr_to_user_rate * data_list?.shipping_cost;
        converted_shipping_cost_per_package = shipping_cost_inr_to_user_rate * shipping_cost_per_package;
        converted_shipping_cost_per_kg = shipping_cost_inr_to_user_rate * shipping_cost_per_kg;
      }

      currency_val = data_list?.user_currency;

      converted_price_per_kg = converted_price / data_list?.package_net_weight;
      converted_total_price = data_list?.quantity * converted_price;
      converted_cargo_price = converted_total_price;
      if (data_list?.shipping_cost != 0) {
        converted_cargo_price = (parseFloat(converted_total_price) + parseFloat(converted_shipping_cost));
      }

      if (main_rate != 0 || main_rate_row != 0) {
        let total = (main_rate != 0 ? converted_price : original_price);
        price_per_kg_without_referral_fee = (main_rate != 0 ? converted_price_per_kg_without_referral_fee : original_price_per_kg_without_referral_fee);
        price_per_kg_referral_fee = (main_rate != 0 ? converted_price_per_kg_referral_fee : original_price_per_kg_referral_fee);
        price_per_package_without_referral_fee = (main_rate != 0 ? converted_price_per_package_without_referral_fee : original_price_per_package_without_referral_fee);
        price_per_package_referral_fee = (main_rate != 0 ? converted_price_per_package_referral_fee : original_price_per_package_referral_fee);
        if (main_rate != 0) {
          total = main_rate * converted_price;
          price_per_kg_without_referral_fee = main_rate * converted_price_per_kg_without_referral_fee;
          price_per_kg_referral_fee = main_rate * converted_price_per_kg_referral_fee;
          price_per_package_without_referral_fee = main_rate * converted_price_per_package_without_referral_fee;
          price_per_package_referral_fee = main_rate * converted_price_per_package_referral_fee;
        } else if (main_rate_row != 0) {
          total = main_rate_row * original_price;
          price_per_kg_without_referral_fee = main_rate_row * original_price_per_kg_without_referral_fee;
          price_per_kg_referral_fee = main_rate_row * original_price_per_kg_referral_fee;
          price_per_package_without_referral_fee = main_rate_row * original_price_per_package_without_referral_fee;
          price_per_package_referral_fee = main_rate_row * original_price_per_package_referral_fee;
        }
        if (data_list?.shipping_cost != 0) {
          seller_shipping_cost = main_rate * converted_shipping_cost;
          shipping_cost_per_package = main_rate * converted_shipping_cost_per_package;
          shipping_cost_per_kg = main_rate * converted_shipping_cost_per_kg;

          if (shipping_main_rate != 0) {
            seller_shipping_cost = shipping_main_rate * converted_shipping_cost;
            shipping_cost_per_package = shipping_main_rate * converted_shipping_cost_per_package;
            shipping_cost_per_kg = shipping_main_rate * converted_shipping_cost_per_kg;
          }
        }
        seller_price = total;
        seller_price_per_kg = seller_price / data_list?.package_net_weight;
        seller_total_price = data_list?.quantity * seller_price;
        seller_cargo_price = seller_total_price;
        if (data_list?.shipping_cost != 0) {
          seller_cargo_price = (parseFloat(seller_total_price) + parseFloat(seller_shipping_cost));
        }
        main_currency = data_list?.main_currency
      }

    }

    let user_currency_symbol = getSymbolFromCurrency(currency_val)
    let seller_currency_symbol = getSymbolFromCurrency(main_currency)

    data = {
      user_currency_symbol: user_currency_symbol,
      converted_price: converted_price,
      converted_price_per_kg: converted_price_per_kg,
      converted_total_price: converted_total_price,
      converted_shipping_cost: converted_shipping_cost,
      converted_cargo_price: converted_cargo_price,
      converted_price_per_kg_without_referral_fee: converted_price_per_kg_without_referral_fee,
      converted_price_per_kg_referral_fee: converted_price_per_kg_referral_fee,
      converted_price_per_package_without_referral_fee: converted_price_per_package_without_referral_fee,
      converted_price_per_package_referral_fee: converted_price_per_package_referral_fee,
      converted_shipping_cost_per_package: converted_shipping_cost_per_package,
      converted_shipping_cost_per_kg: converted_shipping_cost_per_kg,
      seller_currency_symbol: seller_currency_symbol,
      seller_price: seller_price,
      seller_price_per_kg: seller_price_per_kg,
      seller_total_price: seller_total_price,
      seller_shipping_cost: seller_shipping_cost,
      seller_cargo_price: seller_cargo_price,
      price_per_kg_without_referral_fee: price_per_kg_without_referral_fee,
      price_per_kg_referral_fee: price_per_kg_referral_fee,
      price_per_package_without_referral_fee: price_per_package_without_referral_fee,
      price_per_package_referral_fee: price_per_package_referral_fee,
      shipping_cost_per_package: shipping_cost_per_package,
      shipping_cost_per_kg: shipping_cost_per_kg,
      rate: rate
    }

  }
  return data;
};
