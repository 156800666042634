import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import {Grid, Menu, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useNavigate} from "react-router-dom";

import FormButton from "../components/FormButton";
import {getCurrencyConversion, getCurrencyConversionCalc} from "../assets/functions/common";

import {connect} from "react-redux";
import {
  alertMsgEmpty,
  updateAlertMsg,
  updateCartBtnText
} from "../redux/actions/userDataActions";

import {THEME_COLOR} from "../constants";

import {ReactComponent as CartIcon} from "../assets/img/cart_white.svg";
import {ReactComponent as LikeIcon} from "../assets/img/like.svg";
import {ReactComponent as CloseIcon} from "../assets/img/close.svg";
import {ReactComponent as FacebookIcon} from "../assets/img/facebook.svg";
import {ReactComponent as TwitterIcon} from "../assets/img/twitter.svg";
import {ReactComponent as LinkedinIcon} from "../assets/img/linkedin.svg";
import {ReactComponent as InstagramIcon} from "../assets/img/instagram.svg";

const ProductDetailModal = (props) => {
  const [productCount, setProductCount] = useState(1)
  const [showError, setShowError] = useState('');
  const [price, setPrice] = useState(0)
  const [currencySymbol, setCurrencySymbol] = useState('')

  const navigate = useNavigate();

  useEffect(() => {
    if (props.data) {
      if (props.data?.quantity) {
        setProductCount(props.data?.quantity);
        // props.updateCartBtnText('GO TO CART');
      } else {
        setProductCount(props.data?.minimum_order_quantity);
        // props.updateCartBtnText('ADD TO CART');
      }

      // let price_per_package = props?.data?.price_per_package;
      // let package_net_weight = props?.data?.package_net_weight;
      // let referral_fee = props?.data?.referral_fee;
      // let rate_list = props?.data?.rate_list;
      // let seller_currency = props?.data?.seller_currency;
      // let user_currency = props?.data?.user_currency;

      let data_list = {
        price_per_package: props?.data?.price_per_package,
        package_net_weight: props?.data?.package_net_weight,
        referral_fee: props?.data?.referral_fee,
        quantity: 0,
        shipping_cost: 0,
        seller_currency: props?.data?.seller_currency,
        user_currency: props?.data?.user_currency,
        // rate: props?.data?.rate,
      }

      // let data = getCurrencyConversion(price_per_package, package_net_weight, referral_fee, rate_list, seller_currency, user_currency)
      let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)

      if(data != null) {
        setPrice(data?.converted_price)
        setCurrencySymbol(data?.user_currency_symbol)
      }

    }
  }, [props.data, props?.currencyRateList]);

  const onAddToCartClick = () => {
    window.location.href = "/product_detail?id=" + props.data?.product_uid

    // if (props.userToken) {
    //   props.alertMsgEmpty();
    //
    //   if (props.cartButtonText == 'ADD TO CART') {
    //     if (productCount) {
    //       if (productCount < props.data?.minimum_order_quantity) {
    //         setShowError('MOQ is ' + props.data?.minimum_order_quantity)
    //         return;
    //       } else {
    //         setShowError('');
    //       }
    //
    //       if (props.cartButtonText == 'ADD TO CART') {
    //         let data = {
    //           productUid: props.data?.product_uid,
    //           categoryUid: props.data?.category_uid ? props.data?.category_uid : '',
    //           quantity: productCount,
    //           currency: props.currency
    //         };
    //         props.onAddToCart(data)
    //       }
    //     } else {
    //       setShowError('MOQ is ' + props.data?.minimum_order_quantity)
    //     }
    //   } else {
    //     navigate('/cart')
    //   }
    //
    // } else {
    //   props.updateAlertMsg({open: true, message: 'Account not activated / Not Logged', msgType: 'error'});
    // }
  };

  const addSubProduct = (type) => {
    setShowError('');
    let count = productCount;
    if (count != '') {
      if (type == 'add') {
        count = parseInt(count) + 1;
      } else if (type == 'sub') {
        if (count > props.data?.minimum_order_quantity) {
          count = parseInt(count) - 1;
        } else {
          setShowError('MOQ is ' + props.data?.minimum_order_quantity);
        }
      }
      setProductCount(count);

      if (props.data?.quantity != '') {
        updateCartBtn(count);
      }
    }
  };

  const onChangeProductCount = (value) => {
    setProductCount(value);

    if (value != '' && props.data?.quantity != '') {
      updateCartBtn(value);
    }
  };

  const updateCartBtn = (count) => {
    // if (count == props.data?.quantity) {
    //   props.updateCartBtnText('GO TO CART');
    // } else {
    //   props.updateCartBtnText('ADD TO CART');
    // }
  };

  const onCloseClick = () => {
    if (props.data?.quantity) {
      setProductCount(props.data?.quantity);
      // props.updateCartBtnText('GO TO CART');
    } else {
      setProductCount(props.data?.minimum_order_quantity);
      // props.updateCartBtnText('ADD TO CART');
    }
    setShowError('');
    props.onClose();
  };

  let item = props.data;

  return (
    <Modal
      disableScrollLock={false}
      open={props.modalVisible}
      onClose={onCloseClick}
      style={styles.modelView}
      hideBackdrop={true}>
      <Grid container item md={7} xs={12} sx={styles.modalSize} p={4}>
        <Box sx={styles.closeBtnView} onClick={onCloseClick} p={1}>
          <CloseIcon width={20} height={20}/>
        </Box>
        <Grid item md={5.5} xs={12} style={styles.imgMainView}>
          <Box style={styles.imgView}>
            {item.product_image ?
              <img src={item.product_image} style={styles.imgStyle}/>
              : null}
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography sx={styles.productName}>{item.product_name}</Typography>
          <Typography
            sx={styles.productPrice}>
            {currencySymbol} {price}
          </Typography>

          <Box style={styles.borderBottomLine} mb={3} mt={1}/>

          <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Grid item md={4} xs={12}>
              <Typography sx={styles.titleText}>category : </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography sx={styles.valueText}>{item.category}</Typography>
            </Grid>
          </Grid>
          <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}
                mt={1}>
            <Grid item md={4} xs={12}>
              <Typography sx={styles.titleText}>sub category : </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography sx={styles.valueText}>{item.sub_category}</Typography>
            </Grid>
          </Grid>

          <Box style={styles.borderBottomLine} mt={3} mb={3}/>

          <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            {/*<Grid item md={4} xs={12} sx={styles.addToCartView}>*/}
            {/*  <Grid item md={4} xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}*/}
            {/*        sx={styles.pointerView} onClick={() => addSubProduct('sub')}>*/}
            {/*    <Typography sx={styles.plusMinusNoText}>-</Typography>*/}
            {/*  </Grid>*/}
            {/*  <Grid item md={4} xs={4} display={"flex"} flexDirection={"row"}>*/}
            {/*    <Grid item md={0.5} xs={4} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>*/}
            {/*      <Box sx={styles.borderRightLine}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item md={11} xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>*/}
            {/*      <TextField*/}
            {/*        fullWidth*/}
            {/*        variant={"standard"}*/}
            {/*        value={productCount}*/}
            {/*        type={'text'}*/}
            {/*        onChange={e => onChangeProductCount(e.target.value.replace(/[^0-9]/g, ''))}*/}
            {/*        sx={{*/}
            {/*          '& .MuiInput-root': {*/}
            {/*            '&:before, :after, :hover:not(.Mui-disabled):before': {*/}
            {/*              borderBottom: 0,*/}
            {/*            },*/}
            {/*          },*/}
            {/*        }}*/}
            {/*        inputProps={{*/}
            {/*          autoComplete: 'none',*/}
            {/*          sx: styles.textInputValue,*/}
            {/*        }}*/}
            {/*        defaultValue={productCount}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*    <Grid item md={0.5} xs={4} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>*/}
            {/*      <Box sx={styles.borderLeftLine}/>*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*  <Grid item md={4} xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}*/}
            {/*        sx={styles.pointerView} onClick={() => addSubProduct('add')}>*/}
            {/*    <Typography sx={styles.plusMinusNoText}>+</Typography>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <Grid item md={10} xs={12} mt={{md: 0, xs: 2}}>
            {/*<Grid item md={6} xs={12} mt={{md: 0, xs: 2}}>*/}
              <FormButton
                loading={props?.cartBtnLoading}
                btnStyleView={styles.btnStyleView}
                leftIcon={<CartIcon height={18} width={18}/>}
                // title={props.cartButtonText}
                title={'Go to Detail'}
                onSubmit={onAddToCartClick}/>
            </Grid>
          </Grid>
          {showError ?
            <Box mt={1}>
              <Typography sx={styles.moqErrorText}>{showError}</Typography>
            </Box>
            : null}
          {/*<Box display={"flex"} flexDirection={"row"} alignItems={"center"} mt={3}>*/}
          {/*  <LikeIcon height={18} width={18}/>*/}
          {/*  <Typography sx={styles.wishlistText} ml={1}>Add to wishlist</Typography>*/}
          {/*</Box>*/}

          <Box display={"flex"} flexDirection="row" alignItems={"center"} mt={5}>
            <Typography sx={styles.shareText} mr={2}>Share:</Typography>
            <Box>
              <FacebookIcon height={20} width={20}/>
            </Box>
            <Box ml={2}>
              <TwitterIcon height={20} width={20}/>
            </Box>
            <Box ml={2}>
              <LinkedinIcon height={17} width={17}/>
            </Box>
            <Box ml={2}>
              <InstagramIcon height={22} width={22}/>
            </Box>
          </Box>

        </Grid>
      </Grid>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    userToken: state.userData.userToken,
    cartButtonText: state.userData.cartButtonText,
    cartBtnLoading: state.userData.cartBtnLoading,
    currency: state.userData.currency,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
    alertMsgEmpty: data => dispatch(alertMsgEmpty(data)),
    // updateCartBtnText: data => dispatch(updateCartBtnText(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailModal);

const styles = {
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalSize: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    minHeight: {md: window.innerHeight - 300, xs: 'unset'},
    height: {md: 'unset', xs: window.innerHeight},
    overflow: 'auto',
    "&:focus": {
      outline: 'none',
    },
  },
  closeBtnView: {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    top: 0,
  },
  imgMainView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgView: {
    height: 300,
  },
  imgStyle: {
    objectFit: 'contain',
    height: '100%',
    width: '100%'
  },
  productName: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 20
  },
  productPrice: {
    color: THEME_COLOR,
    fontFamily: 'InterBold',
    fontSize: {md: 50, xs: 40}
  },
  borderBottomLine: {
    borderBottom: '1px solid #DDD'
  },
  titleText: {
    color: '#000',
    fontFamily: 'InterRegular',
    fontSize: {md: 15, xs: 13},
  },
  valueText: {
    color: '#000',
    fontFamily: 'InterSemiBold',
    fontSize: {md: 15, xs: 13}
  },
  addToCartView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    border: '1px solid #DDD',
    height: {md: 50, xs: 40},
  },
  pointerView: {
    cursor: 'pointer'
  },
  plusMinusNoText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: {md: 20, xs: 17}
  },
  borderRightLine: {
    borderRight: '1px solid #DDD',
    height: {md: 50, xs: 40},
  },
  borderLeftLine: {
    borderLeft: '1px solid #DDD',
    height: {md: 50, xs: 40},
  },
  textInputView: {
    backgroundColor: '#f0efef',
  },
  textInputValue: {
    color: '#000',
    fontFamily: 'InterRegular',
    fontSize: 14,
    textAlign: 'center'
  },
  btnStyleView: {
    fontSize: {md: 12, xs: 11},
    height: {md: 50, xs: 40},
    width: '100%',
    marginLeft: {md: 2, xs: 0}
  },
  moqErrorText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 12,
  },
  shareText: {
    color: '#555',
    fontFamily: 'InterRegular',
    fontSize: {md: 16, xs: 14}
  },
};
