import React, {useEffect, useState} from 'react'
import {
  Grid,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useNavigate} from "react-router-dom";

import ItemCategoryTabList from "./ItemCategoryTabList";
import ItemProductList from "./ItemProductList";
import ProductDetailModal from "./ProductDetailModal";
import AlertMsg from "./AlertMsg";

import {connect} from "react-redux";
import {addToCart, updateApiStatus} from "../redux/actions/userDataActions";

import {THEME_COLOR2} from "../constants";

const HomeProduct = (props) => {
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState(null)
  const [productDetailModalVisible, setShowProductDetailModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null)

  const navigate = useNavigate();

  useEffect(() => {
    if (props.homeData != null && selectedProduct != null) {
      let data_list = [...props.homeData?.dataList];
      if (data_list.length > 0) {
        let categoryIndex = data_list.findIndex(x => x.cat_id == selectedCategoryProducts.cat_id);
        if (categoryIndex >= 0) {
          let cat_product_list = [...data_list?.[categoryIndex]?.products];
          let categoryProductIndex = cat_product_list.findIndex(x => x.product_uid == selectedProduct.product_uid);
          if (categoryProductIndex >= 0) {
            setSelectedProduct(cat_product_list[categoryProductIndex]);
          }
        }
      }
    } else if (props.homeData != null) {
      let data_list = [...props.homeData?.dataList];
      if (data_list.length > 0) {
        let selected_category_products = props?.homeData?.dataList?.[0];
        setSelectedCategoryProducts(selected_category_products);
      } else {
        setSelectedCategoryProducts(null)
      }
    }
  }, [props.homeData, selectedProduct]);

  const onSelectCategoryProductTab = (val) => {
    setSelectedCategoryProducts(val)
  };

  const onProductDataClick = (item, type) => {
    if (type == 'view') {
      navigate('/product_detail?id=' + item.product_uid)
    } else if (type == 'cart') {
      setSelectedProduct(item);
      setShowProductDetailModalVisible(true)
    }
  };

  const onProductDetailClose = () => {
    setShowProductDetailModalVisible(false)
    setSelectedProduct(null)
  };

  const onAddToCartClick = (data) => {
    props.addToCart(JSON.stringify(data));
  };

  return (
    <Grid container item md={10} xs={10} display={"flex"} flexDirection={"column"}
          justifyContent={"center"} mt={8}>
      <Box mb={1}>
        <Typography sx={style.productTitleText}>Dive into Our Diverse Product Selection</Typography>
        <Typography sx={style.productSubTitleText} mt={1}>Explore a world of trade possibilities with Trebbs diverse
          product categories.</Typography>
      </Box>

      <Grid display={"flex"} flexDirection={"row"}
            flexWrap={"wrap"} alignItems={"center"}
            container item md={12} xs={12} mb={3}>
        {props?.homeData != null ?
          props?.homeData?.dataList.filter(x => x.products.length > 0).map((item, index) => {
            return (
              <Box key={index} mt={4}>
                <ItemCategoryTabList
                  key={index}
                  index={index}
                  length={props?.homeData?.dataList.filter(x => x.products.length > 0).length - 1}
                  data={item}
                  selected={selectedCategoryProducts}
                  onSelect={onSelectCategoryProductTab}/>
              </Box>
            )
          }) : null}
      </Grid>

      <Grid display={"flex"} flexDirection={"row"}
            flexWrap={"wrap"} alignItems={"center"}
            justifyContent={{md: "unset", xs: "space-between"}}
            container item md={12} xs={12}>
        {selectedCategoryProducts?.products?.map((item, index) => {
          return (
            <Grid key={index} item md={2.8} xs={5.6} mr={{md: 1, xs: 0}} mt={2}>
              <ItemProductList
                type={'categoryProducts'}
                data={item}
                onProduct={() => null}
                currencyRateList={props?.currencyRateList}
                onSubmit={onProductDataClick}
              />
            </Grid>
          )
        })}
      </Grid>

      {selectedProduct != null ?
        <ProductDetailModal
          modalVisible={productDetailModalVisible}
          currencyRateList={props?.currencyRateList}
          data={selectedProduct}
          onClose={onProductDetailClose}
          onAddToCart={onAddToCartClick}/>
        : null}

      <AlertMsg/>
    </Grid>
  )
};

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
    userToken: state.userData.userToken,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: data => dispatch(addToCart(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeProduct);

const style = {
  productTitleText: {
    color: '#000',
    fontFamily: "InterBold",
    fontSize: 28,
    lineHeight: {md: 'unset', xs: '27px'}
  },
  productSubTitleText: {
    color: '#aaa',
    fontFamily: "InterMedium",
    fontSize: 14,
  },
};

