import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormButton from "./FormButton";
import FormTextInput from "./FormTextInput";

import './../assets/css/custom.css';

import {THEME_COLOR, THEME_COLOR2, THEME_COLOR3} from "../constants";

import {connect} from "react-redux";

const MailVerification = (props) => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(null);

  const onVerifyMailOtp = () => {
    let errorText = {};
    if (otp == '') {
      errorText = {otp: 'Otp is required'};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      otp: otp
    }
    props.onVerifyMailOtp(data)
  };

  const onUpdateBusinessDetails = () => {
    props.onUpdateBusinessDetails()
  };

  const onResendOtp = () => {
    let data = {
      email: props.userEmail
    }
    props.onResendOtpClick(data)
  };

  return (
    <Grid
      container
      item xs={8} md={8}
      display={'flex'} flexDirection={'column'}
      alignItems={props.isUserVerified ? 'unset' : 'center'}
    >


      {!props.isUserVerified ?
        <Grid item md={4} xs={4} sx={styles.boxView} p={2.5}
        >
          <Grid item md={12} xs={12}>
            <Typography sx={styles.verifyEmailText}>Verify e-mail</Typography>
          </Grid>

          <Grid item md={12} xs={12} mt={2}>
            <Typography sx={styles.otpDescText}>A mail with a One Time Password(OTP) has been sent to your
              mail: {props.userEmail}</Typography>
          </Grid>

          <Grid item md={12} xs={12} mt={2} display={'flex'}
                flexDirection={'row'} justifyContent={'space-between'}>
            <Typography sx={styles.enterOtpText}>Enter OTP</Typography>
            <Typography
              onClick={() => onResendOtp()}
              sx={styles.resendOtpText}>Resend OTP</Typography>
          </Grid>

          <Grid item md={12} xs={12} container>
            <FormTextInput
              page={'MailVerification'}
              value={otp}
              setValue={setOtp}
              error={error?.otp}
              textInputViewStyle={styles.textInputViewStyle}
              required={true}
            />
          </Grid>

          <Grid item md={12} xs={12} container
                display="flex" flexDirection="row" justifyContent="center" pt={2}>
            <FormButton
              btnStyleView={styles.btnStyleView}
              title={'Verify mail'}
              onSubmit={onVerifyMailOtp}
            />
          </Grid>

          <Grid container item md={12} xs={12} pt={2}
                display="flex" flexDirection="row" alignItems={'center'}>
            <Typography sx={styles.userAgreementText} pr={0.5}>By creating an account you agree to Trebbs</Typography>
            <Typography sx={styles.userAgreementTermsText} pr={0.5}>Terms and Conditions</Typography>
            <Typography sx={styles.userAgreementText} pr={0.5}>Please see our</Typography>
            <Typography sx={styles.userAgreementTermsText} pr={0.5}>Privacy Policy, Buyer
              agreement</Typography>
            <Typography sx={styles.userAgreementText} pr={0.5}>and</Typography>
            <Typography sx={styles.userAgreementTermsText}>Related documents</Typography>
          </Grid>
        </Grid>
        :
        <>
          <Grid item md={12} xs={12} sx={styles.mailVerifiedSuccessView} px={2} py={1}>
            <Typography sx={styles.mailVerifiedSuccessText}>Your mail has been verified !!!</Typography>
          </Grid>
          <Grid item md={12} xs={12} mt={10} px={2} py={1}>
            <Typography sx={styles.confirmMsgText}>A confirmation message has been sent to your mail
              address</Typography>
            <Box mt={3}>
              <Typography sx={styles.enableText}>Please complete the registration process to enable your Trebbs Global
                Seller
                Account
                (TGSA).</Typography>
            </Box>
          </Grid>
          <Grid item md={12} xs={12} container
                display="flex" flexDirection="row" justifyContent="right" mt={4}>
            <Box>
              <FormButton
                title={'Update Business details'}
                onSubmit={onUpdateBusinessDetails}
              />
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    userEmail: state.userData.userEmail,
    isUserVerified: state.userData.isUserVerified,
  };
};

export default connect(mapStateToProps, null)(MailVerification);

const styles = {
  boxView: {
    border: '1px solid ' + THEME_COLOR2,
    backgroundColor: THEME_COLOR2
  },
  verifyEmailText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 17
  },
  otpDescText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 12
  },
  enterOtpText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 12
  },
  resendOtpText: {
    cursor: 'pointer',
    color: THEME_COLOR3,
    fontFamily: 'InterMedium',
    fontSize: 12
  },
  btnStyleView: {
    width: '100%'
  },
  userAgreementText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 12,
  },
  userAgreementTermsText: {
    color: THEME_COLOR3,
    fontFamily: 'InterMedium',
    fontSize: 12,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
    height: 40
  },
  mailVerifiedSuccessView: {
    border: '1px solid #aaa'
  },
  mailVerifiedSuccessText: {
    color: THEME_COLOR,
    fontFamily: 'InterBold',
    fontSize: 15
  },
  confirmMsgText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 13
  },
  enableText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 13
  }
};

