import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormCountrySelectBox from "./FormCountrySelectBox";
import FormSelectBox from "./FormSelectBox";
import FormButton from "./FormButton";

import './../assets/css/custom.css';

import {COUNTRIES} from "../constants/countries";

import {ReactComponent as CheckboxIcon} from "../assets/img/checkbox.svg";
import {ReactComponent as UnCheckboxIcon} from "../assets/img/uncheckbox.svg";

import {THEME_COLOR3} from "../constants";

const AddCorporateDetails = (props) => {
  const [country, setCountry] = useState(null);
  const [typeOfEntity, setTypeOfEntity] = useState('');
  const [nameOfEntity, setNameOfEntity] = useState('');

  useEffect(() => {
    if (props.data != null) {
      let country = props.data.corporateCountry;
      let type_of_entity = props.data.typeOfEntity;
      let name_of_entity = props.data.nameOfEntity;

      let list = [...props?.entityTypeList]
      let index = props?.entityTypeList?.findIndex((x) => x.value == type_of_entity);
      if (index >= 0) {
        setTypeOfEntity(list[index]['value']);
      }

      let country_list = [...COUNTRIES]
      let country_index = country_list?.findIndex((x) => x.code == country);
      if (country_index >= 0) {
        setCountry(country_list[country_index]);
      }

      setNameOfEntity(name_of_entity);
    }
  }, [props.data]);

  const onContinueClick = () => {
    let errorText = {};
    let data = {
      corporateCountry: country,
      typeOfEntity: typeOfEntity,
      nameOfEntity: nameOfEntity,
    };

    if (props?.agreeBox == false) {
      errorText = {agreeTerms: 'Please Agree the terms & conditions'};
    }
    if (Object.keys(errorText).length != 0) {
      props?.setError(errorText);
      return;
    }

    props?.setError(null)
    props.onAddCorporateDetailClick(data)
  };

  const onTermsClick = (e) => {
    e.stopPropagation();
    window.open('buyer_agreement', "_blank")
  };

  const onAgreeBoxClick = () => {
    props?.setAgreeBox(!props?.agreeBox)
  };

  return (
    <Grid
      container
      item xs={8} md={8}
      display={'flex'} flexDirection={'column'}
    >

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormCountrySelectBox
          disabled={true}
          page={'AddCorporateDetails'}
          type={"code"}
          value={country}
          setValue={setCountry}
          label={'Country'}
          placeholder={'Select a Country'}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormSelectBox
          disabled={true}
          page={'AddCorporateDetails'}
          value={typeOfEntity}
          setValue={setTypeOfEntity}
          data={props.entityTypeList}
          label={"Type of Entity"}
          placeholder={'Select'}
          textInputViewStyle={styles.textInputViewStyle}
          selectedItemViewStyle={styles.selectedItemViewStyle}
          required={true}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          disabled={true}
          page={'AddCorporateDetails'}
          value={nameOfEntity}
          setValue={setNameOfEntity}
          label={'Name of Entity'}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Typography sx={styles.buyerAgreementTitleText} mb={2} mt={3}>Buyer Agreement</Typography>

      <Grid item md={12} xs={12}
            onClick={() => onAgreeBoxClick()}
            display="flex" flexDirection="row" alignItems={'center'}
            sx={styles.agreeBoxView}
            ml={2}>
        <Box mt={0.3}>
          {props?.agreeBox ?
            <CheckboxIcon height={18} width={18}/>
            :
            <UnCheckboxIcon height={18} width={18}/>
          }
        </Box>
        <Box pl={1}
             display="flex" flexDirection="row" alignItems={'center'}>
          <Typography sx={styles.buyerAgreementText}>I have read and agree to comply with and/or bound by
            the</Typography>
          <Typography sx={styles.buyerAgreementTermsText} pl={0.5} pr={0.5} onClick={(e) => onTermsClick(e)}>terms and
            conditions</Typography>
          <Typography sx={styles.buyerAgreementText}>of Trebbs</Typography>
        </Box>
      </Grid>
      {props?.error?.agreeTerms ?
        <Grid item md={12} xs={12} mt={1} styles={styles.errorView} pl={1}>
          <Typography sx={styles.errorText}>{props?.error?.agreeTerms}</Typography>
        </Grid>
        : null}

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="right" mt={4}>
        <Box>
          <FormButton
            title={'Continue'}
            onSubmit={onContinueClick}
          />
        </Box>
      </Grid>

    </Grid>
  );
}

export default AddCorporateDetails;

const styles = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  agreeBoxView: {
    cursor: 'pointer'
  },
  buyerAgreementTitleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  buyerAgreementText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 13,
  },
  buyerAgreementTermsText: {
    color: THEME_COLOR3,
    fontFamily: 'InterMedium',
    fontSize: 13,
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 12
  }
};

