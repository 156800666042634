import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import moment from 'moment';

import FormButton from "./FormButton";
import {getCurrencyConversion, getCurrencyConversionCalc} from "../assets/functions/common";

import styles from "./CheckoutList.module.css"

import {ReactComponent as CheckBoxIcon} from "../assets/img/checkbox.svg";
import {ReactComponent as UnCheckBoxIcon} from "../assets/img/uncheckbox.svg";
import {THEME_COLOR3} from "../constants";

import {COUNTRIES} from "../constants/countries";

const CheckoutList = (props) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [pricePerKgWithoutReferralFee, setPricePerKgWithoutReferralFee] = useState(0);
  const [pricePerKgReferralFee, setPricePerKgReferralFee] = useState(0);
  const [pricePerPackageWithoutReferralFee, setPricePerPackageWithoutReferralFee] = useState(0);
  const [pricePerPackageReferralFee, setPricePerPackageReferralFee] = useState(0);
  const [shippingCostPerPackage, setShippingCostPerPackage] = useState(0);
  const [shippingCostPerKg, setShippingCostPerKg] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [rate, setRate] = useState(0)
  const [sellerTotalPrice, setSellerTotalPrice] = useState(0);
  const [sellerPrice, setSellerPrice] = useState(0);
  const [sellerCargoPrice, setSellerCargoPrice] = useState(0);
  const [sellerPricePerKg, setSellerPricePerKg] = useState(0);
  const [sellerPricePerKgWithoutReferralFee, setSellerPricePerKgWithoutReferralFee] = useState(0);
  const [sellerPricePerKgReferralFee, setSellerPricePerKgReferralFee] = useState(0);
  const [sellerPricePerPackageWithoutReferralFee, setSellerPricePerPackageWithoutReferralFee] = useState(0);
  const [sellerPricePerPackageReferralFee, setSellerPricePerPackageReferralFee] = useState(0);
  const [sellerShippingCostPerPackage, setSellerShippingCostPerPackage] = useState(0);
  const [sellerShippingCostPerKg, setSellerShippingCostPerKg] = useState(0);
  const [sellerShippingCost, setSellerShippingCost] = useState(0)
  const [sellerCurrencySymbol, setSellerCurrencySymbol] = useState('')
  const [showError, setShowError] = useState('');
  // const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (props.data) {
      setShowError(props.data?.error);
    }
  }, [props.data?.error])

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {

        let price_val = 0;
        let total_price = 0;
        let price_per_kg = 0;
        let cargo_price = 0;
        let shipping_cost = 0;
        let price_per_kg_without_referral_fee = 0;
        let price_per_kg_referral_fee = 0;
        let price_per_package_without_referral_fee = 0;
        let price_per_package_referral_fee = 0;
        let shipping_cost_per_package = 0;
        let shipping_cost_per_kg = 0;
        let rate = 0;
        let seller_price_val = 0;
        let seller_total_price = 0;
        let seller_price_per_kg = 0;
        let seller_cargo_price = 0;
        let seller_shipping_cost = 0;
        let seller_price_per_kg_without_referral_fee = 0;
        let seller_price_per_kg_referral_fee = 0;
        let seller_price_per_package_without_referral_fee = 0;
        let seller_price_per_package_referral_fee = 0;
        let seller_shipping_cost_per_package = 0;
        let seller_shipping_cost_per_kg = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props.data?.quantity,
          shipping_cost: props.data?.shipping_details?.shipping_cost ? props.data?.shipping_details?.shipping_cost : 0,
          seller_currency: props?.data?.seller_currency,
          user_currency: props?.data?.user_currency,
          main_currency: props?.data?.main_currency,
        }


        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          rate = data?.rate;
          price_val = data?.converted_price;
          price_per_kg = data?.converted_price_per_kg;
          total_price = data?.converted_total_price;
          shipping_cost = data?.converted_shipping_cost;
          cargo_price = data?.converted_cargo_price;
          price_per_kg_without_referral_fee = data?.converted_price_per_kg_without_referral_fee;
          price_per_kg_referral_fee = data?.converted_price_per_kg_referral_fee;
          price_per_package_without_referral_fee = data?.converted_price_per_package_without_referral_fee;
          price_per_package_referral_fee = data?.converted_price_per_package_referral_fee;
          shipping_cost_per_package = data?.converted_shipping_cost_per_package;
          shipping_cost_per_kg = data?.converted_shipping_cost_per_kg;

          seller_price_val = data?.seller_price;
          seller_price_per_kg = data?.seller_price_per_kg;
          seller_total_price = data?.seller_total_price;
          seller_shipping_cost = data?.seller_shipping_cost;
          seller_cargo_price = data?.seller_cargo_price;
          seller_cargo_price = data?.seller_cargo_price;
          seller_price_per_kg_without_referral_fee = data?.price_per_kg_without_referral_fee;
          seller_price_per_kg_referral_fee = data?.price_per_kg_referral_fee;
          seller_price_per_package_without_referral_fee = data?.price_per_package_without_referral_fee;
          seller_price_per_package_referral_fee = data?.price_per_package_referral_fee;
          seller_shipping_cost_per_package = data?.shipping_cost_per_package;
          seller_shipping_cost_per_kg = data?.shipping_cost_per_kg;

          setCurrencySymbol(data?.user_currency_symbol)
          setSellerCurrencySymbol(data?.seller_currency_symbol)
        }

        setPrice(price_val);
        setTotalPrice(total_price);
        setPricePerKg(price_per_kg);
        setCargoPrice(cargo_price)
        setShippingCost(shipping_cost)
        setPricePerKgWithoutReferralFee(price_per_kg_without_referral_fee)
        setPricePerKgReferralFee(price_per_kg_referral_fee)
        setPricePerPackageWithoutReferralFee(price_per_package_without_referral_fee)
        setPricePerPackageReferralFee(price_per_package_referral_fee)
        setShippingCostPerPackage(shipping_cost_per_package)
        setShippingCostPerKg(shipping_cost_per_kg)
        setRate(rate);
        setSellerPrice(seller_price_val);
        setSellerTotalPrice(seller_total_price)
        setSellerPricePerKg(seller_price_per_kg);
        setSellerCargoPrice(seller_cargo_price)
        setSellerShippingCost(seller_shipping_cost)
        setSellerPricePerKgWithoutReferralFee(seller_price_per_kg_without_referral_fee)
        setSellerPricePerKgReferralFee(seller_price_per_kg_referral_fee)
        setSellerPricePerPackageWithoutReferralFee(seller_price_per_package_without_referral_fee)
        setSellerPricePerPackageReferralFee(seller_price_per_package_referral_fee)
        setSellerShippingCostPerPackage(seller_shipping_cost_per_package)
        setSellerShippingCostPerKg(seller_shipping_cost_per_kg)
      }
    }
  }, [props.data, props?.currencyRateList]);

  useEffect(() => {
    if (cargoPrice > 0 && currencySymbol) {
      props?.setCargoPrice(cargoPrice)
      props?.setCurrencySymbol(currencySymbol)
    }
  }, [cargoPrice, currencySymbol])

  useEffect(() => {
    if (sellerCargoPrice > 0 && sellerCurrencySymbol) {
      props?.setSellerCargoPrice(sellerCargoPrice)
      props?.setSellerCurrencySymbol(sellerCurrencySymbol)
    }
  }, [sellerCargoPrice, sellerCurrencySymbol])

  useEffect(() => {
    if (rate > 0) {
      props?.setRate(rate)
    }
  }, [rate])

  // useEffect(() => {
  //   let list = [...props?.checkedData];
  //   let index = list.findIndex((x) => x?.cart_uid == props?.data?.cart_uid);
  //
  //   if (index >= 0) {
  //     setChecked(true)
  //   } else {
  //     setChecked(false)
  //   }
  // }, [props?.checkedData])

  // const onCheckboxClick = (item) => {
  //   props.onCheck(item)
  // };

  let item = props.data;

  return (
    <>
      {/*<TableCell*/}
      {/*  onClick={() => onCheckboxClick(item)}*/}
      {/*  className={styles.checkboxView}>*/}
      {/*  {checked ?*/}
      {/*    <CheckBoxIcon height={25} width={25}/>*/}
      {/*    : <UnCheckBoxIcon height={25} width={25}/>}*/}
      {/*</TableCell>*/}
      <TableCell>
        <Typography className={styles.productNameText}>#{item.cart_uid}</Typography>
        {showError ?
          <Box mt={1}>
            <Typography className={styles.moqErrorText}>{showError}</Typography>
          </Box>
          : null}
      </TableCell>
      <TableCell>
        <Typography className={styles.productNameText}>#{item?.shipping_details?.cargo_id}</Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        {item.product_image ?
          <Grid item md={4} xs={12} className={styles.imgView}>
            <img src={item.product_image} className={styles.imgStyle}/>
          </Grid>
          : null}
        <Typography className={styles.productNameTitleText}>
          {(item?.variety ? item.variety : 'NIL Variety')
          + ', ' + (item?.name ? item.name : 'NIL')
          + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
          + ', ' + (item?.package_net_weight ?
            (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
            : 'NIL Packing')
          + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
        </Typography>
        <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          (<Typography className={styles.subPricePerKgText}>{currencySymbol}{pricePerKg?.toFixed(2)} / Kg</Typography>
          <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
        </Box>
        <Box mt={0.4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          (<Typography className={styles.subPricePerKgText}>{currencySymbol}{price?.toFixed(2)} / Package</Typography>
          <Typography className={styles.exwText} pl={1}>(EXW)</Typography>)
        </Box>
        <Typography className={styles.hsCodeText} mt={1}>HS Code: {item?.hs_code ? item.hs_code : ''}</Typography>
        <Typography className={styles.nameEntityText}>{item?.seller_name_entity ? item?.seller_name_entity : ''}</Typography>
        <Typography
          className={styles.productNameText}>{item?.seller_country ? '(' + COUNTRIES.filter(
          (x) => x.code == item?.seller_country
        )?.[0]?.label + ')' : ''}</Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.deliveryAddressText}>
          {item?.delivery_address?.state_province ?
            (item?.delivery_address?.address_line_1
              + ',' + item?.delivery_address?.address_line_2
              + ',' + item?.delivery_address?.city
              + ',' + item?.delivery_address?.state_province
              + ',' + item?.delivery_address?.zip_code
            )
            : '-'}
        </Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.shippingDetailText}>
          Total number of packages : {item?.quantity ? item?.quantity : 0}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Total product quantity
          : {item?.package_net_weight ? (item?.quantity * item?.package_net_weight + (item?.unit ? item.unit : '')) : 0}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Shipping method : {item?.shipping_details?.shipping_mode ? item?.shipping_details?.shipping_mode : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Container type : {item?.shipping_details?.container_type ? item?.shipping_details?.container_type : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Shipping date
          : {item?.shipping_details?.shipping_date ? moment(item?.shipping_details?.shipping_date).format('DD-MM-YYYY') : '-'}
        </Typography>
        {item?.shipping_details?.delivery_date ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Delivery date
            : {item?.shipping_details?.delivery_date ? moment(item?.shipping_details?.delivery_date).format('DD-MM-YYYY') : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.carrier ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Carrier: {item?.shipping_details?.carrier ? item?.shipping_details?.carrier : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.loading_port ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Port of loading: {item?.shipping_details?.loading_port ? item?.shipping_details?.loading_port : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.discharge_port ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Port of discharge: {item?.shipping_details?.discharge_port ? item?.shipping_details?.discharge_port : '-'}
          </Typography>
          : null}
      </TableCell>
      <TableCell>
        {item?.shipping_details?.payment_term ?
          <>
            <Typography
              className={styles.paymentTermText}>{item?.shipping_details?.payment_term}</Typography>
            <Typography
              className={styles.paymentTermDescText}>({item?.shipping_details?.payment_term_description})</Typography>
          </>
          : '-'}
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography
          className={styles.subPriceText} mt={1}>
          {sellerCurrencySymbol} {sellerTotalPrice?.toFixed(2)}
        </Typography>
        {/*<Typography className={styles.subPricePerKgText} pl={3}>{sellerCurrencySymbol}{sellerPricePerKg} /*/}
        {/*  kg</Typography>*/}

        <Typography
          className={styles.subPriceText}>
          ({currencySymbol} {totalPrice?.toFixed(2)})
        </Typography>
        {/*<Typography className={styles.subPricePerKgText} pl={3}>({currencySymbol}{pricePerKg?.toFixed(2)} / kg)</Typography>*/}
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.calculationPendingText}>
          {sellerCurrencySymbol} {sellerShippingCost ? sellerShippingCost?.toFixed(2) : '0'}
        </Typography>
        <Typography className={styles.calculationPendingText} pl={3}>
          {currencySymbol} {shippingCost ? shippingCost?.toFixed(2) : '0'}
        </Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography
          className={styles.subPriceText}>
          {sellerCurrencySymbol} {sellerCargoPrice?.toFixed(2)}
        </Typography>
        <Typography className={styles.labelText}>
          Total Price/Package:
        </Typography>
        <Typography
          className={styles.labelText2}>
         {sellerCurrencySymbol} {(parseFloat(sellerCargoPrice) / parseFloat(item?.quantity))?.toFixed(2)}
        </Typography>
        {/*({sellerCurrencySymbol} {(parseFloat(sellerPrice) + parseFloat(sellerShippingCostPerPackage))?.toFixed(2)})*/}

        <Typography
          className={styles.subPriceText} pl={3} mt={1}>
          {cargoPrice ? '(' + currencySymbol + cargoPrice?.toFixed(2) + ')' : ''}
        </Typography>
        <Typography className={styles.labelText}>
          Total Price/Package:
        </Typography>
        <Typography
          className={styles.labelText2} pl={3}>
          ({currencySymbol} {(parseFloat(cargoPrice) / parseFloat(item?.quantity))?.toFixed(2)})
        </Typography>
        {/*({currencySymbol} {(parseFloat(price) + parseFloat(shippingCostPerPackage))?.toFixed(2)})*/}
      </TableCell>


    </>
  )
};

export default CheckoutList;
