import * as actionTypes from './types';

export const getHomePage = data => ({
  type: actionTypes.GET_HOME_PAGE,
  payload: data,
});

export const getHomePageSuccess = data => ({
  type: actionTypes.GET_HOME_PAGE_SUCCESS,
  payload: data,
});

export const getHomePageFail = data => ({
  type: actionTypes.GET_HOME_PAGE_FAIL,
  payload: data,
});

//login
export const loginUser = data => ({
  type: actionTypes.LOGIN_USER,
  payload: data,
});

export const loginUserSuccess = data => ({
  type: actionTypes.LOGIN_USER_SUCCESS,
  payload: data,
});

export const loginUserFail = data => ({
  type: actionTypes.LOGIN_USER_FAIL,
  payload: data,
});

export const forgotUserName = data => ({
  type: actionTypes.FORGOT_USER_NAME,
  payload: data,
});

export const forgotUserNameSuccess = data => ({
  type: actionTypes.FORGOT_USER_NAME_SUCCESS,
  payload: data,
});

export const forgotUserNameFail = data => ({
  type: actionTypes.FORGOT_USER_NAME_FAIL,
  payload: data,
});

export const forgotUserPassword = data => ({
  type: actionTypes.FORGOT_USER_PASSWORD,
  payload: data,
});

export const forgotUserPasswordSuccess = data => ({
  type: actionTypes.FORGOT_USER_PASSWORD_SUCCESS,
  payload: data,
});

export const forgotUserPasswordFail = data => ({
  type: actionTypes.FORGOT_USER_PASSWORD_FAIL,
  payload: data,
});

export const updateForgotUserPassword = data => ({
  type: actionTypes.UPDATE_FORGOT_USER_PASSWORD,
  payload: data,
});

export const updateForgotUserPasswordSuccess = data => ({
  type: actionTypes.UPDATE_FORGOT_USER_PASSWORD_SUCCESS,
  payload: data,
});

export const updateForgotUserPasswordFail = data => ({
  type: actionTypes.UPDATE_FORGOT_USER_PASSWORD_FAIL,
  payload: data,
});

export const getForgotUserPassword = data => ({
  type: actionTypes.GET_FORGOT_USER_PASSWORD,
  payload: data,
});

export const getForgotUserPasswordSuccess = data => ({
  type: actionTypes.GET_FORGOT_USER_PASSWORD_SUCCESS,
  payload: data,
});

export const getForgotUserPasswordFail = data => ({
  type: actionTypes.GET_FORGOT_USER_PASSWORD_FAIL,
  payload: data,
});

//register
export const getRegisterData = () => ({
  type: actionTypes.GET_REGISTER_DATA,
});

export const getRegisterDataSuccess = data => ({
  type: actionTypes.GET_REGISTER_DATA_SUCCESS,
  payload: data,
});

export const getRegisterDataFail = data => ({
  type: actionTypes.GET_REGISTER_DATA_FAIL,
  payload: data,
});

export const registerUser = data => ({
  type: actionTypes.REGISTER_USER,
  payload: data,
});

export const registerUserSuccess = data => ({
  type: actionTypes.REGISTER_USER_SUCCESS,
  payload: data,
});

export const registerUserFail = data => ({
  type: actionTypes.REGISTER_USER_FAIL,
  payload: data,
});

export const updateRegister = data => ({
  type: actionTypes.UPDATE_REGISTER,
  payload: data,
});

export const getStates = data => ({
  type: actionTypes.GET_STATES,
  payload: data,
});

export const getStatesSuccess = data => ({
  type: actionTypes.GET_STATES_SUCCESS,
  payload: data,
});

export const getStatesFail = data => ({
  type: actionTypes.GET_STATES_FAIL,
  payload: data,
});

export const getCities = data => ({
  type: actionTypes.GET_CITIES,
  payload: data,
});

export const getCitiesSuccess = data => ({
  type: actionTypes.GET_CITIES_SUCCESS,
  payload: data,
});

export const getCitiesFail = data => ({
  type: actionTypes.GET_CITIES_FAIL,
  payload: data,
});

export const updateCities = data => ({
  type: actionTypes.UPDATE_CITIES,
  payload: data,
});

//product
export const getProduct = data => ({
  type: actionTypes.GET_PRODUCT,
  payload: data,
});

export const getProductSuccess = data => ({
  type: actionTypes.GET_PRODUCT_SUCCESS,
  payload: data,
});

export const getProductFail = data => ({
  type: actionTypes.GET_PRODUCT_FAIL,
  payload: data,
});

export const getProductDetail = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL,
  payload: data,
});

export const getProductDetailSuccess = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL_SUCCESS,
  payload: data,
});

export const getProductDetailFail = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL_FAIL,
  payload: data,
});

export const getCategoryList = data => ({
  type: actionTypes.GET_CATEGORY_LIST,
  payload: data,
});

export const getCategoryListSuccess = data => ({
  type: actionTypes.GET_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const getCategoryListFail = data => ({
  type: actionTypes.GET_CATEGORY_LIST_FAIL,
  payload: data,
});

export const getCategoryProductCount = data => ({
  type: actionTypes.GET_CATEGORY_PRODUCT_COUNT,
  payload: data,
});

export const getCategoryProductCountSuccess = data => ({
  type: actionTypes.GET_CATEGORY_PRODUCT_COUNT_SUCCESS,
  payload: data,
});

export const getCategoryProductCountFail = data => ({
  type: actionTypes.GET_CATEGORY_PRODUCT_COUNT_FAIL,
  payload: data,
});

export const addToCart = data => ({
  type: actionTypes.ADD_TO_CART,
  payload: data,
});

export const addToCartSuccess = data => ({
  type: actionTypes.ADD_TO_CART_SUCCESS,
  payload: data,
});

export const addToCartFail = data => ({
  type: actionTypes.ADD_TO_CART_FAIL,
  payload: data,
});

//cart
export const getCart = () => ({
  type: actionTypes.GET_CART,
});

export const getCartSuccess = data => ({
  type: actionTypes.GET_CART_SUCCESS,
  payload: data,
});

export const getCartFail = data => ({
  type: actionTypes.GET_CART_FAIL,
  payload: data,
});

export const updateCartList = data => ({
  type: actionTypes.UPDATE_CART_LIST,
  payload: data,
});

export const updateCart = data => ({
  type: actionTypes.UPDATE_CART,
  payload: data,
});

export const updateCartSuccess = data => ({
  type: actionTypes.UPDATE_CART_SUCCESS,
  payload: data,
});

export const updateCartFail = data => ({
  type: actionTypes.UPDATE_CART_FAIL,
  payload: data,
});

export const deleteCart = data => ({
  type: actionTypes.DELETE_CART,
  payload: data,
});

export const deleteCartSuccess = data => ({
  type: actionTypes.DELETE_CART_SUCCESS,
  payload: data,
});

export const deleteCartFail = data => ({
  type: actionTypes.DELETE_CART_FAIL,
  payload: data,
});

export const addDeliveryAddress = data => ({
  type: actionTypes.ADD_DELIVERY_ADDRESS,
  payload: data,
});

export const addDeliveryAddressSuccess = data => ({
  type: actionTypes.ADD_DELIVERY_ADDRESS_SUCCESS,
  payload: data,
});

export const addDeliveryAddressFail = data => ({
  type: actionTypes.ADD_DELIVERY_ADDRESS_FAIL,
  payload: data,
});

export const getReviewData = data => ({
  type: actionTypes.GET_REVIEW_DATA,
  payload: data,
});

export const getReviewDataSuccess = data => ({
  type: actionTypes.GET_REVIEW_DATA_SUCCESS,
  payload: data,
});

export const getReviewDataFail = data => ({
  type: actionTypes.GET_REVIEW_DATA_FAIL,
  payload: data,
});

export const getCartDetail = data => ({
  type: actionTypes.GET_CART_DETAIL,
  payload: data,
});

export const getCartDetailSuccess = data => ({
  type: actionTypes.GET_CART_DETAIL_SUCCESS,
  payload: data,
});

export const getCartDetailFail = data => ({
  type: actionTypes.GET_CART_DETAIL_FAIL,
  payload: data,
});

export const updateCartDetail = data => ({
  type: actionTypes.UPDATE_CART_DETAIL,
  payload: data,
});

export const updateCartDetailSuccess = data => ({
  type: actionTypes.UPDATE_CART_DETAIL_SUCCESS,
  payload: data,
});

export const updateCartDetailFail = data => ({
  type: actionTypes.UPDATE_CART_DETAIL_FAIL,
  payload: data,
});

export const updateCartBtnText = data => ({
  type: actionTypes.UPDATE_CART_BTN_TEXT,
  payload: data,
});

export const getCartCount = () => ({
  type: actionTypes.GET_CART_COUNT,
});

export const getCartCountSuccess = data => ({
  type: actionTypes.GET_CART_COUNT_SUCCESS,
  payload: data,
});

export const getCartCountFail = data => ({
  type: actionTypes.GET_CART_COUNT_FAIL,
  payload: data,
});

//account

export const getUserDetail = () => ({
  type: actionTypes.GET_USER_DETAIL,
});

export const getUserDetailSuccess = data => ({
  type: actionTypes.GET_USER_DETAIL_SUCCESS,
  payload: data,
});

export const getUserDetailFail = data => ({
  type: actionTypes.GET_USER_DETAIL_FAIL,
  payload: data,
});

export const sendMail = data => ({
  type: actionTypes.SEND_MAIL,
  payload: data,
});

export const sendMailSuccess = data => ({
  type: actionTypes.SEND_MAIL_SUCCESS,
  payload: data,
});

export const sendMailFail = data => ({
  type: actionTypes.SEND_MAIL_FAIL,
  payload: data,
});

export const mailVerification = data => ({
  type: actionTypes.MAIL_VERIFICATION,
  payload: data,
});

export const mailVerificationSuccess = data => ({
  type: actionTypes.MAIL_VERIFICATION_SUCCESS,
  payload: data,
});

export const mailVerificationFail = data => ({
  type: actionTypes.MAIL_VERIFICATION_FAIL,
  payload: data,
});

export const getUserMailVerify = () => ({
  type: actionTypes.GET_USER_MAIL_VERIFY,
});

export const getUserMailVerifySuccess = data => ({
  type: actionTypes.GET_USER_MAIL_VERIFY_SUCCESS,
  payload: data,
});

export const getUserMailVerifyFail = data => ({
  type: actionTypes.GET_USER_MAIL_VERIFY_FAIL,
  payload: data,
});

export const addUserContactDetail = data => ({
  type: actionTypes.ADD_USER_CONTACT_DETAILS,
  payload: data,
});

export const addUserContactDetailSuccess = data => ({
  type: actionTypes.ADD_USER_CONTACT_DETAILS_SUCCESS,
  payload: data,
});

export const addUserContactDetailFail = data => ({
  type: actionTypes.ADD_USER_CONTACT_DETAILS_FAIL,
  payload: data,
});

export const updateUserContactDetail = data => ({
  type: actionTypes.UPDATE_USER_CONTACT_DETAILS,
  payload: data,
});

export const updateUserContactDetailSuccess = data => ({
  type: actionTypes.UPDATE_USER_CONTACT_DETAILS_SUCCESS,
  payload: data,
});

export const updateUserContactDetailFail = data => ({
  type: actionTypes.UPDATE_USER_CONTACT_DETAILS_FAIL,
  payload: data,
});

export const addUserImportLicenseDetail = data => ({
  type: actionTypes.ADD_USER_IMPORT_LICENSE_DETAILS,
  payload: data,
});

export const addUserImportLicenseDetailSuccess = data => ({
  type: actionTypes.ADD_USER_IMPORT_LICENSE_DETAILS_SUCCESS,
  payload: data,
});

export const addUserImportLicenseDetailFail = data => ({
  type: actionTypes.ADD_USER_IMPORT_LICENSE_DETAILS_FAIL,
  payload: data,
});

export const updateUserImportLicenseDetail = data => ({
  type: actionTypes.UPDATE_USER_IMPORT_LICENSE_DETAILS,
  payload: data,
});

export const updateUserImportLicenseDetailSuccess = data => ({
  type: actionTypes.UPDATE_USER_IMPORT_LICENSE_DETAILS_SUCCESS,
  payload: data,
});

export const updateUserImportLicenseDetailFail = data => ({
  type: actionTypes.UPDATE_USER_IMPORT_LICENSE_DETAILS_FAIL,
  payload: data,
});

export const addUserDocuments = data => ({
  type: actionTypes.ADD_USER_DOCUMENTS,
  payload: data,
});

export const addUserDocumentsSuccess = data => ({
  type: actionTypes.ADD_USER_DOCUMENTS_SUCCESS,
  payload: data,
});

export const addUserDocumentsFail = data => ({
  type: actionTypes.ADD_USER_DOCUMENTS_FAIL,
  payload: data,
});

export const updateUserDocuments = data => ({
  type: actionTypes.UPDATE_USER_DOCUMENTS,
  payload: data,
});

export const updateUserDocumentsSuccess = data => ({
  type: actionTypes.UPDATE_USER_DOCUMENTS_SUCCESS,
  payload: data,
});

export const updateUserDocumentsFail = data => ({
  type: actionTypes.UPDATE_USER_DOCUMENTS_FAIL,
  payload: data,
});

export const addUserProductCategory = data => ({
  type: actionTypes.ADD_USER_PRODUCT_CATEGORY,
  payload: data,
});

export const addUserProductCategorySuccess = data => ({
  type: actionTypes.ADD_USER_PRODUCT_CATEGORY_SUCCESS,
  payload: data,
});

export const addUserProductCategoryFail = data => ({
  type: actionTypes.ADD_USER_PRODUCT_CATEGORY_FAIL,
  payload: data,
});

export const getLoginUserDetail = () => ({
  type: actionTypes.GET_LOGIN_USER_DETAIL,
});

export const getLoginUserDetailSuccess = data => ({
  type: actionTypes.GET_LOGIN_USER_DETAIL_SUCCESS,
  payload: data,
});

export const getLoginUserDetailFail = data => ({
  type: actionTypes.GET_LOGIN_USER_DETAIL_FAIL,
  payload: data,
});

export const getContactAddress = () => ({
  type: actionTypes.GET_CONTACT_ADDRESS,
});

export const getContactAddressSuccess = data => ({
  type: actionTypes.GET_CONTACT_ADDRESS_SUCCESS,
  payload: data,
});

export const getContactAddressFail = data => ({
  type: actionTypes.GET_CONTACT_ADDRESS_FAIL,
  payload: data,
});

export const updateUserName = data => ({
  type: actionTypes.UPDATE_USER_NAME,
  payload: data,
});

export const updateUserNameSuccess = data => ({
  type: actionTypes.UPDATE_USER_NAME_SUCCESS,
  payload: data,
});

export const updateUserNameFail = data => ({
  type: actionTypes.UPDATE_USER_NAME_FAIL,
  payload: data,
});

export const updatePassword = data => ({
  type: actionTypes.UPDATE_PASSWORD,
  payload: data,
});

export const updatePasswordSuccess = data => ({
  type: actionTypes.UPDATE_PASSWORD_SUCCESS,
  payload: data,
});

export const updatePasswordFail = data => ({
  type: actionTypes.UPDATE_PASSWORD_FAIL,
  payload: data,
});

//checkout
export const getCheckout = data => ({
  type: actionTypes.GET_CHECKOUT,
  payload: data,
});

export const getCheckoutSuccess = data => ({
  type: actionTypes.GET_CHECKOUT_SUCCESS,
  payload: data,
});

export const getCheckoutFail = data => ({
  type: actionTypes.GET_CHECKOUT_FAIL,
  payload: data,
});

export const placeOrder = data => ({
  type: actionTypes.PLACE_ORDER,
  payload: data,
});

export const placeOrderSuccess = data => ({
  type: actionTypes.PLACE_ORDER_SUCCESS,
  payload: data,
});

export const placeOrderFail = data => ({
  type: actionTypes.PLACE_ORDER_FAIL,
  payload: data,
});

//order
export const listOrder = data => ({
  type: actionTypes.LIST_ORDER,
  payload: data,
});

export const listOrderSuccess = data => ({
  type: actionTypes.LIST_ORDER_SUCCESS,
  payload: data,
});

export const listOrderFail = data => ({
  type: actionTypes.LIST_ORDER_FAIL,
  payload: data,
});

export const getOrderDetail = data => ({
  type: actionTypes.GET_ORDER_DETAIL,
  payload: data,
});

export const getOrderDetailSuccess = data => ({
  type: actionTypes.GET_ORDER_DETAIL_SUCCESS,
  payload: data,
});

export const getOrderDetailFail = data => ({
  type: actionTypes.GET_ORDER_DETAIL_FAIL,
  payload: data,
});

export const getAdminBeneficiaryDetail = () => ({
  type: actionTypes.GET_ADMIN_BENEFICIARY_DETAIL,
});

export const getAdminBeneficiaryDetailSuccess = data => ({
  type: actionTypes.GET_ADMIN_BENEFICIARY_DETAIL_SUCCESS,
  payload: data,
});

export const getAdminBeneficiaryDetailFail = data => ({
  type: actionTypes.GET_ADMIN_BENEFICIARY_DETAIL_FAIL,
  payload: data,
});

export const uploadPaymentDetail = data => ({
  type: actionTypes.UPLOAD_PAYMENT_DETAIL,
  payload: data,
});

export const uploadPaymentDetailSuccess = data => ({
  type: actionTypes.UPLOAD_PAYMENT_DETAIL_SUCCESS,
  payload: data,
});

export const uploadPaymentDetailFail = data => ({
  type: actionTypes.UPLOAD_PAYMENT_DETAIL_FAIL,
  payload: data,
});

export const cancelOrder = data => ({
  type: actionTypes.CANCEL_ORDER,
  payload: data,
});

export const cancelOrderSuccess = data => ({
  type: actionTypes.CANCEL_ORDER_SUCCESS,
  payload: data,
});

export const cancelOrderFail = data => ({
  type: actionTypes.CANCEL_ORDER_FAIL,
  payload: data,
});

export const getOrderCount = () => ({
  type: actionTypes.GET_ORDER_COUNT,
});

export const getOrderCountSuccess = data => ({
  type: actionTypes.GET_ORDER_COUNT_SUCCESS,
  payload: data,
});

export const getOrderCountFail = data => ({
  type: actionTypes.GET_ORDER_COUNT_FAIL,
  payload: data,
});

//export country
export const getExportCountryList = (data) => ({
  type: actionTypes.GET_EXPORT_COUNTRY_LIST,
  payload: data,
});

export const getExportCountryListSuccess = data => ({
  type: actionTypes.GET_EXPORT_COUNTRY_LIST_SUCCESS,
  payload: data,
});

export const getExportCountryListFail = data => ({
  type: actionTypes.GET_EXPORT_COUNTRY_LIST_FAIL,
  payload: data,
});

export const clearCart = () => ({
  type: actionTypes.CLEAR_CART,
});

export const clearCartSuccess = data => ({
  type: actionTypes.CLEAR_CART_SUCCESS,
  payload: data,
});

export const clearCartFail = data => ({
  type: actionTypes.CLEAR_CART_FAIL,
  payload: data,
});

export const getCurrencyRateList = () => ({
  type: actionTypes.GET_CURRENCY_RATE_LIST,
});

export const getCurrencyRateListSuccess = data => ({
  type: actionTypes.GET_CURRENCY_RATE_LIST_SUCCESS,
  payload: data,
});

export const getCurrencyRateListFail = data => ({
  type: actionTypes.GET_CURRENCY_RATE_LIST_FAIL,
  payload: data,
});

export const updateCheckoutData = data => ({
  type: actionTypes.UPDATE_CHECKOUT_DATA,
  payload: data,
});

export const updateShowTgbin = data => ({
  type: actionTypes.UPDATE_SHOW_TGBIN,
  payload: data,
});

export const alertMsgEmpty = () => ({
  type: actionTypes.ALERT_MSG_EMPTY,
});

export const updateAlertMsg = data => ({
  type: actionTypes.UPDATE_ALERT_MSG,
  payload: data,
});

export const updateApiStatus = data => ({
  type: actionTypes.UPDATE_API_STATUS,
  payload: data,
});

export const logOut = () => ({
  type: actionTypes.LOG_OUT,
});

