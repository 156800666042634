import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';

import CartShippingCostPendingList from "./CartShippingCostPendingList";

import styles from "./CartShippingCostPending.module.css"
import ItemProductList from "./ItemProductList";

const CartShippingCostPending = (props) => {

  const onDeleteCartClick = (cart_uid) => {
    let cart_uid_val = [cart_uid];
    props.onDeleteCart(cart_uid_val, 'shipping_cost_pending');
  };

  return (
    <Grid item md={12} xs={12}
          display={"flex"} alignItems={"center"} justifyContent={"center"}
    >
      <Grid container item md={11.6} xs={11.6}
            display={"flex"} alignItems={"center"} justifyContent={"center"}
      >
        <Grid item md={12} xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <TableContainer
            className={'tableContainer'}>
            <Table  stickyHeader
                    aria-label="sticky table">
              <TableHead
                className={'table'}>
                <TableRow>
                  <TableCell>Cart ID</TableCell>
                  <TableCell className={styles.dataView}>Product Details</TableCell>
                  <TableCell className={styles.dataView}>Delivery Address</TableCell>
                  <TableCell className={styles.dataView}>Shipping Details</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell className={styles.dataView}>
                    <Typography className={styles.productPriceText}>Total Product Price</Typography>
                    <Typography className={styles.productPriceExwText} pl={2}>(EXW)</Typography>
                  </TableCell>
                  <TableCell>Total Shipping Cost</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.list?.length > 0 ?
                  props?.list?.map((item, index) => {
                    return (
                      <TableRow key={index} className={styles.tableRow}>
                        <CartShippingCostPendingList
                          key={index}
                          index={index}
                          data={item}
                          onDelete={onDeleteCartClick}
                          currencyRateList={props?.currencyRateList}
                        />
                        <Grid className={styles.borderBottomLine}/>
                      </TableRow>
                    )
                  }) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </Grid>
  )
};

export default CartShippingCostPending;
